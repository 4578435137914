import { Injectable } from '@angular/core';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { ErrorNotificationComponent, ErrorNotificationParams } from './Error-Notification/errorNotification.component';
// import { TaskModalComponent, TaskModalParams } from './taskModal.component';

@Injectable({ providedIn: 'root' })
export class TaskModalsService {
    constructor(private readonly _weissmanModalService: WeissmanModalService) {
    }

    // async launchTaskModal(taskGroupsBySequence: any, modalSize: string): Promise<boolean> {
    //     const params: TaskModalParams = {
    //         tasks: taskGroupsBySequence
    //     };
    //
    //     //returns boolean for reload required
    //     return await this._weissmanModalService.showAsync(TaskModalComponent, params, modalSize);
    // }

    showErrorNotificationModal(errors: string[], warnings: string[], errorMessage: string, warningMessage?: string): void {
        const params: ErrorNotificationParams = {
            errorMessage: errorMessage,
            errors: errors,
            warningMessage: warningMessage,
            warnings: warnings,
        };

        this._weissmanModalService.showAsync(ErrorNotificationComponent, params, 'modal-lg');
    }
}
