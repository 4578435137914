<div class="return-settings-general">
    <div class="card panel-flat-color primary-panel flex-panel">
        <div class="panel-flat-header ws-flex-container-horizontal">
            <h3 class="ws-truncate ws-flex-auto">
                Batch Settings
            </h3>
            <div class="ws-flex-none header-button-container">
                <button *ngIf="!editMode && canEdit"
                        title="Edit"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
                <button title="Save"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode"
                        [disabled]="!settingsForm.valid || saving"
                        (click)="save()">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button title="Cancel"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode"
                        (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
            </div>
        </div>
        <div class="panel-flat-body">
            <div *ngIf="settingsForm" class="form-inline" [formGroup]="settingsForm">
                <label *ngIf="editMode" class="ws-font-weight-normal flex">
                    Maximum <input type="text"
                                    style="width: 50px; margin: 0 5px;"
                                   class="form-control input-number max-items-field"
                                   name="fieldMaxItemsPerCheck"
                                   formControlName="maxItemsPerCheck"
                                   [numbersOnly]="true"
                                   [decimalAllowed]="false"
                                   [negativeAllowed]="false"> payment item{{maxItemsPerCheck.value > 1 || !maxItemsPerCheck.value ? 's' : ''}} per check
                </label>
                <div *ngIf="maxItemsPerCheck.invalid && (maxItemsPerCheck.dirty || maxItemsPerCheck.touched)" class="text-danger">
                    <div *ngIf="maxItemsPerCheck.errors?.min">
                        *Value cannot be lower than 0
                    </div>
                    <div *ngIf="maxItemsPerCheck.errors?.max">
                        *Value cannot be greater than 1000
                    </div>
                </div>
                <span *ngIf="!editMode">Maximum <strong>{{this.settings.maxItemsPerCheck ?? 'unlimited'}}</strong> payment item{{(this.settings.maxItemsPerCheck > 1 || !this.settings.maxItemsPerCheck ? 's' : '')}} per check</span>
            </div>
        </div>
    </div>
</div>
