import { StateJurisdictionCommandCenterComponent } from './stateJurisdiction.component';
import { ColDef, ICellRendererParams, IFilterComp } from 'ag-grid-community';
import { AgGridFilterParams, AgGridColumns, AgGridDropdownCellEditor, AgGridDatepickerCellEditor, AgGridCurrencyCellEditor, AgGridNumberCellEditor, AgGridTextCellEditor } from '../AgGrid';
import { WeissmanDateFormat } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectRendererParams } from '../AgGrid/MultiSelectTracker';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from '../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { CellEditorValidationResult } from '../AgGrid/CellEditors/validator.interface';
import { AgGridTooltipCellRenderer, AgGridTooltipCellRendererParams } from '../AgGrid/CellRenderers/agGridTooltipCellRenderer.component';
import { AgGridYesNoFloatingFilterComponent } from '../AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';

export interface DeadlineInfo {
    propertyType: Core.PropertyTypes;
    deadlineType: Core.AppealDeadlineTypes;
    name: string;
}

export interface DescriptorColumnDefinitions {
    [key: number]: (descriptor: Core.DescriptorInfoModel, pickList?: Core.DescriptorPicklistModel[]) => ColDef;
}

const getTooltipText = (params: AgGridTooltipCellRendererParams, id: number): string => {
    const model = params.data as Compliance.AssessorCommandCenterModel;
    if (model && model.verifications && model.verifications.length) {
        const found = model.verifications.find(val => val.elementId === id);
        return found && found.verifiedByName;
    }
};

export const STATE_JURISDICTION_LIST_COLUMNS = (classRef: StateJurisdictionCommandCenterComponent): ColDef[] => [
    {
        colId: 'grid-column-multiselect',
        headerName: '',
        field: 'assessorId',
        width: AgGridColumns.selectionColumnWidth,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        editable: false,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        suppressMovable: true,
        headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
        headerComponentParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
        cellRendererFramework: AgGridMultiSelectedCellRenderer,
        cellRendererParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
    } as ColDef,
    {
        headerName: 'Assessor Abbr',
        field: 'assessorAbbr',
        width: AgGridColumns.textColumnWidth,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        editable: false,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        suppressMovable: true,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) {
                    return '';
                }
                return `#/assessor/${model.assessorId}?year=${model.taxYear}`;
            }
        } as AgGridLinkCellRendererParams,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Assessor Name',
        field: 'assessorName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Assessor PTX ID',
        field: 'assessorPtxId',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'State',
        field: 'stateAbbr',
        width: AgGridColumns.textColumnSmallWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Full State Name',
        field: 'stateName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Assessor Website',
        field: 'assessorWebsite',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'view-assessor-website',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const row = params.data as Compliance.AssessorCommandCenterModel;
                if (!row || !row.assessorWebsite) {
                    return '';
                }
                if (!row.assessorWebsite.toLowerCase().startsWith('http')) {
                    return `http://${  row.assessorWebsite}`;
                }
                return row.assessorWebsite;
            }
        } as AgGridLinkCellRendererParams
    },
    {
        headerName: 'Assessor Email',
        field: 'assessorEmail',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.send-email-assessor',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const row = params.data as Compliance.AssessorCommandCenterModel;
                if (!row || !row.assessorEmail) {
                    return '';
                }
                return `mailto:${row.assessorEmail}`;
            }
        } as AgGridLinkCellRendererParams
    },
    {
        headerName: 'Default Collectors',
        field: 'defaultCollectors',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Available Collectors',
        field: 'availableCollectors',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'PP Due Date',
        field: 'compliancePPDeadline',
        width: AgGridColumns.dateColumnWidth,
        editable: () => classRef.canEdit,
        cellEditorFramework: AgGridDatepickerCellEditor,
        cellEditorParams: {
            cellFocusLost: classRef.updateDeadline.bind(classRef),
            canReset: true,
            hasOverride: (x) => {
                const model = x.data as Compliance.AssessorCommandCenterModel;
                return !!(model && model.compliancePPAssessorDeadlineIsForTaxYear);
            },
            args: <DeadlineInfo>{
                deadlineType: Core.AppealDeadlineTypes.Compliance,
                propertyType: Core.PropertyTypes.Personal,
                name: 'PP Due Date'
            }
        },
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!(model && model.compliancePPDeadline)) {
                return '';
            }

            return WeissmanDateFormat(model.compliancePPDeadline, true, 'stored');
        },
        cellClass: x => {
            const model = x.data as Compliance.AssessorCommandCenterModel;
            return (model && model.compliancePPAssessorDeadlineIsForTaxYear) ? 'ws-override' : null;
        }
    },
    {
        headerName: 'PP Appeal Date',
        field: 'appealPPDeadline',
        width: AgGridColumns.dateColumnWidth,
        editable: () => classRef.canEdit,
        cellEditorFramework: AgGridDatepickerCellEditor,
        cellEditorParams: {
            cellFocusLost: classRef.updateDeadline.bind(classRef),
            canReset: true,
            hasOverride: (x) => {
                const model = x.data as Compliance.AssessorCommandCenterModel;
                return !!(model && model.appealPPAssessorDeadlineIsForTaxYear);
            },
            args: <DeadlineInfo>{
                deadlineType: Core.AppealDeadlineTypes.Appeal,
                propertyType: Core.PropertyTypes.Personal,
                name: 'PP Appeal Date'
            }
        },
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!(model && model.appealPPDeadline)) {
                return '';
            }

            return WeissmanDateFormat(model.appealPPDeadline, true, 'stored');
        },
        cellClass: x => {
            const model = x.data as Compliance.AssessorCommandCenterModel;
            return (model && model.appealPPAssessorDeadlineIsForTaxYear) ? 'ws-override' : null;
        }
    },
    {
        headerName: 'RE Appeal Date',
        field: 'appealREDeadline',
        width: AgGridColumns.dateColumnWidth,
        editable: () => classRef.canEdit,
        cellEditorFramework: AgGridDatepickerCellEditor,
        cellEditorParams: {
            cellFocusLost: classRef.updateDeadline.bind(classRef),
            canReset: true,
            hasOverride: (x) => {
                const model = x.data as Compliance.AssessorCommandCenterModel;
                return !!(model && model.appealREAssessorDeadlineIsForTaxYear);
            },
            args: <DeadlineInfo>{
                deadlineType: Core.AppealDeadlineTypes.Appeal,
                propertyType: Core.PropertyTypes.RealEstate,
                name: 'RE Appeal Date'
            }
        },
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!(model && model.appealREDeadline)) {
                return '';
            }

            return WeissmanDateFormat(model.appealREDeadline, true, 'stored');
        },
        cellClass: x => {
            const model = x.data as Compliance.AssessorCommandCenterModel;
            return (model && model.appealREAssessorDeadlineIsForTaxYear) ? 'ws-override' : null;
        }
    },
    {
        headerName: 'CA Appeal Date',
        field: 'appealCADeadline',
        width: AgGridColumns.dateColumnWidth,
        editable: () => classRef.canEdit,
        cellEditorFramework: AgGridDatepickerCellEditor,
        cellEditorParams: {
            cellFocusLost: classRef.updateDeadline.bind(classRef),
            canReset: true,
            hasOverride: (x) => {
                const model = x.data as Compliance.AssessorCommandCenterModel;
                return !!(model && model.appealCAAssessorDeadlineIsForTaxYear);
            },
            args: <DeadlineInfo>{
                deadlineType: Core.AppealDeadlineTypes.Appeal,
                propertyType: Core.PropertyTypes.CentrallyAssessed,
                name: 'CA Appeal Date'
            }
        },
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!(model && model.appealCADeadline)) {
                return '';
            }

            return WeissmanDateFormat(model.appealCADeadline, true, 'stored');
        },
        cellClass: x => {
            const model = x.data as Compliance.AssessorCommandCenterModel;
            return (model && model.appealCAAssessorDeadlineIsForTaxYear) ? 'ws-override' : null;
        }
    },
    {
        headerName: 'MN Appeal Date',
        field: 'appealMNDeadline',
        width: AgGridColumns.dateColumnWidth,
        editable: () => classRef.canEdit,
        cellEditorFramework: AgGridDatepickerCellEditor,
        cellEditorParams: {
            cellFocusLost: classRef.updateDeadline.bind(classRef),
            canReset: true,
            hasOverride: (x) => {
                const model = x.data as Compliance.AssessorCommandCenterModel;
                return !!(model && model.appealMNAssessorDeadlineIsForTaxYear);
            },
            args: <DeadlineInfo>{
                deadlineType: Core.AppealDeadlineTypes.Appeal,
                propertyType: Core.PropertyTypes.Mineral,
                name: 'MN Appeal Date'
            }
        },
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!(model && model.appealMNDeadline)) {
                return '';
            }

            return WeissmanDateFormat(model.appealMNDeadline, true, 'stored');
        },
        cellClass: x => {
            const model = x.data as Compliance.AssessorCommandCenterModel;
            return (model && model.appealMNAssessorDeadlineIsForTaxYear) ? 'ws-override' : null;
        }
    },
    {
        headerName: 'PP Return Forms',
        field: 'ppReturnForms',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        cellClass: (params: ICellRendererParams): string => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            return (model && model.ppReturnFormsPrimaryCount > 1) ? 'cell-validation-warning' : '';
        }
    },
    {
        headerName: 'Appeal Forms',
        field: 'appealForms',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Default Address',
        field: 'defaultAddress',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Default Address Type',
        field: 'defaultAddressCorrespondenceTypeName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        editable: () => classRef.canEdit,
        cellEditorFramework: AgGridDropdownCellEditor,
        cellEditorParams: {
            getOptions: () => [
                { name: 'Appeal Filing', value: 'Appeal Filing' },
                { name: 'General', value: 'General' },
                { name: 'PP Return', value: 'PP Return' }
            ],
            cellFocusLost: classRef.updateDefaultAddressType.bind(classRef)
        },
        hide: true
    },
    {
        headerName: 'General Address',
        field: 'generalAddress',
        width: AgGridColumns.textColumnLargeWidth,
        hide: true,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return; }
                const text: string[] = [];
                const verification = model.verifications.find(v => v.elementId === model.generalAddressVerificationElementId);

                if (verification && !verification.qcDate && verification.verifiedDate) {
                    text.push('Pending QC');
                } else if (model.generalAddress && !verification) {
                    text.push('Verification required');
                }
                if (model.generalAddressCount > 1) {
                    text.push(`${(text.length) ? 'd' : 'D'  }uplicate General Address exists`);
                }

                return text.join(' and ');
            }
        },
        cellClass: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!model) { return; }
            return classRef.getAddressCellStyle(model.verifications, model.generalAddressVerificationElementId, model.generalAddress, model.generalAddressChangeDate, model.generalAddressCount);
        }
    },
    {
        headerName: 'V. General Address',
        field: 'generalAddressVerification',
        width: AgGridColumns.dateColumnWidth,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return; }
                return getTooltipText(params, model.generalAddressVerificationElementId);
            }
        },
        valueFormatter: (params) => WeissmanDateFormat(params.value, true),
        valueGetter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (model && model.verifications && model.verifications.length) {
                const found = model.verifications.find(val => val.elementId === model.generalAddressVerificationElementId);
                return found && found.verifiedDate;
            }
        }
    },
    {
        headerName: 'PP Return Address',
        field: 'ppReturnAddress',
        width: AgGridColumns.textColumnLargeWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return; }
                const text: string[] = [];
                const verification = model.verifications.find(v => v.elementId === model.ppReturnAddressVerificationElementId);

                if (verification && !verification.qcDate && verification.verifiedDate) {
                    text.push('Pending QC');
                } else if (model.ppReturnAddress && !verification) {
                    text.push('Verification required');
                }
                if (model.ppReturnAddressCount > 1) {
                    text.push(`${(text.length) ? 'd' : 'D'  }uplicate PP Return Address exists`);
                }

                return text.join(' and ');
            }
        },
        cellClass: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!model) { return; }
            return classRef.getAddressCellStyle(model.verifications, model.ppReturnAddressVerificationElementId, model.ppReturnAddress, model.ppReturnAddressChangeDate, model.ppReturnAddressCount);
        }
    },
    {
        headerName: 'V. PP Return Address',
        field: 'ppReturnAddressVerification',
        width: AgGridColumns.dateColumnWidth,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return; }
                return getTooltipText(params, model.ppReturnAddressVerificationElementId);
            }
        },
        valueFormatter: (params) => WeissmanDateFormat(params.value, true),
        valueGetter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (model && model.verifications && model.verifications.length) {
                const found = model.verifications.find(val => val.elementId === model.ppReturnAddressVerificationElementId);
                return found && found.verifiedDate;
            }
        }
    },
    {
        headerName: 'Appeal Filing Address',
        field: 'appealFilingAddress',
        width: AgGridColumns.textColumnLargeWidth,
        hide: true,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return; }
                const text: string[] = [];
                const verification = model.verifications.find(v => v.elementId === model.appealFilingAddressVerificationElementId);

                if (verification && !verification.qcDate && verification.verifiedDate) {
                    text.push('Pending QC');
                } else if (model.appealFilingAddress && !verification) {
                    text.push('Verification required');
                }
                if (model.appealFilingAddressCount > 1) {
                    text.push(`${(text.length) ? 'd' : 'D'  }uplicate Appeal Filing Address exists`);
                }

                return text.join(' and ');
            }
        },
        cellClass: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!model) { return; }
            return classRef.getAddressCellStyle(model.verifications, model.appealFilingAddressVerificationElementId, model.appealFilingAddress, model.appealFilingAddressChangeDate, model.appealFilingAddressCount);
        }
    },
    {
        headerName: 'V. Appeal Filing Address',
        field: 'appealFilingAddressVerification',
        width: AgGridColumns.dateColumnWidth,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return; }
                return getTooltipText(params, model.appealFilingAddressVerificationElementId);
            }
        },
        valueFormatter: (params) => WeissmanDateFormat(params.value, true),
        valueGetter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (model && model.verifications && model.verifications.length) {
                const found = model.verifications.find(val => val.elementId === model.appealFilingAddressVerificationElementId);
                return found && found.verifiedDate;
            }
        }
    },
    {
        headerName: 'Pending QC',
        field: 'isPendingQC',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.booleanFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
        valueFormatter: (params) => {
            if (params.value === true) {
                return 'Yes';
            }
            else if (params.value === false) {
                return 'No';
            } else {
                return null;
            }
        },
        hide: true
    },
    {
        headerName: 'Verified Date',
        field: 'minVerifiedDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            if (!(model && model.minVerifiedDate)) {
                return '';
            }

            return WeissmanDateFormat(model.minVerifiedDate, true);
        },
        hide: true
    }
];

const getDescriptorValue =
    (model: Compliance.AssessorCommandCenterModel, descriptor: Core.DescriptorInfoModel): any => {
        if (model && model.descriptorValues && model.descriptorValues.length) {
            const found = model.descriptorValues.find(val => val.descriptorId === descriptor.descriptorId);
            return found && found.value;
        }
    };

export const BASE_DYNAMIC_DEFINITIONS: DescriptorColumnDefinitions = {
    [Core.DescriptorFieldTypes.Currency]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.numericColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridCurrencyCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { }
            },
            hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
            filter: 'agNumberColumnFilter',
            filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            valueFormatter: (params) => (+params.value) ? `$ ${(+params.value).toFixed(2)}` : '',
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Date]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.dateColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridDatepickerCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { },
                validator: (value: Date): CellEditorValidationResult => {
                    const year = value.getFullYear();
                    const validation = getDescriptor().validation;
                    const isValid = validation
                        && (validation.minValue ? year >= validation.minValue : true)
                        && (validation.maxValue ? year <= validation.maxValue : true);
                    let message = 'Year must be ';
                    message += (validation.minValue) && `> ${validation.minValue - 1}`;
                    message += (validation.minValue && validation.maxValue) && ' and ';
                    message += (validation.maxValue) && `< ${validation.maxValue + 1}`;
                    return { isValid, validationMessage: message };
                }
            },
            hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
            filter: 'agDateColumnFilter',
            filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
            valueFormatter: (params) => WeissmanDateFormat(params.value, true),
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Number]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.numericColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridNumberCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { },
                validator: (value: number): CellEditorValidationResult => {
                    const validation = getDescriptor().validation;
                    const isValid = validation
                        && (validation.minValue ? value >= validation.minValue : true)
                        && (validation.maxValue ? value <= validation.maxValue : true);
                    let message = 'Value must be ';
                    message += (validation.minValue) && `> ${validation.minValue - 1}`;
                    message += (validation.minValue && validation.maxValue) && ' and ';
                    message += (validation.maxValue) && `< ${ validation.maxValue + 1 }`;
                    return { isValid, validationMessage: message};
                }
            },
            hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
            filter: 'agNumberColumnFilter',
            filterParams: AgGridFilterParams.numberFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Picklist]: (descriptor, picklist) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.selectionColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridDropdownCellEditor,
            cellEditorParams: {
                getOptions: () => {
                    return picklist.reduce((acc, x) => {
                        if (x.groupId === getDescriptor().picklistGroupId) {
                            acc.push({ name: x.name, value: x.name });
                        }
                        return acc;
                    }, []);
                },
                cellFocusLost: () => { }
            },
            hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Text]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.textColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridTextCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { },
                validator: (value: string): CellEditorValidationResult => {
                    const validation = getDescriptor().validation;
                    const isValid = validation
                        && (validation.maxLength ? value.length <= validation.maxLength : true);
                    const message = `Max characters: ${validation.maxLength}`;
                    return { isValid, validationMessage: message };
                },
                maxLength: descriptor.validation && descriptor.validation.maxLength
            },
            hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.YesNo]: (descriptor) => {
        const getDescriptor = () => descriptor;// create closure for later access
        let headerParams = {};
        // #268558 - PP Original Form Required needs tooltip
        if (descriptor.name === 'PP Original Form Required') {
            headerParams = {
                headerTooltipText: () => {
                    return 'state-jurisdiction-command-center.pp-original-form-required';
                }
            };
        }
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.checkboxColumnMinWidth,
            headerComponentParams: headerParams,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridDropdownCellEditor,
            cellEditorParams: {
                getOptions: () => [{ name: 'Yes', value: true }, {name: 'No', value: false}],
                cellFocusLost: () => { }
            },
            hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
            filter: 'agYesNoColumnFilter',
            filterParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
            floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
            floatingFilterComponentParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            valueFormatter: (params) => {
                if (params.value || params.value === false) {
                    return (params.value) ? 'Yes' : 'No';
                } else {
                    return '';
                }
            },
            cellClass: () => ''
        };
    }
};

export const BASE_VERIFICATION_COLUMN = (descriptor: Core.DescriptorInfoModel): ColDef => {
    const getDescriptor = () => descriptor; // create closure for later access
    return {
        headerName: `V. ${descriptor.name}`,
        field: `v.${descriptor.descriptorId}`,
        width: AgGridColumns.dateColumnWidth,
        hide: true,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        cellRendererFramework: AgGridTooltipCellRenderer,
        cellRendererParams: {
            getTooltipText: (params) => getTooltipText(params, getDescriptor().descriptorId)
        },
        valueFormatter: (params) => WeissmanDateFormat(params.value, true),
        valueGetter: (params) => {
            const model = params.data as Compliance.AssessorCommandCenterModel;
            const desc = getDescriptor();
            if (model && model.verifications && model.verifications.length) {
                const found = model.verifications.find(val => val.elementId === desc.descriptorId);
                return found && found.verifiedDate;
            }
        }
    };
};
