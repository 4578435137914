import { Injectable } from '@angular/core';

export interface SearchFilterOr {
    text: string;
    operatorId: number;
}

export interface SearchFilter {
    name: string;
    field: string;
    associatedWith: string;
    default: boolean;
    dropdown: boolean;
    nullable?: boolean;
    numeric?: boolean;
    text?: string;
    ors?: SearchFilterOr[];
}

@Injectable({ providedIn: 'root' })
export class SearchFiltersService {
    getAll(advanced: boolean): SearchFilter[] {
        return this._initializeFilters(advanced);
    }

    getActive(advanced: boolean): SearchFilter[] {
        return this._initializeFilters(advanced).filter(x => x.default);
    }

    getInactive(advanced: boolean): SearchFilter[] {
        return this._initializeFilters(advanced).filter(x => !x.default);
    }

    private _initializeFilters(advanced: boolean): SearchFilter[] {
        let filters: SearchFilter[] = [{
            name: 'Company Name',
            field: 'site.company.companyName',
            associatedWith: 'company',
            nullable: true,
            numeric: false,
            default: true,
            dropdown: false
        }, {
            name: 'Site Name',
            field: 'site.name',
            associatedWith: 'site',
            nullable: true,
            numeric: false,
            default: true,
            dropdown: false
        }, {
            name: 'Parcel Acct Num',
            field: 'acctNum',
            associatedWith: 'parcel',
            nullable: false,
            numeric: false,
            default: true,
            dropdown: false
        }, {
            name: 'Site Address1',
            field: 'site.address1',
            associatedWith: 'site',
            dropdown: false,
            nullable: true,
            numeric: false,
            default: false
        }, {
            name: 'Site City',
            field: 'site.city',
            associatedWith: 'site',
            dropdown: false,
            nullable: true,
            numeric: false,
            default: false
        }, {
            name: 'Site Zip Code',
            field: 'site.postalCode',
            associatedWith: 'site',
            dropdown: false,
            nullable: true,
            numeric: false,
            default: false
        }, {
            name: 'Property Type',
            field: 'propertyTypeID',
            associatedWith: 'parcel',
            dropdown: true,
            nullable: true,
            numeric: false,
            default: false
        }, {
            name: 'State Abbr',
            field: 'site.stateAbbr',
            associatedWith: 'state',
            dropdown: true,
            nullable: true,
            numeric: false,
            default: false
        }];

        filters = advanced ? filters.map(this._initializeOrs) : filters.map(this._addEmptyTexts);

        return filters;
    }

    private _initializeOrs(filter: SearchFilter): SearchFilter {
        filter.ors = [{
            text: '',
            operatorId: 0
        }];

        return filter;
    }

    private _addEmptyTexts(filter: SearchFilter): SearchFilter {
        filter.text = '';

        return filter;
    }
}
