import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { PAYMENT_BATCH_COMMAND_CENTER_HELP } from '../Command-Center/paymentBatch.component.help';
import { PaymentBatchService } from '../paymentBatch.service';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { TaskService } from '../../Task/task.service.upgrade';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { PaymentBatchAddPaymentsModalComponent } from './Add-Payments-Modal/addPaymentsToBatchModal.component';
import { PaymentBatchPaymentsGridComponent } from './Payments-Grid/paymentBatchPaymentsGrid.component';
import { EntityTypeIds } from '../../constants.new';
import { ToastrService } from 'ngx-toastr';
import { PaymentBatchTaskExceptionModalComponent } from './Task-Exception-Modal/paymentBatchTaskExceptionModal.component';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as _ from 'lodash';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { uniqBy } from 'lodash';
import { PaymentBatchPackagesComponent } from './Packages-Table/paymentBatchPackages.component';

enum TabIdxs {
    Payments = 0,
    Packages = 1,
    Output = 2
}

interface RefreshComponents {
    payments: boolean;
    packages: boolean;
    status: boolean;
}


@Component({
    selector: 'payment-batch-details',
    templateUrl: './paymentBatchDetails.component.html',
    styleUrls: ['./paymentBatchDetails.component.scss']
    // encapsulation: ViewEncapsulation.None
})
export class PaymentBatchDetailsComponent implements OnInit {
    constructor(
    private readonly _routerService: UpgradeNavigationServiceHandler,
    private readonly _helpService: HelpService,
    private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
    private readonly _modalService: WeissmanModalService,
    private readonly _taskService: TaskService,
    private readonly _busyIndicatorService: BusyIndicatorService,
    private readonly _toastr: ToastrService,
    private readonly _messageModalService: MessageModalService,
    private readonly _featureFlagService: FeatureFlagsService,
    private readonly _paymentBatchService: PaymentBatchService){}


    @ViewChild('paymentBatchDetailsGrid') paymentBatchDetailsGrid: PaymentBatchPaymentsGridComponent;
    @ViewChild('paymentBatchPackages') paymentBatchPackages: PaymentBatchPackagesComponent;
    @ViewChild('paymentDetailsTabs', { static: false }) paymentDetailsTabs?: TabsetComponent;

    isInitialized: boolean = true;
    pageTitle: string = 'Batch';
    breadcrumbs: BreadCrumb[] = [];
    attachmentModalData: AttachmentModalData;
    commentModalData: CommentModalData;
    hasComments: boolean = false;
    hasAttachments: boolean = false;
    paymentBatch: Core.PaymentBatchModel;
    editingFundedAmount: boolean = false;
    savingFundedAmount: boolean = false;
    workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;
    tabIndex: number;
    rowCount: number;
    settingsEditing: boolean = false;

    private _originalFundedAmount: number;
    private _errors: string[] = [];

    async ngOnInit(): Promise<void> {
        this.breadcrumbs = [
            {
                name: 'Payment Batch Command Center',
                target: 'paymentBatchCommandCenter',
                options: { }
            }
        ];
        if (this._upgradeNavigationServiceHandler.getQuerystringParam('tabIndex'))
        this.tabIndex = JSON.parse(this._upgradeNavigationServiceHandler.getQuerystringParam('tabIndex'));

        this._helpService.setContent(PAYMENT_BATCH_COMMAND_CENTER_HELP);
        await this.loadPaymentBatch();
        await this.loadPaymentBatchDetailsWorkflowStatus();

        if (this.tabIndex)
         this.paymentDetailsTabs.tabs[this.tabIndex].active = true;
    }

    get isTransmittal(): boolean {
        return this.paymentBatch?.batchType === Core.PaymentBatchTypeEnum.Transmittal;
    }

    get isBillPay(): boolean {
        return this.paymentBatch?.batchType === Core.PaymentBatchTypeEnum.BillPay;
    }

    launchTaskModal(event: any): void {
        event.stopPropagation();
        this._taskService.launchTaskModal(this.paymentBatch.paymentBatchId, EntityTypeIds.PAYMENT_BATCH, true);
    }

    goBack(): void {
       const indexedArray: {[key: string]: any} = {
        companyId: this.paymentBatch.topLevelCompanyId,
        companyName: this.paymentBatch.topLevelCompanyName
       };
       const data = JSON.stringify(indexedArray);
       localStorage.setItem('PBCC', data);
       this._routerService.go('paymentBatchCommandCenter', null);
    }

    editFundedAmount(): void {
        this.editingFundedAmount = true;
        this._originalFundedAmount = this.paymentBatch.fundedAmount;
    }
    cancelEditFundedAmount(): void {
        this.editingFundedAmount = false;
        this.paymentBatch.fundedAmount = this._originalFundedAmount;
    }
    async saveFundedAmount(): Promise<void> {
        this.savingFundedAmount = true;
        try {
            await this._paymentBatchService.saveFundedAmount(this.paymentBatch.paymentBatchId, this.paymentBatch.fundedAmount);
            this.editingFundedAmount = false;
        } finally {
            this.savingFundedAmount = false;
        }
    }

    async launchAddPaymentsModal(): Promise<void> {
        const params = this.paymentBatch.paymentBatchId;
        const result = await this._modalService.showAsync(PaymentBatchAddPaymentsModalComponent, params, 'modal-xl modal-no-max-width');

        if(result?.succeeded) {
            this.refreshPage({status: true, payments: true, packages: false});
        }
    }

    async loadPaymentBatch(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            const paymentBatchId = +this._upgradeNavigationServiceHandler.getQuerystringParam('paymentBatchId');
            this.paymentBatch = await this._paymentBatchService.getPaymentBatchDetailById(paymentBatchId);
            this.attachmentModalData = {
                entityType: 'Payment',
                entityName: this.paymentBatch.description,
                disableYears: false,
                singleYear: false,
                entityData: {
                    typeId: Core.EntityTypes.PaymentBatch,
                    id: this.paymentBatch.paymentBatchId,
                    name: this.paymentBatch.description
                },
                readOnly: false,
            } as AttachmentModalData;

            this.commentModalData = {
                entityID: this.paymentBatch.paymentBatchId,
                entityTypeID: Core.EntityTypes.PaymentBatch,
                entityName: this.paymentBatch.description,
                canEdit: true,
                description: null
            } as CommentModalData;
            this.hasAttachments = this.paymentBatch.hasAttachments;
            this.hasComments = this.paymentBatch.hasComments;
        }
        finally {
            busyRef.hide();
        }
    }

    async loadPaymentBatchDetailsWorkflowStatus(): Promise<void>{
        this.workflowStatus = await this._paymentBatchService.getPaymentBatchDetailsWorkflowStatus(this.paymentBatch.paymentBatchId);
    }

    refreshPage({payments, packages, status}: RefreshComponents, bypassTabSwitch?: boolean): void {

        // Tab switching
        if (packages) {
            if(this.paymentDetailsTabs.tabs[TabIdxs.Packages].active) {
                this.paymentBatchPackages.loadData();
            } else {
                !bypassTabSwitch && (this.paymentDetailsTabs.tabs[TabIdxs.Packages].active = true);
            }
        } else if (payments) {
            !bypassTabSwitch && (this.paymentDetailsTabs.tabs[TabIdxs.Payments].active = true);
        }

        // Loading data
        this.loadPaymentBatch();

        if (payments) {
            this.paymentBatchDetailsGrid.refresh();
        }
        if (status) {
            this.loadPaymentBatchDetailsWorkflowStatus();
        }
    }

    async advanceTask() {
        try {
            const message = `Are you sure you wish to ${this.workflowStatus?.taskAdvanceName}`;
            await this._messageModalService.confirm(message, 'Confirm Completion' );
        }
        catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Advancing task...' });
        try {
            const updateModel = { taskId: this.workflowStatus.taskID };
            const {
                succeeded,
                refreshStatus: status,
                refreshPayments: payments,
                refreshPackages: packages,
                errorMessage,
                paymentErrors,
                packageIds
            } = await this._paymentBatchService.advanceTask(this.paymentBatch.paymentBatchId, updateModel);

            if (succeeded) {
                this.refreshPage({status, payments, packages});

                // If this is a Transmittal advance, we know that draft Payment Packages have been
                // created, so we want to navigate to the Bill Payment Packages page to finalize.
                if (packageIds && packageIds.length) {
                    const newKey = (new Date()).getTime();
                    sessionStorage[`PaymentPackageDrafts${  newKey}`] = JSON.stringify(packageIds);
                    const params = { draftID: newKey, paymentBatchId: this.paymentBatch.paymentBatchId, taskId: this.workflowStatus.taskID} as any;
                    if(this.workflowStatus.taskAdvanceName === 'Prepare Preliminary AP Feed') {
                        params.isPrelimApFeed = true;
                    }
                    this._routerService.go('paymentPackagesDraft', params);
                }
            } else {
                // TODO handle PaymentErrors
                if (errorMessage) {
                    this._toastr.error(errorMessage);
                    return;
                }

                if(paymentErrors) {
                    this.paymentBatchDetailsGrid.addPaymentErrors(paymentErrors);
                    const uniqueErrorPayments = uniqBy(paymentErrors, 'entityID');
                    this._toastr.error('Hover over payment error icon for more details.', `${uniqueErrorPayments.length} payments have errors.`);
                }


            }
        } finally {
            busyRef.hide();
        }
    }

    async launchExceptionModal() {
        const params = {
            workflowStatus: this.workflowStatus,
            paymentBatchId: this.paymentBatch.paymentBatchId
        };

        const result = await this._modalService.showAsync(PaymentBatchTaskExceptionModalComponent, params, 'modal-md');

        if (!result) return;

        if (result.succeeded) {
            const {refreshStatus: status, refreshPayments: payments, refreshPackages: packages} = result;
            this.refreshPage({status, payments, packages});
        } else {
            // TODO handle result.PaymentErrors
            this._toastr.error(result.errorMessage);
        }
    }

    // async draftTransmittalPackage(): Promise<void>{
    //     const updateModel = {taskId: this.workflowStatus.taskID};
    //     const busyRef = this._busyIndicatorService.show({ message: 'Drafting transmittal...' });
    //     const result = await this._paymentBatchService.draftTransmittalPackage(this.paymentBatch.paymentBatchId, updateModel);
    //     this.processResultPromise(result.operationResult).then(() => {
    //         const newKey = (new Date()).getTime();
    //         sessionStorage[`PaymentPackageDrafts${  newKey}`] = JSON.stringify(result.packageIDs);
    //         this._routerService.go('paymentPackagesDraft', { draftID: newKey, isTransmittal: this.isTransmittal, paymentBatchId: this.paymentBatch.paymentBatchId, taskId: this.workflowStatus.taskID});
    //         busyRef.hide();
    //     }, errorResult => {
    //         const errors = _.uniq(_.map(_.filter(errorResult.errors, function (error) {
    //                     return error.errorMessage;
    //                 }), 'errorMessage'));

    //         const warnings = _.uniq(_.map(_.filter(errorResult.errors, function (error) {
    //                 return !error.errorMessage;
    //             }), 'warningMessage'));

    //         this._taskService.showErrorNotificationModal(errors, warnings,
    //                     'The following error or errors were encountered attempting to create bill ' +
    //                     'payment packages; please attempt to correct these errors and try again',
    //                     'The following issue or issues were encountered attempting to create bill ' +
    //                     'payment packages; tasks which had issues will require re-review before ' +
    //                     'they can be processed');
    //         busyRef.hide();
    //         }

    //     );
    // }

    // async processResultPromise(processingResult: any): Promise<any> {
    //     const permissionErrorMessage = 'User does not have permission on these items.';
    //     const genericErrorMessage = 'An error has occured processing these tasks.';
    //     const concurrencyErrorMessage = 'Items have been modified since the search was performed. Please refresh the search result.';

    //     return new Promise((resolve, reject) => {
    //         const successfulResults = _.filter(processingResult, function (result){
    //             return !result.errorMessage && !result.warningMessage && !result.isConcurrencyCheckFailed;
    //         });
    //         const errorResults = _.filter(processingResult, function (result) {
    //             return result.errorMessage || result.warningMessage || result.isConcurrencyCheckFailed;
    //         });

    //         this.markErrorResults(successfulResults, errorResults);

    //         if (successfulResults.length < 1) {
    //             if (errorResults.length < 1) {
    //                 console.log('Processing result successfully retrieved from server but could not be parsed');
    //                 reject({
    //                     message: genericErrorMessage,
    //                     errors: errorResults
    //                 });
    //             }
    //             else if (_.every(errorResults, function (result) { return !result.isAuthorized; })) {
    //                 reject({
    //                     message: permissionErrorMessage,
    //                     errors: errorResults
    //                 });
    //             }
    //             else if (_.every(errorResults, function (result) { return result.isConcurrencyCheckFailed; })) {
    //                 reject({
    //                     message: concurrencyErrorMessage,
    //                     errors: errorResults
    //                 });
    //             }
    //             else {
    //                 console.log(`Processing result successfully retrieved from server, but processing indicated errors (taskID: error):\n${
    //                     _.map(processingResult, (result: any) => {
    //                     return `${result.taskID  }: ${  result.errorMessage || result.warningMessage}`;
    //                 }).join('\n')}`);
    //                 reject({
    //                 message: genericErrorMessage,
    //                 errors: errorResults
    //                 });
    //             }

    //         } else {
    //             const authReasons = [];
    //             _.forEach(successfulResults, function (result) {
    //                 authReasons.push(`${result.taskID  }: ${  result.authorizationReason}`);
    //             });

    //             if (authReasons.length > 0) {
    //                 console.log('Task authorization reasons (taskID: reason):\n', authReasons.join('\n'));
    //             }
    //             resolve({
    //                 successfulResults: successfulResults,
    //                 errorResults: errorResults
    //             });
    //         }
    //     });
    // }

    // async markErrorResults(successfulResults, errorResults) {
    //     const consoleErrors = [];
    //     _.forEach(successfulResults, result => {
    //         this._errors = _.reject(this._errors, {taskID: result.taskID});
    //     });
    //     _.forEach(errorResults, result => {
    //         consoleErrors.push(`${result.taskID  }: ${  result.errorMessage || result.warningMessage}`);
    //         this._errors.push(result);
    //     });
    //     if (consoleErrors.length > 0) {
    //         console.log(`Processing errors (taskID: error):\n${  consoleErrors.join('\n')}`);
    //     }
    // }

    async recallBatch() {
        try {
            const message = `Are you sure you want to recall the batch #${this.paymentBatch.batchNo}`;
            await this._messageModalService.confirm(message, 'Confirm Recall');
        }
        catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Recalling' });

        try {
            const updateModel = {taskId: this.workflowStatus.taskID};
            const {
                refreshStatus: status,
                refreshPayments: payments,
                refreshPackages: packages
            } = await this._paymentBatchService.rescindTask(this.paymentBatch.paymentBatchId, updateModel);

            this.refreshPage({status, payments, packages});
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve(true);
    }
}
