import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { HelpModule } from '../../../UI-Lib/Help-Tooltip/help.module';
import { UILibModule } from '../../../UI-Lib/UILib.module';
import { AutoPopulateInputComponent } from './IDP-Auto-Populate-Input/autoPopulateInput.component';
import { QuickSearchCriteriaComponent } from './quickSearchCriteria.component';

@NgModule({
    imports: [
        CommonModule,
        UILibModule,
        FormsModule,
        BsDropdownModule,
        HelpModule,
        WeissmanSharedModule
    ],
    declarations: [
        QuickSearchCriteriaComponent,
        AutoPopulateInputComponent
    ],
    exports: [
        QuickSearchCriteriaComponent
    ]
})
export class QuickSearchCriteriaModule { }
