import { Component, Input } from '@angular/core';

interface IdpCardDetail {
    name: string;
    value: any;
    type?: string;
}

export interface IdpCard {
    title: string;
    total: IdpCardDetail;
    details: IdpCardDetail[];
}

@Component({
    selector: 'idp-detail-card',
    templateUrl: './idpDetailCard.component.html',
    styleUrls: ['./idpDetailCard.component.scss']
})
export class IdpDetailCardComponent {
    @Input() detail: IdpCard;
}
