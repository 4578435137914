import { Injectable, ComponentFactoryResolver, ComponentRef, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { IBusyIndicatorConfig, BusyIndicatorConfig } from './Models/busyIndicatorConfig';
import { BusyIndicatorRef } from './busyIndicatorRef';
import { BusyIndicatorComponent } from './busyIndicator.component';
import { BusyIndicatorMessageIdService } from './busyIndicatorMessageId.service';
import { SnackBarService } from './SnackBar/snackBar.service';
import { take, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BusyIndicatorService {
    constructor(
        private readonly _componentFactoryResolver: ComponentFactoryResolver,
        private readonly _applicationRef: ApplicationRef,
        private readonly _injector: Injector,
        private readonly _busyIndicatorMessageIdService: BusyIndicatorMessageIdService,
        private readonly _snackBarService: SnackBarService
    ) { }

    private _globalBusyIndicatorComponentRef: ComponentRef<BusyIndicatorComponent>;
    private _indicatorsActive: number = 0;
    private _indicatorActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _indicatorActive$: Observable<boolean> = this._indicatorActiveSubject.asObservable();

    get indicatorActive$(): Observable<boolean> {
        return this._indicatorActive$;
    }

    /**
     * Displays a busy indicator on the document body.
     * @param config Busy indicator configuration options. If not provided, the default configuration will be used.
     */
    show(config?: IBusyIndicatorConfig): BusyIndicatorRef {
        // merge the provided config options with the default options
        config = Object.assign(new BusyIndicatorConfig(), config);

        if (!config.identifier) {
            config.identifier = this.generateUniqueMessageIdentifier();
        }

        // if global busy indicator component has been disposed create a new one
        if (!(this._globalBusyIndicatorComponentRef &&
                this._globalBusyIndicatorComponentRef.instance &&
                !this._globalBusyIndicatorComponentRef.instance.isDestroyed)) {
            this._createComponent(config);
        } else {
            this._updateComponent(config);
        }

        const busyIndicatorRef = new BusyIndicatorRef(this._globalBusyIndicatorComponentRef);
        busyIndicatorRef.isDisposed$.pipe(takeWhile(x => !x, true))
            .subscribe(x => {
                if (x) {
                    this._indicatorsActive -= 1;
                    this._indicatorActiveSubject.next(!!this._indicatorsActive);
                }
            });
        busyIndicatorRef.addMessage(config.message, config.identifier);
        busyIndicatorRef.addLongRunningProcess = (id: number) => {
            if (id) {
                this._snackBarService.setBusyIndicatorActive(id);
                busyIndicatorRef.onDismiss().pipe(take(1)).subscribe(() => {
                    this._snackBarService.setBusyIndicatorInactive(id);
                });
            }
        };

        // integrate with snack bar
        if (config.longRunningProcessId) {
            busyIndicatorRef.setLongRunningProcessId(config.longRunningProcessId);
        }

        this._indicatorsActive += 1;
        this._indicatorActiveSubject.next(!!this._indicatorsActive);
        return busyIndicatorRef;
    }

    /**
     * Generate a unique ID for the busy indicator or snack
     */
    generateUniqueMessageIdentifier(): string {
        return this._busyIndicatorMessageIdService.generateUniqueMessageIdentifier();
    }

    /**
     * Create the busy indicator component and add it to the DOM
     * @param config
     */
    private _createComponent(config: IBusyIndicatorConfig): void {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(BusyIndicatorComponent);
        const componentRef: ComponentRef<BusyIndicatorComponent> = componentFactory.create(this._injector);
        this._applicationRef.attachView(componentRef.hostView);
        const domEl = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domEl);

        this._globalBusyIndicatorComponentRef = componentRef;
        this._updateComponent(config);
    }

    private _updateComponent(config: IBusyIndicatorConfig): void {
        this._globalBusyIndicatorComponentRef.instance.identifier = config.identifier;
        this._globalBusyIndicatorComponentRef.instance.title = config.title;
        this._globalBusyIndicatorComponentRef.instance.hasAction = config.hasAction;
        this._globalBusyIndicatorComponentRef.instance.canDismiss = config.canDismiss;
        this._globalBusyIndicatorComponentRef.instance.actionMessage = config.actionMessage;
        this._globalBusyIndicatorComponentRef.instance.delay = config.delay;
        this._globalBusyIndicatorComponentRef.instance.captureFocus = config.captureFocus;
        this._globalBusyIndicatorComponentRef.instance.restoreFocus = config.restoreFocus;
    }

}
