<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !editMode}">
        <panel-chevron [showBody]="isShowSettings"></panel-chevron>
        <h3>Company Settings</h3>
        <div class="float-end header-button-container" (click)="$event.stopPropagation()">
            <button class="flat-button icon-button" *ngIf="isShowSettings && !editMode && allowEdit" (click)="setEditMode()">
                <i class="fa fa-pencil"></i>
            </button>
            <button class="flat-button icon-button" *ngIf="isShowSettings && editMode" (click)="save()">
                <i class="fa fa-save"></i>
            </button>
            <button class="flat-button icon-button" *ngIf="isShowSettings && editMode" (click)="cancelEdit()">
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
    <div class="card-body" *ngIf="isShowSettings">
        <loading-spinner [show]="isLoading" [size]="5" [marginBottom]="50"></loading-spinner>
        <div class="row" *ngIf="!isLoading">
            <div class="col-lg-6">
                <div *ngIf="!company.parentId" class="setting">
                    <span class="settings-label" style="margin-right: 6px; font-weight: bold;">Company Collectors:</span>
                    <span>
                        <a *ngIf="editMode" anchor-route [sref]="{ target: 'companyCollectors', options: {companyID: company.companyID} }">
                            Manage ({{company.collectorCount || 0}})
                        </a>
                        <span *ngIf="!editMode">{{company.collectorCount || 0}}</span>
                    </span>
                </div>
                <div class="setting" *ngIf="isShowSettings">
                    <div *ngIf="company.parentId">
                        <span style="margin-right: 10px; font-weight: bold;">
                            Use Top Level Company's Vendor Code Mapping:
                        </span>
                        <ws-switch [(ngModel)]="options.useParentVendorFlag"
                                   [disabled]="!editMode"
                                   size="small">
                        </ws-switch>
                    </div>

                    <span *ngIf="!company.useParentVendorCodes || !company.parentId">
                        <span style="margin-right: 6px; font-weight: bold;">Company Vendor Code Mapping: </span>
                        <a *ngIf="editMode" anchor-route [sref]="{ target: 'companyVendorCodes', options: {companyId: companyId} }">Manage ({{ company.vendorCodeCount || 0 }})</a>
                        <span *ngIf="!editMode">{{ company.vendorCodeCount || 0 }}</span>
                    </span>
                </div>
                <div class="setting">
                    <span style="margin-right: 10px; font-weight: bold; display: inline-block;">
                        Fiscal Year Start:
                    </span>
                    <input type="text" class="form-control sd-validate-input-text" [(ngModel)]="options.fiscalYearStart" required
                           pattern="(0?[1-9]|1[012])/(0?[1-9]|[12]\d|3[01])" title="Value format is MM/DD" name="fiscalYearStartInput"
                           style="width:60px; display: inline-block;" [disabled]="!editMode" />
                </div>
                <div class="setting" *ngIf="isTopLevelCompany && !dxpSyncDisabled && entityIsInRyanInstance">
                    <span style="margin-right: 10px; font-weight: bold;">
                            Sync with Ryan.com Platform:
                        </span>
                    <ws-switch [(ngModel)]="options.syncDXPFlag"
                               [disabled]="!editMode || syncDxpLocked"
                               size="small">
                    </ws-switch>
                </div>
                <div *ngIf="showInvoiceAppealSavingsSetting" class="setting">
                    <div>
                        <span style="margin-right: 10px; font-weight: bold; padding-right: 4px;" [ngClass]="{'red': invoiceAppealSavingsOverridden()}">
                            Invoice Appeal Savings:
                        </span>
                        <ws-switch [(ngModel)]="options.invoiceAppealSavingsFlag"
                                   [disabled]="!this.editMode || !this.entityIsInRyanInstance">
                        </ws-switch>
                    </div>
                    <div>
                        <span style="margin-right: 10px; font-weight: bold;" [ngClass]="{'red': requireClientApprovalOverridden()}">
                                Require Client Approval:
                        </span>
                        <ws-switch [(ngModel)]="options.requireClientApprovalFlag"
                                   [disabled]="!this.editMode || !this.entityIsInRyanInstance">
                        </ws-switch>
                    </div>
                </div>
                <div class="setting" *ngIf="isTopLevelCompany">
                    <div>
                        <span style="margin-right: 10px; font-weight: bold;">
                            Budgets Enabled:
                        </span>
                        <ws-switch [(ngModel)]="options.budgetsEnabled"
                                   [disabled]="!editMode">
                        </ws-switch>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="options.budgetsEnabled">
                        <label style="margin-right: 5px;">Basis:</label>
                        <ws-select [(ngModel)]="options.budgetBasis"
                                   labelProperty="name"
                                   [options]="budgetBasisOptions"
                                   [disabled]="!editMode">
                        </ws-select>
                    </div>
                </div>
                <div class="setting" *ngIf="isTopLevelCompany && accrualsEnabled">
                    <div>
                        <span style="margin-right: 10px; font-weight: bold;">
                            Accruals Enabled:
                        </span>
                        <ws-switch [(ngModel)]="options.accrualsEnabled"
                                    (selectedOptionChanged)="accrualsEnabledChanged($event)"
                                   [disabled]="!editMode">
                        </ws-switch>
                    </div>
                    <div *ngIf="options.accrualsEnabled">
                        <div style="margin-top: 10px;">
                            <accounting-periods-link [companyId]="company.companyID" [editMode]="editMode"></accounting-periods-link>
                        </div>
                        <div style="margin-top: 10px;">
                            <accruals-descriptors-link *ngIf="flatInUseDescriptors"
                                [companyId]="company.companyID"
                                [flatInUseDescriptors]="flatInUseDescriptors"
                                [options]="options"
                                [editMode]="editMode">
                            </accruals-descriptors-link>
                        </div>
                        <div style="margin-top: 10px;">
                            <label style="margin-right: 5px;">Tax Amount Adjustments:</label>
                            <ws-select [(ngModel)]="options.defaultAccrualAdjustmentMethodId"
                                       labelProperty="name"
                                       [options]="taxAmountAdjustments"
                                       [disabled]="!editMode">
                            </ws-select>
                        </div>
                        <div style="margin-top: 10px;">
                            <label style="margin-right: 5px;">Economic Unit:</label>
                            <ws-select [(ngModel)]="options.accrualEconUnitTypeId"
                                       labelProperty="name"
                                       valueProperty="economicUnitTypeId"
                                       [disabled]="!editMode"
                                       [options]="economicUnitTypes">
                            </ws-select>
                        </div>
                        <div *ngIf="options.accrualEconUnitTypeId === 3" style="margin-top: 10px;">
                            <label style="margin-right: 5px;">Economic Unit Site Code:</label>
                            <descriptor-picker style="display: inline-block; width: auto;"
                                               [companyId]="company.companyID"
                                               [entityTypeId]="5"
                                               [descriptorId]="options.accrualEconUnitDescriptorId"
                                               [disabled]="!editMode"
                                               (selected)="descriptorSelected($event)">
                            </descriptor-picker>
                        </div>
                        <div style="margin-top: 10px;">
                            <label style="margin-right: 5px;">Export File Type:</label>
                            <ws-select [(ngModel)]="options.accrualFileTypeId"
                                       [options]="exportFileTypes"
                                       valueProperty="accrualFileTypeId"
                                       labelProperty="name"
                                       [disabled]="!editMode">
                            </ws-select>
                        </div>
                        <div style="margin-top: 10px;">
                            <label style="margin-right: 5px;">Export Property Identifier:</label>
                            <ws-select [ngModel]="options.accrualExportPropertyIdentificationId"
                                       [options]="exportProperties"
                                       labelProperty="name"
                                       (ngModelChange)="exportPropertyChanged($event)"
                                       [disabled]="!editMode">
                            </ws-select>
                            <div style="margin-top: 8px;">
                                <accruals-descriptor-selector
                                    *ngIf="!siteNumberSelectedForExport"
                                    [(selectedDescriptorId)]="options.accrualExportPropIdentDescriptorId"
                                    placeholder="Choose Descriptor"
                                    [disabled]="!editMode"
                                    [inUseDescriptors]="siteCharSelectedForExport ? inUseDescriptors.SITE : inUseDescriptors.PARCEL">
                                </accruals-descriptor-selector>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="setting" *ngIf="!isTopLevelCompany && accrualsEnabled && options.tlCompanyAccrualsEnabled">
                    <div>
                        <span style="margin-right: 10px; font-weight: bold;">
                            Exclude Company From Accruals:
                        </span>
                        <ws-switch [(ngModel)]="options.excludeFromAccruals"
                                   [disabled]="!editMode">
                        </ws-switch>
                    </div>
                </div>
                <div class="setting">
                    <div>
                        <a anchor-route
                           [sref]="{ target: 'glAccountList', options: { companyId: ((options.useTopLevelCompanyCOA) ? company.topLevelCompanyId : company.companyID)}}">
                            Chart of Accounts <span *ngIf="options.useTopLevelCompanyCOA">({{parentCompany.companyName}} and it's subsidiary companies)</span>
                        </a>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="!isTopLevelCompany">
                        <span style="margin-right: 10px; font-weight: bold;">
                            Use Top-Level Company Chart of Accounts:
                        </span>
                        <ws-switch [(ngModel)]="options.useTopLevelCompanyCOA"
                                   [disabled]="!editMode || !canChangeUseTopLevelCompanyCOA"
                                   [title]="useTopLevelCompanyCOATitle">
                        </ws-switch>
                    </div>
                </div>
                 <div class="setting" *ngIf="hasManageConsultingEngagements && isTopLevelCompany">
                    <span><strong>Consultants:</strong></span> <a *ngIf="editMode" class="pointer" (click)="showContracts()">Manage</a>
                </div>
                <div class="setting" *ngIf="company.hasIncomeStatements && isTopLevelCompany">
                    <a anchor-route
                        [sref]="{ target: 'companyIncomeStatement', options: { companyId: company.companyID}}">
                        Income Statements
                    </a>
                </div>
                <div class="setting" *ngIf="company.hasRentRolls && isTopLevelCompany">
                    <a anchor-route
                        [sref]="{ target: 'companyRentRoll', options: { companyId: company.companyID}}">
                        Rent Rolls
                    </a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0;">
                    <div class="panel-flat-header small-header">
                        <h3>Utilities</h3>
                    </div>
                    <div class="card-body">
                        <div class="setting">
                            <div class="ws-flex-container-horizontal ws-flex-align-items-center spacing-bottom">
                                <label class="utilities-form-label">Year</label>
                                <ws-select class="ws-stretch-width"
                                           [(ngModel)]="yearToAdd"
                                           [options]="yearsToAdd"
                                           [isPrimitive]="true"
                                           [disabled]="!editMode">
                                </ws-select>
                                <button *ngIf="editMode"
                                        class="flat-button primary-button small-button spacing-margin"
                                        (click)="startAddYear()"
                                        helpTooltip
                                        tooltipText="Add Year"
                                        [disabled]="addingYear || (selectingState && !companyStateModel.stateNames.length)">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div *ngIf="editMode && addingYear" class="ws-flex-container-vertical">
                                <div *ngIf="companyStates && companyStates.length" class="ws-flex-container-horizontal ws-flex-align-items-center ws-stretch-width spacing-bottom">
                                    <label class="utilities-form-label">Select States</label>
                                    <span class="checkbox-container" [class.disabled]="!editMode">
                                        <input type="checkbox" [checked]="selectingState" [disabled]="!editMode">
                                        <span class="checkbox-checkmark" (click)="toggleSelectingState()"></span>
                                    </span>
                                    <div class="ws-flex-grow spacing-margin">
                                        <ws-multi-select class="ws-stretch-width"
                                                         [(ngModel)]="companyStateModel.stateNames"
                                                         [options]="companyStates"
                                                         [isPrimitive]="true"
                                                         [disabled]="!(selectingState && editMode)">
                                        </ws-multi-select>
                                    </div>
                                </div>
                                <div class="ws-flex-container-horizontal ws-flex-justify-content-flex-end">
                                    <button *ngIf="editMode"
                                            class="form-control flat-button warning-button small-button"
                                            (click)="cancelAddYear()"
                                            helpTooltip
                                            tooltipText="Cancel">
                                        Cancel
                                    </button>
                                    <button *ngIf="editMode"
                                            class="form-control flat-button primary-button small-button"
                                            (click)="addYear(yearToAdd)"
                                            helpTooltip
                                            tooltipText="Add Year"
                                            [disabled]="selectingState && !companyStateModel.stateNames.length">
                                        Add Year
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="setting" *ngIf="isTopLevelCompany && allowEdit && canImportData">
                            <a anchor-route [sref]="{ target: 'companyImports', options: {companyId: company.companyID} }">Data Imports</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
