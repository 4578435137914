<button type="button"
        class="btn flat-button primary-button"
        [ngClass]="buttonClass"
        helpTooltip
        [position]="tooltipPosition"
        [tooltipText]="pinnedComment"
        [helpDisabled]="!pinnedComment"
        [disabled]="disabled"
        (click)="showCommentsDialog()">
    <i class="fa" [ngClass]="(hasComments ? 'fa-commenting' : 'fa-comment-o')"></i>
</button>
