import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { CommentModalData, CommentsService } from '../comments.service';
import { CommentsModalService } from '../commentsModal.service';

@Component({
    selector: 'entity-comments',
    templateUrl: './entityCommentsButton.component.html'
})
export class EntityCommentsButtonComponent {
    constructor(
        private readonly _commentsService: CommentsService,
        private readonly _commentsModalService: CommentsModalService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Input() pinnedComment?: string;
    @Input() hasComments: boolean;
    @Input() buttonClass: string;
    @Input() getModalData: () => CommentModalData;
    @Input() modalData: CommentModalData;
    @Input() disabled: boolean;
    @Input() isNestedModal: boolean;
    @Input() analyticsCategory: string;
    @Input() tooltipPosition: string = 'bottom';

    @Output() hasCommentsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() commentCountChange: EventEmitter<number> = new EventEmitter<number>();

    async showCommentsDialog(): Promise<void> {
        const modalData = this.modalData || (this.getModalData && this.getModalData());
        if (this.analyticsCategory) {
            this._productAnalyticsService.logEvent(`click-${this.analyticsCategory}-comment`);
        }

        const commentData = await this._commentsModalService.openCommentModal(modalData);

        this.hasComments = !!commentData.commentCount;
        this.pinnedComment = commentData.pinnedComment;
        this.hasCommentsChange.emit(this.hasComments);
        this.commentCountChange.emit(commentData.commentCount);
    }
}
