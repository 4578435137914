import { Component, OnInit, OnDestroy } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { BusyIndicatorService } from '../../../../../../Busy-Indicator';
import { ReturnAssetRepository } from '../../../../../Repositories/returnAsset.repository';
import { ReturnService, ReturnServiceSharedState } from '../../../../return.service';
import { ReturnAssetsService, AssessorFactorsFilter, AssessorFactorsEnum } from '../../returnAssets.service';
import { IReturnPartComponent } from '../../../../Models/returnPartServiceBase';
import * as _ from 'lodash';

@Component({
    selector: 'return-asset-form-factor-selector',
    templateUrl: './returnAssetFormFactorSelector.component.html'
})
export class ReturnAssetFormFactorSelectorComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor (
        private _busyIndicatorService: BusyIndicatorService,
        private _returnService: ReturnService,
        private _returnAssetsService: ReturnAssetsService,
        private _returnAssetRepository: ReturnAssetRepository
    ) { }

    private readonly _ANY_ASSESSOR_OPTION: Compliance.FormRevisionAssessorModel = {
        assessorName: 'Any Assessor',
        assessorId: AssessorFactorsEnum.AnyAssessor,
        isMappingCertified: null,
        isUsingDefaultMappings: null,
        isUsingDefaultTables: null
    } as Compliance.FormRevisionAssessorModel;

    private readonly _DEFAULT_ASSESSORS_OPTION: Compliance.FormRevisionAssessorModel = {
        assessorName: 'State-Provided Factor Tables',
        assessorId: AssessorFactorsEnum.AssessorsUsingDefaultFactors,
        isMappingCertified: null,
        isUsingDefaultMappings: null,
        isUsingDefaultTables: null
    } as Compliance.FormRevisionAssessorModel;

    private _formRevisionIdSub: Subscription;
    private _returnsSub: Subscription;

    private _localReturnServiceSharedState: ReturnServiceSharedState;

    // keep track of all the assessors for the selected form revision
    private _selectedFormRevisionAssessors: Compliance.FormRevisionAssessorModel[] = [];

    assessorFilters: Compliance.FormRevisionAssessorModel[] = [];
    selectedAssessorFilter: Compliance.FormRevisionAssessorModel;

    ngOnInit(): void {
        this._returnAssetsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnAssetsService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnsSub = this._returnService.returns$.subscribe((returns) => {
                if (returns) {
                    this._refreshAssessors();
                }
            });

        this._formRevisionIdSub = this._returnService.formRevisionId$.subscribe(() => this._refreshAssessors());
    }

    onReturnPartServiceDeactivated(): void {
        this._formRevisionIdSub && this._formRevisionIdSub.unsubscribe();
        this._returnsSub && this._returnsSub.unsubscribe();
    }

    onSelectedAssessorFilterChange(_event?: any): void {
        const filter = this._getAssessorFactorsFilterFromSelectedAssessor();
        this._returnAssetsService.setAssessorFactorsFilter(filter.assessorFactor, filter.assessorIds);
    }

    private async _refreshAssessors(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localFormRevisionId = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        // check to see if input parameters are available
        if (!sharedReturnIds.length || !sharedFormRevisionId) {
            this._blankAssessors();
        }
        else {
            // check to see if input parameters have changed
            if (!_.isEqual(localReturnIds, sharedReturnIds) || !_.isEqual(localFormRevisionId, sharedFormRevisionId)) {
                await this._loadAssessors();
            }

            // auto-select the assessor filter
            const filter = this._returnAssetsService.sharedState.assessorFactorsFilter;
            const assessor = filter &&
                this.assessorFilters.find(x => x.assessorId === ((filter.assessorFactor < 0) ? filter.assessorFactor : filter.assessorIds[0]));

            this.selectedAssessorFilter = assessor || this.assessorFilters[0];
        }

        if (this.selectedAssessorFilter) {
            this.onSelectedAssessorFilterChange();
        }
    }

    private _persistState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this.selectedAssessorFilter = this._ANY_ASSESSOR_OPTION;
    }

    private _blankAssessors(): void {
        this._persistState();
        this._selectedFormRevisionAssessors = [];
        this.assessorFilters = [];
    }

    private async _loadAssessors(): Promise<void> {
        this._persistState();

        const busyRef = this._busyIndicatorService.show({ message: 'Loading Factors' });
        try {
            const assessors = await lastValueFrom(this._returnAssetRepository.getAssessorsByFilingBatch(this._returnService.filingBatchId, {
                parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
                formRevisionId: this._returnService.sharedState.formRevisionId,
                reportingAssetIds: []
            })) || [];

            // the api brings back the FRYA record for the default assessor
            // we do not care about it here so ignoring it
            this._selectedFormRevisionAssessors = assessors.filter(x => x.assessorId !== null);

            // reset assessor filters to empty array; the following logic will populate them
            this.assessorFilters = [];

            // if no assessor then select "Any" as the default option
            // if there are assessors then check how many
            // option 1: there is only one assessor so just make available that one assessor
            //   if that assessor uses default tables then only make available the "Default assessors" option
            // option 2: there are multiple assessors then:
            //   if there is more than one assessor that uses custom tables or one with custom tables and any assessor with default make available the "Any" option
            //   make available any assessors that use custom tables
            //   if there are assessors using default tables then make available the "Default assessors" options
            if (this._selectedFormRevisionAssessors.length) {
                if (this._selectedFormRevisionAssessors.length > 1) {
                    // multiple assessors, examine the collection
                    const assessorsThatUseDefaultTables = this._selectedFormRevisionAssessors.filter(x => x.isUsingDefaultTables);
                    const assessorsThatUseCustomTables = this._selectedFormRevisionAssessors.filter(x => !x.isUsingDefaultTables);

                    // if we have more than one assessor that uses custom tables or just one but a mix of assessors that use default tables, expose "Any"
                    if (assessorsThatUseCustomTables.length > 1 || (assessorsThatUseCustomTables.length === 1 && assessorsThatUseDefaultTables.length)) {
                        this.assessorFilters = this.assessorFilters.concat(this._ANY_ASSESSOR_OPTION);
                    }

                    // always show assessors that use custom tables
                    this.assessorFilters = this.assessorFilters.concat(assessorsThatUseCustomTables);

                    // if any assessor use default tables, show the default tables option
                    if (assessorsThatUseDefaultTables.length) {
                        this.assessorFilters = this.assessorFilters.concat(this._DEFAULT_ASSESSORS_OPTION);
                    }
                } else {
                    // only one assessor; if using default tables show the default tables option, otherwise show the actual assessor
                    this.assessorFilters = this._selectedFormRevisionAssessors[0].isUsingDefaultTables ?
                        this.assessorFilters.concat(this._DEFAULT_ASSESSORS_OPTION) :
                        this.assessorFilters.concat(this._selectedFormRevisionAssessors);
                }
            } else {
                // no assessors, default "Any"
                this.assessorFilters = this.assessorFilters.concat(this._ANY_ASSESSOR_OPTION);
            }
        } finally {
            busyRef.hide();
        }
    }

    private _getAssessorFactorsFilterFromSelectedAssessor(): AssessorFactorsFilter {
        switch (this.selectedAssessorFilter.assessorId) {
            case AssessorFactorsEnum.AnyAssessor:
                return {
                    assessorFactor: AssessorFactorsEnum.AnyAssessor,
                    assessorIds: []
                };

            case AssessorFactorsEnum.AssessorsUsingDefaultFactors:
                return {
                    assessorFactor: AssessorFactorsEnum.AssessorsUsingDefaultFactors,
                    assessorIds: this._selectedFormRevisionAssessors.filter(x => x.isUsingDefaultTables).map(x => x.assessorId)
                };

            default:
                return {
                    assessorFactor: AssessorFactorsEnum.SpecificAssessor,
                    assessorIds: [this.selectedAssessorFilter.assessorId]
                };
        }
    }
}
