import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PaymentBatchRepository } from '../Core-Repositories/paymentBatch.repository';
import { PaymentBatchCompany } from './paymentBatch.model';
import { PaymentPackage } from '../Processing/Transmittal/transmittal.models';

@Injectable({
    providedIn: 'root'
})
export class PaymentBatchService {
    constructor(private readonly _paymentBatchRepo: PaymentBatchRepository) { }

    async getPaymentBatches(searchModel: Core.PaymentBatchSearchModel): Promise<Compliance.QueryResultModel<Core.PaymentBatchModel>> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchList(searchModel));
    }

    async exportList(model: Compliance.AssessorCommandCenterExportModel): Promise<any> {
        return lastValueFrom(this._paymentBatchRepo.exportList(model));
    }

    async savePaymentBatch(request: Core.PaymentBatchCreateRequestModel): Promise<Core.PaymentBatchModel> {
        return lastValueFrom(this._paymentBatchRepo.savePaymentBatch(request));
    }

    async getPaymentBatchDetailById(Id: number): Promise<Core.PaymentBatchModel> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchDetailById(Id));
    }

    async getPaymentBatchDetailsWorkflowStatus(Id: number): Promise<Core.PaymentBatchDetailsWorkflowStatusModel> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchWorkflowStatusById(Id));
    }

    async getPaymentBatchPaymentsList(searchModel: Core.PaymentBatchDetailsSearchModel): Promise<Compliance.QueryResultWithTotalsModel<Core.PaymentBatchDetailsEntryModel, Core.PaymentBatchPaymentTotalsModel>> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchPaymentsList(searchModel));
    }

    async getPaymentBatchPaymentsRowIds(searchModel: Core.PaymentBatchDetailsSearchModel): Promise<Compliance.QueryResultModel<number>> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchPaymentsRowIds(searchModel));
    }

    async startPaymentBatchDetailsPaymentBulkDelete(deleteModel: Core.PaymentBatchDetailsPaymentBulkDeleteModel): Promise<number> {
        return lastValueFrom(this._paymentBatchRepo.startPaymentBatchDetailsPaymentBulkDelete(deleteModel));
    }

    async paymentBatchDetailsPaymentBulkReReview(model: Core.PaymentBatchDetailsPaymentBulkReReviewModel): Promise<number> {
        return lastValueFrom(this._paymentBatchRepo.paymentBatchDetailsPaymentBulkReReview(model));
    }

    async getTaskSeriesConfig(): Promise<Core.PaymentBatchConfigModel[]> {
        return lastValueFrom(this._paymentBatchRepo.getTaskSeriesConfig());
    }

    async getDeliveryDetailPaymentBatch(entityId: number): Promise<Core.PaymentBatchDeliveryDetail> {
        return lastValueFrom(this._paymentBatchRepo.getDeliveryDetailPaymentBatch(entityId));
    }

    async getAvailablePaymentBatchesForAddPayments(tlCompanyId: number, taskIds: number[]): Promise<Core.PaymentBatchModel[]> {
        return lastValueFrom(this._paymentBatchRepo.getAvailablePaymentBatchesForAddPayments(tlCompanyId, taskIds));
    }

    async getPaymentBatchPaymentList(searchModel: Core.PaymentBatchAddPaymentSearchModel): Promise<Compliance.QueryResultWithTotalsModel<Core.PaymentBatchAddPaymentModel, Core.PaymentBatchPaymentTotalsModel>> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchPaymentList(searchModel));
    }

    async getPaymentBatchPaymentRowIds(searchModel: Core.PaymentBatchAddPaymentSearchModel): Promise<Compliance.QueryResultModel<number>> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchPaymentRowIds(searchModel));
    }

    async addPaymentsToPaymentBatch(paymentBatchId: number, request: Core.PaymentBatchAddPaymentRequestModel): Promise<Core.PaymentBatchAddPaymentResultModel> {
        return lastValueFrom(this._paymentBatchRepo.addPaymentsToPaymentBatch(paymentBatchId, request));
    }

    async addPaymentsToBatchByTaskId(paymentBatchId: number, taskIds: number[]): Promise<Core.BulkOperationResult[]> {
        return lastValueFrom(this._paymentBatchRepo.addPaymentsToBatchByTaskId(paymentBatchId, taskIds));
    }

    async saveFundedAmount(paymentBatchId: number, fundedAmount: number): Promise<void> {
        return lastValueFrom(this._paymentBatchRepo.saveFundedAmount(paymentBatchId, fundedAmount));
    }

    async advanceTask(paymentBatchId: number, updateModel: Core.PaymentBatchTaskUpdateModel): Promise<Core.PaymentBatchTaskUpdateResultModel> {
        return lastValueFrom(this._paymentBatchRepo.advanceTask(paymentBatchId, updateModel));
    }

    async rescindTask(paymentBatchId: number, updateModel: Core.PaymentBatchTaskUpdateModel): Promise<Core.PaymentBatchTaskUpdateResultModel> {
        return lastValueFrom(this._paymentBatchRepo.rescindTask(paymentBatchId, updateModel));
    }

    async reassignTask(paymentBatchId: number, updateModel: Core.PaymentBatchTaskUpdateModel): Promise<Core.PaymentBatchTaskUpdateResultModel> {
        return lastValueFrom(this._paymentBatchRepo.reassignTask(paymentBatchId, updateModel));
    }

    async getAllowedBatchTypes(companyId: number): Promise<Core.PaymentBatchCreationBatchTypesModel> {
        return lastValueFrom(this._paymentBatchRepo.getAllowedBatchTypes(companyId));
    }

    async finalizeTransmittalPackage(paymentBatchId: number, taskId: number, packages: PaymentPackage[]): Promise<any> {
        return lastValueFrom(this._paymentBatchRepo.finalizeTransmittalPackage(paymentBatchId, taskId, packages));
    }

    async getTransmittalPaymentPackages(paymentBatchId: number): Promise<any> {
        return lastValueFrom(this._paymentBatchRepo.getTransmittalPaymentPackages(paymentBatchId));
    }

    async getBillPaymentPackages(paymentBatchId: number): Promise<Core.PaymentBatchFeedPackage[]> {
        return lastValueFrom(this._paymentBatchRepo.getBillPaymentPackages(paymentBatchId));
    }

    async getOutputZip(paymentBatchId: number, feedTypes: Core.PaymentBatchFeedTypes[]): Promise<number> {
        return lastValueFrom(this._paymentBatchRepo.getOutputZip(paymentBatchId, feedTypes));
    }

    async getPaymentBatchSettings(paymentBatchId: number): Promise<Core.PaymentBatchSettingsModel> {
        return lastValueFrom(this._paymentBatchRepo.getPaymentBatchSettings(paymentBatchId));
    }

    async savePaymentBatchSettings(paymentBatchId: number, settings: Core.PaymentBatchSettingsModel): Promise<Core.PaymentBatchSettingsModel> {
        return lastValueFrom(this._paymentBatchRepo.savePaymentBatchSettings(paymentBatchId, settings));
    }
}

