import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { QuickSearchCriteriaComponent } from './Criteria/quickSearchCriteria.component';
import { QuickSearchCriteriaModule } from './Criteria/quickSearchCriteria.module';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        QuickSearchCriteriaModule
    ],
    declarations: [],
    providers: []
})
export class QuickSearchModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('quickSearchCriteria', QuickSearchCriteriaComponent);
    }
}
