import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { DraggableElementSearchDirection } from '../Directives/Draggable/draggable.directive';

/**
 * Simple wrapper for providing header and footer content to modals
 */
@Component({
    selector: 'ws-modal-wrapper',
    templateUrl: './modalWrapper.component.html',
    styleUrls: ['./modalWrapper.component.scss'],
})
export class ModalWrapperComponent {

    @Input() headerLabel: string;
    @Input() headerHelpContentId: string;
    @Input() isHeaderLink: boolean;
    @Input() subHeaderLabel: string;
    @Input() headerIcon: string;
    @Input() headerIconHelpContentId: string;
    @Input() footerLabel: string;
    @Input() cancelLabel: string = 'Cancel';
    @Input() acceptLabel: string = 'OK';
    @Input() auxButtonLabel: string;
    @Input() cancelClass: string = 'warning-button';
    @Input() acceptClass: string = 'primary-button';
    @Input() auxButtonClass: string = 'secondary-button';
    @Input() disableSave: boolean = false;
    @Input() disableCancel: boolean = false;
    @Input() disableAuxButton: boolean = false;
    @Input() acceptHelpContentId: string;
    @Input() cancelHelpContentId: string;
    @Input() auxButtonHelpContentId: string;
    @Input() hideSave: boolean = false;
    @Input() hideCancel: boolean = false;
    @Input() hideCancelX: boolean = false;
    @Input() hideAuxButton: boolean = false;
    @Input() hideFooter: boolean = false;
    @Input() tabindex: number = 0;
    @Input() suppressKeyboardEvents: boolean;
    @Input() excludeModalStyling: boolean;
    @Input() hideBackdrop: boolean;
    @Input() useAceStyles: boolean;

    @Output() save: EventEmitter<void> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();
    @Output() auxButton: EventEmitter<void> = new EventEmitter();
    @Output() onHeaderClick: EventEmitter<void> = new EventEmitter();

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.code === 'Enter' && !this.suppressKeyboardEvents) {
            this.enterPressed();
        }
        event.stopPropagation();
    }

    @HostListener('window:hashchange')
    locationEvent() {
        // If the user navigates away from the page (browser back button), we want to cancel the modal
        // This is the only time true is passed
        // The boolean allows handling situations where the modal should be shown globally, regardless of user navigation
        this.cancel.emit(true);
    }

    searchDirection = DraggableElementSearchDirection;

    enterPressed(): void {
        if (!this.disableSave) {
            this.save.emit();
        }
    }

    headerClicked(): void {
        if (this.isHeaderLink) {
            this.onHeaderClick.emit();
        }
    }
}
