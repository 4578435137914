export abstract class ActionViewPersistenceService {
    // I very lazily ported these over; anything I could tell the type of at a glance I provided
    // a type for, otherwise used any. We should put in proper types at some point, but for now
    // this gets us TS support
    routeParams: Object;
    filterColumns: any;
    outputColumns: any[];
    detailResults: any;
    overviewResults: any;
    overviewHeaders: any[];
    drilldownMode: boolean;
    resultsChanged: boolean;
    selectedResult: any;
    actionViewType: any;
    actionViewName: string;
    masterFilter: any;
    drilldownFilter: any;
    showOrs: boolean;
    organizeBySelection: number;
    groupByCount: number;
    externalDrilldown: boolean;
    externalDrilldownData: any;
    revisionChangeTypeID: number;

    abstract clearSavedData(): void;
}
