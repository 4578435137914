import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { AppealRecommendationTemplateComponent } from './Appeal-Recommendation-Template/appealRecommendationTemplate.component';
import { AppealRecommendationTemplateModalService } from './Appeal-Recommendation-Template/appealRecommendationTemplateModal.service';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { QuillEditorComponent } from 'ngx-quill';
import { AppealRecommendationRepository } from './appealRecommendation.repository';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
    ARCCComponent
} from './ARCC/aRCC.component';
import { CompanyModule } from '../Entity/Company/company.module';
import {
    AppealRecommendationCommandCenterGridActionCellRendererComponent
} from './ARCC/agGridActionCellRenderer.component';
import {
    ARCCAssignedComponent
} from './ARCC/ARCC-Assigned/aRCCAssigned.component';
import {
    ARCCAssignedDetailsModalComponent
} from './ARCC/ARCC-Assigned-Details-Modal/aRCCAssignedDetailsModal.component';
import { TeamModule } from '../Team/team.module';
import {
    AppealRecommendationTemplateLastSentHelpComponent
} from './Appeal-Recommendation-Template/appealRecommendationTemplateLastSentHelp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    AppealRecommendationTemplateDetailsComponent
} from './Appeal-Recommendation-Template/Appeal-Recommendation-Template-Details/appealRecommendationTemplateDetails.component';
import {
    ARCCBulkUpdateComponent
} from './ARCC/ARCC-Bulk-Update/aRCCBulkUpdate.component';
import { PropertyCharacteristicsModule } from '../Property-Characteristics/propertyCharacteristics.module';
import {
    ARCCBulkUpdateResultConfirmationComponent
} from './ARCC/ARCC-Bulk-Update/ARCC-Bulk-Update-Result-Confirmation/aRCCBulkUpdateResultConfirmation.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        BusyIndicatorModule,
        QuillEditorComponent,
        BsDropdownModule.forRoot(),
        CompanyModule,
        TeamModule,
        ReactiveFormsModule,
        FormsModule,
        PropertyCharacteristicsModule
    ],
    declarations: [
        AppealRecommendationTemplateComponent,
        ARCCComponent,
        AppealRecommendationCommandCenterGridActionCellRendererComponent,
        ARCCAssignedComponent,
        ARCCAssignedDetailsModalComponent,
        AppealRecommendationTemplateLastSentHelpComponent,
        AppealRecommendationTemplateDetailsComponent,
        ARCCBulkUpdateComponent,
        ARCCBulkUpdateResultConfirmationComponent
    ],
    providers: [
        AppealRecommendationTemplateModalService,
        AppealRecommendationRepository
    ],
    exports: [
        AppealRecommendationTemplateComponent,
        ARCCComponent,
        AppealRecommendationCommandCenterGridActionCellRendererComponent,
        ARCCAssignedComponent,
        ARCCAssignedDetailsModalComponent,
        AppealRecommendationTemplateLastSentHelpComponent
    ]

})
export class AppealRecommendationModule {
    static setupModule() {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('appealRecommendationModalService', AppealRecommendationTemplateModalService);
        hybridAdapterUtility.downgradeNg2Component('appealRecommendationCommandCenter', ARCCComponent);
    }
}
