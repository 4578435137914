import { CollectorPayment } from '../../Assessor-Collector/Collector/Billing-Scenario/billingScenario.models';

declare const moment: any;

export class Payment {
	constructor() {
		this.paymentAmount = 0;
		this.discountAmount = 0;
		this.interestAmount = 0;
		this.penaltyAmount = 0;
		this.dueDate =  moment().startOf('day').toDate();
		this.directAsmt = 0;
		this.name = 'Payment';
		this.accrualsExclude = {
			excludeFromAccruals: false,
			parentExcludedFromAccruals: false
		};
	}

    billID: number;
	collectorPayment: CollectorPayment;
	collectorPaymentID: number;
	discountAmount: number;
	dueDate: Date;
	dueDateOverridden: boolean;
	dueDateString: string;
	efAction: string;
	paymentAmount: number;
	paymentID: number;
	interestAmount: number;
	penaltyAmount: number;
	processedDate: Date;
	processedDateString: string;
	SeriesIsComplete: boolean;
	originalDueDate: Date;
	directAsmt: number;
	attachmentID: string;
	checkNumber: string;
	checkDate: Date;
	// previouslyPaid: boolean;
	accrualBegin: Date;
	accrualEnd: Date;
	originalAccrualBegin: Date;
	originalAccrualEnd: Date;
	postDate: Date;
	accrualsExclude: Core.AccrualsExcludeSummaryModel;
	exemptionAmount: number;
	completedPaymentPackageCount: number;
	belongsToPaymentBatchId?: number;
	belongsToPaymentBatchNo?: string;

	// Derived Values
	grossPayment: number;
	name: string;
	taskSummary: any;
	dueDateChanged: boolean;
}
