<div class="ws-flex-container-vertical ws-stretch">
    <div class="ws-flex-container-horizontal ws-flex-none" style="margin-bottom: 8px;" *ngIf="isInitialized">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Returns"></ws-bread-crumbs>
        </div>
        <div class="ws-flex-none">
            <filing-batch-progress [filingBatch]="filingBatch" [clickable]="false"></filing-batch-progress>
        </div>
    </div>

    <div class="ws-section ws-flex-none" *ngIf="isInitialized">
        <div class="ws-section__header">
            <div class="ws-section__header__title ws-flex-align-items-center ws-flex-justify-content-flex-start">
                <div class="ws-display-inline-block ws-vertical-align-middle">
                    <h4 class="ws-truncate ws-display-inline-block">
                        <span>{{ filingBatch.description }}</span>
                    </h4>
                </div>
                <div class="ws-display-inline-block ws-vertical-align-middle">
                    <return-compare-message (reset)="resetForms()"></return-compare-message>
                </div>
                <div class="ws-display-inline-block ws-vertical-align-middle">
                    <return-assessor-address-changed-message></return-assessor-address-changed-message>
                </div>
            </div>
            <div class="ws-section__header__actions ws-flex-align-items-center">
                <div class="ws-section__header__actions__item">
                    <div style="margin-right: 8px;">
                        <small>Status:</small>
                    </div>
                    <div class="btn btn-link pointer"
                         (click)="viewTasks()"
                         helpTooltip
                         helpContentId="app.view-task-series"
                         position="bottom">
                        {{ filingBatch.status }}
                    </div>
                </div>
                <return-task (taskError)="taskError($event)"></return-task>
                <return-task-exception></return-task-exception>
            </div>
            <div class="ws-section__header__actions__item">
                <entity-attachments buttonClass="flat-button small-button primary-button"
                                    [(hasAttachments)]="filingBatch && filingBatch.hasAttachments"
                                    [modalData]="attachmentsModel"
                                    [disabled]="!canEnterEditMode"
                                    helpTooltip
                                    [helpContentId]="attachmentsHelpContentId"
                                    position="bottom">
                </entity-attachments>
            </div>
            <div class="ws-section__header__actions__item">
                <entity-comments buttonClass="flat-button small-button primary-button"
                                 [(hasComments)]="filingBatch && filingBatch.hasComments"
                                 [modalData]="commentsModel"
                                 [disabled]="!canEnterEditMode"
                                 helpTooltip
                                 [helpContentId]="commentsHelpContentId"
                                 position="bottom">
                </entity-comments>
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="flat-button small-button secondary-button"
                            (click)="refreshBatch()"
                            helpTooltip
                            helpContentId="app.refresh"
                            position="bottom">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="ws-flex-container-horizontal ws-flex-auto return-parts-container">
        <return-batch></return-batch>
        <return-parts></return-parts>
    </div>
</div>
