import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface AppealRecommendationCommandCenterBulkUpdateResultConfirmationComponentParams {
    ignoredSites: Core.AppealRecommendationBulkUpdateSiteResultModel[]
}

@Component({
    selector: 'appeal-recommendation-command-center-bulk-update-result-confirmation',
    templateUrl: './aRCCBulkUpdateResultConfirmation.component.html'
})
export class ARCCBulkUpdateResultConfirmationComponent implements OnInit, IWeissmanModalComponent<AppealRecommendationCommandCenterBulkUpdateResultConfirmationComponentParams, void>{
    params: AppealRecommendationCommandCenterBulkUpdateResultConfirmationComponentParams;
    result: void;
    sites: Core.AppealRecommendationBulkUpdateSiteResultModel[];

    constructor(private readonly _bsModalRef: BsModalRef) {
    }

    cancel() {
        this._bsModalRef.hide();
    }

    ngOnInit(): void {
        this.sites = this.params.ignoredSites.sort((x, y) => `${x.companyName + x.siteName}`.localeCompare(`${y.companyName + y.siteName}`));
    }
}
