import { Injectable } from '@angular/core';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import {
    ProcessAppealWarrantedComponent, ProcessAppealWarrantedParams
} from '../../Processing/Process-Appeal-Warranted-Modal/processAppealWarranted.component';
import {
    ProcessNoAppealComponent,
    ProcessNoAppealParams
} from '../../Processing/Process-No-Appeal-Modal/processNoAppeal.component';

@Injectable({ providedIn: 'root' })
export class ActionViewContextMenuService {
    constructor(private readonly _weissmanModalService: WeissmanModalService) {
    }

    //Using this to open modals for now, but the rest of the old service should be migrated over

    async openProcessNoAppealModal(taskIDs: number[], searchTimestamp: Date): Promise<Core.BulkOperationResult[]> {
        const params: ProcessNoAppealParams = {
            taskIDs: taskIDs,
            searchTimestamp: searchTimestamp,
            updateAppealRecommendation: false
        };
        return this._weissmanModalService.showAsync(ProcessNoAppealComponent, params, 'modal-lg');
    }

    async openProcessAppealWarrantedModal(taskIDs: number[], searchTimestamp: Date): Promise<Core.BulkOperationResult[]> {
        const params: ProcessAppealWarrantedParams = {
            taskIDs: taskIDs,
            searchTimestamp: searchTimestamp,
            runWithBuffer: true,
            updateAppealRecommendation: false
        };
        return await this._weissmanModalService.showAsync(ProcessAppealWarrantedComponent, params, 'modal-lg');
    }
}
