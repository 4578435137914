<div class="payment-batch-list ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Payment Batch Command Center
                <!-- <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count> -->
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button type="submit" class="btn flat-button primary-button small-button" (click)="launch()" title="Launch">
                        Create Payment Batch
                    </button>
                </div>
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         [helpContentId]="refreshHelpContentId"
                         position="bottom">
                        <button type="button" class="flat-button small-button secondary-button" [disabled]="(!canGetRows)" (click)="refresh()"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ws-section">
        <div class="ws-section__filters">
            <div class="ws-section__filters__filter-group">
                <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                    <label class="ws-no-margin small"
                        helpTooltip
                        helpContentId="payment-batch-command-center.company"
                        position="bottom">Top Level Company:</label>
                    <company-picker style="width: 350px;" [company]="selectedCompany" [topLevelOnly]="true" [activeOnly]="true" (companySelected)="companySelected($event)"></company-picker>
                </div>
            </div>
        </div>
    </div>

    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        [exportOptions]="exportOptions"
                        (gridReady)="onAgGridReady($event)"
                        [gridId]="gridId">
    </ws-ag-grid-angular>
</div>
