import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TimerService } from '../../Utilities';

export interface MaskGenerator {
    (value: string): string;
}

@Directive({
    selector: '[wsMask]'
})
export class InputMaskDirective {
    constructor(private ngControl: NgControl, private readonly _timer: TimerService) { }
    @Input('wsMask') maskGenerator: MaskGenerator;
    @Input() wsKeepMask: boolean;

    @Output() wsMaskValueChange: EventEmitter<string> = new EventEmitter();

    private static readonly _ALPHA = 'A';
    private static readonly _NUMERIC = '0';
    private static readonly _ALPHANUMERIC = '?';
    private static readonly _REGEX_MAP = new Map([
        [InputMaskDirective._ALPHA, /\w/],
        [InputMaskDirective._NUMERIC, /\d/],
        [InputMaskDirective._ALPHANUMERIC, /\w|\d/],
    ]);

    private _value: string = null;
    private _displayValue: string = null;

    @Input()
    set wsMaskValue(value: string) {
        if (value !== this._value) {
            this._value = value;
            this._defineValue();
        }
    }


    @HostListener('input', ['$event'])
    onInput(event: { target: { value?: string; }; }): void {
        const target = event.target;
        const value = target.value;
        this._onValueChange(value);
    }


    private _updateValue(value: string) {
        this._value = value;
        this.wsMaskValueChange.emit(value);
        this._delay().then(
            () => this.ngControl.control.updateValueAndValidity()
        );
    }

    private _defineValue() {
        let value = this._value;
        let displayValue = this._value;

        if (this.maskGenerator) {
            const mask = this.maskGenerator(value);

            if (!value) {
                displayValue = InputMaskDirective._mask(value, mask);
                value = InputMaskDirective._processValue(displayValue, mask, this.wsKeepMask);
            }
        }

        this._delay().then(() => {
            if (this._displayValue !== displayValue) {
                this._displayValue = displayValue;
                this.ngControl.control.setValue(displayValue);
                return this._delay();
            }
        }).then(() => {
            if (value !== this._value) {
                return this._updateValue(value);
            }
        });
    }

    private _onValueChange(newValue: string) {
        if (newValue !== this._displayValue) {
            let displayValue = newValue;
            let value = newValue;

            if ((newValue == null) || (newValue.trim() === '')) {
                value = null;
            } else if (this.maskGenerator) {
                const mask = this.maskGenerator(newValue);
                displayValue = InputMaskDirective._mask(newValue, mask);
                value = InputMaskDirective._processValue(displayValue, mask, this.wsKeepMask);
            }

            this._displayValue = displayValue;

            if (newValue !== displayValue) {
                this.ngControl.control.setValue(displayValue);
            }

            if (value !== this._value) {
                this._updateValue(value);
            }
        }
    }

    private static _processValue(displayValue: string, mask: string, wsKeepMask: boolean) {
        return wsKeepMask ? displayValue : InputMaskDirective._unmask(displayValue, mask);
    }

    private static _mask(value: string, mask: string): string {
        if (!mask) {
            return value;
        }

        value = value.toString();

        let len = value.length;
        const maskLen = mask.length;
        let pos = 0;
        let newValue = '';

        for (let i = 0; i < Math.min(len, maskLen); i++) {
            const maskChar = mask.charAt(i);
            const newChar = value.charAt(pos);
            const regex = InputMaskDirective._REGEX_MAP.get(maskChar);

            if (regex) {
                pos++;

                if (regex.test(newChar)) {
                    newValue += newChar;
                } else {
                    i--;
                    len--;
                }
            } else {
                if (maskChar === newChar) {
                    pos++;
                } else {
                    len++;
                }

                newValue += maskChar;
            }
        }

        return newValue;
    }

    private static _unmask(maskedValue: string, mask: string): string {
        const maskLen = (mask && mask.length) || 0;
        return maskedValue.split('').filter(
            (x, idx) => (idx < maskLen) && InputMaskDirective._REGEX_MAP.has(mask[idx])
        ).join('');
    }

    private _delay(ms: number = 0): Promise<void> {
        return new Promise<void>(resolve => this._timer.setTimeout(() => resolve(), ms)).then(() => null);
    }
}
