import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';

export interface ErrorNotificationParams {
    errors: string[];
    warnings: string[];
    errorMessage: string;
    warningMessage: string;
}

@Component({
    selector: 'error-notification',
    templateUrl: './errorNotification.component.html'
})
export class ErrorNotificationComponent implements OnInit, IWeissmanModalComponent<ErrorNotificationParams, void> {
    constructor(private readonly _bsModalRef: BsModalRef) { }

    params: ErrorNotificationParams;
    result: void;

    errors: string[];
    warnings: string[];
    topLevelMessage: string;
    errorMessage: string;
    warningMessage: string;

    ngOnInit() {
        this.errors = this.params.errors;
        this.warnings = this.params.warnings;
        if (this.params.errorMessage && !this.params.warningMessage) {
            this.topLevelMessage = this.params.errorMessage;
        } else {
            this.errorMessage = this.params.errorMessage;
            this.warningMessage = this.params.warningMessage;
        }
    }

    close(): void {
        this._bsModalRef.hide();
    }
}
