import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { OutputColumn } from '../../Search/Advanced/Column-Picker/column.model';
import * as _ from 'lodash';
import { SearchOperatorsService } from '../../Search/searchOperators.service';
import { TaskSeriesType } from '../../Task/Task.Model';

export interface DraggableOutputColumn extends OutputColumn {
    isRemovable: boolean;
}

export interface ColumnCategory {
    categoryName?: string;
    columns: DraggableOutputColumn[];
    defaultColumns: DraggableOutputColumn[];
    showBody?: boolean;
}

@Component({
    selector: 'category-column-picker',
    template: `
        <div class="checkbox text-end" *ngIf="!disableUseSystemDefined">
            <label>
                <input type="checkbox" [(ngModel)]="useSystemDefined" (change)="onSysDefinedChanged()"> Use System Defined
            </label>
        </div>
        <bs-sortable [(ngModel)]="category.columns" itemClass="sortable-item" [itemTemplate]="outputColumnTemplate"
            itemActiveClass="sortable-item-active" placeholderItem="Add output fields" placeholderClass="sortable-item-placeholder"
            wrapperClass="category-columns-wrapper">
        </bs-sortable>
        <div *ngIf="disableUseSystemDefined || !useSystemDefined" style="display: flex; align-items: center; justify-content: center; margin-top: 20px;">
            <h5 style="white-space: nowrap;">Add Column</h5>
            <column-picker
                style="width: 420px; margin-left: 10px;"
                [existingColumns]="category.columns"
                [disableColumnsAfterLoad]="true"
                [cpMode]="cpMode"
                [selectedTaskType]="selectedTaskType"
                (columnsSelected)="addColumns($event)">
            </column-picker>
        </div>

        <ng-template #outputColumnTemplate let-item="item">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <span *ngIf="cpMode === 'av'">{{ item.value.displayName }}</span>
                <span *ngIf="cpMode === 'smart'">{{ item.value.typeAheadName }}</span>
                <i *ngIf="(disableUseSystemDefined || !useSystemDefined) && item.value.isRemovable" class="fa fa-close" (click)="removeColumn(item.value)"></i>
            </div>
        </ng-template>
    `,
    styleUrls: ['categoryColumnPicker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CategoryColumnPickerComponent implements OnInit {
    @Input() cpMode: 'av' | 'smart';
    @Input() category: ColumnCategory;
    @Input() selectedTaskType: TaskSeriesType;
    @Input() disableUseSystemDefined: boolean;

    useSystemDefined: boolean;

    constructor(private searchOperators: SearchOperatorsService) { }

    ngOnInit() {
        if(!this.disableUseSystemDefined) {
            const colIds = _.map(this.category.columns, x => x.id || x.columnId || x.advancedSearchFieldID);
            const defaultColIds = _.map(this.category.defaultColumns, x => x.id || x.columnId || x.advancedSearchFieldID);

            this.useSystemDefined = _.xor(colIds, defaultColIds).length == 0;
        }
    }

    removeColumn(column: DraggableOutputColumn): void {
        let idProperty = 'columnId';

        if(column.id) {
            idProperty = 'id';
        } else if(column.advancedSearchFieldID) {
            idProperty = 'advancedSearchFieldID';
        }

        this.category.columns = _.reject(this.category.columns, x => {
            return x[idProperty] == column[idProperty];
        });
    }

    addColumns(columns: OutputColumn[]): void {
        const draggableOutputColumns = _.map(columns, column => {
            const addedColumn = _.cloneDeep(column);

            if(this.cpMode == 'av') {
                addedColumn.operators = this.searchOperators.getOperatorArray(addedColumn.operators as string[]);

                if (addedColumn.columnId === 101 || addedColumn.columnId === 109) {
                    addedColumn.or = [this.searchOperators.getCompanyOr(addedColumn.operators)];
                } else {
                    addedColumn.or = [this.searchOperators.getOr(addedColumn.operators)];
                }
            }

            return { ...addedColumn, isRemovable: true };
        });

        this.category.columns = [...this.category.columns, ...draggableOutputColumns];
    }

    onSysDefinedChanged(): void {
        if (this.useSystemDefined) {
            this.category.columns = _.cloneDeep(this.category.defaultColumns);
        }
    }
}

