import { Component, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UntypedFormControl } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { takeUntil, debounce, distinctUntilChanged } from 'rxjs/operators';
import { CellEditorValidationResult } from './validator.interface';
import { MaskGenerator } from '../../../UI-Lib/Directives/Input-Mask/inputMask.directive';
import { TimerService } from '../../../UI-Lib/Utilities/timer.service';

export interface TextCellEditorParams extends ICellEditorParams {
    cellChanged?: (initialRowValue: any, changedText: string) => Promise<void>;
    cellFocusLost?: (initialRowValue: any, changedText: string) => Promise<void>;
    validator?: (value: string) => CellEditorValidationResult;
    maxLength?: number;
    fillWidth?: boolean;
    canReset?: boolean;
    resetToValue?: (params: TextCellEditorParams) => any;
    showOverrideDuringEdit?: boolean;
    hasOverride?: (params: TextCellEditorParams, currentValue: string) => boolean;
    inputMask?: MaskGenerator;
}

@Component({
    selector: 'editor-cell',
    template: `
        <div class="ws-ag-grid-text-cell-editor ws-flex-container-horizontal">
            <input #wsTextInput
                   type="text"
                   [formControl]="textValue"
                   [class.fillWidth]="fillWidth"
                   [class.invalid]="!isValid"
                   [class.has-reset]="showReset"
                   [class.overridden]="showOverrideDuringEdit && hasOverride"
                   helpTooltip
                   [tooltipText]="validationMessage"
                   [helpDisabled]="isValid"
                   [attr.maxLength]="maxLength || null"
                   [wsMask]="mask"/>
            <span class="reset-value clickable"
                  [class.showReset]="showReset"
                  helpTooltip
                  tooltipText="Reset to the original value"
                  position="right"
                  (click)="resetToOriginalValue()">
                <i class="fa fa-times"></i>
            </span>
        </div>
    `,
    styleUrls: ['./cellEditors.scss']
})
export class AgGridTextCellEditor implements ICellEditorAngularComp, OnDestroy, AfterViewInit {
    constructor(private readonly _timer: TimerService) {}

    @ViewChild('wsTextInput', { static: true }) wsTextInput: ElementRef;

    initialValue: string;
    isValid: boolean = true;
    validationMessage: string;
    maxLength: number;
    textValue: UntypedFormControl = new UntypedFormControl();
    fillWidth: boolean = true;
    canReset: boolean = false;
    showOverrideDuringEdit: boolean = false;
    mask: MaskGenerator;

    private _params: TextCellEditorParams;
    private _destroy$: Subject<void> = new Subject();

    get hasOverride(): boolean {
        return this._params.hasOverride && this._params.hasOverride(this._params, this.textValue.value);
    }

    get showReset(): boolean {
        return this.canReset && this.hasOverride;
    }

    agInit(params: TextCellEditorParams): void {
        this._params = params;
        this.initialValue = params.value;
        this.textValue.setValue(this.initialValue);
        this.fillWidth = !(params.fillWidth === false);
        this.canReset = params.canReset;
        this.showOverrideDuringEdit = params.showOverrideDuringEdit;
        this.mask = params.inputMask;
        if (params.maxLength) {
            this.maxLength = params.maxLength;
        }

        this.textValue.valueChanges
            .pipe(takeUntil(this._destroy$))
            .pipe(debounce(() => timer(400)))
            .pipe(distinctUntilChanged())
            .subscribe(async (value: string) => {
                if (this._params.validator) {
                    const validation = this._params.validator(value);
                    this.isValid = validation.isValid;
                    this.validationMessage = validation.validationMessage;
                }
                if (this._params.cellChanged && this._isUnique(value)) {
                    await this._params.cellChanged(this._params, value || null);
                }
            });
    }

    ngAfterViewInit(): void {
        // Timeout forces into async queue, otherwise it happens too quick
        // This seems to be a known Angular issue when doing this type of dom control
        this._timer.setTimeout(() => this.wsTextInput.nativeElement.focus(), 0);
    }

    async ngOnDestroy(): Promise<void> {
        if (!this.isValid) {
            this.textValue.setValue(this.initialValue);
        }
        if (this._params.cellFocusLost && this._isUnique(this.textValue.value)) {
            await this._params.cellFocusLost(this._params, this.textValue.value || null);
        }
        this._destroy$.next();
        this._destroy$.complete();
    }

    getValue(): boolean {
        return this.textValue.value;
    }

    isPopup(): boolean {
        return false;
    }

    resetToOriginalValue(): void {
        const value = (this._params.resetToValue)
          ? this._params.resetToValue(this._params)
          : null;
        this.textValue.setValue(value);
        this._params.api.stopEditing();
    }

    private _isUnique(value: string): boolean {
        return !(value === this.initialValue);
    }
}
