import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    AppealRecommendationEmailTemplateListItemModel,
    VisibilityModel
} from '../appealRecommendationTemplate.component';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { lastValueFrom } from 'rxjs';
import { AppealRecommendationRepository } from '../../appealRecommendation.repository';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { APPEAL_RECOMMENDATION_TEMPLATE_DETAILS_HELP } from './appealRecommendationTemplateDetails.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';

export interface AppealRecommendationTemplateDetailsParams{
    emailSubjectTemplate: string,
    emailBodyTemplate: string,
    options: Core.AppealRecommendationTemplateOptionsModel,
    taskIds: number[],
    existingTemplates: AppealRecommendationEmailTemplateListItemModel[],
    selectedTemplate?: AppealRecommendationEmailTemplateListItemModel;
}

@Component({
    selector: 'appeal-recommendation-template-details',
    templateUrl: './appealRecommendationTemplateDetails.component.html',
})
export class AppealRecommendationTemplateDetailsComponent implements OnInit, IWeissmanModalComponent<AppealRecommendationTemplateDetailsParams, Core.AppealRecommendationEmailTemplateModel>{
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _appealRecommendationRepository: AppealRecommendationRepository,
        private readonly _restrictService: RestrictService,
        private readonly _helpService: HelpService) {
    }

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    visibilities: VisibilityModel[] = [
        {
            name: 'Private',
            description: 'Visible only to you',
            isDefault: false,
            isShared: false
        },
        {
            name: 'Shared',
            description: 'Visible to everyone',
            isDefault: false,
            isShared: true
        }
    ];

    params: AppealRecommendationTemplateDetailsParams;
    result: Core.AppealRecommendationEmailTemplateModel;

    name: string;
    description: string;
    visibility: VisibilityModel = this.visibilities[0];
    hasEditSystemSearch: boolean = false;

    ngOnInit(): void {
        this._helpService.setContent(APPEAL_RECOMMENDATION_TEMPLATE_DETAILS_HELP);

        this.hasEditSystemSearch = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);

        if (this.params.selectedTemplate) {
            this.name = this.params.selectedTemplate.name;
            this.description = this.params.selectedTemplate.description;
            this.visibility = this.visibilities.find(x => x.isShared === this.params.selectedTemplate.visibilityItem.isShared);
        }
    }

    canSave(): boolean {
        return !!this.name && !!this.visibility && !this._hasDuplicateName();
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving template';
        this.busyIndicatorMessageManager.add('Saving Template', busyMsgId);

        try {
            const saveTemplateModel: Core.AppealRecommendationEmailTemplateUpdateModel = {
                appealRecommendationEmailTemplateId: this.params.selectedTemplate ? this.params.selectedTemplate.appealRecommendationEmailTemplateId : 0,
                name: this.name,
                description: this.description,
                isShared: this.visibility.isShared,
                emailSubjectTemplate: this.params.emailSubjectTemplate,
                emailBodyTemplate: this.params.emailBodyTemplate,
                showPriorYear: this.params.options.showPriorYear,
                showYearBuilt: this.params.options.showYearBuilt,
                showAcquisitionInfo: this.params.options.showAcquisitionInfo,
                showAppealDeadlines: this.params.options.showAppealDeadlines,
                showAssessments: this.params.options.showAssessments,
                showMetrics: this.params.options.showMetrics,
                showInitialValue: this.params.options.showInitialValue,
                showTargetValue: this.params.options.showTargetValue,
                showLatestValue: this.params.options.showLatestValue,
                showTaxDetails: this.params.options.showTaxDetails,
                showSavings: this.params.options.showSavings,
                embedInEmail: this.params.options.embedInEmail,
                includeSiteComment: this.params.options.includeSiteComment,
                attachChart: this.params.options.attachChart,
                taskIds: this.params.taskIds
            };

            this.result = await lastValueFrom(this._appealRecommendationRepository.saveTemplate(saveTemplateModel));
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }

        this._bsModalRef.hide();

        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    get saveHelpId(): string {
        return this._hasDuplicateName() ? 'appeal-recommendation-template-details.template-exists' : 'app.save';
    }

    private _hasDuplicateName() {
        return !!this.params.existingTemplates.find(x => x.name.toLowerCase() === (this.name || '').toLowerCase() &&
            x.isShared === this.visibility.isShared && (!this.params.selectedTemplate || x.appealRecommendationEmailTemplateId !== this.params.selectedTemplate.appealRecommendationEmailTemplateId));
    }
}
