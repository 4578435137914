import { Injectable } from '@angular/core';
import { UserService } from '../../Account/user.service';
import { AccountService } from '../../User/account.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { FeatureFlagsService } from '../FeatureFlags/feature-flags-service';
import { Roles } from '../Permissions/restrict.service';
import { init, Identify, identify, logEvent, setUserId } from '@amplitude/analytics-browser';

import * as moment from 'moment';

export interface AmplitudeEvent {
    event: string;
    properties?: { [key: string]: any };
}

interface AmplitudeSequenceEvent {
    event: AmplitudeEvent;
    targetSequence: string[];
    eventSequence: string[];
    strict?: boolean;
}

interface AmplitudeSequenceEvents {
    [event: string]: AmplitudeSequenceEvent;
}

@Injectable({
    providedIn: 'root'
})
export class ProductAnalyticsService {
    constructor(
        private readonly _accountService: AccountService,
        private readonly _featureFlagsService: FeatureFlagsService,
        private readonly _userInstanceService: UserInstanceService
    ) {
        this._amplitude = {
            init: init,
            logEvent: logEvent,
            identify: identify
        };
    }

    private _userPropertiesSet: boolean;
    // I hate to do this but otherwise this service is untestable
    private _amplitude: {
        init: (
            apiKey: string,
            userId?: string | undefined,
            options?: import('@amplitude/analytics-types').BrowserOptions | undefined
        ) => import('@amplitude/analytics-types').AmplitudeReturn<void>;
        identify: (
            identify: import('@amplitude/analytics-types').Identify,
            eventOptions?: import('@amplitude/analytics-types').EventOptions | undefined
        ) => import('@amplitude/analytics-types').AmplitudeReturn<import('@amplitude/analytics-types').Result>;
        logEvent: (
            eventInput: string | import('@amplitude/analytics-types').BaseEvent,
            eventProperties?: Record<string, any> | undefined,
            eventOptions?: import('@amplitude/analytics-types').EventOptions | undefined
        ) => import('@amplitude/analytics-types').AmplitudeReturn<import('@amplitude/analytics-types').Result>;
    };

    private _sequenceEvents: AmplitudeSequenceEvents = {};

    init(amplitudeApiKey: string): void {
        const userId = this._getIAMUserId();
        this._amplitude.init(amplitudeApiKey, userId, {
            serverUrl: 'https://api.app.tax.com/analytics/2/httpapi',
            trackingOptions: {
                ipAddress: false
            }
        });
    }

    logEvent(eventName: string, properties: any = {}) {
        const envConfig = (window as any)?.weissmanEnvironmentConfig;
        if (envConfig?.amplitudeApiKey) {
            if (!this._userPropertiesSet) {
                this._setUserProperties();
            }
            this._amplitude.logEvent(eventName, properties);
        }
        if (['local', 'test', 'uat'].includes(envConfig?.runtimeEnvironment)) {
            console.log(`Amplitude Event: ${eventName}`, properties);
        }
    }

    /**
     * Initiates a sequence event
     * @param event - The event to log when the sequence is complete
     * @param sequence - The sequence of events to log starting with the current event
     * @param strict - If true, there may not be any other events logged between the events in the sequence
     */
    initiateSequenceEvent(event: AmplitudeEvent, sequence: string[], strict?: boolean): void {
        this._sequenceEvents[`${event.event}.${sequence[sequence.length - 1]}`] = {
            event,
            targetSequence: sequence,
            eventSequence: [sequence[0]],
            strict
        };
    }

    /**
     * Logs a sequence event if it is the final event in the sequence
     * @param eventName
     * @param sequenceEvent
     */
    logSequenceEvent(eventName: string, sequenceEvent: string): void {
        // Check for events with multiple sequences
        const events = Object.keys(this._sequenceEvents).filter(x => x.startsWith(eventName));
        if (!events.length) {
            return;
        }

        events.forEach(x => {
            const sequence = this._sequenceEvents[x];
            if (
                sequence.targetSequence.includes(sequenceEvent) &&
                sequence.targetSequence.indexOf(sequenceEvent) === sequence.eventSequence.length
            ) {
                sequence.eventSequence.push(sequenceEvent);
            }

            if (sequence.eventSequence.toString() === sequence.targetSequence.toString()) {
                this.logEvent(sequence.event.event, sequence.event.properties);
                delete this._sequenceEvents[x];
            }
        });

        // Clear any strict events that remain
        this._sequenceEvents = Object.keys(this._sequenceEvents).reduce(
            (acc, key) => (this._sequenceEvents[key].strict ? acc : { ...acc, [key]: this._sequenceEvents[key] }),
            {}
        );
    }

    private _getUserType(): string {
        const hasRyanInstance = this._accountService.userData.instanceMembership.some(x => x.name === 'Ryan');
        const hasRyanPrivate = this._accountService.userData.globalRoles.some(
            x => x === Roles.RYANPRIVATEITEMSVIEW || x === Roles.RYANPRIVATEITEMSEDIT
        );
        if (hasRyanInstance && hasRyanPrivate) {
            return 'internal';
        } else if (hasRyanInstance && !hasRyanPrivate) {
            return 'external read only';
        } else {
            return 'licensed';
        }
    }

    private _getUserAge(): string {
        const days = moment().diff(this._accountService.userData.createDate, 'days');
        const years = moment().diff(this._accountService.userData.createDate, 'years');
        if (days < 365) {
            return 'Less than 1 year';
        } else if (years >= 5) {
            return '5+ years';
        } else {
            return `${years} ${years === 1 ? 'year' : 'years'}`;
        }
    }

    private _setUserProperties(): void {
        const userType = this._getUserType();
        const userAge = this._getUserAge();
        const selectedInstance = this._userInstanceService.getSelectedInstance();

        const IAMUserId = this._getIAMUserId();
        if (IAMUserId) {
            setUserId(IAMUserId);
        }

        const identityEvent = new Identify();
        identityEvent.set('userType', userType);
        identityEvent.set('createDate', this._accountService.userData.createDate);
        identityEvent.set('userAge', userAge);
        identityEvent.set('userTenant', selectedInstance?.name);
        identityEvent.set('defaultTeam', this._accountService.userData.defaultTeam);
        this._amplitude.identify(identityEvent);

        if (['test', 'uat'].includes((window as any)?.weissmanEnvironmentConfig?.runtimeEnvironment)) {
            console.log('Amplitude User:', {
                userId: IAMUserId,
                userType,
                createDate: this._accountService.userData.createDate,
                userAge,
                userTenant: selectedInstance?.name,
                defaultTeam: this._accountService.userData.defaultTeam
            });
        }

        this._userPropertiesSet = true;
    }

    private _getIAMUserId(): string {
        if (this._featureFlagsService.featureFlags.enableIAM && this._accountService.userData?.iamId) {
            return this._accountService.userData.iamId;
        }
        return null;
    }
}
