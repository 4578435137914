<ws-modal-wrapper headerLabel="Process - Appeal Warranted"
                  acceptLabel="Process"
                  [cancelLabel]="cancelLabel"
                  [hideSave]="!appealLevels"
                  [disableSave]="loading || !!validationMessage"
                  [disableCancel]="loading"
                  (save)="process()"
                  (cancel)="cancel()">
    <loading-spinner [show]="loading" [marginTop]="20" [size]="4"></loading-spinner>
    <h4 *ngIf="loading && loadingMessage" class="text-center mt-4">{{loadingMessage}}</h4>
    <div *ngIf="!loading && payload">
        <p class="lead text-danger mb-0" *ngIf="isClientApprovalRequired">Please confirm client approval has been received prior to processing.</p>
        <p class="lead text-danger mb-0" *ngIf="validationMessage">{{ validationMessage }}</p>
        <div *ngIf="appealLevels">
            <label>Appeal Level:</label>
            <ws-select [options]="appealLevels"
                       labelProperty="abbr"
                       valueProperty="appealLevelID"
                       [(ngModel)]="payload.appealLevelID">
            </ws-select>
            <div class="row mt-3">
                <div class="col-lg-2">
                    Comment:
                </div>
                <div class="col-lg-10">
                    <textarea class="form-control mb-3"
                              cols="40"
                              rows="5"
                              [(ngModel)]="payload.comment">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
