import { LandingViewTypes } from '../../Landing/landing.component';
import { SaveListItem } from '../../Navigation/User-Save/userSavePage.model';

export abstract class ActionViewService {
    abstract processSearchResultPlusRequest(searchResults: any);
    abstract fixAPISearchRequest(searchRequest: any);
    abstract executeActionView(filter: any): Promise<any>;
    abstract welcomeSearch(viewMode: LandingViewTypes): Promise<any>;
    abstract getUrgencyColumnStyleClass(sequence: number): string;
    abstract cancelAvSearch(): void;
    abstract getCategories(): Promise<Core.ActionViewSavedSearchCategoryManagementDTO[]>;
    abstract getTaskTypes(): Promise<any>;
    abstract sortTaskTypes(taskTypes: any[]): any[];
    abstract launchCustomOutputModal(clickedTaskType: any, taskTypeColumns: any[]): Promise<any>;
    abstract patch(actionView: any, actionViewType: number): Promise<void>;
    abstract changeCategory(actionView: any, categoryId: string, actionViewType: number): Promise<void>;
    abstract createCategory(category: any): Promise<any>;
    abstract share(actionViewItem: Core.ActionViewListDTO): Promise<void>;
    abstract toggleFavorite(actionViewId: number, isFavorite: boolean): Promise<void>;
    abstract deleteCategory(actionViewItem: Core.ActionViewListDTO, categoryType: number): Promise<void>;
    abstract deleteSystemActionView(actionViewItem: Core.ActionViewListDTO): Promise<void>;
    abstract deleteCustomActionView(actionViewItem: Core.ActionViewListDTO): Promise<void>;
    abstract getFavoriteCount(actionViewItem: Core.ActionViewListDTO): Promise<number>;
}
