import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentBatchService } from '../../paymentBatch.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { NavigationService } from 'src/app/Layout/Navigation.Service.upgrade';

@Component({
    selector: 'payment-batch-settings',
    templateUrl: 'paymentBatchSettings.component.html'
})

export class PaymentBatchSettingsComponent implements OnInit {
    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _paymentBatchService: PaymentBatchService,
        private readonly _navigationService: NavigationService
    ) {}

    @Input() paymentBatch: Core.PaymentBatchModel;
    @Input() workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;
    @Output() editChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    settingsForm: UntypedFormGroup;
    editMode: boolean = false;
    settings: Core.PaymentBatchSettingsModel;
    saving: boolean = false;


    async ngOnInit(): Promise<void> {
        this.settings = await this._paymentBatchService.getPaymentBatchSettings(this.paymentBatch.paymentBatchId);

        this.settingsForm = this._fb.group({
            maxItemsPerCheck: [this.settings.maxItemsPerCheck, [Validators.min(0), Validators.max(1000)]]
        });
    }

    get canEdit() {
        return this.workflowStatus.canModifyMaxItemsPerCheck;
    }

    get maxItemsPerCheck(): UntypedFormControl {
        return this.settingsForm.get('maxItemsPerCheck') as UntypedFormControl;
    }

    edit() {
        this.editMode = true;
        this.editChange.emit(true);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    async save() {
        this.saving = true;

        try {
            const formValues = this.settingsForm.getRawValue();
            formValues.maxItemsPerCheck = formValues.maxItemsPerCheck > 0 ? formValues.maxItemsPerCheck : null;
            const update = {
                ...cloneDeep(this.settings),
                ...formValues
            };

            await this._paymentBatchService.savePaymentBatchSettings(this.paymentBatch.paymentBatchId, update);

            this.settings.maxItemsPerCheck = formValues.maxItemsPerCheck;
            this._exitEditMode();

        } finally {
            this.saving = false;
        }
    }

    cancel() {
        this._exitEditMode();
        this.settingsForm.reset(this.settings);
    }

    private _exitEditMode() {
        this._navigationService.disableNavWarning();
        this.editMode = false;
        this.editChange.emit(false);
    }
}