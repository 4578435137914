import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable({ providedIn: 'root' })
export class SearchRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/search';
    private readonly DEFAULT_CRITERIA_NAME = 'Search-Quick-Saved-Criteria';

    searchAdvanced(searchTerms): Observable<any> {
        return this.httpPost(this.SERVICE_URL, searchTerms);
    }

    search(query): Observable<any> {
        return this.httpPost(`${this.SERVICE_URL  }/quick`, query);
    }
}
