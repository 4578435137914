import {Component, OnInit, ViewEncapsulation, OnDestroy} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { CompanyRepository, ImportSpecificationRepository } from '../../../Repositories';
import { RestrictService, Roles } from '../../../../Common/Permissions/restrict.service';
import { AccountService } from '../../../../User/account.service';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridOptionsBuilder } from '../../../AgGrid';
import * as _ from "lodash";
import { IWeissmanModalComponent } from '../../../WeissmanModalService';

export interface EntityImportSpecificationEditParams {
    specificationId: number;
}

interface EditFormResultValues {
    displayName: string;
    visibility: string;
    companyId: number;
    isDisabled: boolean;
}

@Component({
    selector: 'import-specification-edit',
    templateUrl: './importSpecificationEdit.component.html',
    styleUrls: ['./importSpecificationEdit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EntityImportSpecificationEditComponent implements OnInit, OnDestroy, IWeissmanModalComponent<EntityImportSpecificationEditParams, Compliance.ImportFileSpecificationModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _companyRepository: CompanyRepository,
        private readonly _accountService: AccountService,
        private readonly _restrictService: RestrictService,
        private readonly _entityImportSpecificationRepository: ImportSpecificationRepository) {}

    private readonly visibilityPrivate: string = 'Private';
    private readonly visibilityCompany: string = 'Company';
    private readonly visibilityShared: string = 'Shared';
    private _gridApi: GridApi;
    private destroy$: Subject<void> = new Subject<void>();

    params: EntityImportSpecificationEditParams;
    result: Compliance.ImportFileSpecificationModel;

    specification: Compliance.ImportFileSpecificationModel;
    visibilityOptions: string[] = [];
    editForm: UntypedFormGroup;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    companyFilter: string = '';
    companiesLoading: boolean = false;
    selectedCompanyId: number;
    companiesDataSource$: Observable<Core.CompanyDTO[]> = (Observable
        .create((observer: any) => { observer.next(this.companyFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterCompanies(token)));
    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withColumnResize()
        .withLoadingOverlay()
        .build();
    canEdit: boolean = false;
    isInitialized: boolean = false;

    async ngOnInit(): Promise<void> {

        const busyMsg = 'loading';

        this.busyIndicatorMessageManager.add('Loading', busyMsg);
        try {
            this.specification = await lastValueFrom(this._entityImportSpecificationRepository.get(this.params.specificationId))
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        this.selectedCompanyId = this.specification.companyId;
        this.companyFilter = this.specification.companyName;

        if (this.specification.userId === this._accountService.userData.id) {
            this.visibilityOptions.push(this.visibilityPrivate);
        }

        this.visibilityOptions.push(this.visibilityCompany);

        if (this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT)) {
            this.visibilityOptions.push(this.visibilityShared);
        }

        this.canEdit = this.specification.userId === this._accountService.userData.id;

        this.editForm = new UntypedFormGroup({
            displayName: new UntypedFormControl({value: this.specification.displayName, disabled: !this.canEdit},[Validators.required]),
            visibility: new UntypedFormControl({value: this.specification.visibility, disabled: !this.canEdit}),
            companyName: new UntypedFormControl({
                value: this.specification.companyName,
                disabled: !this.canEdit || (this.specification.visibility !== this.visibilityCompany)
            }, [Validators.required]),
            isDisabled: new UntypedFormControl({value: this.specification.isDisabled, disabled: !this.canEdit})
        });

        this.editForm.get('visibility').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
            if (v === this.visibilityCompany) {
                this.editForm.get('companyName').enable();
            } else {
                this.editForm.get('companyName').disable();
            }
        });

        this._setRowData();

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async save(): Promise<void> {
        const busyMsg = 'saving';

        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        try {
            const formValues = this.editForm.value as EditFormResultValues;

            const updateModel: Compliance.ImportFileSpecificationUpdateModel = {
                companyId: formValues.visibility === this.visibilityCompany ? this.selectedCompanyId : null,
                displayName: formValues.displayName,
                importFileSpecificationId: this.specification.importFileSpecificationId,
                isDisabled: formValues.isDisabled,
                isSystem: formValues.visibility === this.visibilityShared
            };

            this.result = await lastValueFrom(this._entityImportSpecificationRepository.update(updateModel));

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    onCompanySelected(typeAheadMatch: TypeaheadMatch): void {
        const company = typeAheadMatch.item as Core.CompanyDTO;
        this.editForm.get('companyName').setValue(company.companyName);
        this.selectedCompanyId = company.companyID;
    }

    onCompanyBlur(): void {
        if (!this.companyFilter || this.companyFilter.trim() === '') {
            this.editForm.get('companyName').setValue(null);
            this.selectedCompanyId = null;
        }
    }

    onCompanyLoadingChange(isLoading: boolean): void {
        this.companiesLoading = isLoading;
    }

    onAgGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Import Field Name',
                field: `importFieldName`,
                width: 100,
                minWidth: 50
            },
            {
                headerName: 'Column Index',
                field: `columnIndex`,
                width: 70,
                minWidth: 50
            },
            {
                headerName: 'Static Value',
                field: `staticValue`,
                width: 100,
                minWidth: 50,
                valueFormatter: params => {
                    const data = params.data as Compliance.ImportFileSpecificationFieldModel;
                    return data.isStatic ? params.value : ''
                }
            },
            {
                headerName: 'Import Field Data Type',
                field: `importFieldDataType`,
                width: 100,
                minWidth: 50,
                valueFormatter: params =>{
                    const data = params.data as Compliance.ImportFileSpecificationFieldModel;
                    let result: string;

                    const fieldDataType = <keyof typeof Compliance.ImportFieldDataTypeEnum>data.importFieldDataType;
                    switch (fieldDataType) {
                        case Compliance.ImportFieldDataTypeEnum.YesNo.toString():
                        case Compliance.ImportFieldDataTypeEnum.YesNoToTrueFalse.toString():
                            result = 'Yes/No';
                            break;
                        case Compliance.ImportFieldDataTypeEnum.YesNoBlank.toString():
                        case Compliance.ImportFieldDataTypeEnum.YesNoBlankDefaultNo.toString():
                            result = 'Yes/No/Blank';
                            break;
                        default:
                            result = data.importFieldDataType;
                            break;
                    }

                    return result;
                }
            },
            {
                headerName: 'Min Value/Length',
                field: `min`,
                width: 100,
                minWidth: 50,
                valueFormatter: params => {
                    const data = params.data as Compliance.ImportFileSpecificationFieldModel;
                    let result = '';

                    if (data.min) {
                        const fieldDataType = <keyof typeof Compliance.ImportFieldDataTypeEnum>data.importFieldDataType;

                        switch (fieldDataType) {
                            case Compliance.ImportFieldDataTypeEnum.Currency.toString():
                                result = data.min.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                                break;
                            case Compliance.ImportFieldDataTypeEnum.Integer.toString():
                            case Compliance.ImportFieldDataTypeEnum.Text.toString():
                                result = data.min.toFixed().toLocaleString();
                                break;
                            default:
                                result = data.min.toLocaleString();
                                break;
                        }
                    }

                    return result;
                }
            },
            {
                headerName: 'Max Value/Length',
                field: `max`,
                width: 100,
                minWidth: 50,
                valueFormatter: params => {
                    const data = params.data as Compliance.ImportFileSpecificationFieldModel;
                    let result = '';

                    if (data.max) {
                        const fieldDataType = <keyof typeof Compliance.ImportFieldDataTypeEnum>data.importFieldDataType;

                        switch (fieldDataType) {
                            case Compliance.ImportFieldDataTypeEnum.Currency.toString():
                                result = data.max.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                                break;
                            case Compliance.ImportFieldDataTypeEnum.Integer.toString():
                            case Compliance.ImportFieldDataTypeEnum.Text.toString():
                                result = data.max.toFixed().toLocaleString();
                                break;
                            default:
                                result = data.max.toLocaleString();
                                break;
                        }
                    }

                    return result;
                }
            }
        ];

        this._gridApi.setColumnDefs(columns);

        this._setRowData();
    }

    private _filterCompanies(searchValue: string): Observable<Core.CompanyDTO[]> {
        return this._companyRepository.searchCompanies(searchValue);
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.specification)) {
            return;
        }

        let gridData: Compliance.ImportFileSpecificationFieldModel[] = [];

        let lastColumnIndex: number = 1;
        _.forEach(this.specification.fields.sort(field => field.columnIndex), field => {
            for (lastColumnIndex; lastColumnIndex < field.columnIndex; lastColumnIndex++){
                gridData.push({columnIndex: lastColumnIndex} as Compliance.ImportFileSpecificationFieldModel);
            }
            gridData.push(field);
            lastColumnIndex++;
        })

        this._gridApi.setRowData(gridData);
        this._gridApi.sizeColumnsToFit();
    }
}
