import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnnualDetailsModule } from '../../Annual-Details/annual-details.module';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { UILibModule } from '../../UI-Lib/UILib.module';
import { EntityEditComponent } from './Entity-Edit/entity-edit.component';
import { IdpDetailCardComponent } from './Entity-Edit/IDP/Detail-Modal/Card/idpDetailCard.component';
import { IdpDetailModalComponent } from './Entity-Edit/IDP/Detail-Modal/idpDetailModal.component';
import { IdpConfidencePanelComponent } from './Entity-Edit/IDP/idpConfidencePanel.component';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule,
        UILibModule,
        CurrencyPipe,
        AnnualDetailsModule
    ],
    declarations: [
        EntityEditComponent,
        IdpConfidencePanelComponent,
        IdpDetailModalComponent,
        IdpDetailCardComponent
    ],
    exports: [IdpConfidencePanelComponent]
})
export class DocumentProcessingModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('processingEntityEdit', EntityEditComponent);
        hybridAdapterUtility.downgradeNg2Component('idpConfidencePanel', IdpConfidencePanelComponent);
    }
}
