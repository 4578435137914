import { Injectable } from '@angular/core';

import * as _ from 'lodash';

export interface Wildcard {
    wildcard: { [key: string]: string };
}

export interface Range {
    range: { [key: string]: { [key: string]: string } };
}

export interface NotOperator {
    bool: {
        must_not: Wildcard[]
    };
}

export interface SearchOperatorOr {
    value: string;
    operator: SearchOperator;
}

export interface SearchOperator {
    id: number;
    name: string;
    type: string;
    should: (field: string, text: string) => any;
}

export interface DisplayName {
    displayName: string;
    sortOrder: number;
    name?: string;
}

interface DisplayNames {
    [key: string]: DisplayName;
}

@Injectable({ providedIn: 'root' })
export class SearchOperatorsService {
    constructor() {
        this._operators = this._initializeOperators();
    }

    private _operators: SearchOperator[];

    getOperatorArray(keys: string[]): DisplayName[] {
        const map = this.displayNameMap();

        return keys.map((key) => {
            return {...map[key], ...{ name: key }};
        });
    }

    getOr(filterOperators): SearchOperatorOr {
        const operator = filterOperators.find(x => x.name === 'beginsWith') || _.minBy(filterOperators, 'sortOrder');

        return {
            value: '',
            operator: operator
        };
    }

    getCompanyOr(filterOperators): SearchOperatorOr {
        const operator = filterOperators.find(x => x.name === 'equals') || _.minBy(filterOperators, 'sortOrder');

        return {
            value: '',
            operator: operator
        };
    }

    getById(id: number): SearchOperator {
        return this._operators.find(x => x.id === id);
    }

    getByFilter(filter): SearchOperator[] {
        if (!filter) {
            return;
        }

        let filterOps = this._operators.filter(x => x.type === 'all');

        if (filter.nullable) {
            filterOps = _.union(filterOps, this._operators.filter(x => x.type === 'nullable'));
        }

        if (filter.numeric) {
            filterOps = _.union(filterOps, this._operators.filter(x => x.type === 'numeric'));
        } else {
            filterOps = _.union(filterOps, this._operators.filter(x => x.type === 'alpha'));
        }

        return filterOps;
    }

    displayNameMap(): DisplayNames {
        return {
            equal: {
                displayName: 'Equals',
                sortOrder: 1
            },
            notEqual: {
                displayName: 'Does Not Equal',
                sortOrder: 2
            },
            contains: {
                displayName: 'Contains',
                sortOrder: 3
            },
            doesNotContain: {
                displayName: 'Does Not Contain',
                sortOrder: 4
            },
            beginsWith: {
                displayName: 'Begins With',
                sortOrder: 5
            },
            endsWith: {
                displayName: 'Ends With',
                sortOrder: 6
            },
            blank: {
                displayName: 'Is Blank',
                sortOrder: 7
            },
            notBlank: {
                displayName: 'Is Not Blank',
                sortOrder: 8
            },
            lessThan: {
                displayName: 'Is Less Than',
                sortOrder: 9
            },
            greaterThan: {
                displayName: 'Is Greater Than',
                sortOrder: 10
            },
            lessThanEqual: {
                displayName: 'Less Than Equal',
                sortOrder: 11
            },
            greaterThanEqual: {
                displayName: 'Greater Than Equal',
                sortOrder: 12
            },
            nullable: {
                displayName: 'Nullable',
                sortOrder: 13
            },
            isNA: {
                displayName: 'Is N/A',
                sortOrder: 14
            },
            withinDate: {
                displayName: 'Is Within',
                sortOrder: 15
            }
        };
    }

    private _initializeOperators(): SearchOperator[] {
        return [
            {
                id: 0,
                name: 'Equals',
                type: 'all',
                should: this._createWildcard
            },
            {
                id: 1,
                name: 'Does Not Equal',
                type: 'all',
                should: (field: string, text: string) => {
                    return this._makeNot(this._createWildcard(field, text));
                }
            },
            {
                id: 2,
                name: 'Is Blank',
                type: 'nullable',
                should: (field: string, text: string) => {
                    return this._createWildcard(field, '');
                }
            },
            {
                id: 3,
                name: 'Is Not Blank',
                type: 'nullable',
                should: (field: string, text: string) => {
                    return this._makeNot(this._createWildcard(field, ''));
                }
            },
            {
                id: 4,
                name: 'Is N/A',
                type: 'nullable',
                should: (field: string, text: string) => {
                    return this._createWildcard(field, '');
                }
            },
            {
                id: 5,
                name: 'Within Date',
                type: 'numeric',
                should: (field: string, text: string) => {
                    return this._createRange(field, text, 'gt');
                }
            },
            {
                id: 6,
                name: 'Contains',
                type: 'alpha',
                should: (field: string, text: string) => {
                    return this._createWildcard(field, `*${  text  }*`);
                }
            },
            {
                id: 7,
                name: 'Does Not Contain',
                type: 'alpha',
                should: (field: string, text: string) => {
                    return this._makeNot(this._createWildcard(field, `*${  text  }*`));
                }
            },
            {
                id: 8,
                name: 'Begins With',
                type: 'alpha',
                should: (field: string, text: string) => {
                    return this._createWildcard(field, `${text  }*`);
                }
            },
            {
                id: 9,
                name: 'Ends With',
                type: 'alpha',
                should: (field: string, text: string) => {
                    return this._createWildcard(field, `*${  text}`);
                }
            },
            {
                id: 10,
                name: '>',
                type: 'numeric',
                should: (field: string, text: string) => {
                    return this._createRange(field, text, 'gt');
                }
            },
            {
                id: 11,
                name: '>=',
                type: 'numeric',
                should: (field: string, text: string) => {
                    return this._createRange(field, text, 'gte');
                }
            },
            {
                id: 12,
                name: '<',
                type: 'numeric',
                should: (field: string, text: string) => {
                    return this._createRange(field, text, 'lt');
                }
            },
            {
                id: 13,
                name: '<=',
                type: 'numeric',
                should: (field: string, text: string) => {
                    return this._createRange(field, text, 'lte');
                }
            }
        ];
    }

    private _createWildcard(field: string, text: string): Wildcard {
        const wildcard = {};

        if (typeof text === 'string') {
            text = text.toLowerCase();
        }

        wildcard[field] = text;
        return {
            wildcard: wildcard
        };
    }

    private _createRange(field: string, text: string, op: string): Range {
        const range = {};
        const opObj = {};

        opObj[op] = text;

        range[field] = opObj;

        return {
            range: range
        };
    }

    private _makeNot(entity: Wildcard): NotOperator[] {
        return [{
            bool: {
                must_not: [entity]
            }
        }];
    }
}
