(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdDocumentProcessingSearch', sdDocumentProcessingSearch);

	// sdDocumentProcessingSearch.$inject = ['dependencies'];

	function sdDocumentProcessingSearch() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Processing/Documents/_documentProcessingQuickSearch.html',
			scope: {
				currentDocument: '=',
				showCriteria: '=',
				criteria: '=',
				resultTypes: '=',
                attachmentTypeId: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {
			scope.$on("changeProcessingDocument", function () {
				scope.vm.refreshCriteria();
			});
		}
	}

	Controller.$inject = ['processingService', '$timeout'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(processingService, $timeout) {
		var vm = this;

		activate();

		function activate() {
			vm.savingCriteria = false;
		}

		vm.search = function () {
		    if (vm.currentDocument && vm.currentDocument.intakeItem) {
		        vm.criteria.currentIntakeItemID = vm.currentDocument.intakeItem.intakeItemID;
		    }
		    vm.criteria.includeIntakeItemData = true;
			vm.criteria.executeSearch = false;
			if (vm.criteria.anticipated) {
                vm.criteria.anticipatedAttachmentTypeID = vm.attachmentTypeId;
			}

			$timeout(function () {
				vm.criteria.executeSearch = true;
			});

			vm.showCriteria = false;
		}

		vm.saveCriteria = async function () {
			vm.savingCriteria = true;

			try {
				await processingService.saveCriteria(_.omit(vm.criteria, 'resultType'))
			} finally {
				vm.savingCriteria = false;
			}
		}

		vm.refreshCriteria = function ()  {
			_.assign(vm.criteria, processingService.getCriteria());
		}
	}
})();
