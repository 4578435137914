import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ColDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
    AgGridColumns,
    AgGridDropdownCellEditor,
    AgGridFilterParams,
    AgGridNumberCellEditor,
    AgGridOptionsBuilder
} from '../../Compliance/AgGrid';
import { AppealRecommendationRepository } from '../appealRecommendation.repository';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import {
    ExpandCellRendererComponent,
    ICellRendererParamsForExpandCellRenderer
} from 'src/app/Common/AgGrid/agGridExpandCellRenderer.component';
import {
    ExpandCellHeaderRendererComponent,
    ICellRendererParamsForExpandCellHeaderParams
} from '../../Common/AgGrid/agGridExpandCellHeaderRenderer.component';
import {
    AgGridMultiSelectCellRendererParams,
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../Compliance/AgGrid/MultiSelectTracker';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { takeUntil } from 'rxjs/operators';
import { AppealRecommendationGridRowInfoTreeItemModel } from './appealRecommendationGridRowInfoTreeItemModel';
import { WeissmanDateFormatPipe } from '../../UI-Lib/Pipes';
import { DecimalPipe, PercentPipe } from '@angular/common';
import {
    AgGridYesNoFloatingFilterComponent
} from '../../Compliance/AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import {
    AgGridExportOptions,
    AgGridExportStartLRP,
    AgGridToolPanelButton
} from '../../Compliance/AgGrid/ToolPanel/models';
import { DESCRIPTOR_COLUMN_DEFINITIONS } from '../../Compliance/AgGrid/agGridDescriptorColumnDefenition';
import { AppealRecommendationCommandCenterGridActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { AgGridTooltipCellRenderer } from '../../Compliance/AgGrid/CellRenderers/agGridTooltipCellRenderer.component';
import {
    AppealRecommendationTemplateComponent
} from '../Appeal-Recommendation-Template/appealRecommendationTemplate.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StateService } from '../../Common/States/States.Service';
import { StateSummary } from '../../Common/States/state.model';
import { ToastrService } from 'ngx-toastr';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { APPEAL_RECOMMENDATION_COMMAND_CENTER_HELP } from './aRCC.component.help';
import {
    AppealRecommendationCommandCenterBulkUpdateParams,
    ARCCBulkUpdateComponent
} from './ARCC-Bulk-Update/aRCCBulkUpdate.component';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import {
    AppealRecommendationCommandCenterBulkUpdateResultConfirmationComponentParams,
    ARCCBulkUpdateResultConfirmationComponent
} from './ARCC-Bulk-Update/ARCC-Bulk-Update-Result-Confirmation/aRCCBulkUpdateResultConfirmation.component';
import {
    ProcessAppealWarrantedComponent,
    ProcessAppealWarrantedParams
} from '../../Processing/Process-Appeal-Warranted-Modal/processAppealWarranted.component';
import {
    ProcessNoAppealComponent,
    ProcessNoAppealParams
} from '../../Processing/Process-No-Appeal-Modal/processNoAppeal.component';
import { TaskModalsService } from '../../Task/taskModals.service';
import DescriptorUsageEnum = Core.DescriptorUsageEnum;
import DescriptorFieldTypes = Core.DescriptorFieldTypes;
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { BulkEditButtonOptions } from '../../Compliance/AgGrid/ToolPanel/agGridToolPanel.component';

interface SelectionValidationResult {
    isValid: boolean;
    validationMessage: string;
}

@Component({
    selector: 'appeal-recommendation-command-center',
    templateUrl: './aRCC.component.html',
    styleUrls: ['./aRCC.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ARCCComponent implements OnInit, OnDestroy {

    constructor(
        private readonly _appealRecommendationRepository: AppealRecommendationRepository,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _percentPipe: PercentPipe,
        private readonly _messageModalService: MessageModalService,
        private readonly _restrictService: RestrictService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _modalService: BsModalService,
        private readonly _weissmanModalService: WeissmanModalService,
        private readonly _stateService: StateService,
        private toastr: ToastrService,
        private readonly _helpService: HelpService,
        private readonly _taskModalService: TaskModalsService) {
    }

    isInitialized: boolean = false;
    refreshing: boolean = false;
    gridId: System.Guid = 'FDEA0E55-AFE5-40DE-BE50-D85B70ED24EF';
    gridTracker: AgGridMultiSelectTracker;
    states: StateSummary[] = [];
    selectedStates: number[] = [];
    appealDeadlineDays: number = 45;

    //It has to be out of the order as it is used in exportOptions.
    private _hasSelectedRows: boolean = false;
    private _assignedFilter: Core.AppealRecommendationCCAssignedFilterModel;

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum, customExportType: number): Promise<AgGridExportStartLRP> => {
            const selectedRowsOrdered: string[]= this._mappingViewData
                .filter(x => this._selectedRowIds.indexOf(x.entityId) !== -1)
                .map(x => x.entityId);

            const exportModel: Core.AppealRecommendationCCExportModel = {
                stateIds: this.selectedStates,
                appealDeadlineDays: this.appealDeadlineDays,
                exportType: customExportType,
                columnsToReturn: columnsToReturn,
                selectedRows: selectedRowsOrdered,
                assignedFilter: this._assignedFilter
            };

            const lrp$ = this._appealRecommendationRepository.startExport(exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportAppealRecommendationCommandCenter };
        },
        getDisabled: () => !this._hasSelectedRows,
        canCancel: true,
        showFileFormatSelection: true,
        availableFileFormats: [],
        customExportFileFormats: [
            {
                name: 'Sites Only',
                value: Core.AppealRecommendationCommandCenterExportTypeEnum.SitesOnly,
                isDisabled: () => this._sitesOnlyExportOptionDisabled(),
                disabledTooltip: 'Selection does not include sites.'
            },
            {
                name: 'Sites and Parcels',
                value: Core.AppealRecommendationCommandCenterExportTypeEnum.SitesAndParcels,
                isDisabled: () =>  this._parcelsOnlyExportOptionDisabled(),
                disabledTooltip: 'Selection does not include parcels.'
            }
        ]
    };

    additionalButtons = [{
            icon: 'fa-regular fa-envelope',
            getTooltipText: () => this._canOpenEmailPreview().validationMessage,
            disabled: () => !this._canOpenEmailPreview().isValid,
            onClickCallback: () => this._showAppealRecommendationEmailModal()
        }] as AgGridToolPanelButton[];

    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as AppealRecommendationGridRowInfoTreeItemModel).entityId),
                'no-filter-applies': (params) => params.data && this._siteRequiredFilterSet.has(params.data.entityId) && !params.data.parcelId,
                'has-error': (params) => params.data && (params.data as AppealRecommendationGridRowInfoTreeItemModel).hasError
            },
            singleClickEdit: true,
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            getRowNodeId: (data) => data.entityId,
        })
        .withColumnResize()
        .withLoadingOverlay()
        .withContext(this)
        .withTextSelection()
        .withoutCellEditingStoppedOnGridLostFocus()
        .withColumnPinning()
        .withSort()
        .build();

    isBulkUpdateVisible$: BehaviorSubject<boolean | BulkEditButtonOptions> = new BehaviorSubject(false);

    private _gridApi: GridApi;
    private _gridColumnApi: ColumnApi;
    private _destroy$: Subject<void> = new Subject();

    private _canExpandAllRows: boolean = true;

    private _expandedSites = new Set<string>();
    private _collapsedSites = new Set<string>();
    private _filteredOutParcelsMap: Map<number, Core.AppealRecommendationGridRowInfoModel[]> = new Map();
    private _siteRequiredFilterSet: Set<string> = new Set();
    private _redrawGrid: boolean = false;

    private _sites: AppealRecommendationGridRowInfoTreeItemModel[] = [];
    private _parcels: AppealRecommendationGridRowInfoTreeItemModel[] = [];
    private _descriptorInfo: Core.DescriptorInfoModel[] = [];
    private _descriptorValues: Core.AppealRecommendationDescriptorValueModel[] = [];
    private _picklistInfo: Core.DescriptorPicklistModel[] = [];
    private _mappingViewData: AppealRecommendationGridRowInfoTreeItemModel[] = [];
    private _appealRecommendationStatuses: Core.AppealRecommendationStatusModel[];
    private _selectedRowIds: string[] = [];
    private _searchTimeStamp: Date;

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(APPEAL_RECOMMENDATION_COMMAND_CENTER_HELP);

        if (!this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW) && !this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT)) {
            this._routerService.go('unauthorizedAccess', {});
        }

        const busyRef = this._busyIndicatorService.show({message: 'Loading'});

        try {
            const [descriptorMetadata, statuses, states] = await Promise.all([
                lastValueFrom(this._appealRecommendationRepository.getDescriptorMetadata()),
                lastValueFrom(this._appealRecommendationRepository.getAppealRecommendationStatuses()),
                this._stateService.getSummary()
            ]);

            this._descriptorInfo = descriptorMetadata.descriptors;
            this._picklistInfo = descriptorMetadata.pickList;
            this._appealRecommendationStatuses = statuses.sort((x, y) => x.id - y.id);
            this.states = states.filter(x => x.countryID === 1);
        } finally {
            await busyRef.hide();
        }

        this.isInitialized = true;
    }

    get isRefreshDisabled(): boolean {
        return !this.isInitialized || this.selectedStates.length === 0;
    }

    onAssignedFilterChange(value: Core.AppealRecommendationCCAssignedFilterModel) {
        this._assignedFilter = value;
    }

    private async _loadData() {
        if (this.appealDeadlineDays > 120) {
            this.toastr.error('Appeal Deadline should be within 120 days.');
        } else if (this.appealDeadlineDays < 1) {
            this.toastr.error('Number of Days to Appeal Deadline is required.');
        } else if (this.selectedStates.length !== 0) {
            const busyRef = this._busyIndicatorService.show({message: 'Loading'});

            const searchModel: Core.AppealRecommendationCCSearchModel = {
                stateIds: this.selectedStates,
                appealDeadlineDays: this.appealDeadlineDays,
                assignedFilter: this._assignedFilter
            };

            try {
                const data = await lastValueFrom(this._appealRecommendationRepository.getList(searchModel));

                this._sites = data.siteInfo.map(x => new AppealRecommendationGridRowInfoTreeItemModel(x));
                this._parcels = data.parcelInfo.map(x => new AppealRecommendationGridRowInfoTreeItemModel(x));
                this._descriptorValues = data.descriptorValues;
                this._searchTimeStamp = data.searchTimeStamp;

            } finally {
                await busyRef.hide();
            }
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        // get API objects and start setting up the AgGrid
        this._gridApi = event.api;
        this._gridColumnApi = event.columnApi;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(async () => {
            const hasSelectedRows = this.gridTracker.hasSelectedRows();

            const isBulkUpdateVisible = BulkEditButtonOptions.Create({
                show: hasSelectedRows
            });

            if (isBulkUpdateVisible) {
                const selectedRows = await this.gridTracker.getSelectedRowIds();
                const selectedEntityIds = selectedRows.map(x => x.toString());

                const disableBulkUpdate = !this._getCanChangeAppealRecommendationStatus(selectedEntityIds) &&
                    !this._getCanChangeIsClientApproved(selectedEntityIds) &&
                    this._getVisibleDescriptorInfo().length === 0;

                if (disableBulkUpdate) {
                    isBulkUpdateVisible.disable = true;
                    isBulkUpdateVisible.toolTipId = 'ag-grid-tool-panel.bulk-update-empty'
                }
            }

            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
            this._hasSelectedRows = hasSelectedRows;
            const selectedRows = await this.gridTracker.getSelectedRowIds();

            this._selectedRowIds = selectedRows.map(x => x.toString());
        });

        this._setColumns();
        this._setRowData(true);
    }

    getDescriptorValue = (model: AppealRecommendationGridRowInfoTreeItemModel, descriptor: Core.DescriptorInfoModel): any => {
        if (model) {
            return this._getDescriptorValue(model.siteId, model.parcelId, descriptor.descriptorId, descriptor.fieldType);
        }
    };

    onAgGridFilterChanged(event: FilterChangedEvent) {
        const filters = event.api.getFilterModel();
        let numberExpanded = 0;
        const hadRequiredSites = this._siteRequiredFilterSet.size;

        this._siteRequiredFilterSet.clear();

        if (!Object.keys(filters).length) {
            this._setRowData();
            return;
        }

        this._collapsedSites.forEach((entityId: string) => {
            const site = this._sites.find(x => x.entityId === entityId);

            if (!site) {
                this._collapsedSites.delete(entityId);
                return;
            }

            const expandSites: string[] = [];

            for (const fieldName in filters) {
                const filter = filters[fieldName].filterValues[0];
                const filterMethod = filter.filterType.displayKey;
                const filterValue = filter.filterValue;

                switch (fieldName) {
                    case 'entityName':
                    case 'responsibleUser':
                    case 'state':
                    case 'assessorName':
                    case 'class':
                    case 'propertyType':
                    case 'accountHandler':
                    case 'companyName':
                    case 'revisionName':
                    case 'team':
                    case 'topLevelCompanyName':
                    case 'priorYearAppeal':
                    case 'appealLevel':
                    case 'assesseeName':
                        if (this._filterString(fieldName, filterMethod, site[fieldName], filterValue, site)) {
                            expandSites.push(site.entityId);
                        }
                        break;
                    case 'appealDeadline':
                    case 'createDate':
                    case 'emailSentDate':
                        if (this._filterDate(fieldName, filterMethod, this._datePipe.transform(site[fieldName], false, 'central'), filterValue, site)) {
                            expandSites.push(site.entityId);
                        }
                        break;
                    case 'taxYear':
                    case 'originalFMV':
                    case 'currentFMV':
                    case 'priorYearFMV':
                    case 'targetValue':
                    case 'originalLandFMV':
                    case 'originalImpsFMV':
                    case 'originalPersFMV':
                    case 'originalAltFMV':
                    case 'currentLandFMV':
                    case 'currentImpsFMV':
                    case 'currentPersFMV':
                    case 'currentAltFMV':
                    case 'priorYearLandFMV':
                    case 'priorYearImpsFMV':
                    case 'priorYearPersFMV':
                    case 'priorYearAltFMV':
                    case 'originalAV':
                    case 'originalLandAV':
                    case 'originalImpsAV':
                    case 'originalPersAV':
                    case 'originalAltAV':
                    case 'currentAV':
                    case 'currentLandAV':
                    case 'currentImpsAV':
                    case 'currentPersAV':
                    case 'currentAltAV':
                    case 'priorYearAV':
                    case 'priorYearLandAV':
                    case 'priorYearImpsAV':
                    case 'priorYearPersAV':
                    case 'priorYearAltAV':
                    case 'savings':
                    case 'totalDollarSqFt':
                    case 'totalDollarUnit':
                    case 'totalLandDollarAcre':
                    case 'totalLandDollarSqFt':
                    case 'totalPriorYearDollarSqFt':
                    case 'totalPriorYearDollarUnit':
                    case 'totalPriorYearLandDollarAcre':
                    case 'totalPriorYearLandDollarSqFt':
                        if (this._filterNumber(fieldName, filterMethod, site[fieldName], filterValue, site)) {
                            expandSites.push(site.entityId);
                        }
                        break;
                    case 'isReady':
                        if (this._filterBoolean(fieldName, filterMethod, site[fieldName].toString(), filterValue, site)) {
                            expandSites.push(site.entityId);
                        }
                        break;
                    case 'fmvVariance':
                        if (this._filterPercent(fieldName, filterMethod, site[fieldName], filterValue, site)) {
                            expandSites.push(site.entityId);
                        }
                        break;
                    default:
                        if (fieldName.startsWith('d.')){
                            const descriptorId = +fieldName.substring(2);
                            const descriptorInfo = this._descriptorInfo.find(x => x.descriptorId === descriptorId);
                            const descriptorValue = this._descriptorValues.find(x =>
                                x.siteId === site.siteId && x.parcelId === 0 && x.descriptorId === descriptorId);

                            if (!descriptorValue) {
                                break;
                            }

                            switch (descriptorInfo.fieldType) {
                                case DescriptorFieldTypes.Text:
                                case DescriptorFieldTypes.Picklist:
                                    if (this._filterString(fieldName, filterMethod, descriptorValue.value, filterValue, site)) {
                                        expandSites.push(site.entityId);
                                    }
                                    break;
                                case DescriptorFieldTypes.Currency:
                                case DescriptorFieldTypes.Number:
                                    if (this._filterNumber(fieldName, filterMethod, descriptorValue.value, +filterValue, site)) {
                                        expandSites.push(site.entityId);
                                    }
                                    break;
                                case DescriptorFieldTypes.YesNo:
                                    if (this._filterBoolean(fieldName, filterMethod, descriptorValue.value, filterValue, site)) {
                                        expandSites.push(site.entityId);
                                    }
                                    break;
                                case DescriptorFieldTypes.Date:
                                    if (this._filterDate(fieldName, filterMethod, descriptorValue.value, filterValue, site)) {
                                        expandSites.push(site.entityId);
                                    }
                                    break;
                            }
                        }
                        break;
                }
            }

            expandSites.every(entityId => {
                this._collapsedSites.delete(entityId);
                this._expandedSites.add(entityId);
                numberExpanded++;
            });
        });

        if (numberExpanded > 0 || hadRequiredSites > 0 || this._redrawGrid) {
            this._setRowData();
        }
    }

    onAgGridSortChanged() {
        if (this._gridApi) {
            this._setRowData();
        }
    }

    reports() {
        alert('Coming soon');
    }

    async refresh(): Promise<void>{
        await this._loadData();
        this._setRowData();
    }

    async bulkUpdate(): Promise<void> {
        const selectedRows = await this.gridTracker.getSelectedRowIds();
        const selectedEntityIds = selectedRows.map(x => x.toString());

        const params: AppealRecommendationCommandCenterBulkUpdateParams = {
            selectedRowsInfo: this._mappingViewData
                .filter(x => selectedEntityIds.includes(x.entityId))
                .map(x => {
                    return {
                        siteId: x.siteId,
                        parcelId: x.parcelId,
                        taxYear: x.taxYear
                    } as Core.AppealRecommendationBulkUpdateRowInfoModel
                }),
            canChangeAppealRecommendationStatus: this._getCanChangeAppealRecommendationStatus(selectedEntityIds),
            canChangeIsClientApproved: this._getCanChangeIsClientApproved(selectedEntityIds),
            stateIds: this.selectedStates,
            appealDeadlineDays: this.appealDeadlineDays,
            assignedFilter: this._assignedFilter,
            descriptorInfo: this._getVisibleDescriptorInfo(),
            picklistInfo: this._picklistInfo,
            appealRecommendationStatuses: this._appealRecommendationStatuses
        };

        const result = await this._weissmanModalService.showAsync(ARCCBulkUpdateComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        if (result.ignoredSites.length !== 0) {
            const resultsParams: AppealRecommendationCommandCenterBulkUpdateResultConfirmationComponentParams = {
                ignoredSites: result.ignoredSites
            };

            await this._weissmanModalService.showAsync(ARCCBulkUpdateResultConfirmationComponent, resultsParams, 'modal-sd');
        }

        this.gridTracker.clear();
        await this.refresh();
    }

    private _getVisibleDescriptorInfo() {
        const displayedDescriptorColumns = this.gridOptions.columnApi.getAllDisplayedColumns()
            .filter(x => x.getColId().startsWith('d.'))
            .map(x => +x.getColId().substring(2));

        return this._descriptorInfo
            .filter(x => displayedDescriptorColumns.indexOf(x.descriptorId) !== -1);
    }

    private _getCanChangeIsClientApproved(selectedEntityIds: string[]) {
        return this._mappingViewData
            .filter(x => selectedEntityIds.includes(x.entityId) && !x.parcelId).some(x => x.isClientApprovalRequired);
    }

    private _getCanChangeAppealRecommendationStatus(selectedEntityIds: string[]) {
        return this._mappingViewData
            .filter(x => selectedEntityIds.includes(x.entityId) && !x.parcelId)
            .some(x => x.appealRecommendationStatusId !== +Core.AppealRecommendationStatusEnum.Appeal &&
                x.appealRecommendationStatusId !== +Core.AppealRecommendationStatusEnum.NoAppeal);
    }

    private _setRowData(firstLoad: boolean = false): void {
        if (!this._gridApi || !this.isInitialized) {
            return;
        }

        this._gridApi.setRowData(this._populateMappingViewData());

        if (firstLoad) {
            this._gridApi.sizeColumnsToFit();
        }

        this._redrawGrid = false;

        this.isBulkUpdateVisible$.next(false);
    }

    private _populateMappingViewData() {
        this._mappingViewData = [];
        const sortModel = this._gridApi.getSortModel();

        if (!Object.keys(sortModel).length) {
            sortModel[0] = {
                colId: 'entityName',
                sort: 'asc'};
        }

        const colId = sortModel[0].colId;
        const sort = sortModel[0].sort;

        let sortFunction: (x: any, y: any, sort: string) => number;

        switch (colId) {
            case 'entityName':
            case 'responsibleUser':
            case 'state':
            case 'assessorName':
            case 'class':
            case 'propertyType':
            case 'accountHandler':
            case 'companyName':
            case 'revisionName':
            case 'team':
            case 'topLevelCompanyName':
            case 'appealRecommendationStatus':
            case 'priorYearAppeal':
            case 'appealLevel':
            case 'assesseeName':
                sortFunction = (x: string = '', y : string = '', sort: string) => sort === 'asc' ? (x || '').localeCompare(y) : (y || '').localeCompare(x);
                break;
            case 'appealDeadline':
            case 'createDate':
            case 'emailSentDate':
                sortFunction = (x: Date = new Date(0), y : Date = new Date(0), sort: string) => sort === 'asc' ? (x || new Date()).getTime() - (y || new Date()).getTime() : (y || new Date()).getTime() - (x || new Date()).getTime();
                break;
            case 'taxYear':
            case 'originalFMV':
            case 'currentFMV':
            case 'priorYearFMV':
            case 'targetValue':
            case 'originalLandFMV':
            case 'originalImpsFMV':
            case 'originalPersFMV':
            case 'originalAltFMV':
            case 'currentLandFMV':
            case 'currentImpsFMV':
            case 'currentPersFMV':
            case 'currentAltFMV':
            case 'priorYearLandFMV':
            case 'priorYearImpsFMV':
            case 'priorYearPersFMV':
            case 'priorYearAltFMV':
            case 'originalAV':
            case 'originalLandAV':
            case 'originalImpsAV':
            case 'originalPersAV':
            case 'originalAltAV':
            case 'currentAV':
            case 'currentLandAV':
            case 'currentImpsAV':
            case 'currentPersAV':
            case 'currentAltAV':
            case 'priorYearAV':
            case 'priorYearLandAV':
            case 'priorYearImpsAV':
            case 'priorYearPersAV':
            case 'priorYearAltAV':
            case 'savings':
            case 'totalDollarSqFt':
            case 'totalDollarUnit':
            case 'totalLandDollarAcre':
            case 'totalLandDollarSqFt':
            case 'totalPriorYearDollarSqFt':
            case 'totalPriorYearDollarUnit':
            case 'totalPriorYearLandDollarAcre':
            case 'totalPriorYearLandDollarSqFt':
            case 'fmvVariance':
                sortFunction = (x: number = 0, y : number = 0, sort: string) => sort === 'asc' ? x - y : y - x;
                break;
            case 'isReady':
                sortFunction = (x: string = '', y : string = '', sort: string) => sort === 'asc' ? (x || '').toString().localeCompare(y) : (y || '').toString().localeCompare(x);
                break;
            case 'isClientApproved':
                sortFunction = (x: boolean | string, y: boolean | string, sort: string) => {
                    const localX = typeof x === 'boolean' ? x : x === 'true';
                    const localY = typeof y === 'boolean' ? y : y === 'true';
                    const result = sort === 'asc' ? Number(localX) - Number(localY) : Number(localY) - Number(localX);
                    return result;
                };
                break;
            default:
                if (colId.startsWith('d.')){
                    const descriptorId = +colId.substring(2);
                    const descriptorInfo = this._descriptorInfo.find(x => x.descriptorId === descriptorId);

                    switch (descriptorInfo.fieldType) {
                        case DescriptorFieldTypes.Text:
                        case DescriptorFieldTypes.Picklist:
                        case DescriptorFieldTypes.YesNo:
                            sortFunction = (x: string = '', y : string = '', sort: string) => sort === 'asc' ? (x || '').toString().localeCompare(y) : (y || '').toString().localeCompare(x);
                            break;
                        case DescriptorFieldTypes.Currency:
                        case DescriptorFieldTypes.Number:
                            sortFunction = (x: number = 0, y : number = 0, sort: string) => sort === 'asc' ? x - y : y - x;
                            break;
                        case DescriptorFieldTypes.Date:
                            sortFunction = (x: Date = new Date(0), y : Date = new Date(0), sort: string) => sort === 'asc' ? x.getTime() - y.getTime() : y.getTime() - x.getTime();
                            break;
                    }
                }
                break;
        }

        this._sites
            .sort((x, y)  =>
            {
                let xValue = x[sortModel[0].colId];
                let yValue = y[sortModel[0].colId];

                if (colId.startsWith('d.')) {
                    const descriptorId = +colId.substring(2);
                    xValue = this._getDescriptorValue(x.siteId, 0, descriptorId);
                    yValue = this._getDescriptorValue(y.siteId, 0, descriptorId);
                }
                return sortFunction(xValue, yValue, sort);
            })
            .forEach(site => {
                this._mappingViewData.push(site);
                if (this._expandedSites.has(site.entityId)) {
                    this._parcels
                        .filter(parcel => parcel.siteId === site.siteId && parcel.taxYear === site.taxYear)
                        .sort((x, y) => {
                            const colId = sortModel[0].colId === 'appealRecommendationStatus'
                                ? 'entityName'
                                : sortModel[0].colId;
                            const sortDirection = sortModel[0].colId === 'appealRecommendationStatus'
                                ? 'asc'
                                : sort;

                            let xValue = x[colId];
                            let yValue = y[colId];

                            if (colId.startsWith('d.')) {
                                const descriptorId = +colId.substring(2);
                                xValue = this._getDescriptorValue(x.siteId, x.parcelId, descriptorId);
                                yValue = this._getDescriptorValue(y.siteId, y.parcelId, descriptorId);
                            }
                            return sortFunction(xValue, yValue, sortDirection);
                        })
                        .forEach((parcel) => {
                            this._mappingViewData.push(parcel);
                    });
                } else {
                    this._collapsedSites.add(site.entityId);
            }
        });

        return this._mappingViewData;
    }

    private _expand(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as AppealRecommendationGridRowInfoTreeItemModel;
        if (!item) {
            return;
        }

        this._expandedSites.add(item.entityId);
        this._collapsedSites.delete(item.entityId);
        this._setRowData();
    }

    private _collapse(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as AppealRecommendationGridRowInfoTreeItemModel;
        if (!item) {
            return;
        }

        this._expandedSites.delete(item.entityId);
        this._collapsedSites.add(item.entityId);
        this._setRowData();
    }

    private _showHiddenParcels(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as AppealRecommendationGridRowInfoTreeItemModel;
        if (!item) {
            return;
        }

        // expand node if collapsed
        if (this._collapsedSites.has(item.entityId)) {
            this._expandedSites.add(item.entityId);
            this._collapsedSites.delete(item.entityId);
        }

        this._setRowData();
    }

    private _canExpand(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as AppealRecommendationGridRowInfoTreeItemModel;
        if (!item) {
            return false;
        }

        return !item.parcelId && this._collapsedSites.has(item.entityId);
    }

    private _canCollapse(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as AppealRecommendationGridRowInfoTreeItemModel;
        if (!item) {
            return false;
        }

        return !item.parcelId && this._expandedSites.has(item.entityId);
    }

    private _canShowHiddenParcels(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as AppealRecommendationGridRowInfoTreeItemModel;
        if (!item || this._collapsedSites.has(item.entityId)) {
            return false;
        }
        return this._filteredOutParcelsMap.has(item.siteId);
    }

    private _canExpandAll(): boolean {
        return this._canExpandAllRows;
    }

    private _canCollapseAll(params: ICellRendererParamsForExpandCellHeaderParams): boolean {
        return !this._canExpandAllRows;
    }

    private _expandAll(params: ICellRendererParamsForExpandCellHeaderParams): void {
        this._sites.forEach(site => {
            if (this._collapsedSites.has(site.entityId)) {
                this._expandedSites.add(site.entityId);
                this._collapsedSites.delete(site.entityId);
            }
        });

        this._setRowData();

        this._canExpandAllRows = false;
    }

    private _collapseAll(params: ICellRendererParamsForExpandCellHeaderParams): void {
        this._sites.forEach(site => {
            if (this._expandedSites.has(site.entityId)) {
                this._expandedSites.delete(site.entityId);
                this._collapsedSites.add(site.entityId);
            }
        });

        this._setRowData();

        this._canExpandAllRows = true;
    }

    private _filterString(fieldName: string, filter: string, gridValue: any, filterText: string, rowData?: AppealRecommendationGridRowInfoTreeItemModel): boolean {
        const filterPassed = this._filterCheckStringValue(filter, gridValue, filterText);
        let someParcelsPass: boolean = false;

        if (!rowData.parcelId) {
            someParcelsPass = this._parcels
                .filter(parcel => parcel.siteId == rowData.siteId && parcel.taxYear === rowData.taxYear)
                .some(parcel =>
                    fieldName === 'appealRecommendationStatus' && filterPassed ||
                    this._filterString(fieldName, filter, parcel[fieldName], filterText, parcel));

            if (rowData && !filterPassed && someParcelsPass) {
                this._siteRequiredFilterSet.add(rowData.entityId);
            } else if (rowData && this._siteRequiredFilterSet.has(rowData.entityId)) {
                this._siteRequiredFilterSet.delete(rowData.entityId);
                this._redrawGrid = true;
            }
        } else if (fieldName === 'appealRecommendationStatus') {
            const siteNode = this._gridApi.getRowNode(`S${rowData.siteId}`);
        }

        return filterPassed || someParcelsPass;
    }

    private _filterDate(fieldName: string, filter: string, gridValue: any, filterText: string, rowData?: AppealRecommendationGridRowInfoTreeItemModel): boolean {
        const filterPassed = this._filterCheckDateValue(filter, gridValue, filterText);
        let someParcelsPass: boolean = false;

        if (!rowData.parcelId) {
            someParcelsPass = this._parcels
                .filter(parcel => parcel.siteId == rowData.siteId && parcel.taxYear === rowData.taxYear)
                .some(parcel => this._filterDate(fieldName, filter, this._datePipe.transform(parcel[fieldName], false, 'central'), filterText, parcel));

            if (rowData && !filterPassed && someParcelsPass) {
                this._siteRequiredFilterSet.add(rowData.entityId);
            } else if (rowData && this._siteRequiredFilterSet.has(rowData.entityId)) {
                this._siteRequiredFilterSet.delete(rowData.entityId);
                this._redrawGrid = true;
            }
        }

        return filterPassed || someParcelsPass;
    }

    private _filterNumber(fieldName: string, filter: string, gridValue: any, filterText: number, rowData?: AppealRecommendationGridRowInfoTreeItemModel): boolean {
        const filterPassed = this._filterCheckNumberValue(filter, gridValue, filterText);
        let someParcelsPass: boolean = false;

        if (!rowData.parcelId) {
            someParcelsPass = this._parcels
                .filter(parcel => parcel.siteId == rowData.siteId && parcel.taxYear === rowData.taxYear)
                .some(parcel => {
                    let result: boolean;

                    if (fieldName.startsWith('d.')) {
                        const  descriptorValue = this._descriptorValues.find(x => x.parcelId == parcel.parcelId &&
                            x.descriptorId == +fieldName.substring(2));

                        result = descriptorValue && this._filterNumber(fieldName, filter, descriptorValue.value, filterText, parcel);
                    } else {
                        result = this._filterNumber(fieldName, filter, parcel[fieldName], filterText, parcel);
                    }

                    return result;
                });

            if (rowData && !filterPassed && someParcelsPass) {
                this._siteRequiredFilterSet.add(rowData.entityId);
            } else if (rowData && this._siteRequiredFilterSet.has(rowData.entityId)) {
                this._siteRequiredFilterSet.delete(rowData.entityId);
                this._redrawGrid = true;
            }
        }

        return filterPassed || someParcelsPass;
    }

    private _filterPercent(fieldName: string, filter: string, gridValue: any, filterText: number, rowData?: AppealRecommendationGridRowInfoTreeItemModel): boolean {
        const filterPassed = this._filterCheckPercentValue(filter, gridValue, +filterText);
        let someParcelsPass: boolean = false;

        if (!rowData.parcelId) {
            someParcelsPass = this._parcels
                .filter(parcel => parcel.siteId == rowData.siteId && parcel.taxYear === rowData.taxYear)
                .some(parcel => {
                    let result: boolean;

                    if (fieldName.startsWith('d.')) {
                        const  descriptorValue = this._descriptorValues.find(x => x.parcelId == parcel.parcelId &&
                            x.descriptorId == +fieldName.substring(2));

                        result = descriptorValue && this._filterPercent(fieldName, filter, descriptorValue.value, filterText, parcel);
                    } else {
                        result = this._filterPercent(fieldName, filter, parcel[fieldName], filterText, parcel);
                    }

                    return result;
                });

            if (rowData && !filterPassed && someParcelsPass) {
                this._siteRequiredFilterSet.add(rowData.entityId);
            } else if (rowData && this._siteRequiredFilterSet.has(rowData.entityId)) {
                this._siteRequiredFilterSet.delete(rowData.entityId);
                this._redrawGrid = true;
            }
        }

        return filterPassed || someParcelsPass;
    }

    private _filterBoolean(fieldName: string, filter: string, gridValue: any, filterText: number, rowData?: AppealRecommendationGridRowInfoTreeItemModel): boolean {
        //TODO: For some reason, this method called multiple times for the same parcel and the last call has "" as the gridValue.
        //      So, we cannot use it and have to use the rowData property
        const filterPassed = this._filterCheckBooleanValue(filter, gridValue);
        let someParcelsPass: boolean = false;

        if (!rowData.parcelId) {
            someParcelsPass = this._parcels
                .filter(parcel => parcel.siteId == rowData.siteId && parcel.taxYear === rowData.taxYear)
                .some(parcel => {
                    let result: boolean;

                    if (fieldName.startsWith('d.')) {
                        const  descriptorValue = this._descriptorValues.find(x => x.parcelId == parcel.parcelId &&
                            x.descriptorId == +fieldName.substring(2));

                        result = descriptorValue && this._filterBoolean(fieldName, filter, descriptorValue.value, filterText, parcel);
                    } else {
                        result = this._filterBoolean(fieldName, filter, parcel[fieldName], filterText, parcel);
                    }

                    return result;
                });

            if (rowData && !filterPassed && someParcelsPass) {
                this._siteRequiredFilterSet.add(rowData.entityId);
            } else if (rowData && this._siteRequiredFilterSet.has(rowData.entityId)) {
                this._siteRequiredFilterSet.delete(rowData.entityId);
                this._redrawGrid = true;
            }
        }

        return filterPassed || someParcelsPass;
    }

    private _filterCheckStringValue(method: string, sourceString: string, matchString: string): boolean {
        const sourceCompare = (sourceString || '').toLowerCase();
        const matchCompare = (matchString || '').toLowerCase();

        switch (method) {
            case 'startsWith':
                return sourceCompare.startsWith(matchCompare);
            case 'equals':
                return sourceCompare === matchCompare;
            case 'notEqual':
                return sourceCompare !== matchCompare;
            case 'contains':
                return sourceCompare.includes(matchCompare);
            case 'notContains':
                return !sourceCompare.includes(matchCompare);
            case 'endsWith':
                return sourceCompare.endsWith(matchCompare);
            case AgGridFilterParams.blankFilterOptionDef.displayKey:
                return sourceString.length === 0;
            case AgGridFilterParams.notBlankFilterOptionDef.displayKey:
                return sourceString.length > 0;

        }

        return false;
    }

    private _filterCheckDateValue(method: string, source: string, match: string): boolean {
        const sourceDate = source ? Date.parse(source) : new Date(1, 1, 1);
        const matchDate = match ? Date.parse(match) : new Date(1, 1, 1);
        switch (method) {
            case 'equals':
                return sourceDate === matchDate;
            case 'notEqual':
                return sourceDate !== matchDate;
            case 'lessThan':
                return sourceDate < matchDate;
            case 'greaterThan':
                return sourceDate > matchDate;
        }

        return false;
    }

    private _filterCheckNumberValue(method: string, source: number, match: number): boolean {
        switch (method) {
            case 'equals':
                return +source === +match;
            case 'notEqual':
                return +source !== +match;
            case 'lessThan':
                return +source < +match;
            case 'greaterThan':
                return +source > +match;
        }

        return false;
    }

    private _filterCheckPercentValue(method: string, source: number, match: number): boolean {
        const fixedSource = (source * 100).toFixed(2);

        switch (method) {
            case 'equals':
                return +fixedSource === +match;
            case 'notEqual':
                return +fixedSource !== +match;
            case 'lessThan':
                return +fixedSource < +match;
            case 'greaterThan':
                return +fixedSource > +match;
        }

        return false;
    }

    private _filterCheckBooleanValue(method: string, source: string): boolean {
        switch (method) {
            case AgGridFilterParams.yesOptionDef.displayKey:
                return ['yes', 'true'].indexOf((source || '').toString()) !== -1;
            case AgGridFilterParams.noOptionDef.displayKey:
                return ['no', 'false'].indexOf((source || '').toString()) !== -1;
            case AgGridFilterParams.blankFilterOptionDef.displayKey:
                return true;
        }

        return false;
    }

    private _getGridRowIds(skip: number, take: number): Compliance.QueryResultModel<string> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                const row = x.data as AppealRecommendationGridRowInfoTreeItemModel;
                return row && row.entityId;
            })
        } as Compliance.QueryResultModel<string>;
    }

    private async _updateDescriptor(params: ICellEditorParams, updatedValue: any, descriptor: Core.DescriptorInfoModel): Promise<void> {
        const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

        if (!data) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const updateModel: Core.AppealRecommendationCommandCenterUpdateDescriptorModel = {
            siteId: data.siteId,
            parcelId: data.parcelId,
            force: false,
            descriptorId: descriptor.descriptorId,
            descriptorValue: updatedValue,
            taxYear: data.taxYear,
            stateIds: this.selectedStates,
            assignedFilter: this._assignedFilter,
            appealDeadlineDays: this.appealDeadlineDays
        };

        let busyRef = this._busyIndicatorService.show({message: 'Saving'});

        let result: Core.AppealRecommendationCommandCenterUpdateDescriptorResultModel = null;

        try {
            result = await lastValueFrom(this._appealRecommendationRepository.updateDescriptor(updateModel));
        } catch (e) {
            // service returns a 422 and a message if the user confirmation needed to force update
            if (e && e.status !== 422) {
                return Promise.reject(e);
            }
            await busyRef.hide();

            try {
                await this._messageModalService.confirm(e.error.message, `Confirm ${descriptor.name} Exception`);
            } catch (cancel) {
                // revert the cell value
                params.node.setDataValue(params.column.getColId(), params.value);
                return await Promise.reject(e);
            }

            busyRef = this._busyIndicatorService.show({message: 'Loading'});

            updateModel.force = true;
            result = await lastValueFrom(this._appealRecommendationRepository.updateDescriptor(updateModel));
        } finally {
            await busyRef.hide();
        }

        if (result) {
            if (result.updatedSiteInfo) {
                const nodes = [];

                const sites = this._sites
                    .filter(x => x.siteId == result.updatedSiteInfo.siteId && x.taxYear === data.taxYear);

                sites.forEach(x => {
                    x.totalDollarSqFt = result.updatedSiteInfo.totalDollarSqFt;
                    x.totalDollarUnit = result.updatedSiteInfo.totalDollarUnit;
                    x.totalLandDollarAcre = result.updatedSiteInfo.totalLandDollarAcre;
                    x.totalLandDollarSqFt = result.updatedSiteInfo.totalLandDollarSqFt;
                    x.totalPriorYearDollarSqFt = result.updatedSiteInfo.totalPriorYearDollarSqFt;
                    x.totalPriorYearDollarUnit = result.updatedSiteInfo.totalPriorYearDollarUnit;
                    x.totalPriorYearLandDollarAcre = result.updatedSiteInfo.totalPriorYearLandDollarAcre;
                    x.totalPriorYearLandDollarSqFt = result.updatedSiteInfo.totalPriorYearLandDollarSqFt;
                    nodes.push(this._gridApi.getRowNode(x.entityId));

                    params.node.setData(x);
                });
            }

            const descriptorValue = this._descriptorValues
                .find(x =>
                    x.siteId === data.siteId &&
                    x.parcelId === data.parcelId &&
                    x.descriptorId === descriptor.descriptorId);
            if (descriptorValue) {
                descriptorValue.value = updatedValue;
            } else {
                this._descriptorValues.push(result.newDescriptorInfo);
            }

            const nodes = this._mappingViewData
                .filter(x => x.siteId === data.siteId && x.parcelId === data.parcelId)
                .map(x => this._gridApi.getRowNode(x.entityId));

            this._gridApi.redrawRows({rowNodes: nodes});
        }

        return Promise.resolve();
    }

    private async _updateTargetValue(params: ICellEditorParams, updatedValue: number): Promise<void> {
        const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

        if (!data) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const updateModel: Core.AppealRecommendationCommandCenterUpdateTargetValueModel = {
            parcelId: data.parcelId,
            taxYear: data.taxYear,
            targetValue: updatedValue,
            force: false
        };

        if (data.currentFMV < updatedValue) {
            try {
                await this._messageModalService.confirm(
                    'You have entered an amount greater than the assessment value. Is this correct?',
                    'Warning');
                updateModel.force = true;
            } catch (cancel) {
                // revert the cell value
                params.node.setDataValue(params.column.getColId(), params.value);
                return Promise.resolve();
            }
        }

        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        try {
            await lastValueFrom(this._appealRecommendationRepository.updateTargetValue(updateModel));

            const parcelInfo = this._parcels.find(x => x.parcelId === data.parcelId);
            parcelInfo.targetValue = updatedValue;
            params.node.setData(parcelInfo);

            const siteInfo = this._sites
                .find(x => x.siteId === parcelInfo.siteId && x.taxYear === data.taxYear);
            siteInfo.targetValue = siteInfo.targetValue + (updatedValue - initialValue);
            const siteNode = this._gridApi.getRowNode(siteInfo.entityId);
            siteNode.data = siteInfo;

            this._gridApi.redrawRows({rowNodes: [params.node, siteNode]});
        } finally {
            await busyRef.hide();
        }

        return Promise.resolve();
    }

    private async _updateAppealRecommendationStatus(params: ICellEditorParams, updatedValue: string): Promise<void> {
        const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

        if (!data) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const status = this._appealRecommendationStatuses
            .find(x => x.name === updatedValue)
            .id;

        const updateModel: Core.UpdateSiteAppealRecommendationModel = {
            siteId: data.siteId,
            taxYear: data.taxYear,
            status: status,
            stateIds: this.selectedStates,
            assignedFilter: this._assignedFilter,
            appealDeadlineDays: this.appealDeadlineDays
        };

        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        try {
            await lastValueFrom(this._appealRecommendationRepository.updateSiteAppealRecommendation(updateModel));
            const siteInfo = this._sites
                .find(x => x.siteId === data.siteId && !x.parcelId && x.taxYear === data.taxYear);
            siteInfo.appealRecommendationStatusId = status;
        } catch (e) {
            const siteInfo = this._sites
                .find(x => x.siteId === data.siteId && !x.parcelId && x.taxYear === data.taxYear);
            siteInfo.appealRecommendationStatus = initialValue;
            params.node.setData(siteInfo);
            throw e;
        } finally {
            await busyRef.hide();
        }

        return Promise.resolve();
    }

    private async _updateIsClientApproved(params: ICellEditorParams, updatedValue: boolean): Promise<void> {
        const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

        if (!data) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const updateModel: Core.UpdateSiteAppealRecommendationModel = {
            siteId: data.siteId,
            taxYear: data.taxYear,
            isClientApproved: updatedValue,
            stateIds: [],
            assignedFilter: this._assignedFilter,
            appealDeadlineDays: this.appealDeadlineDays
        };

        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        try {
            await lastValueFrom(this._appealRecommendationRepository.updateSiteAppealRecommendation(updateModel));
            const siteInfo = this._sites
                .find(x => x.siteId === data.siteId && !x.parcelId && x.taxYear === data.taxYear);
            siteInfo.isClientApproved = updatedValue;
        } catch (e) {
            const siteInfo = this._sites
                .find(x => x.siteId === data.siteId && !x.parcelId && x.taxYear === data.taxYear);
            siteInfo.isClientApproved = initialValue;
            params.node.setData(siteInfo);
            throw e;
        } finally {
            await busyRef.hide();
        }

        return Promise.resolve();
    }

    private _setColumns() {
        const columns: ColDef[] = [
            {
                lockPinned: true,
                suppressMenu: true,
                width: AgGridColumns.selectionColumnWidth,
                lockVisible: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                cellRendererFramework: ExpandCellRendererComponent,
                cellRendererParams: {
                    expand: this._expand.bind(this),
                    collapse: this._collapse.bind(this),
                    canExpand: this._canExpand.bind(this),
                    canCollapse: this._canCollapse.bind(this),
                    canShowHiddenChildren: this._canShowHiddenParcels.bind(this),
                    showHiddenChildren: this._showHiddenParcels.bind(this),
                    getName: (params) => '',
                    getTooltip: (params) => {
                        return null;
                    }
                } as ICellRendererParamsForExpandCellRenderer,
                headerComponentFramework: ExpandCellHeaderRendererComponent,
                headerComponentParams: {
                    expandAll: this._expandAll.bind(this),
                    collapseAll: this._collapseAll.bind(this),
                    canExpandAll: this._canExpandAll.bind(this),
                    canCollapseAll: this._canCollapseAll.bind(this),
                    headerText: ''
                }
            },
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'entityId',
                width: AgGridColumns.selectionColumnWidth,
                lockVisible: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                resizable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {tracker: this.gridTracker} as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {tracker: this.gridTracker} as AgGridMultiSelectCellRendererParams
            },
            {
                colId: 'entityName',
                headerName: 'Site / Parcel',
                field: 'entityName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model) {
                            return '';
                        }

                        let result: string;

                        if (model.parcelId) {
                            result = `#/parcel/${model.parcelId}`;
                        } else {
                            result = `#/site/${model.siteId}`;
                        }

                        return result;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'entityName'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'responsibleUser',
                headerName: 'Responsible User',
                field: 'responsibleUser',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'responsibleUser'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'isReady',
                headerName: 'Ready',
                field: 'isReady',
                valueFormatter: (params) => {
                    const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                    if (!data) {
                        return '';
                    }

                    return data.isReady ? 'Yes' : 'No';
                },
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: {
                    filterOptions: [AgGridFilterParams.noFilterOptionDef, AgGridFilterParams.yesOptionDef, AgGridFilterParams.noOptionDef],
                    textCustomComparator: this._filterBoolean.bind(this, 'isReady'),
                    newRowsAction: 'keep',
                    defaultOption: AgGridFilterParams.noFilterOptionDef.displayKey,
                    suppressAndOrCondition: true,
                },
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'appealRecommendationStatus',
                headerName: 'Rec Status',
                field: 'appealRecommendationStatus',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'appealRecommendationStatus'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                editable: (params) => {
                    const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                    return model && model.canEdit && model.canChangeAppealRecommendationStatus;
                },
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: (params) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                        return this._appealRecommendationStatuses
                            .filter(x => !model || model.isReady ||
                                (x.id !== Core.AppealRecommendationStatusEnum.Recommend &&
                                    x.id !== Core.AppealRecommendationStatusEnum.DoNotRecommend))
                            .map(x => {
                                return {name: x.name, value: x.name};
                            });
                        },
                    cellFocusLost: (params, updatedValue) => this._updateAppealRecommendationStatus(params, updatedValue)
                },
                cellRendererFramework: AgGridTooltipCellRenderer,
                cellRendererParams: {
                    getTooltipText: (params) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                        if (!model || !model.canEdit) {
                            return '';
                        }

                        return !model.canChangeAppealRecommendationStatus
                            ? 'All underlying assessment revisions have completed the Determine if Appeal Warranted task.'
                            : '';
                    }
                },
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'isClientApproved',
                headerName: 'Client Approved',
                field: 'isClientApproved',
                valueFormatter: (params) => {
                    const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                    if (!data) {
                        return '';
                    }

                    const result = data.parcelId || !data.isClientApprovalRequired
                        ? ''
                        : data.isClientApproved.toString() === 'true'
                            ? 'Yes'
                            : 'No';
                    return result;
                },
                valueSetter: (params) => {
                    const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                    if (!data) {
                        return null;
                    }

                    data.isClientApproved = params.newValue === 'true' || params.newValue;
                    return true;
                },
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: {
                    filterOptions: [AgGridFilterParams.noFilterOptionDef, AgGridFilterParams.yesOptionDef, AgGridFilterParams.noOptionDef],
                    textCustomComparator: this._filterBoolean.bind(this, 'isClientApproved'),
                    newRowsAction: 'keep',
                    defaultOption: AgGridFilterParams.noFilterOptionDef.displayKey,
                    suppressAndOrCondition: true,
                },
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
                editable: (params) => {
                    const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                    return model && model.canEdit && !model.parcelId && model.isClientApprovalRequired;
                },
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: (params) => {
                        return [
                            { name: 'No', value: false },
                            { name: 'Yes', value: true }
                        ];
                    },
                    cellFocusLost: (params, updatedValue) => this._updateIsClientApproved(params, updatedValue)
                },
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'emailSentDate',
                headerName: 'Email Sent',
                field: 'emailSentDate',
                width: AgGridColumns.dateColumnWidth,
                valueGetter: params => {
                    const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                    if (!data || !data.emailSentDate) {
                        return '';
                    }

                    return this._datePipe.transform(data.emailSentDate, false);
                },
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterDate.bind(this, 'emailSentDate'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'state',
                headerName: 'State',
                field: 'state',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model) {
                            return '';
                        }

                        return `#/states/${model.stateId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'state'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'assessorName',
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model || !model.assessorId) {
                            return null;
                        }

                        return `#/states/${model.stateId}/assessors/${model.assessorId}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        return !model || !model.assessorId;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'assessorName'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'appealDeadline',
                headerName: 'Appeal Deadline',
                field: 'appealDeadline',
                width: AgGridColumns.dateColumnWidth,
                valueGetter: params => {
                    const data = params.data as AppealRecommendationGridRowInfoTreeItemModel;

                    if (!data) {
                        return '';
                    }

                    return this._datePipe.transform(data.appealDeadline, false);
                },
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterDate.bind(this, 'appealDeadline'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'taxYear',
                headerName: 'Tax Year',
                field: 'taxYear',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'taxYear'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'class',
                headerName: 'Class',
                field: 'class',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'class'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'propertyType',
                headerName: 'Prop Type',
                field: 'propertyType',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'propertyType'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalFMV',
                headerName: 'Original FMV',
                field: 'originalFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentFMV',
                headerName: 'Current FMV',
                field: 'currentFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearFMV',
                headerName: 'PY FMV',
                field: 'priorYearFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'targetValue',
                headerName: 'Target Value',
                field: 'targetValue',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'targetValue'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                editable: (params) => {
                    const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                    if (!model) {
                        return false;
                    }

                    return model.canEdit && !!model.parcelId;
                },
                cellEditorFramework: AgGridNumberCellEditor,
                cellEditorParams: {
                    cellFocusLost: (params, updatedValue) => this._updateTargetValue(params, updatedValue)
                },
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'fmvVariance',
                headerName: 'FMV YOY Var%',
                field: 'fmvVariance',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._percentPipe.transform(params.value ?? 0, '1.2-2'),
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterPercent.bind(this, 'fmvVariance'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'accountHandler',
                headerName: 'Account Handler',
                field: 'accountHandler',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'accountHandler'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'companyName',
                headerName: 'Company Name',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model) {
                            return null;
                        }

                        return `#/company/${model.companyId}`;
                    },
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'companyName'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalLandFMV',
                headerName: 'Original Land FMV',
                field: 'originalLandFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalLandFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalImpsFMV',
                headerName: 'Original Imps FMV',
                field: 'originalImpsFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalImpsFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalPersFMV',
                headerName: 'Original Pers FMV',
                field: 'originalPersFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalPersFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalAltFMV',
                headerName: 'Original Alt FMV',
                field: 'originalAltFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalAltFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentLandFMV',
                headerName: 'Current Land FMV',
                field: 'currentLandFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentLandFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentImpsFMV',
                headerName: 'Current Imps FMV',
                field: 'currentImpsFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentImpsFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentPersFMV',
                headerName: 'Current Pers FMV',
                field: 'currentPersFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentPersFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentAltFMV',
                headerName: 'Current Alt FMV',
                field: 'currentAltFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentAltFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearLandFMV',
                headerName: 'PY Land FMV',
                field: 'priorYearLandFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearLandFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearImpsFMV',
                headerName: 'PY Imps FMV',
                field: 'priorYearImpsFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearImpsFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearPersFMV',
                headerName: 'PY Pers FMV',
                field: 'priorYearPersFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearPersFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearAltFMV',
                headerName: 'PY Alt FMV',
                field: 'priorYearAltFMV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearAltFMV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalAV',
                headerName: 'Original AV',
                field: 'originalAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalLandFMV',
                headerName: 'Original Land AV',
                field: 'originalLandAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalLandAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalImpsFMV',
                headerName: 'Original Imps AV',
                field: 'originalImpsAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalImpsAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalPersAV',
                headerName: 'Original Pers AV',
                field: 'originalPersAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalPersAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'originalAltAV',
                headerName: 'Original Alt AV',
                field: 'originalAltAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'originalAltAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentAV',
                headerName: 'Current AV',
                field: 'currentAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentLandAV',
                headerName: 'Current Land AV',
                field: 'currentLandAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentLandAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentImpsAV',
                headerName: 'Current Imps AV',
                field: 'currentImpsAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentImpsAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentPersAV',
                headerName: 'Current Pers AV',
                field: 'currentPersAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentPersAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'currentAltAV',
                headerName: 'Current Alt AV',
                field: 'currentAltAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'currentAltAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearAV',
                headerName: 'PY AV',
                field: 'priorYearAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearLandAV',
                headerName: 'PY Land AV',
                field: 'priorYearLandAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearLandAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearImpsAV',
                headerName: 'PY Imps AV',
                field: 'priorYearImpsAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearImpsAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearPersAV',
                headerName: 'PY Pers AV',
                field: 'priorYearPersAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearPersAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearAltAV',
                headerName: 'PY Alt AV',
                field: 'priorYearAltAV',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'priorYearAltAV'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'revisionName',
                headerName: 'Revision Name',
                field: 'revisionName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'revisionName'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'appealLevel',
                headerName: 'Appeal Level',
                field: 'appealLevel',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'appealLevel'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'priorYearAppeal',
                headerName: 'PY Appeal',
                field: 'priorYearAppeal',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model) {
                            return '';
                        }

                        return `#/appeal/${model.priorYearAppealId}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        return !model || !model.priorYearAppealId;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'priorYearAppeal'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'savings',
                headerName: 'Savings',
                field: 'savings',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(Math.floor(params.value ?? 0), '1.0-0')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'savings'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'assesseeName',
                headerName: 'Parcel Assessee',
                field: 'assesseeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'assesseeName'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'createDate',
                headerName: 'Create Date',
                field: 'createDate',
                width: AgGridColumns.dateColumnWidth,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterDate.bind(this, 'createDate'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'team',
                headerName: 'Team',
                field: 'team',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'team'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'topLevelCompanyName',
                headerName: 'Top Company Name',
                field: 'topLevelCompanyName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model) {
                            return null;
                        }

                        return `#/company/${model.topLevelCompanyId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterString.bind(this, 'topLevelCompanyName'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalDollarSqFt',
                headerName: 'Total $/SqFt',
                field: 'totalDollarSqFt',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalDollarSqFt'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalLandDollarAcre',
                headerName: 'Land $/Acre',
                field: 'totalLandDollarAcre',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalLandDollarAcre'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalPriorYearDollarSqFt',
                headerName: 'PY Total $/SqFt',
                field: 'totalPriorYearDollarSqFt',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalPriorYearDollarSqFt'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalPriorYearLandDollarAcre',
                headerName: 'PY Land $/Acre',
                field: 'totalPriorYearLandDollarAcre',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalPriorYearLandDollarAcre'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalDollarUnit',
                headerName: 'Total $/Unit',
                field: 'totalDollarUnit',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalDollarUnit'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalLandDollarSqFt',
                headerName: 'Land $/SqFt',
                field: 'totalLandDollarSqFt',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalLandDollarSqFt'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalPriorYearDollarUnit',
                headerName: 'PY Total $/Unit',
                field: 'totalPriorYearDollarUnit',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalPriorYearDollarUnit'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                colId: 'totalPriorYearLandDollarSqFt',
                headerName: 'PY Land $/SqFt',
                field: 'totalPriorYearLandDollarSqFt',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => `${this._decimalPipe.transform(params.value, '1.2-2')}`,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                    textCustomComparator: this._filterNumber.bind(this, 'totalPriorYearLandDollarSqFt'),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                suppressMenu: true,
                sortable: false,
                cellRendererFramework: AppealRecommendationCommandCenterGridActionCellRendererComponent
            }
        ];

        const descriptorColumns = DESCRIPTOR_COLUMN_DEFINITIONS(this._decimalPipe);
        this._descriptorInfo
            .sort((x, y) => x.name.localeCompare(y.name))
            .reduce((acc, descriptor) => {
                    const column: ColDef = descriptorColumns[descriptor.fieldType](descriptor, this.getDescriptorValue.bind(this), this._picklistInfo);
                    column.editable = (params) => {
                        const model = params.data as AppealRecommendationGridRowInfoTreeItemModel;
                        if (!model) {
                            return false;
                        }

                        const canEditParcel = !!model.parcelId && descriptor.parcelUsage !== DescriptorUsageEnum.Never;
                        const canEditSite = !model.parcelId && descriptor.siteUsage !== DescriptorUsageEnum.Never;

                        return model.canEdit && (canEditParcel || canEditSite);
                    };
                    column.cellEditorParams.cellFocusLost = (params, updatedValue) => this._updateDescriptor(params, updatedValue, descriptor);
                    column.cellRendererParams = {
                        getTooltipText: (params) => '',
                    };

                    switch (descriptor.fieldType) {
                        case DescriptorFieldTypes.Text:
                        case DescriptorFieldTypes.Picklist:
                            column.filterParams = {
                                filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                                textCustomComparator: this._filterString.bind(this, column.field),
                                newRowsAction: 'keep',
                                defaultOption: 'contains',
                                suppressAndOrCondition: true
                            };
                            break;
                        case DescriptorFieldTypes.Currency:
                        case DescriptorFieldTypes.Number:
                            column.filterParams = {
                                filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                                textCustomComparator: this._filterNumber.bind(this, column.field),
                                newRowsAction: 'keep',
                                defaultOption: 'contains',
                                suppressAndOrCondition: true
                            };
                            break;
                        case DescriptorFieldTypes.YesNo:
                            column.filterParams = {
                                filterOptions: [AgGridFilterParams.noFilterOptionDef, AgGridFilterParams.yesOptionDef, AgGridFilterParams.noOptionDef],
                                textCustomComparator: this._filterBoolean.bind(this, column.field),
                                newRowsAction: 'keep',
                                defaultOption: AgGridFilterParams.noFilterOptionDef.displayKey,
                                suppressAndOrCondition: true,
                            };
                            break;
                        case DescriptorFieldTypes.Date:
                            column.filterParams = {
                                filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
                                textCustomComparator: this._filterDate.bind(this, column.field),
                                newRowsAction: 'keep',
                                defaultOption: 'contains',
                                suppressAndOrCondition: true
                            };
                            break;
                    }

                    acc.push(column);

                    return acc;
                },
                columns);

        const defaultSortModel = [
            {
                colId: 'entityName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridColumnApi.setColumnPinned('actions', 'right');
    }

    private _getDescriptorValue(siteId: number, parcelId: number, descriptorId: number, fieldType: Core.DescriptorFieldTypes = null): any {
        const found = this._descriptorValues
            .find(x => x.descriptorId == descriptorId &&
                x.siteId === siteId && x.parcelId === parcelId);

        let result = found && found.value;
        if (fieldType === Core.DescriptorFieldTypes.YesNo) {
            result = result && result.toString() === 'true';
        }

        return result;
    }

    private _sitesOnlyExportOptionDisabled(): boolean {
        return this._selectedRowIds.every(x => x.substring(0, 1) !== 'S');
    }

    private _parcelsOnlyExportOptionDisabled(): boolean {
        return this._selectedRowIds.every(x => x.substring(0, 1) !== 'P');
    }

    private _canOpenEmailPreview(): SelectionValidationResult {
        const result: SelectionValidationResult= {
            isValid: this._hasSelectedRows,
            validationMessage: !this._hasSelectedRows ? 'No rows selected' : 'Send Appeal Recommendation Email.'
        };

        if (result.isValid) {
            if (this._assignedFilter.that === Core.AppealRecommendationCCAssignedFilterThatOptionEnum.Completed) {
                result.isValid = false;
                result.validationMessage = 'Determination task has been completed.';
            } else {
                const companyIds: number[] = [];
                const taxYears: number[] = [];

                this._mappingViewData
                    .filter(x => this._selectedRowIds.indexOf(x.entityId) !== -1)
                    .forEach(x => {
                        companyIds.push(x.topLevelCompanyId);
                        taxYears.push(x.taxYear);
                    });

                if (companyIds.filter(this._uniqueNumberFilter).length > 1 ||
                    taxYears.filter(this._uniqueNumberFilter).length > 1) {
                    result.isValid = false;
                    result.validationMessage = 'Selection must be in the same top company and tax year.';
                } else {
                    const taskIds = this._getSelectedTaskIds();

                    if (taskIds.length === 0) {
                        result.isValid = false;
                        result.validationMessage = 'Selected rows are not yet ready.';
                    }
                }
            }
        }

        return result;
    }

    private _canAcceptRecommendation(): SelectionValidationResult {
        const selectedSites = this._mappingViewData
            .filter(x => this._hasSelectedRows && !x.parcelId && this._selectedRowIds.includes(x.entityId));
        const hasSelectedSites = selectedSites.length !== 0;
        const result: SelectionValidationResult= {
            isValid: hasSelectedSites,
            validationMessage: !hasSelectedSites ? 'No Site rows selected' : 'Accept recommendations.'
        };

        if (result.isValid) {
            const selectedAppealRecommendationStatuses: number[] = selectedSites
                .map(x => x.appealRecommendationStatusId);
            if (selectedAppealRecommendationStatuses.some(x => x === Core.AppealRecommendationStatusEnum.AwaitingReview ||
                x === Core.AppealRecommendationStatusEnum.UnderReview)){
                result.isValid = false;
                result.validationMessage = 'Selection contains sites that are missing a recommendation.'
            } else if (selectedAppealRecommendationStatuses.some(x => x !== selectedAppealRecommendationStatuses[0])) {
                result.isValid = false;
                result.validationMessage = 'Selected sites must have the same recommendation status.'
            } else if (selectedAppealRecommendationStatuses.some(x => x === Core.AppealRecommendationStatusEnum.Appeal ||
                x === Core.AppealRecommendationStatusEnum.NoAppeal)) {
                result.isValid = false;
                result.validationMessage = 'Selection contains sites that have already been accepted.'
            }
        }

        return result;
    }

    private _getSelectedTaskIds() {
        const taskIds: number[] = this._parcels
            .filter(x => {
                const result = x.isReady && (this._selectedRowIds.indexOf(x.entityId) !== -1 ||
                    this._selectedRowIds.indexOf(`S${x.siteId}_${x.taxYear}`) !== -1);

                return result;
            })
            .map(x => x.determineIfAppealWarrantedTaskId);

        return taskIds;
    }

    private _uniqueNumberFilter(value: number, index: number, self: number[]) {
        return self.indexOf(value) === index;
    }

    private async _showAppealRecommendationEmailModal(): Promise<void> {
        const initialState = {
            taskIds: this._getSelectedTaskIds(),
            instanceId: this._sites[0].instanceId
        };

        this._modalService.show(AppealRecommendationTemplateComponent, { initialState, ignoreBackdropClick: true, class: 'modal-no-max-width modal-xl'});
    }

    private async _acceptRecommendation(): Promise<void> {
        const selectedSites = this._sites
            .filter(x => this._selectedRowIds.indexOf(x.entityId) !== -1);

        const selectedSiteIds = selectedSites
            .filter(x => !x.parcelId)
            .map(x => x.siteId);

        const selectedTaskIds = this._parcels
            .filter(x => selectedSiteIds.includes(x.siteId))
            .map(x => x.determineIfAppealWarrantedTaskId);

        let result: Core.BulkOperationResult[];

        if (selectedSites[0].appealRecommendationStatusId === Core.AppealRecommendationStatusEnum.Recommend) {
            const model: ProcessAppealWarrantedParams = {
                taskIDs: selectedTaskIds,
                searchTimestamp: this._searchTimeStamp,
                runWithBuffer: true,
                updateAppealRecommendation: true
            };

            result = await this._weissmanModalService.showAsync(ProcessAppealWarrantedComponent, model, 'modal-lg');
        } else {
            const model: ProcessNoAppealParams = {
                taskIDs: selectedTaskIds,
                searchTimestamp: this._searchTimeStamp,
                updateAppealRecommendation: true
            };

            result = await this._weissmanModalService.showAsync(ProcessNoAppealComponent, model, 'modal-lg');
        }

        if (result) {
            const processedTaskIds = result
                .filter(x => !x.errorMessage && !x.warningMessage)
                .map(x => x.taskID);
            processedTaskIds.forEach( x => {
                const parcel = this._parcels.find(y => y.determineIfAppealWarrantedTaskId === x);
                this._parcels.splice(this._parcels.indexOf(parcel), 1);

                const site = this._sites.find(y => y.siteId === parcel.siteId);
                if (site) {
                    this._sites.splice(this._sites.indexOf(site), 1);
                    let displayedRow: AppealRecommendationGridRowInfoTreeItemModel;

                    while((displayedRow = this._mappingViewData.find(y => y.siteId === site.siteId))) {
                        this._mappingViewData.splice(this._mappingViewData.indexOf(displayedRow), 1);
                    }
                }
            });

            if (result.some(x => x.errorMessage || x.warningMessage)) {
                result.forEach(x =>
                {
                    const parcel = this._parcels.find(y => y.determineIfAppealWarrantedTaskId === x.taskID);
                    if (parcel) {
                        parcel.hasError = true
                    }
                });

                this._redrawGrid = true;

                const allErrors = result
                    .filter(x => x.errorMessage)
                    .map(x => x.errorMessage);
                const uniqueErrors = Array.from(new Set(allErrors));

                const allWarnings = result
                    .filter(x => x.warningMessage)
                    .map(x => x.warningMessage);
                const uniqueWarnings = Array.from(new Set(allWarnings));

                const errorMessage = 'The following error(s) or warning(s) were encountered attempting to accept the recommendation; please attempt to correct these errors and try again';

                this._taskModalService.showErrorNotificationModal(uniqueErrors, uniqueWarnings, errorMessage);
            }

            this._setRowData();
        }
    }
}
