<div class="card panel-flat-color primary-panel idp-panel">
    <div class="panel-flat-header document-processing-quick-search-header">
        <h3>OCR Detected Values</h3>
    </div>
    <div class="panel-flat-body">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex confidence-level" 
                style="border-radius: 24px; padding: 12px;" 
                [class.low-confidence]="confidenceLevel === 'Low'">
                <label class="mb-0 me-2">Confidence Level: </label>
                <span>{{confidenceLevel}}</span>
            </div>
            <button *ngIf="canSeeDetails"
                    class="flat-button"
                    (click)="seeDetails()">
                See Details <i class="fa fa-external-link"></i>
            </button>
            <button *ngIf="!canSeeDetails"
                    class="flat-button icon-button m-0"
                    (click)="reset()">
                <i class="fa fa-undo"></i>
            </button>
        </div>
    </div>
</div>
