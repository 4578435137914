import {Component, OnInit, Renderer2} from '@angular/core';
import { TaxRateService } from '../tax.rate.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Constants, TaxAuthorityStatuses } from '../../constants.new';
import { TaxRateAreaTaxAuthorityDetail, TaxAuthorityDetail, TaxRateAreaInclusions } from '../../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { TaxAuthorityPayload } from '../tax.rate.model';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { Address } from '../../Common/Models/common.model';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddTaxYearModalComponent } from './add.tax.year.modal.component';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { Decimal } from 'decimal.js';
import { TimerService } from '../../UI-Lib/Utilities/timer.service';
import { BusyIndicatorRef, BusyIndicatorService } from '../../Busy-Indicator';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';
import { BehaviorSubject, Subject, Subscription, lastValueFrom, takeUntil } from 'rxjs';

export interface Picklist {
    label: string;
    id: number
}

@Component({
    selector: 'tax-authority',
    templateUrl: './tax.authority.component.html'
})
export class TaxAuthorityComponent implements OnInit {
    constructor(public taxRateService: TaxRateService,
                public constants: Constants,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _toastr: ToastrService,
                private readonly _modalService: BsModalService,
                private readonly _restrictService: RestrictService,
                public bsModalRef: BsModalRef,
                private readonly _featureFlagsService: FeatureFlagsService,
                public readonly busyIndicatorService: BusyIndicatorService,
                private readonly _renderer: Renderer2,
                private readonly _timer: TimerService) {
            this.canEdit = _restrictService.isInRole(Roles.TAXRATESETUP);
        }
    _busyRef: BusyIndicatorRef;
    _destroy$: Subject<void> = new Subject();
    busyRefId: string = this.busyIndicatorService.generateUniqueMessageIdentifier();

    canEdit: boolean = false;
    currentYear: number = Number(moment().format('YYYY'));
    categories: Picklist[];
    taxableAssessments: Picklist[];
    statuses: Picklist[];
    certificationTypes: Picklist[];
    certificationMonths: number[];
    certificationDays: number[];
    displayAs: 'rate' | 'mills' = 'rate';
    taxAuthority: TaxAuthorityPayload;
    originalTaxAuthority: TaxAuthorityPayload;
    saving: boolean = false;
    tempVals: any = {};
    taxRateAreaInclusions: TaxRateAreaInclusions[] = [];
    editMode: boolean = false;

    launchInEditMode: boolean;
    taxAuthorityId: number;
    onClose: any;

    async ngOnInit() {
        this._initPickLists();

        const taxAuthority =  await this.taxRateService.getTaxAuthority(this.taxAuthorityId);
        this.taxAuthority = this._prepareData(taxAuthority);
        //this.taxAuthority = taxAuthority;

        const uniqTRAs = this.getCompiledListOfTaxRateAreaTaxAuthorityDetails(this.taxAuthority.taxRateAreaTaxAuthorityDetails);
        this.taxRateAreaInclusions = _.map(uniqTRAs, (tratad: TaxRateAreaTaxAuthorityDetail) : TaxRateAreaInclusions => {
            return {
                taxRateAreaName: tratad.taxRateAreaName,
                yearsIncluded: _.map(this.taxAuthority.details, (tad: TaxAuthorityDetail) => {
                    return this.shouldDisplayCheck(tratad, tad.taxYear);
                })
            };
        });

        if (this.launchInEditMode) {
            this.goToEditMode();
        }
        //console.log(this.taxAuthority)
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.taxAuthority) {
            return null;
        }

        return {
            entityType: 'TaxAuthority',
            entityName: this.taxAuthority.name,
            entityData: {
                typeId: Core.EntityTypes.TaxAuthority,
                id: this.taxAuthority.taxAuthorityId,
                name: this.taxAuthority.name
            },
            readOnly: !this.canEdit
        } as AttachmentModalData;
    }
    get commentsModel(): CommentModalData {
        if (!this.taxAuthority) {
            return null;
        }

        return {
            entityID: this.taxAuthority.taxAuthorityId,
            entityTypeID: Core.EntityTypes.TaxAuthority,
            description: this.taxAuthority.name,
            canEdit: this.canEdit
        } as CommentModalData;
    }

    goToEditMode(): void {
        this.originalTaxAuthority = _.cloneDeep(this.taxAuthority);
        this.editMode = true;
    }

    cancel(): void {
        this.taxAuthority = this.originalTaxAuthority;
        this.editMode = false;
    }

    getCategory(taxAuthorityCategoryId: number): string {
        return taxAuthorityCategoryId ?  this.constants.TaxAuthorityCategories[taxAuthorityCategoryId].displayName : '';
    }

    getTaxableAssessmentLabel(taxableAssessmentId: number): string {
        if(!taxableAssessmentId) {
            return;
        }
        return _.find(this.taxableAssessments, {id: taxableAssessmentId}).label;
    }

    getStatus(taxAuthorityStatusId: number): string {
        if(!taxAuthorityStatusId) {
            return;
        }

        return _.find(this.statuses, {id: taxAuthorityStatusId}).label;
    }

    getCertificationType(): string {
        return _.find(this.certificationTypes, {id: this.taxAuthority.certificationType}).label;
    }

    getYearEstimated(yearDetail: TaxAuthorityDetail): boolean {
        return yearDetail.taxAuthorityStatusId != TaxAuthorityStatuses.Actual;
    }

    getShadingFromStatus(yearDetail: TaxAuthorityDetail): string {
        switch(yearDetail.taxAuthorityStatusId) {
            case TaxAuthorityStatuses.Estimated:
                return 'not-actual-tax-year';
            case TaxAuthorityStatuses.Pending:
                return 'pending-tax-year';
            default:
                return '';
        }
    }

    shouldDisplayCheck(tratd: TaxRateAreaTaxAuthorityDetail, year: number): boolean {
        // get all records with this taxRateAreaId
        // if any of those records would include this year - return true

        const allTratad = _.filter(this.taxAuthority.taxRateAreaTaxAuthorityDetails, {taxRateAreaId: tratd.taxRateAreaId});

        return _.some(allTratad, (tratad: TaxRateAreaTaxAuthorityDetail) => {
            return  (!tratad.yearBegin || tratad.yearBegin <= year)
                 && (!tratad.yearEnd || tratad.yearEnd >= year);
        });
    }

    getCompiledListOfTaxRateAreaTaxAuthorityDetails(tartadList: TaxRateAreaTaxAuthorityDetail[]): TaxRateAreaTaxAuthorityDetail[] {
        return _.uniqBy(tartadList, 'taxRateAreaId');
    }

    removeYearDetail(yearDetail: TaxAuthorityDetail): void {
        if(yearDetail.efAction == 'add') {
            _.remove(this.taxAuthority.details, yearDetail);
        } else {
            yearDetail.efAction = 'delete';
        }
    }

    disableTaxAuthority(): void {

        //  In order to disable the max year must have an entry of 0.
        //  When user checks disabled, automatically add this year if not already present.

        this.taxAuthority.disabled = true;
        const maxYearDetail = _.maxBy(this.taxAuthority.details, 'taxYear');

        if(!maxYearDetail || maxYearDetail.rate != 0) {
            const taxAuthorityDetail = new TaxAuthorityDetail(this.taxAuthority.taxAuthorityId);

            taxAuthorityDetail.rate = 0;
            taxAuthorityDetail.rateAsMills = new Decimal(0).toFixed(6);
            this.syncRateFromMillsToPct(taxAuthorityDetail);

            // was there prev. year?
            if (this.taxAuthority.details.length > 0)
                taxAuthorityDetail.rateChangeAsPercent = new Decimal(-100).toFixed(2);
            else
                taxAuthorityDetail.rateChangeAsPercent = new Decimal(0).toFixed(2);

            this.syncRateChange(taxAuthorityDetail);

            taxAuthorityDetail.taxYear = maxYearDetail ? maxYearDetail.taxYear + 1 : this.currentYear;
            taxAuthorityDetail.taxAuthorityStatusId = TaxAuthorityStatuses.Estimated;

            this.taxAuthority.details.push(taxAuthorityDetail);
            this.taxAuthority.details = _.orderBy(this.taxAuthority.details, 'taxYear', 'desc');

            this._toastr.info(`Year ${taxAuthorityDetail.taxYear} added`);
        }
    }

    launchAddTaxYearModal(): void {
        const initialState = {
            yearsAlreadyAdded: _.chain(this.taxAuthority.details)
                .reject({efAction: 'delete'})
                .map('taxYear')
                .value()
        };

        const modalRef = this._modalService.show(AddTaxYearModalComponent, {initialState});

        modalRef.content.onClose = (year: number) => {

            //console.log('adding year', year);

            // do we have deleted entry for this given year?
            const deletedYear = _.find(this.taxAuthority.details, {taxYear: year});
            if(deletedYear) {
                deletedYear.efAction = null;

                this._calculateRate(deletedYear);
            } else {
                // create new entry for this given yeat
                const taxAuthorityDetail = new TaxAuthorityDetail(this.taxAuthority.taxAuthorityId);
                taxAuthorityDetail.taxYear = year;
                taxAuthorityDetail.taxAuthorityStatusId = TaxAuthorityStatuses.Estimated;
                this._calculateRate(taxAuthorityDetail);

                this.taxAuthority.details.push(taxAuthorityDetail);
            }

            this.taxAuthority.details = _.orderBy(this.taxAuthority.details, 'taxYear', 'desc');
            const detailIdx = _.findIndex(this.taxAuthority.details, {taxYear: year});

            this._timer.setTimeout(() => {
                const rateClass = this.displayAs == 'rate' ? '.rate' : '.mills';
                const element = this._renderer.selectRootElement(rateClass + detailIdx);
                element.focus();
            });
        };
    }

    captureVal(entity: any, field: string): void {
		this.tempVals[field] = entity[field];
	}

    syncDefaultAnnualRateIncrease(): void {
        if(!this._isValidNumber(this.taxAuthority.defaultAnnualRateIncreaseAsPct)) {
            return;
        }

        this.taxAuthority.defaultAnnualRateIncrease = new Decimal(this.taxAuthority.defaultAnnualRateIncreaseAsPct).dividedBy(100).toNumber();
    }

    syncRateFromPctToMills(yearDetail: TaxAuthorityDetail): void {
        if(!this._isValidNumber(yearDetail.rateAsPct)) {
            yearDetail.rateAsMills = null;
            return;
        }

        yearDetail.rateAsMills = new Decimal(yearDetail.rateAsPct).times(10).toFixed(6);
    }

    syncRateFromMillsToPct(yearDetail: TaxAuthorityDetail): void {
        if(!this._isValidNumber(yearDetail.rateAsMills)) {
            yearDetail.rateAsPct = null;
            return;
        }

        yearDetail.rateAsPct = new Decimal(yearDetail.rateAsMills).dividedBy(10).toFixed(6);
    }

    syncRateChange(yearDetail: TaxAuthorityDetail): void {
        if(!this._isValidNumber(yearDetail.rateChangeAsPercent)) {
            yearDetail.rateChange = null;
            return;
        }

        yearDetail.rateChange = new Decimal(yearDetail.rateChangeAsPercent).dividedBy(100).toNumber();
    }

    defaultAnnualRateIncreaseAsPctBlur(): void {
        if(!this.taxAuthority.defaultAnnualRateIncreaseAsPct) {
            return;
        }

        this.taxAuthority.defaultAnnualRateIncreaseAsPct = new Decimal(this.taxAuthority.defaultAnnualRateIncreaseAsPct).toFixed(2);
    }

    rateAsMillsBlur(currentYearDetail: TaxAuthorityDetail): void {
        //console.log('rateAsMillsBlur', currentYearDetail);

        if(currentYearDetail.rateAsMills == this.tempVals.rateAsMills) {
            return;
        }

        if (!this._isValidNumber(currentYearDetail.rateAsMills)){
            currentYearDetail.rateAsMills = null;
            currentYearDetail.rateAsPct = null;
            currentYearDetail.rateChangeAsPercent = null;
            return;
        }

        // store
        currentYearDetail.rate = this._isInvalidNumber(currentYearDetail.rateAsMills) ? null : new Decimal(currentYearDetail.rateAsMills).dividedBy(1000).toNumber();
        // format
        currentYearDetail.rateAsMills = this._isInvalidNumber(currentYearDetail.rateAsMills) ? new Decimal(0).toFixed(6) : new Decimal(currentYearDetail.rateAsMills).toFixed(6);
        // sync
        currentYearDetail.rateAsPct = new Decimal(currentYearDetail.rate).times(100).toFixed(6);

        this._updateCurrentYearRateChange(currentYearDetail);
    }

    rateAsPctBlur(currentYearDetail: TaxAuthorityDetail): void {
        //console.log('rateAsPctBlur', currentYearDetail);

        if(currentYearDetail.rateAsPct == this.tempVals.rateAsPct) {
            return;
        }

        if (!this._isValidNumber(currentYearDetail.rateAsPct)){
            currentYearDetail.rateAsPct = null;
            currentYearDetail.rateAsMills = null;
            currentYearDetail.rateChangeAsPercent = null;
            return;
        }

        // store
        currentYearDetail.rate = this._isInvalidNumber(currentYearDetail.rateAsPct) ? null : new Decimal(currentYearDetail.rateAsPct).dividedBy(100).toNumber();
        // format
        currentYearDetail.rateAsPct = this._isInvalidNumber(currentYearDetail.rateAsPct) ? new Decimal(0).toFixed(6) : new Decimal(currentYearDetail.rateAsPct).toFixed(6);
        // sync
        currentYearDetail.rateAsMills = new Decimal(currentYearDetail.rate).times(1000).toFixed(6);

        this._updateCurrentYearRateChange(currentYearDetail);
    }

    rateChangeAsPercentBlur(currentYearDetail: TaxAuthorityDetail): void {
        //console.log('rateChangeAsPercentBlur', currentYearDetail);

        if(!this._isValidNumber(currentYearDetail.rateChangeAsPercent)) {
            currentYearDetail.rateChangeAsPercent = null;
            return;
        }

        if(currentYearDetail.rateChangeAsPercent == this.tempVals.rateChangeAsPercent) {
            return;
        }

        currentYearDetail.rateChangeAsPercent = new Decimal(currentYearDetail.rateChangeAsPercent).toFixed(2);

        this._updateCurrentYearRate(currentYearDetail);
    }

    async save() {
        if (_.some(this.taxAuthority.details, ['rate', null]) || _.some(this.taxAuthority.details, ['rateAsPct', null]) || _.some(this.taxAuthority.details, ['rateAsMills', null])) {
            this._toastr.error('Rate is required for each tax year');
            return;
        }

        if(this.taxAuthority.disabled && Number(this.taxAuthority.details[0].rate) !== 0) {
            this._toastr.error('Max Year must have rate of 0%');
            return;
        }

        if(_.some(this.taxAuthority.details, x => x.rate > 9999)) {
            this._toastr.error('Tax rate cannot exceed 999,999.');
            return;
        }

        if(!_.isEqual(_.omit(this.taxAuthority, 'details'), _.omit(this.originalTaxAuthority, 'details'))) {
            this.taxAuthority.efAction = 'update';
        }

        this.taxAuthority.details = _.map(this.taxAuthority.details, yearDetail => {
            yearDetail.rate = new Decimal(yearDetail.rateAsPct).dividedBy(100).toNumber();

            if(!yearDetail.efAction) {
                const originalYearDetail = _.find(this.originalTaxAuthority.details, {taxAuthorityDetailId: yearDetail.taxAuthorityDetailId});

                if(originalYearDetail && !_.isEqual(yearDetail, originalYearDetail)) {
                    yearDetail.efAction = 'update';
                }
            }

            return yearDetail;
        });

        this.saving = true;

        try {
            const savedAuthority = await this._saveOrUpdateTaxAuthority(this.taxAuthority);
            this.taxAuthority = this._prepareData(savedAuthority);
            this.editMode = false;
            this.onClose(savedAuthority);
            this.bsModalRef.hide();
        } finally {
            this.saving = false;
        }
    }

    async delete(): Promise<void> {
        const result: number = await this._messageBoxService.open({
            message: `Are you sure you want to delete ${this.taxAuthority.name}?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if (result === MessageBoxResult.OK) {
            if (this._featureFlagsService.featureFlags.enableTaxRateAreaTaxAuthorityBackgroundProcess){
                this._showBusyIndicator('Tax Authority', 'Deleting Tax Authority', null, false, true);
                try
                {
                const longRunningProcessId = await this.taxRateService.deleteTaxAuthority(this.taxAuthority.taxAuthorityId);
                await this._busyRef.setLongRunningProcessId(longRunningProcessId);
                }
                catch(e)
                {
                await this._hideBusyIndicator();
                return Promise.reject(e);
                }
                this.bsModalRef.hide();
                return Promise.resolve();
            
            }
            else {
            await this.taxRateService.deleteTaxAuthority(this.taxAuthority.taxAuthorityId);

            this.onClose();
            this.bsModalRef.hide();
          }
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this.busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            if (success) {
                this._refreshAndNotifyParent();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private _refreshAndNotifyParent() {
        this.onClose()
        //this.gridDataChanged.emit();
    }

    getQcInfo(yearDetail: TaxAuthorityDetail): string {
        let result: string = null;
        if (yearDetail.taxAuthorityStatusId === TaxAuthorityStatuses.Pending) {
            if (yearDetail.qcRequestTimeUtc) {
                result = `QC requested at ${  moment(yearDetail.qcRequestTimeUtc).utc().format('M/D/Y')}`;
                if (yearDetail.qcRequestUserFullName) {
                    result += ` by ${  yearDetail.qcRequestUserFullName}`;
                }
            }
        }

        return result;
    }

    async closeModal() {
        if(this.editMode && this._changesWereMade()) {
            const result: number = await this._messageBoxService.open({
                message: 'Are you sure you want to cancel and discard all changes?',
                buttons: MessageBoxButtons.OKCancel
            });

            if (result === MessageBoxResult.OK) {
                this.onClose();
                this.bsModalRef.hide();
            }
        } else {
            this.onClose();
            this.bsModalRef.hide();
        }
    }

    private _initPickLists() {
        this.certificationTypes = this.constants.CertificationTypes;

        this.certificationMonths = _.range(1, 13);
        this.certificationDays = _.range(1, 32);

        this.categories = _.map(this.constants.TaxAuthorityCategories, (names: any, id: number) => {
            return {
                id: Number(id),
                label: names.displayName
            };
        });

        this.taxableAssessments = _.map(this.constants.TaxableAssessmentTypes, (names: any, id: number) => {
            return {
                id: Number(id),
                label: `${names.displayName} ${names.extraDisplay}`
            };
        });

        this.statuses = this.constants.TaxAuthorityStatuses;
    }

    private _prepareData(taxAuthority: TaxAuthorityPayload): TaxAuthorityPayload {
        if(!taxAuthority) {
            return;
        }
        taxAuthority.address = taxAuthority.address || new Address();
        taxAuthority.defaultAnnualRateIncreaseAsPct = taxAuthority.defaultAnnualRateIncrease ? new Decimal(taxAuthority.defaultAnnualRateIncrease).times(100).toFixed(2) : undefined;
        if (!taxAuthority.defaultAnnualRateIncreaseAsPct)
            taxAuthority.defaultAnnualRateIncreaseAsPct = new Decimal(0).times(100).toFixed(2);

        taxAuthority.details = _.chain(taxAuthority.details)
            .map(yearDetail => {
                //yearDetail.rate = yearDetail.rate ? new Decimal(yearDetail.rate).toFixed(8) : yearDetail.rate;
                if (yearDetail.rate != null){
                    yearDetail.rateAsPct =  new Decimal(yearDetail.rate).times(100).toFixed(6);
                }
                else{
                    yearDetail.rateAsPct = new Decimal(0).times(100).toFixed(6);
                }

                // we have pct -> sync pct to mills
                this.syncRateFromPctToMills(yearDetail);

                yearDetail.rateChangeAsPercent = yearDetail.rateChange ? new Decimal(yearDetail.rateChange).times(100).toFixed(2) : undefined;

                if (yearDetail.rateChange == 0)
                    yearDetail.rateChangeAsPercent = new Decimal(0).toFixed(2);
                return yearDetail;
            })
            .orderBy('taxYear', 'desc')
            .value();

        return taxAuthority;
    }

    private _calculateRate(yearDetail: TaxAuthorityDetail): void {
        yearDetail.rateChangeAsPercent = this.taxAuthority.defaultAnnualRateIncreaseAsPct || new Decimal(0).toFixed(2);
        this.syncRateChange(yearDetail);

        this._updateCurrentYearRate(yearDetail);
    }

    private _isValidNumber(value: string | number): boolean {
        if (this._isInvalidNumber(value))
            return false;

        if (isNaN(Number(value)))
            return false;

        return true;
    }


    private _isInvalidNumber(value: string | number): boolean {
        return value === null || value === undefined || value === '' || value === '.';
    }

    private _updateCurrentYearRateChange(currentYearDetail: TaxAuthorityDetail): void {

        //console.log('_updateCurrentYearRateChange', currentYearDetail);
        const previousYearDetail = this._findPreviousYearDetail(currentYearDetail);
        if(previousYearDetail) {
            if (Number(previousYearDetail.rate) > 0)
                currentYearDetail.rateChangeAsPercent = new Decimal(currentYearDetail.rate).minus(previousYearDetail.rate).dividedBy(previousYearDetail.rate).times(100).toFixed(2);

            if (Number(currentYearDetail.rate) == 0 && Number(previousYearDetail.rate) == 0)
                currentYearDetail.rateChangeAsPercent = new Decimal(0).toFixed(2);

            if (Number(previousYearDetail.rate) == 0 && Number(currentYearDetail.rate) > 0)
                currentYearDetail.rateChangeAsPercent = null;

            this.syncRateChange(currentYearDetail);
        }

        this._updateNextYearRateChange(currentYearDetail);
    }

    private _updateCurrentYearRate(currentYearDetail: TaxAuthorityDetail): void {
        const previousYearDetail = this._findPreviousYearDetail(currentYearDetail);

        if(previousYearDetail) {
            if (previousYearDetail.rate != null){
                currentYearDetail.rate = this._computeRateFromPrevious(previousYearDetail, currentYearDetail);
                currentYearDetail.rateAsMills = new Decimal(currentYearDetail.rate).times(1000).toFixed(6);

                this.syncRateFromMillsToPct(currentYearDetail);

                this._updateNextYearRateChange(currentYearDetail);
            }
            return;
        }
        // there was no prev. year, do we have next year instead?
        // If so, we project the next year's Rate flat to current.
        //

        const nextYearDetail = this._findNextYearDetail(currentYearDetail);

        if(nextYearDetail) {
            if (nextYearDetail.rate != null){
                currentYearDetail.rate = nextYearDetail.rate;
                currentYearDetail.rateAsMills = new Decimal(currentYearDetail.rate).times(1000).toFixed(6);

                this.syncRateFromMillsToPct(currentYearDetail);

                currentYearDetail.rateChangeAsPercent = null;
                this.syncRateChange(currentYearDetail);

                this._updateNextYearRateChange(currentYearDetail);
            }

        }
    }

    private _computeRateFromPrevious(previousYearDetail: TaxAuthorityDetail, currentYearDetail: TaxAuthorityDetail) : number {
        if ( previousYearDetail.rate == null )
        {
            return new Decimal(0).toNumber();
        }

        // Compute the Rate compounded over gap years between Previous and Current
        // using Default Annual Rate Increase.
        let prevRate: Decimal = new Decimal(previousYearDetail.rate);
        for (let yr = previousYearDetail.taxYear + 1; yr < currentYearDetail.taxYear; yr++)
        {
            prevRate = prevRate.times(new Decimal(1).plus(this.taxAuthority.defaultAnnualRateIncrease));
        }

        // Compute final Rate for Current with Current's rate change
        return new Decimal(currentYearDetail.rateChange).plus(1).times(prevRate).toNumber();
    }

    private _updateNextYearRateChange(currentYearDetail: TaxAuthorityDetail): void {
        const nextYearDetail = this._findNextYearDetail(currentYearDetail);

        if (!nextYearDetail)
            return;

        if (Number(currentYearDetail.rate) == 0 && Number(nextYearDetail.rate) > 0){
            nextYearDetail.rateChangeAsPercent = null;
            return;
        }

        if (Number(currentYearDetail.rate) == 0 && Number(nextYearDetail.rate) == 0)
            nextYearDetail.rateChangeAsPercent = new Decimal(0).toFixed(2);

        if(Number(currentYearDetail.rate) > 0)
            nextYearDetail.rateChangeAsPercent = new Decimal(nextYearDetail.rate).minus(currentYearDetail.rate).dividedBy(currentYearDetail.rate).times(100).toFixed(2);

        this.syncRateChange(nextYearDetail);
    }

    private _findPreviousYearDetail(currentYearDetail: TaxAuthorityDetail): TaxAuthorityDetail {
        return _.find(this.taxAuthority.details, function(d) {
            return d.taxYear < currentYearDetail.taxYear;
        });
    }

    private _findNextYearDetail(currentYearDetail: TaxAuthorityDetail): TaxAuthorityDetail {
        return _.findLast(this.taxAuthority.details, function(d) {
            return d.taxYear > currentYearDetail.taxYear;
        });
    }

    private _saveOrUpdateTaxAuthority(taxAuthority: TaxAuthorityPayload): Promise<TaxAuthorityPayload> {
        if(taxAuthority.taxAuthorityId) {
            return this.taxRateService.updateTaxAuthority(this.taxAuthority);
        } else {
            return this.taxRateService.createTaxAuthority(this.taxAuthority);
        }
    }

    private _changesWereMade(): boolean {
        if(!_.isEqual(_.omit(this.taxAuthority, 'details'), _.omit(this.originalTaxAuthority, 'details'))) {
            return true;
        }

        const isChangedDetail = _.some(this.taxAuthority.details, yearDetail => {
            if(!yearDetail.efAction) {
                const originalYearDetail = _.find(this.originalTaxAuthority.details, {taxAuthorityDetailId: yearDetail.taxAuthorityDetailId});

                return originalYearDetail && !_.isEqual(yearDetail, originalYearDetail);
            }

            return true;

        });

        return isChangedDetail;
    }

}
