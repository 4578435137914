import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ProcessingService } from '../processing.service.upgrade';

export interface ProcessAppealWarrantedParams {
    taskIDs: number[];
    searchTimestamp: Date;
    runWithBuffer: boolean;
    updateAppealRecommendation: boolean;
}

export interface ProcessAppealWarrantedForm {
    taskIDs: number[];
    appealLevelID: number;
    comment: string;
    searchTimestamp: Date;
    updateAppealRecommendation: boolean;
}

@Component({
    selector: 'process-appeal-warranted',
    templateUrl: './processAppealWarranted.component.html'
})
export class ProcessAppealWarrantedComponent implements OnInit, IWeissmanModalComponent<ProcessAppealWarrantedParams, Core.BulkOperationResult[]> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _processingService: ProcessingService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _toastr: ToastrService) {
    }

    params: ProcessAppealWarrantedParams;
    result: Core.BulkOperationResult[];

    loading: boolean;
    loadingMessage: string;
    validationMessage: string;
    appealLevels: any[];
    isClientApprovalRequired: boolean;

    payload: ProcessAppealWarrantedForm;

    get cancelLabel(): string {
        return this.appealLevels ? 'Cancel' : 'Close';
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;

        this.payload = {
            taskIDs: this.params.taskIDs,
            appealLevelID: null,
            comment: null,
            searchTimestamp: this.params.searchTimestamp,
            updateAppealRecommendation: this.params.updateAppealRecommendation
        };

        try {
            const result = await this._processingService.getAppealLevels(this.params.taskIDs);
            this.validationMessage = result.validationMessage;
            this.appealLevels = result.appealLevels;
            this.isClientApprovalRequired = result.isClientApprovalRequired;

            if (result.warningMessage) {
                await this._messageBoxService.warning(result.warningMessage);
            }

            if (this.appealLevels.length) {
                this.payload.appealLevelID = this.appealLevels[0].appealLevelID;
            }
        } finally {
            this.loading = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async process(): Promise<void> {
        this.loading = true;

        if (this.params.runWithBuffer) {
            // This is "experimental" at the moment; I'm leaving in the ability to set a processingBufferOverride in
            // a console in case we run into any obscure problems here and for instance need to set it to 1 to clear
            // things out. Once this has been in place for a while we can probably get rid of the override thing.
            await this._runModalResultWithBuffer();
        } else {
            this.result = await this._processingService.processAppealWarranted(this.payload);
            this._bsModalRef.hide();
        }
    }

    private async _runModalResultWithBuffer(): Promise<void> {
        const bufferSize = (window as any).processingBufferOverride || 20;

        const chunks = [];
        for (let i = 0; i < this.params.taskIDs.length; i += bufferSize) {
            chunks.push(this.params.taskIDs.slice(i, i + bufferSize));
        }

        let index = 1;
        this.result = [];

        const processChunk = async () => {
            if (chunks.length) {
                const chunk = chunks.shift();
                if (bufferSize === 1) {
                    this.loadingMessage = `Processing Task ${  index  } of ${  this.params.taskIDs.length  }...`;
                } else {
                    this.loadingMessage = `Processing Tasks ${  index  } - ${  index + chunk.length - 1  } of ${  this.params.taskIDs.length  }...`;
                }

                try {
                    const payload = structuredClone(this.payload);
                    payload.taskIDs = chunk;
                    const tempResult = await this._processingService.processAppealWarranted(this.payload);
                    this.result = [...this.result, ...tempResult];
                    index += chunk.length;
                    await processChunk();
                } catch (err) {
                    this.loading = false;
                    this.loadingMessage = null;
                    this._toastr.error('An unexpected error has occurred');
                }
            } else {
                this.loading = false;
                this.loadingMessage = null;
                this._bsModalRef.hide();
            }
        };

        await processChunk();
    }
}
