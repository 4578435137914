import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { ActionViewContextMenuService } from './actionViewContextMenu.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
})
export class ActionViewModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('avContextMenuService', ActionViewContextMenuService);
    }
}
