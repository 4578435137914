import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TeamService } from 'src/app/Team/team.service';

interface UserTeamModel extends Core.UserTeamModel {
    label: string;
}

declare const _: any;

@Component({
    selector: 'user-team-picker',
    templateUrl: './user-team-picker.component.html',
    styles: [`
        .ng-invalid.ng-touched {
            border-color: #a94442;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
        }
    `]
})
export class UserTeamPicker implements OnChanges {
    @Input() user: Core.UserTeamModel;
    @Input() userId: System.Guid | string;
    @Input() teamId: number;
    @Input() isReassign: boolean = false;
    @Input() entityIdScope: number;
    @Input() instanceIdScope: number;
    @Input() entityTypeScope: string;
    @Input() restrictToRyanInstance: boolean = false;
    @Input() required: boolean = false;
    @Input() showInvalidState: boolean = false;
    @Input() disabled: boolean = false;
    @Input() revertIfEmptyOnBlur: boolean = false;
    @Input() parentUsers: Core.UserTeamModel[];
    @Input() placeholder: string;
    @Input() isConsultantPerspective: boolean = false;
    @Input() entityIdsMultiselectScope: number[] = [];
    @Input() showDocumentIntakeUserTeamsOnly: boolean = false;
    @Output() userChange = new EventEmitter<Core.UserTeamModel>();

    users: UserTeamModel[] = [];
    selectedUserName: string = '';

    constructor(private teamService: TeamService) {}

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = 'Search User...';
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['placeholder']) {
            if (!this.placeholder) {
                this.placeholder = 'Search User...';
            }
        }

        const isUserObjRemoved = changes['user'] 
                            && (!changes['user'].currentValue || !changes['user'].currentValue.userID);
        const areIdsRemoved = (changes['userId'] && !changes['userId'].currentValue) 
                            || (changes['teamId'] && !changes['teamId'].currentValue);

        if (isUserObjRemoved || areIdsRemoved) {
            this.selectedUserName = '';
            this.user = undefined;
        }

        if(!this.users.length) {
            const users = this.parentUsers || await this._getUsers();
            this.users = _.map(users, userTeam => {
                return {
                    ...userTeam,
                    label: this.formatInput(userTeam)
                };
            });
        } else if (changes['parentUsers']) {
            this.users = _.map(this.parentUsers, userTeam => {
                return {
                    ...userTeam,
                    label: this.formatInput(userTeam)
                };
            });
        }

        let selectedUser: UserTeamModel;

        if (this.user) {
            selectedUser = _.find(this.users, {
                userID: this.user.userID,
                teamID: this.user.teamID
            });
        } else if(this.userId && this.teamId) {
            selectedUser = _.find(this.users, {
                userID: this.userId,
                teamID: this.teamId
            });

            if(selectedUser) {
                this.user = _.omit(selectedUser, 'label');
            }
        }

        if(selectedUser) {
            this.selectedUserName = selectedUser.label;
        }
    }

    formatInput(model: Core.UserTeamModel): string {
        if (_.isEmpty(model)) {
            return;
        }

        return `${model.lastName}, ${model.firstName} (${model.teamName})`;
    }

    changed(): void {
        if(!_.trim(this.selectedUserName) && !this.revertIfEmptyOnBlur) {
            this.user = undefined;
            this.userChange.emit(this.user);
        }
    }

    onBlur(): void {
        if (!_.trim(this.selectedUserName) && this.revertIfEmptyOnBlur) {
            this.selectedUserName = this.formatInput(this.user);
            return;
        }

        // If a user is copy-pasted into the input, we need to find the user in the list and select it
        // The username must be an exact match
        if (this.selectedUserName.trim() && !this.user) {
            const user = this.users.find(user => user.label === this.selectedUserName);
            if (user) {
                this.selectItem(user);
                return;
            }
        }

        if(this.user) {  // only emit if there's a user. we already emitted onchange if there's no user
            this.userChange.emit(this.user);
        }
    }

    selectItem(selectedUser: UserTeamModel): void {
        this.user = selectedUser;
        this.selectedUserName = selectedUser.label;
        this.userChange.emit(this.user);
    }

    select(eventTarget: EventTarget): void {
        (eventTarget as HTMLInputElement).select();
    }

    private _getUsers(): Promise<Core.UserTeamModel[]> {
        return this.restrictToRyanInstance
            ? this.teamService.getAllAssignableRyanUsers(false, this.entityIdScope, this.entityTypeScope)
            : (this.entityIdsMultiselectScope && this.entityIdsMultiselectScope.length > 0)
                ? this.teamService.getAllAssignableUsers(this.isReassign, 
                                                        false, 
                                                        null, 
                                                        this.entityTypeScope, 
                                                        this.instanceIdScope, 
                                                        null, 
                                                        this.entityIdsMultiselectScope,  
                                                        this.showDocumentIntakeUserTeamsOnly) //there is currently no DI multiselect, but included this here anyway
                : this.teamService.getAllAssignableUsers(this.isReassign, 
                                                        false, 
                                                        this.entityIdScope,
                                                        this.entityTypeScope,
                                                        this.instanceIdScope, 
                                                        this.showDocumentIntakeUserTeamsOnly)
    }
}
