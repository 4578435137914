import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const STATE_JURISDICTION_COMMAND_CENTER_HELP: HelpContent[] = [
    {
        helpContentId: 'state-jurisdiction-command-center.filter-disabled',
        tooltipText: 'Not available until a valid filter is selected'
    },
    {
        helpContentId: 'state-jurisdiction-command-center.highlight',
        tooltipText: 'Highlights change-tracked cells which have been recently modified.',
        hasIcon: true
    },
    {
        helpContentId: 'state-jurisdiction-command-center.filter-verification',
        tooltipText: 'Filters based on verification status.',
        hasIcon: true
    },
    {
        helpContentId: 'state-jurisdiction-command-center.pp-original-form-required',
        tooltipText: 'Original jurisdiction paper form should be submitted with any software generated return.'
    }
];
