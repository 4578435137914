import { Injectable } from '@angular/core';
import { LongRunningProcessRepository } from '../../Compliance/Repositories';
import { ProgressIndicatorConfig, ProgressIndicator } from '../Models/progressIndicator.model';
import { Observable, Subject, BehaviorSubject, lastValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebsocketListenerService } from '../../Compliance/websocketListener.service';
import { AccountService } from '../../User/account.service';
import { LongRunningProcessIndicatorConfigService } from '../Config/longRunningProcessIndicatorConfig.service';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';

export enum SnackSize {
    Hidden,
    Small,
    Large
}

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    constructor(
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _accountService: AccountService,
        private readonly _lrpConfigService: LongRunningProcessIndicatorConfigService,
        private readonly _messageModalService: MessageModalService
    ) { }

    // Snack bar
    private _next: Function = () => this._longRunningProcessesSubject.next(this._longRunningProcesses.filter(lrp => lrp.isSnackBar));

    private _longRunningProcesses: ProgressIndicator[] = [];
    private _busyIndicatorsActive: number[] = [];
    private _snackSize: SnackSize;
    private _loadingPrevious: boolean;

    private _longRunningProcessesSubject: Subject<ProgressIndicator[]> = new Subject<ProgressIndicator[]>();
    private _displayStateSubject: BehaviorSubject<SnackSize> = new BehaviorSubject(SnackSize.Large);

    longRunningProcesses: Observable<ProgressIndicator[]> = this._longRunningProcessesSubject.asObservable();
    displayState: Observable<SnackSize> = this._displayStateSubject.asObservable();

    // Processes
    private _activeTab: boolean = true;

    async start(): Promise<void> {
        this._lrpConfigService.init();
        this._loadPreviousProcesses();

        this._websocketListenerService.longRunningProcessReconnected$.subscribe(() => {
            this._loadPreviousProcesses(false);
        });

        // Listen for processes started while the user session is active
        this._websocketListenerService.longRunningProcessStatusChange$.subscribe(async (process) => {
            if (process.createdBy === this._accountService.userData.id) {
                const longRunningProcessModel = await lastValueFrom(this._longRunningProcessRepository.get(process.longRunningProcessId));

                if (this._activeTab) {
                    this._addSnack(longRunningProcessModel, true);
                } else {
                    this._addSnack(longRunningProcessModel, false);
                }
            }
        });

        // Listen for tab visibility change events and update the processes
        // Helps complete the acknowledgement gap between tabs
        this._activeTab = !document.hidden;
        document.addEventListener('visibilitychange', async () => {
            if (!document.hidden) {
                this._activeTab = true;
                this._next();
            } else {
                this._activeTab = false;
                this._longRunningProcesses.forEach(p => p.isOwner = false);
            }
        });
        window.addEventListener('focus', async () => {
            this._activeTab = true;
            this._next();
        });
        window.addEventListener('blur', () => {
            this._activeTab = false;
            this._longRunningProcesses.forEach(p => p.isOwner = false);
        });
    }

    setBusyIndicatorActive(longRunningProcessId: number): void {
        const active = this._longRunningProcesses.find(p => p.longRunningProcessId === longRunningProcessId);
        if (active) {
            active.isSnackBar = false;
            this._next();
        }
        if (!this._busyIndicatorsActive.includes(longRunningProcessId)) {
            this._busyIndicatorsActive.push(longRunningProcessId);
        }
    }

    setBusyIndicatorInactive(longRunningProcessId: number): void {
        const active = this._longRunningProcesses.find(p => p.longRunningProcessId === longRunningProcessId);
        if (active && !active.isComplete) {
            active.isSnackBar = true;
            this._next();
        }
        const index = this._busyIndicatorsActive.findIndex(p => p === longRunningProcessId);
        if (index !== -1) {
            this._busyIndicatorsActive.splice(index, 1);
        }
    }

    /**
     * Show the snack bar at a specified size
     */
    showSnackBar(): void {
        this._snackSize = SnackSize.Large;
        this._displayStateSubject.next(SnackSize.Large);
    }

    /**
     * Show the mini snack bar
     */
    showMiniSnackBar(): void {
        this._displayStateSubject.next(SnackSize.Small);
    }

    /**
     * Minimize the snack bar to the nav menu
     */
    hideSnackBar(): void {
        this._snackSize = SnackSize.Hidden;
        this._displayStateSubject.next(SnackSize.Hidden);
    }

    /**
     * Return the snack bar to it's previous state
     */
    hideMiniSnackBar(): void {
        this._displayStateSubject.next(this._snackSize);
    }

    /**
     * Add an immediate base snack to represent a long-running process that hasn't started from the server yet
     */
    addById(longRunningProcessId: number, longRunningProcessTypeId: Compliance.LongRunningProcessTypeEnum): ProgressIndicator {
        const active = this._longRunningProcesses.find((p: ProgressIndicator) => p.longRunningProcessId === longRunningProcessId);
        if (active) {
            return;
        }

        const baseConfig = this._lrpConfigService.processMap.get(longRunningProcessTypeId);
        if (!baseConfig) {
            if (baseConfig === undefined) {
                console.warn(`Process map not found for long running process type ${longRunningProcessTypeId}`);
            }
            return null;
        }

        const process: Compliance.LongRunningProcessModel = {
            longRunningProcessId,
            longRunningProcessTypeId,
            correlationId: null,
            createdByName: null,
            error: null,
            errorGuid: null,
            hostName: null,
            isAcknowledged: false,
            isCanceled: false,
            isCompleted: false,
            isPaused: false,
            longRunningProcessQueueTypeId: null,
            operationName: null,
            parameters: null,
            statusCode: null
        };

        const config = baseConfig(process);

        return this._createIndicator(config, false);
    }

    /**
     * Checks to see if id is active in snack bar and removes it to prevent duplicates
     * @param id
     */
    async removeById(id: string | number): Promise<void> {
        const existing = this._longRunningProcesses.find((p: ProgressIndicator) => p.identifier === id || p.longRunningProcessId === id);
        if (existing) {
            await existing.remove();
        }
    }

    /**
     * Add a snack directly to the snack bar
     * @param process
     * @param isOwner
     */
    private _addSnack(process: Compliance.LongRunningProcessModel, isOwner: boolean): ProgressIndicator {
        const active = this._longRunningProcesses.find((p: ProgressIndicator) => p.longRunningProcessId === process.longRunningProcessId);
        if (active) {
            active.isOwner = isOwner;
            return null;
        }

        const baseConfig = this._lrpConfigService.processMap.get(process.longRunningProcessTypeId);
        if (!baseConfig) {
            if (baseConfig === undefined) {
                console.warn(`Process map not found for long running process type ${process.longRunningProcessTypeId}`);
            }
            return null;
        }

        const config = baseConfig(process);

        const initialError = process.isCompleted ? process.error : null;

        return this._createIndicator(config, isOwner, initialError);
    }

    /**
     * Create the progress indicator
     * @param config
     * @param isOwner
     */
    private _createIndicator(config: ProgressIndicatorConfig, isOwner: boolean = true, initialError: string = null): ProgressIndicator {
        const indicator = new ProgressIndicator(config, this._next, this._messageModalService, this._longRunningProcessRepository);
        indicator.isOwner = isOwner;
        indicator.isSnackBar = !config.isHidden && this._busyIndicatorsActive.findIndex(p => p === indicator.longRunningProcessId) === -1;
        indicator.remove = async () => {
            const i = this._longRunningProcesses.findIndex(p => p.longRunningProcessId === indicator.longRunningProcessId);
            if (i > -1) {
                this._longRunningProcesses.splice(i, 1);
                await lastValueFrom(this._longRunningProcessRepository.acknowledge(indicator.longRunningProcessId));
                this._next();
            }
        };
        indicator.setWebsocketListeners(
            this._websocketListenerService.longRunningProcessStatusChange$,
            this._websocketListenerService.longRunningProcessProgressChange$,
            this._websocketListenerService.longRunningProcessAcknowledged$.pipe(
                filter(m => m.userId === this._accountService.userData.id)
            )
        );

        this._longRunningProcesses.push(indicator);
        this._next();

        if (!this._loadingPrevious) {
            this.showSnackBar();
        }

        if (initialError) {
            indicator.setError(initialError);
        }

        return indicator;
    }

    private async _loadPreviousProcesses(hideSnackbar = true): Promise<void> {
        this._loadingPrevious = true;
        const searchModel: Compliance.LongRunningProcessSearchModel = {
            notAcknowledged: true,
            allUsers: false
        };
        const queryModel = await lastValueFrom(this._longRunningProcessRepository.getList(searchModel));
        const list = queryModel.data;

        this._longRunningProcesses = [];
        list.forEach((process) => this._addSnack(process, false));
        this._loadingPrevious = false;
        if (hideSnackbar) {
            this.hideSnackBar();
        }
    }
}
