import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppealService, AppealViewModel } from '../../../Annual-Details/Appeals/appeal.service';
import { BillClusterService, BillViewModel, RefundViewModel } from '../../../Annual-Details/Taxes/bill-cluster.service';
import { Appeal } from '../../../Annual-Details/Appeals/appeal.model';
import { AnnualDetailEditState } from '../../../Annual-Details/annual-details-navigation.service';
import { Bill } from '../../../Annual-Details/Taxes/bill.model';
import { Refund } from '../../../Annual-Details/Taxes/refund.model';
import { AssessmentService, AssessmentViewModel } from '../../../Annual-Details/Assessments/assessment.service';
import { Assessment } from '../../../Annual-Details/Assessments/assessment.model';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';
import { Task, TaskTypeID } from '../../../Task/Task.Model';
import { TaskService } from '../../../Task/task.service.upgrade';
import { AttachmentTypeID } from '../../../Attachment/attachment.model';
import { CommentModalData, CommentsService } from '../../../Comments/comments.service';
import { AttachmentModalData, AttachmentModalEntityData } from '../../../Attachment/attachment.modal.model';
import { ComplianceViewModel, ComplianceService } from '../../../Annual-Details/Compliance/compliance.service';
import { Filing } from '../../../Annual-Details/Compliance/compliance.model';
import { AnnualDetailAssessment } from '../../../Annual-Details/Annual-Year/annual-year.model';
import { UtilitiesService } from '../../../UI-Lib/Utilities';
import { BillClusterTaxRateSetupModalResult } from '../../../Entity/Parcel/TaxRateSetup/billClusterTaxRateSetupModal.component';
import { TaxSetupMinimumDTO, FirstEncounterSaveResult } from '../../../Entity/Parcel/TaxRateSetup/parcelTaxRateService';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { IdpService } from './IDP/idp.service';
import { assign } from 'lodash';

declare const _: any;

// TODO: Create a const system and reference these from there
const COMPANY_ENTITY_TYPE_ID = 1;
const SITE_ENTITY_TYPE_ID = 5;
const PARCEL_ENTITY_TYPE_ID = 6;
const ASSESSMENT_ENTITY_TYPE_ID = 7;
const APPEAL_ENTITY_TYPE_ID = 8;
const TAXBILL_ENTITY_TYPE_ID = 9;
const REFUND_ENTITY_TYPE_ID = 14;
const FILING_ENTITY_TYPE_ID = 10;

// TODO: This definition is pretty generic and should probably be in a shared module somewhere
// Also, the name 'EntityIdentifier' is questionable since ID is short for Identifier; by convention,
// things named [thing]ID is usually a number, still... this is kinda awkward.
export class EntityIdentifier {
    entityId: number;
    entityTypeId: number;
}

export interface DataEntryJSONHandler {
    retrieveDataEntryJSON: () => string;
}

export interface ValidationHandler {
    validate: (boolean, string) => void;
}

export class DataEntry<T> {
    // When the reload entity button is pressed, we want to wipe out
    // the entity object but leave everything else; use this property
    // as a flag to do that when the entity association is processed
    // (in all other circumstances should be false)
    resetOnLoad: boolean;
    // attachmentYear being present is actually the exception; it's
    // usually null for entities below parcel where it's implied by
    // the annual year, but for company/site/parcel it may be
    // specified
    attachmentYear: string;

    constructor(
            public entity: T,
            public attachmentDescription: string,
            public completeReadyTask: boolean,
            public useDocumentAsBillImage: boolean,
            public readyTaskID: number,
            public dataEntryChanged: boolean,
            public searchAnticipated: boolean,
            // This is a sort of dirty flag specifically for the complete task checkbox;
            // if the user has manually checked or unchecked it, we want to stop invoking
            // the checked or unchecked by default logic
            public completeReadyTaskChanged: boolean,
            public calcProjected: boolean,
            public taxSetup: BillClusterTaxRateSetupModalResult) {
        this.resetOnLoad = false;
    }
}

// Appeals, assessments, bills, and refunds all have their own edit panels
// that include comments and attachments; for company, site, and parcel
// we need to add comments and attachments for document processing
class GenericEntityVM {
    hasComments: boolean;
    hasAttachments: boolean;
    commentModalData: CommentModalData;
    attachmentModalData: AttachmentModalData;
}

@Component({
    selector: 'processing-entity-edit',
    templateUrl: './entity-edit.component.html'
})
export class EntityEditComponent implements OnInit, OnChanges {
    constructor(private readonly _appealService: AppealService,
                private readonly _billClusterService: BillClusterService,
                private readonly _assessmentService: AssessmentService,
                private readonly _taskService: TaskService,
                private readonly _commentsService: CommentsService,
                private readonly _attachmentService: AttachmentService,
                private readonly _complianceService: ComplianceService,
                private readonly _util: UtilitiesService,
                private readonly _featureFlagsService: FeatureFlagsService,
                private readonly _idpService: IdpService) {
        this.warnOnEntityReloadChanged = new EventEmitter();
        this.populateYears();
    }

    @Input() intakeDocumentInfo: Core.IntakeItemInfo;
    @Input('dataEntryJson') dataEntryJSON: string;
    @Input() firstEncounterSavedCallback: (result: FirstEncounterSaveResult) => Promise<void>;
    @Input() entityIdentifier: EntityIdentifier;
    @Input('attachmentId') attachmentID: string;
    @Input('dataEntryJsonHandler') dataEntryJSONHandler: DataEntryJSONHandler;
    @Input() validationHandler: ValidationHandler;
    @Input('attachmentTypeId') attachmentTypeID: number;
    @Input() setDirty: (boolean) => void;
    @Input('parcelId') parcelID: number;
    @Input() parcelAcctNum: string;
    @Input() companyName: string;
    @Input() companyId: number;
    @Input() siteName: string;
    @Input() intakeBatchItemNumber: string;
    @Input() stateId: number;
    @Output() warnOnEntityReloadChanged: EventEmitter<boolean>;
    @Input() searchAnticipated: boolean;
    @Output() billRevisionChangedX: EventEmitter<AnnualDetailAssessment> = new EventEmitter<AnnualDetailAssessment>();
    @Input() associatedEntityChanged: boolean;
    @Input() parcelChanges: { newTaxSetup: TaxSetupMinimumDTO };

    wrappedFirstEncounterSavedCallback: (result: FirstEncounterSaveResult) => Promise<void>;
    dataEntryChanged: boolean;
    entityTypeName: string;
    genericEntityVM: GenericEntityVM;
    appealVM: AppealViewModel;
    billVM: BillViewModel;
    refundVM: RefundViewModel;
    assessmentVM: AssessmentViewModel;
    complianceVM: ComplianceViewModel;
    editState: ProcessingDetailEditState;
    // This panel should be displayed for certain entity type IDs and not others
    displayEditPanel: boolean;
    attachmentDescription: string;
    targetEntityTasks: Task[];
    readyTaskName: string;
    isReadyTaskAnticipated: boolean;
    isReadyTaskReviewBill: boolean;
    useDocumentAsBillImage: boolean = false;
    completeReadyTask: boolean;
    completeReadyTaskChanged: boolean;
    readyTaskID: number;
    years: number[];
    year: string;
    showExtraBillFields: {bill: boolean, payment: boolean};
    verifiedChangedEntity: boolean;
    hasIdpFeature: boolean;
    showOcrResults: boolean;
    private inProcessTaxSetupChanges: BillClusterTaxRateSetupModalResult;

    ngOnInit(): void {
        this.hasIdpFeature = this._featureFlagsService.featureFlags.enableIdp;

        this.verifiedChangedEntity = false;
        this.editState = this._getInitEditState();

        if (this.dataEntryJSONHandler) {
            this.dataEntryJSONHandler.retrieveDataEntryJSON = () => {
                return this.retrieveEntityJSON();
            };
        } else {
            console.log('Skipping step to set dataEntryJSONHandler');
        }

        if (this.validationHandler) {
            this.validationHandler.validate = (callback: (boolean, string) => void, forComplete: boolean) => {
                return this.validate(callback, forComplete);
            };
        } else {
            console.log('Skipping step to set validationHandler');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(!this.editState) {
            this.editState = this._getInitEditState();
        }

        if (Object.keys(changes).indexOf('firstEncounterSavedCallback') >= 0) {
            this.wrappedFirstEncounterSavedCallback = async (result) => {
                if (result.updateVersion) {
                    const model = this.billVM.model[0];
                    if (model.rowVersion === result.initialBillRowVersion) {
                        model.rowVersion = result.finalBillRowVersion;
                    }
                    if (model.billClusterRowVersion === result.initialBillClusterRowVersion) {
                        model.billClusterRowVersion = result.finalBillClusterRowVersion;
                    }
                }

                if (result.inProcessTaxSetupChanges) {
                    this.inProcessTaxSetupChanges = result.inProcessTaxSetupChanges;
                }
                await this.firstEncounterSavedCallback(result);
            };
        }
        if (Object.keys(changes).indexOf('entityIdentifier') >= 0) {
            this.verifiedChangedEntity = false;
            this.completeReadyTaskChanged = false;
            const anticipatedTaskPromise = this._handleAnticipatedTask();
            this.dataEntryChanged = false;
            this.year = null;
            if (this.entityIdentifier) {
                this.displayEditPanel = true;
                let buildVMPromise: Promise<void> = null;
                switch (this.entityIdentifier.entityTypeId) {
                    case FILING_ENTITY_TYPE_ID:
                        this.entityTypeName = 'filing';
                        let dataEntryFiling: DataEntry<Filing> = null;
                        if (this.dataEntryJSON) {
                            dataEntryFiling = this._parseJSON<DataEntry<Filing>>(this.dataEntryJSON);
                            this.dataEntryChanged = !dataEntryFiling.resetOnLoad && dataEntryFiling.dataEntryChanged;
                        }
                        buildVMPromise = this._complianceService.getComplianceViewModelByFilingId(this.entityIdentifier.entityId)
                            .then((complianceVM) => {
                                if (dataEntryFiling && !dataEntryFiling.resetOnLoad) {
                                    complianceVM.assignFromExistingFiling(dataEntryFiling.entity);
                                }
                                this._processDataEntry(dataEntryFiling);
                                if (this.parcelID && this.parcelAcctNum) {
                                    complianceVM.setParcelDetails(this.parcelID, this.parcelAcctNum);
                                }
                                this._resetVMs();
                                this.complianceVM = complianceVM;
                            });
                        break;
                    case APPEAL_ENTITY_TYPE_ID:
                        this.entityTypeName = 'appeal';
                        let dataEntry: DataEntry<Appeal> = null;
                        if (this.dataEntryJSON) {
                            dataEntry = this._parseJSON<DataEntry<Appeal>>(this.dataEntryJSON);
                            this.dataEntryChanged = !dataEntry.resetOnLoad && dataEntry.dataEntryChanged;
                        }
                        buildVMPromise = this._appealService.getAppealViewModelByAppealID(this.entityIdentifier.entityId)
                            .then((appealVM) => {
                                if (dataEntry && !dataEntry.resetOnLoad) {
                                    appealVM.assignFromExistingAppeal(dataEntry.entity);
                                }
                                this._processDataEntry(dataEntry);
                                if (this.parcelID && this.parcelAcctNum) {
                                    appealVM.setParcelDetails(this.parcelID, this.parcelAcctNum);
                                }
                                this._resetVMs();
                                this.appealVM = appealVM;
                            });
                        break;
                    case TAXBILL_ENTITY_TYPE_ID:
                        this.entityTypeName = 'bill';
                        let dataEntryBill: DataEntry<Bill> = null;
                        if (this.dataEntryJSON) {
                            dataEntryBill = this._parseJSON<DataEntry<Bill>>(this.dataEntryJSON);
                            this.dataEntryChanged = !dataEntryBill.resetOnLoad && dataEntryBill.dataEntryChanged;
                        }
                        buildVMPromise = this._billClusterService.getBillViewModelByBillID(this.entityIdentifier.entityId)
                            .then((billVM: BillViewModel) => {
                                if (dataEntryBill && !dataEntryBill.resetOnLoad) {
                                     billVM.assignFromExistingBill(dataEntryBill.entity);
                                    billVM.processingTaxSetupResult = dataEntryBill.taxSetup;
                                }
                                this._processDataEntry(dataEntryBill);
                                billVM.parcelID = this.parcelID;
                                billVM.parcelAcctNum = this.parcelAcctNum;
                                billVM.hasWritePermission = this.editState.hasWritePermission;
                                billVM.intakeAttachmentId = this.attachmentID;
                                billVM.model[0].annualAssessmentID = billVM.model[0].annualAssessmentID || _.maxBy(billVM.yearRevisions, 'revisionNum').annualAssessmentID;
                                this.showExtraBillFields = this._billClusterService.getShowExtraFields(billVM.model);

                                this._resetVMs();

                                this.billVM = billVM;

                                this.showOcrResults = !!this.intakeDocumentInfo.ocrResults;
                                if (this.intakeDocumentInfo.idpConfidence > 0.33) {
                                    const lowerConfidence = this._idpService.mapIdpResult(this.billVM, this.intakeDocumentInfo.ocrResults);

                                    if(lowerConfidence) {
                                        this.intakeDocumentInfo.idpConfidence = 0.2;
                                    }

                                }

                                if (this.inProcessTaxSetupChanges) {
                                    billVM.processingTaxSetupResult = this.inProcessTaxSetupChanges;
                                    this.inProcessTaxSetupChanges = null;
                                    this.editState.setDirty(true);
                                }
                            });
                        break;

                    case REFUND_ENTITY_TYPE_ID:
                        this.entityTypeName = 'refund';
                        let dataEntryRefund: DataEntry<Refund> = null;
                        if (this.dataEntryJSON) {
                            dataEntryRefund = this._parseJSON<DataEntry<Refund>>(this.dataEntryJSON);
                            this.dataEntryChanged = !dataEntryRefund.resetOnLoad && dataEntryRefund.dataEntryChanged;
                        }
                        buildVMPromise = this._billClusterService.getRefundViewModelByRefundID(this.entityIdentifier.entityId)
                            .then((refundVM: RefundViewModel) => {
                                if (dataEntryRefund && !dataEntryRefund.resetOnLoad) {
                                    refundVM.assignFromExistingRefund(dataEntryRefund.entity);
                                }
                                this._processDataEntry(dataEntryRefund);
                                refundVM.parcelID = this.parcelID;
                                refundVM.parcelAcctNum = this.parcelAcctNum;
                                refundVM.hasWritePermission = this.editState.hasWritePermission;
                                // refundVM.year =

                                this._resetVMs();
                                this.refundVM = refundVM;
                            });
                        break;

                    case ASSESSMENT_ENTITY_TYPE_ID:
                        this.entityTypeName = 'assessment';
                        let dataEntryAssessment: DataEntry<Assessment> = null;
                        if (this.dataEntryJSON) {
                            dataEntryAssessment = this._parseJSON<DataEntry<Assessment>>(this.dataEntryJSON);
                            this.dataEntryChanged = !dataEntryAssessment.resetOnLoad && dataEntryAssessment.dataEntryChanged;
                        }
                        buildVMPromise = this._assessmentService.getAssessmentViewModelByAssessmentID(this.entityIdentifier.entityId)
                            .then((assessmentVM) => {
                                if (dataEntryAssessment && !dataEntryAssessment.resetOnLoad) {
                                    assessmentVM.assignFromExistingAssessment(dataEntryAssessment.entity);
                                    assessmentVM.currentYear.calcProjected = dataEntryAssessment.calcProjected;
                                }
                                this._processDataEntry(dataEntryAssessment);
                                assessmentVM.parcelAcctNum = this.parcelAcctNum;
                                assessmentVM.parcelID = this.parcelID;
                                this._resetVMs();
                                this.assessmentVM = assessmentVM;
                            });
                        break;
                    default:
                        buildVMPromise = this._setGenericEntityVM().then(() => {
                            // Maybe we should restructure this so entities that aren't really editable
                            // get a different panel that just has the attachment description and anticipated
                            // task stuff
                            this.displayEditPanel = true;
                            this.dataEntryChanged = false;

                            let dataEntryFallback: DataEntry<any> = null;
                            if (this.dataEntryJSON) {
                                dataEntryFallback = this._parseJSON<DataEntry<any>>(this.dataEntryJSON);
                            }

                            this._processDataEntry(dataEntryFallback);
                        });
                        break;
                }

                Promise.all([anticipatedTaskPromise, buildVMPromise]).then(() => {
                    // If there isn't a ready task, the checkbox will not show up, and we
                    // should treat it as unchecked
                    if (!this.isReadyTaskAnticipated) {
                        this.completeReadyTask = false;
                    }
                    // If the user has touched the complete ready task checkbox, don't do anything
                    else if (!this.completeReadyTaskChanged) {
                        // If the user is on an anticipated search, default to complete being true
                        this.completeReadyTask = this.searchAnticipated;
                    }
                });

                this.warnOnEntityReloadChanged.emit(this.dataEntryChanged);
            }
            else {
                this.displayEditPanel = false;
            }
        }

        // When the document processing page loads, we expect that the entity tree and entity edit UIs will be loaded
        // independently; when the entity tree finishes, we'll know the parcel account number, so we should go update
        // the entity edit UI at that time.
        if (Object.keys(changes).indexOf('parcelAcctNum') >= 0 ||
            Object.keys(changes).indexOf('parcelID') >= 0) {
            switch (this.entityIdentifier.entityTypeId) {
                case PARCEL_ENTITY_TYPE_ID:
                    if (this.genericEntityVM) {
                        this.genericEntityVM.commentModalData.description = this.parcelAcctNum;
                        this.genericEntityVM.commentModalData.parcelAcctNum = this.parcelAcctNum;
                        this.genericEntityVM.commentModalData.parcelID = this.parcelID;
                        this.genericEntityVM.attachmentModalData.entityName = this.parcelAcctNum;
                        this.genericEntityVM.attachmentModalData.entityData.name = this.parcelAcctNum;
                    }
                    break;
                case APPEAL_ENTITY_TYPE_ID:
                    if (this.appealVM) {
                        this.appealVM.setParcelDetails(this.parcelID, this.parcelAcctNum);
                    }
                    break;
                case FILING_ENTITY_TYPE_ID:
                    if(this.complianceVM) {
                        this.complianceVM.setParcelDetails(this.parcelID, this.parcelAcctNum);
                    }
                    break;
            }
        }

        if (Object.keys(changes).indexOf('siteName') >= 0 &&
            this.genericEntityVM &&
            this.entityIdentifier.entityTypeId === SITE_ENTITY_TYPE_ID) {
            this.genericEntityVM.commentModalData.description = this.siteName;
            this.genericEntityVM.attachmentModalData.entityName = this.siteName;
            this.genericEntityVM.attachmentModalData.entityData.name = this.siteName;
        }

        if (Object.keys(changes).indexOf('companyName') >= 0 &&
            this.genericEntityVM &&
            this.entityIdentifier.entityTypeId === COMPANY_ENTITY_TYPE_ID) {
            this.genericEntityVM.commentModalData.description = this.companyName;
            this.genericEntityVM.attachmentModalData.entityName = this.companyName;
            this.genericEntityVM.attachmentModalData.entityData.name = this.companyName;
        }
    }

    populateYears(): void {
        const now = new Date();
        const currentYear = now.getFullYear();

        this.years = [];

        for (let i = currentYear + 3; i >= currentYear - 10; i--) {
            this.years.push(i);
        }
    }

    billRevisionChanged(revision: AnnualDetailAssessment): void {
        this.billRevisionChangedX.emit(revision);
    }

    verifyChangedEntity(): void {
        this.verifiedChangedEntity = true;
    }

    validate(callback: (boolean, string) => void, forComplete: boolean): void {
        if (this.entityIdentifier && this.entityIdentifier.entityTypeId) {
            switch (this.entityIdentifier.entityTypeId) {
                case ASSESSMENT_ENTITY_TYPE_ID:
                    if (forComplete && this.completeReadyTask && this.assessmentVM.currentYear.calcProjected) {
                        callback(false, 'Cannot complete Auto Calculated assessment');
                        return;
                    }
                    break;
                case APPEAL_ENTITY_TYPE_ID:
                    this.appealVM.validate(callback);
                    return;
                case FILING_ENTITY_TYPE_ID:
                    this.complianceVM.validate(callback);
                    return;
                case TAXBILL_ENTITY_TYPE_ID:
                    const anyCalcProjected = _.some(this.billVM.model, (bill: Bill) => {
                        return bill.calcProjected;
                    }) as boolean;
                    if (forComplete && this.completeReadyTask && anyCalcProjected) {
                        callback(false, 'Cannot complete Auto Calculated bill');
                    }
                    else {
                        this.billVM.validate(callback);
                    }
                    return;
            }
        }

        callback(true, '');
    }

    retrieveEntityJSON(): string {
        if (!this.entityIdentifier || !this.entityIdentifier.entityTypeId) {
            return null;
        }

        let completeReadyTask = this.isReadyTaskAnticipated && this.completeReadyTask;

        switch (this.entityIdentifier.entityTypeId) {
            case APPEAL_ENTITY_TYPE_ID:
                return JSON.stringify(new DataEntry(this.appealVM.currentAppeal, this.attachmentDescription, completeReadyTask, false,
                    this.readyTaskID, this.dataEntryChanged, this.searchAnticipated, this.completeReadyTaskChanged, null, null));
            case FILING_ENTITY_TYPE_ID:
                return JSON.stringify(new DataEntry(this.complianceVM.currentFiling, this.attachmentDescription, completeReadyTask, false,
                    this.readyTaskID, this.dataEntryChanged, this.searchAnticipated, this.completeReadyTaskChanged, null, null));
            case TAXBILL_ENTITY_TYPE_ID:
                return JSON.stringify(new DataEntry(this.billVM.model[0], this.attachmentDescription, completeReadyTask, this.useDocumentAsBillImage,
                    this.readyTaskID, this.dataEntryChanged, this.searchAnticipated, this.completeReadyTaskChanged, null, this.billVM.processingTaxSetupResult));
            case REFUND_ENTITY_TYPE_ID:
                return JSON.stringify(new DataEntry(this.refundVM.model, this.attachmentDescription, completeReadyTask, false,
                    this.readyTaskID, this.dataEntryChanged, this.searchAnticipated, this.completeReadyTaskChanged, null, null));
            case ASSESSMENT_ENTITY_TYPE_ID:
                if (this.assessmentVM.currentYear.calcProjected) {
                    completeReadyTask = false;
                }
                // We have a little extra handling for overriding the FMV in components for assessments that needs to be done prior to save
                const assessmentModel: Assessment = this._assessmentService.prepareAssessmentForSave(this.assessmentVM.model);
                return JSON.stringify(new DataEntry(assessmentModel, this.attachmentDescription, completeReadyTask, false,
                    this.readyTaskID, this.dataEntryChanged, this.searchAnticipated, this.completeReadyTaskChanged,
                    this.assessmentVM.currentYear.calcProjected, null));
            default:
                const newEntry = new DataEntry<any>(null, this.attachmentDescription, false, false, null, false, false, false, null, null);
                newEntry.attachmentYear = this.year;
                return JSON.stringify(newEntry);
        }
    }

    onCompleteReadyTaskChange(): void {
        this.completeReadyTaskChanged = true;
    }

    idpAccepted(updatedIdpBillModel: Bill[]): void {
        assign(this.billVM.model, updatedIdpBillModel);
        this.editState.setDirty(true);
    }

    private _setGenericEntityVM(): Promise<void> {
        const commentModalData = new CommentModalData();
        commentModalData.entityID = this.entityIdentifier.entityId;
        commentModalData.entityTypeID = this.entityIdentifier.entityTypeId;
        commentModalData.canEdit = true;

        const attachmentModalData = new AttachmentModalData();
        attachmentModalData.entityData = new AttachmentModalEntityData();
        attachmentModalData.entityData.id = this.entityIdentifier.entityId;
        attachmentModalData.entityData.typeId = this.entityIdentifier.entityTypeId;

        const checkForComments = () => {
            return this._commentsService.checkForComments(this.entityIdentifier.entityTypeId, this.entityIdentifier.entityId);
        };

        const checkForAttachments = () => {
            return this._attachmentService.checkForAttachments(this.entityIdentifier.entityTypeId, this.entityIdentifier.entityId);
        };

        this._resetVMs();
        switch (this.entityIdentifier.entityTypeId) {
            case COMPANY_ENTITY_TYPE_ID:
                return Promise.all([checkForComments(), checkForAttachments()]).then(checkResult => {
                    const hasComments = checkResult[0];
                    const hasAttachments = checkResult[1];

                    this.entityTypeName = 'Company';
                    this.genericEntityVM = new GenericEntityVM();
                    this.genericEntityVM.hasComments = hasComments;
                    commentModalData.description = this.companyName;
                    this.genericEntityVM.commentModalData = commentModalData;

                    this.genericEntityVM.hasAttachments = hasAttachments;
                    attachmentModalData.entityType = 'company';
                    attachmentModalData.entityName = this.companyName;
                    attachmentModalData.entityData.name = this.companyName;
                    // I don't think we need to set attachmentModalData.parentId or parentType
                    // since you can't navigate to a parent from parcel/site/company
                    this.genericEntityVM.attachmentModalData = attachmentModalData;
                });
            case SITE_ENTITY_TYPE_ID:
                return Promise.all([checkForComments(), checkForAttachments()]).then(checkResult => {
                    const hasComments = checkResult[0];
                    const hasAttachments = checkResult[1];

                    this.entityTypeName = 'Site';
                    this.genericEntityVM = new GenericEntityVM();
                    this.genericEntityVM.hasComments = hasComments;
                    commentModalData.description = this.siteName;
                    this.genericEntityVM.commentModalData = commentModalData;

                    this.genericEntityVM.hasAttachments = hasAttachments;
                    attachmentModalData.entityType = 'site';
                    attachmentModalData.entityName = this.siteName;
                    attachmentModalData.entityData.name = this.siteName;
                    // I don't think we need to set attachmentModalData.parentId or parentType
                    // since you can't navigate to a parent from parcel/site/company
                    this.genericEntityVM.attachmentModalData = attachmentModalData;
                });
            case PARCEL_ENTITY_TYPE_ID:
                return Promise.all([checkForComments(), checkForAttachments()]).then(checkResult => {
                    const hasComments = checkResult[0];
                    const hasAttachments = checkResult[1];

                    this.entityTypeName = 'Parcel';
                    this.genericEntityVM = new GenericEntityVM();
                    this.genericEntityVM.hasComments = hasComments;
                    commentModalData.parcelID = this.entityIdentifier.entityId;
                    commentModalData.parcelAcctNum = this.parcelAcctNum;
                    commentModalData.description = this.parcelAcctNum;
                    this.genericEntityVM.commentModalData = commentModalData;

                    this.genericEntityVM.hasAttachments = hasAttachments;
                    attachmentModalData.entityType = 'parcel';
                    attachmentModalData.entityName = this.parcelAcctNum;
                    attachmentModalData.entityData.name = this.parcelAcctNum;
                    // I don't think we need to set attachmentModalData.parentId or parentType
                    // since you can't navigate to a parent from parcel/site/company
                    this.genericEntityVM.attachmentModalData = attachmentModalData;
                });
            default:
                this.entityTypeName = 'entity';
                return Promise.resolve();
        }
    }

    private _processDataEntry<T>(dataEntry: DataEntry<T>): void {
        // Don't reload the readyTaskID from the dataEntry object; let handleAnticipatedTask do that
        if (dataEntry) {
            this.attachmentDescription = dataEntry.attachmentDescription;
            this.completeReadyTask = dataEntry.completeReadyTask;
            dataEntry.resetOnLoad = false;
            this.completeReadyTaskChanged = dataEntry.completeReadyTaskChanged;
            this.useDocumentAsBillImage = dataEntry.useDocumentAsBillImage;
            this.searchAnticipated = dataEntry.searchAnticipated;
            this.year = dataEntry.attachmentYear;
        }
        else {
            this.attachmentDescription = this.intakeBatchItemNumber;
            this.completeReadyTask = false;
        }
    }

    private _handleAnticipatedTask(): Promise<void> {
        this.targetEntityTasks = null;
        this.readyTaskName = null;
        this.isReadyTaskAnticipated = false;
        this.isReadyTaskReviewBill = false;
        this.readyTaskID = null;
        this.entityTypeName = null;

        if (this.entityIdentifier) {
            return this._taskService.getByEntity(this.entityIdentifier.entityId, this.entityIdentifier.entityTypeId).then((result) => {
                const tasks = result.tasks;
                this.targetEntityTasks = tasks;
                const aid = this.attachmentTypeID;

                let isMatchAnticipated = false;
                let isMatchReviewBill = false;

                _.forEach(_.filter(tasks, (task: Task) => {
                    return task.isReady && !task.completedDateTime;
                }), (readyTask: Task): boolean => {
                    const tid = readyTask.taskTypeID;

                    //this.isReadyTaskAnticipated
                    isMatchAnticipated = ((tid === TaskTypeID.ObtainDataEnterValueNotice && aid === AttachmentTypeID.ValueNotice) ||
                        (tid === TaskTypeID.ObtainPostDeterminationValueNotice && aid === AttachmentTypeID.ValueNotice) ||
                        (tid === TaskTypeID.ObtainHearingNotice && aid === AttachmentTypeID.HearingNotice) ||
                        (tid === TaskTypeID.ObtainDataEnterTaxBill && aid === AttachmentTypeID.TaxBill) ||
                        (tid === TaskTypeID.TrackDataEnterRefund && aid === AttachmentTypeID.Refund) ||
                        (tid === TaskTypeID.PrintApplication && aid === AttachmentTypeID.AppealApplication) ||
                        (tid === TaskTypeID.FileAppeal && aid === AttachmentTypeID.AppealApplication) ||
                        (tid === TaskTypeID.SubmitEvidence && aid === AttachmentTypeID.AppealDocumentExchange) ||
                        (tid === TaskTypeID.ObtainMagistrateRecommendation && aid === AttachmentTypeID.AppealDecision) ||
                        (tid === TaskTypeID.ObtainPaymentReceipt && aid === AttachmentTypeID.TaxReceipt) ||
                        (tid === TaskTypeID.ObtainFinalDecision && aid === AttachmentTypeID.AppealDecision) ||
                        (tid === TaskTypeID.FileMiscFiling && aid === AttachmentTypeID.MiscFiling) ||
                        (tid === TaskTypeID.FilePPReturn && aid === AttachmentTypeID.PPReturn) ||
                        (tid === TaskTypeID.FileFreeport && aid === AttachmentTypeID.FreeportApplication) ||
                        (tid === TaskTypeID.FileAbatement && aid === AttachmentTypeID.AbatementApplication) ||
                        (tid === TaskTypeID.FilePollutionControl && aid === AttachmentTypeID.PollutionControlApplication) ||
                        (tid === TaskTypeID.ConfirmHearing && aid === AttachmentTypeID.AppealConfirmation));

                    if (isMatchAnticipated) {
                        this.readyTaskID = readyTask.taskID;
                        this.readyTaskName = readyTask.name;
                        this.isReadyTaskAnticipated = true;
                    }

                    isMatchReviewBill = tid == TaskTypeID.ReviewPaymentDetails && aid == AttachmentTypeID.TaxBill;

                    if(isMatchReviewBill) {
                        this.isReadyTaskReviewBill = true;
                    }

                    return !isMatchAnticipated || !isMatchReviewBill;
                });
            });
        }
        else {
            return Promise.resolve();
        }
    }

    private _parseJSON<T>(inputJSON: string): T {
        const result = JSON.parse(inputJSON) as T;
        this._util.convertDateStringsToDates(result);
        return result;
    }

    private _resetVMs(): void {
        this.appealVM = null;
        this.assessmentVM = null;
        this.billVM = null;
        this.refundVM = null;
        this.complianceVM = null;
        this.genericEntityVM = null;
    }

    private _getInitEditState(): ProcessingDetailEditState {
        return new ProcessingDetailEditState((isDirty: boolean) => {
            this.setDirty(isDirty);
            this.warnOnEntityReloadChanged.emit(true);
            this.dataEntryChanged = true;
        });
    }
}

export class ProcessingDetailEditState implements AnnualDetailEditState {
    showEditControls: boolean;
    editMode: boolean;
    hasWritePermission: boolean;
    refreshGrid: boolean;
    refreshActivity: boolean;
    entityLoading: boolean;
    validationMessage: string;
    staleAppealSavings: boolean;
    staleAppealStatus: boolean;
    validationHandler: (callback: (boolean, string) => void) => void;
    cancelHandler: () => void;
    saveHandler: () => Promise<any>;
    // TODO: Wire this up to the document processing page's dirty indicator instead
    isDirty: boolean;

    constructor(private setDirtyHandler: (boolean) => void) {
        this.editMode = true;
        this.isDirty = false;
        this.hasWritePermission = true;
    }

    setDirty(isDirty: boolean): void {
        this.setDirtyHandler(isDirty);
        if (this.isDirty !== isDirty) {
            this.isDirty = isDirty;
        }
    }

    getDirty(): boolean {
        return this.isDirty;
    }

    setEditMode(editMode: boolean): void {
        this.editMode = editMode;
    }
}
