(function () {
	'use strict';

	var APP_SOURCE = 'sd.Site.Activity.Panel.Controller';

	angular.module('weissmanApp')
		.directive('sdActivityAnalysis', directive);

	function directive() {
		return {
			restrict: 'E',
			scope: {
				siteId: '=',
				parcelId: '=',
				parcelYears: '='
			},
			templateUrl: 'app/Common/Activity/_activityPanel.html',
			bindToController: true,
			controller: APP_SOURCE,
			controllerAs: 'vm'
		}
	}

	/**
	 * @ngdoc function
	 * @name weissmanApp.controller:Site.Activity.Controller
	 * @description
	 * # Site.Activity.Controller
	 * Controller of the weissmanApp
	 */
	angular.module('weissmanApp')
		.controller(APP_SOURCE, Controller);

	Controller.$inject = [
		'ParcelService',
		'$stateParams',
		'sd.Activity.Service',
		'$uibModal',
		'$rootScope',
		'$q',
        'productAnalyticsService'
	];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(parcelService, $stateParams, activityService, $uibModal, $rootScope, $q, productAnalyticsService) {
		var vm = this;

		vm.noDataYet = true;
		vm.parcels = [];
		vm.annualData = [];
		vm.propertyCharacteristics = [];
		vm.openParcelFilter = openParcelFilter;
		vm.parcelFilterList = [];
		vm.useLatestActualized = false;
		vm.loading = false;

		//vm.activityData = activityService.getSampleData();

		vm.getDataByYear = getDataByYear;
		vm.siteId = $stateParams.siteId
		vm.getSummaryColumnClass = getSummaryColumnClass;
		vm.getGraphColumnClass = getGraphColumnClass;

		vm.parcelFilterSub = null;

		activate();

		function activate() {
            var storedParcelFilterList = sessionStorage['ws_analysisIncludedParcels' + vm.siteId];
            vm.parcelFilterList = storedParcelFilterList ? JSON.parse(storedParcelFilterList) : [];

			vm.parcelFilterSub = activityService.userSessionFilterCache$.subscribe(async (cache) => {
				const cachedFilters = cache[vm.siteId];
				if (cachedFilters?.length && !_.isEqual(vm.parcelFilterList, cachedFilters)) {
					vm.parcelFilterList = cachedFilters;
					if (!vm.noDataYet) {
						await getDataByYear();
					}
				}
			});

			getYears().then(function (result) {
				vm.availableYears = result;

				getDataByYear(true).then(function () {
                    const maxYear = _.maxBy(vm.annualData, 'year');
                    if(maxYear){
                        vm.selectedYear = maxYear.year;
                    }
					if (!vm.parcelFilterList.length) {
						vm.parcelFilterList = _.map(vm.parcels, 'parcelID');
					}
				});
			});

			vm.$onDestroy = function () {
				vm.parcelFilterSub?.unsubscribe();
			}
		}

		function getYears() {
			const defer = $q.defer();

			if (vm.parcelYears) {
				defer.resolve(vm.parcelYears);
			} else {
				parcelService.getAvailableYears(vm.siteId)
					.then(function (result) {
						defer.resolve(result)
					})
			}

			return defer.promise;
		}

		async function getDataByYear(useLatestActualized, fromUserChange) {
			vm.loading = true;

            if (fromUserChange) {
                productAnalyticsService.logEvent('click-activity-analysis-panel', { viewSitePortfolioAnalysis: 'Tax Year' });
            }

			try {
				vm.useLatestActualized = useLatestActualized;

				const data = vm.parcelId
					? await activityService.getDataByParcel(vm.siteId, vm.selectedYear, vm.parcelId, useLatestActualized)
					: await activityService.getDataByYear(vm.siteId, vm.selectedYear, vm.parcelFilterList, useLatestActualized);

				processData(data)
			} finally {
				vm.loading = false;
			}
		}

		function processData(result) {
			vm.activityData = result;

			if (vm.parcelId) {
				vm.activityData.parcelFilterList = [vm.parcelId];
			} else {
				vm.activityData.parcelFilterList = vm.parcelFilterList; // we will need it if we want to load data for all years from within the table
			}

			vm.activityData.siteId = vm.siteId;
			vm.activityData.useLatestActualized = vm.useLatestActualized;


			vm.activityData.availableYears = vm.availableYears;

			vm.parcels = _.map(result.parcels, function (element) {
				return _.extend({}, element, {
					isIncluded: true
				});
			});

			vm.annualData = result.annualData;
			vm.propertyCharacteristics = result.propertyCharacteristics;

			vm.noDataYet = false;
		}

		async function openParcelFilter() {
			const result = await activityService.openParcelFilter(vm.parcels, vm.parcelFilterList);
			activityService.updateParcelFilterCacheForSite(vm.siteId, result);
		}

		function getSummaryColumnClass() {
			return vm.parcelId ? 'col-sm-4' : 'col-sm-5';
		}

		function getGraphColumnClass() {
			return vm.parcelId ? 'col-sm-8' : 'col-sm-7';
		}
	}
})();
