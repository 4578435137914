<ws-modal-wrapper headerLabel="Bulk Update Results"
                  subHeaderLabel="The following sites were not updated because they are missing one or more assessment notices."
                  (save)="cancel()"
                  [hideCancel]="true">
    <div style="overflow-y: auto; max-height: 300px;">
        <ul>
            <li *ngFor="let site of sites">{{site.companyName}} - {{site.siteName}}</li>
        </ul>
    </div>
</ws-modal-wrapper>
