import { Injectable, ComponentFactoryResolver, Injector, ComponentRef, EmbeddedViewRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HelpContent, HelpContentComponentConfig } from './help.interface';
import { APP_HELP } from '../../app.help';
import { HelpTooltipComponent, TooltipTargetPosition } from './help-tooltip.component';
import { TimerService } from '../Utilities/timer.service';

interface HelpContentMap {
    [helpContentId: string]: HelpContent;
}

interface RetrievalMemo {
    [helpContentId: string]: Observable<HelpContent>;
}

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    constructor(
        private readonly _componentFactoryResolver: ComponentFactoryResolver,
        private readonly _injector: Injector,
        private readonly _timer: TimerService
    ) {
        this.setContent(APP_HELP);
    }

    showHelp: boolean;

    // tooltip library
    private _lib: HelpContentMap = {};
    private _libChanged: Subject<void> = new Subject<void>();
    private _observerMemo: RetrievalMemo = {};
    private _tooltipActive: boolean;

    // tooltip DOM control
    private _tooltipRef: ComponentRef<HelpTooltipComponent>;
    private _tooltip: HTMLElement;
    private _contentComponentRef: ComponentRef<any>;
    private _contentComponent: any;

    private _position: TooltipTargetPosition;
    private _delay: number = 300;
    private _openingInterval;
    private _closingInterval;

    private _currentContent: HelpContent;
    private _hoverTransitionTimer;
    private _componentHovering: string;

    set tooltipActive(active: boolean) {
        this._tooltipActive = active;
    }

    get tooltipActive(): boolean {
        return this._tooltipActive;
    }

    /**
     * Get the tooltip HelpContent from the helpId
     * @param helpContentId
     */
    getContent(helpContentId: string): Observable<HelpContent> {
        if (!this._observerMemo[helpContentId]) {
            const helpGetter = () => this._lib[helpContentId];

            this._observerMemo[helpContentId] = new Observable<HelpContent>(observer => {
                observer.next(helpGetter());
                this._libChanged.subscribe(() => observer.next(helpGetter()));
            });
        }

        return this._observerMemo[helpContentId];
    }

    /**
     * Set the HelpContent
     * @param helpContent
     */
    setContent(helpContent: HelpContent[]): void {
        this._lib = helpContent.reduce((acc, c) => {
            if (!acc[c.helpContentId]) {
                acc[c.helpContentId] = c;
            }
            return acc;
        }, this._lib);

        this._libChanged.next();
    }

    /**
     * Update the HelpContent
     * @param helpContent
     */
    updateContent(helpContent: HelpContent[]): void {
        this._lib = helpContent.reduce((acc, c) => {
            acc[c.helpContentId] = c;
            return acc;
        }, this._lib);

        this._libChanged.next();
    }

    /**
     * Toggle showing help for this HelpService Instance
     */
    toggleShowHelp(): void {
        this.showHelp = !this.showHelp;
    }

    /**
     * Refresh the current active tooltip
     */
    refresh(): void {
        if (this._closingInterval || !this._tooltipActive) {
            return;
        }

        if (this._contentComponentRef) {
            this._contentComponentRef.changeDetectorRef.detectChanges();
        }
        this._tooltipRef.changeDetectorRef.detectChanges();

        this._tooltipRef.instance.targetPosition = this._position;
        this._tooltipRef.changeDetectorRef.detectChanges();
    }

    setHoverIn(helpContent: HelpContent, contentComponentValue: any, position: TooltipTargetPosition): void {
        if (!helpContent) { return; }
        this._componentHovering = helpContent.helpContentId;
        this._currentContent = helpContent;
        this._contentComponent = contentComponentValue;
        this._position = position;
        this._evaluateHoverState();
    }

    setHoverOut(): void {
        this._componentHovering = null;
        this._evaluateHoverState();
    }

    /**
     * Remove the tooltip
     */
    removeTooltip(): void {
        this._clearIntervals();
        if (this._tooltipActive) {
            this.resetTooltip();
            if (document.body.contains(this._tooltip)) {
                document.body.removeChild(this._tooltip);
                this.resetTooltip();
            }
        }
        this._tooltipActive = false;
    }

    /**
     * Set the opening delay
     * @param delay
     */
    setDelay(delay: number) {
        this._delay = delay;
    }

    /**
     * Reset the tooltip DOM object
     */
    resetTooltip(): void {
        this._currentContent = null;
        this._tooltipRef.instance.helpContent = null;
        this._tooltipRef.instance.targetPosition = {
                position: 'bottom',
                boundingBox: {
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                height: 0,
                width: 0,
                x: 260, // allows left pre-render for positioning calculations
                y: 0,
                toJSON: null
            },
            edgeBuffer: '10px'
        };
        this._tooltipRef.instance.removeComponent();
        this._tooltipRef.changeDetectorRef.detectChanges();
    }

    /**
     * Creates a unique ID for static provided configuration
     * required to properly handle hover state
     */
    getUniqueId(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = (c === 'x') ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getById(id: string): string {
        return this._lib[id].tooltipText;
    }

    /**
     * Update the tooltip with new values
     * @param helpContent
     */
    private _showTooltip(): void {
        if (!this.tooltipActive) {
            if (!this._tooltip) {
                this._createTooltip();
            }
            this.tooltipActive = true;
            document.body.appendChild(this._tooltip);
        }
        // Init blank component
        if (this._currentContent.helpContentComponent) {
            this._tooltipRef.instance.componentToRender =
                this._createComponent(this._currentContent.helpContentComponent, this._contentComponent);
        } else {
            this._tooltipRef.instance.removeComponent();
        }
        this._tooltipRef.instance.helpContent = this._currentContent;
        this._tooltipRef.changeDetectorRef.detectChanges();

        this._tooltipRef.instance.targetPosition = this._position;
        this._tooltipRef.changeDetectorRef.detectChanges();

        this._tooltipRef.instance.opacity = 1;
        this._tooltipRef.changeDetectorRef.detectChanges();
    }

    /**
     * Close the tooltip smoothly
     */
    private _closeTooltip(): void {
        if (this._tooltipActive) {
            this._tooltipRef.instance.opacity = 0;
            this._tooltipRef.changeDetectorRef.detectChanges();

            this._closingInterval = this._timer.setTimeout(() => {
                this.removeTooltip();
            }, 300);
        }
    }

    /**
    * Create the tooltip DOM element
    */
    private _createTooltip(): void {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(HelpTooltipComponent);
        this._tooltipRef = componentFactory.create(this._injector);

        this._tooltipRef.instance.isHovered.subscribe((isHovering: boolean) => this._evaluateHoverState(isHovering));

        this._tooltip = (this._tooltipRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this._tooltipRef.changeDetectorRef.detectChanges();
    }

    /**
    * Create the child display component
    */
    private _createComponent(config: HelpContentComponentConfig<any, any>, helpContentValue: any): HTMLElement {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(config.component);
        this._contentComponentRef = componentFactory.create(this._injector);

        // Set the dynamic provided value
        if (config.componentParams) {
            config.componentParams.value = helpContentValue;
            if (config.componentParams.valueGetter) {
                config.componentParams.value = config.componentParams.valueGetter(config.componentParams.value);
            }
        }

        this._contentComponentRef.instance.params = config.componentParams;
        this._contentComponentRef.instance.helpInit(config.componentParams);
        this._contentComponentRef.changeDetectorRef.detectChanges();

        return (this._contentComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    }

    /**
     * Check that one of the hover states is hovering
     * slight delay is required as one component will always hover out before the next hovers in
     */
    private _evaluateHoverState(tooltipHovering: boolean = false): void {
        this._clearIntervals();

        this._hoverTransitionTimer = this._timer.setTimeout(() => {
            const tooltipCanHover = this._currentContent && (this._currentContent.url || (this._currentContent.helpContentComponent && this._currentContent.helpContentComponent.canHover));
            const hovered = (this._componentHovering || (tooltipHovering && tooltipCanHover));

            if (hovered && !(tooltipHovering && tooltipCanHover)) {
                if (this._tooltipActive) {
                    this._showTooltip();
                } else {
                    const delay = (this._currentContent.delay || this._currentContent.delay === 0) ? this._currentContent.delay : this._delay;
                    this._openingInterval = this._timer.setTimeout(() => this._showTooltip(), delay);
                }
            } else if (!hovered) {
                this._closeTooltip();
            }

        }, 200);
    }

    /**
     * Clear all of the current timeouts
     */
    private _clearIntervals(): void {
        if (this._hoverTransitionTimer) {
            clearTimeout(this._hoverTransitionTimer);
            this._hoverTransitionTimer = null;
        }
        if (this._openingInterval) {
            clearTimeout(this._openingInterval);
            this._openingInterval = null;
        }
        if (this._closingInterval) {
            clearTimeout(this._closingInterval);
            this._closingInterval = null;
        }
    }

}
