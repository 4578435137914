import { filter } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Roles } from '../Common/Permissions/restrict.service';
import { AccountRepository } from '../Core-Repositories';
import { AuthStorageService, AuthData, StorageTokenKeys } from './authStorage.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AppStateService, AppStates } from '../Layout/appStateService';
import { IdleTimeoutModes } from '../constants.new';
import { TimerService } from '../UI-Lib/Utilities';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { TermsAndConditionsModalComponent } from '../Account/Login/termsAndConditionsModal.component';

import * as moment from 'moment';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { AceAuthOidcWrapperService } from '../IAM/aceAuthOidcWrapper.service';
import { ToastrService } from 'ngx-toastr';
import { RouteService } from '../Common/Routing/route.service';
import { SettingsService } from '../Common/Settings/settings.service';

const clientId = 'ngAuthApp';
const userInfoUrl = '/api/account/GetUserInfo';
// Time in minutes before an access token expires that we should refresh the token
const refreshWindow = 2;

const logoutMessageInvalidLogin = 'Invalid login.';
const logoutMessageError = 'An unexpected error occurred during login.';
const logoutMessageExpired = 'Your session has expired.';
const logoutMessageUnexpectedEnd = 'Your session has ended unexpectedly.';
const logoutMessageIdleTimeout = 'Your session has been terminated due to inactivity.';
const logoutMessageLoggedOut = 'You have logged out.';

export enum GrantTypes {
    password = 'password',
    saml20 = 'weissman_sso_saml20_integration',
    tokenExchange = 'urn:ietf:params:oauth:grant-type:token-exchange'
}

export interface ILoginData { }

export class PasswordLoginData implements ILoginData {
    username: string;
    password: string;
}

export class Settings {
    id?: number;
    name: string;
    value: any;
    groupId?: number;
    groupName?: string;
    folderId?: number;
    folderName?: string;
    userId?: number;
    userSettingID?: number;
}

export class UserInstanceRightsViewModel {
    instanceId: number;
    rights: Array<Core.InstanceRightViewModel>;
}

export interface UserData {
    id: string;
    contactId: number;
    iamId: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    isDemoUser: boolean;
    claims: string[];
    globalRoles: string[];
    instanceRights: Array<Core.UserInstanceRightsViewModel>;
    instanceMembership: Array<Core.InstanceViewModel>;
    loginDate: Date;
    isTeamMemberAndAssignable: boolean;
    isLicensedUser: boolean;
    createDate: string;
    settings: Settings[];
    onboardingStatus: Core.UserOnboardingStatusEnum;
    defaultTeam: string;
}

export class RefreshTokenData {
    refreshToken: string;
    loginDate: number;
    refreshTokenExpireTimeSpanMinutes: number;
    setNoRelogin: boolean;
    originalAccessToken: string;
    idleTimeoutInMinutes: number;
    idleTimeoutMode: string;
}

@Injectable(
    { providedIn: 'root' }
)
export class AccountService {
    constructor(
        private readonly _http: HttpClient,
        private readonly _authStorageService: AuthStorageService,
        private readonly _appStateService: AppStateService,
        private readonly _ngZone: NgZone,
        private readonly _timer: TimerService,
        private readonly _weissmanModalService: WeissmanModalService,
        private readonly _featureFlagsService : FeatureFlagsService,
        private readonly _aceAuthOidcWrapperService: AceAuthOidcWrapperService,
        private readonly _toastrService: ToastrService,
        private readonly _routeService: RouteService,
        private readonly _settingsService: SettingsService,
        private readonly _accountRepository: AccountRepository
    ) {
        this._tokenHandled$ = new BehaviorSubject<boolean>(false);
        this.initialMessage = null;
        this._timeouts = {
            refresh: null,
            loginExpire: null,
            idleTimeout: null
        };
        this._authStorageService.logoutMessage$.subscribe(() => {
            clearTimeout(this._timeouts.refresh);
            clearTimeout(this._timeouts.loginExpire);
            clearTimeout(this._timeouts.idleTimeout);
        });
    }

    userData: UserData;
    initialMessage: string;
    homeRealmDiscoveryStarted: boolean;

    // tokenHandled fires if either we've retrieved an auth token and handled it or if we've loaded the app
    // and there is no stored token
    private _tokenHandled$: BehaviorSubject<boolean>;
    // See the longer note in HandleTokenResponse; remember the last activity time we synched to the server
    private _lastSynchedActivityTime: Date;
    private _timeouts: {
        refresh: number,
        loginExpire: number,
        idleTimeout: number
    };
    private _rememberedRoute: string;

    async loginMigration(emailAddress: string, grantType: GrantTypes, loginData: ILoginData | string) {
        const requestData: { [key:string]: string } = {
            'grant_type': grantType,
            'client_id': clientId
        };

        switch (grantType) {
            case GrantTypes.password:
                const { username, password } = <PasswordLoginData>loginData;
                requestData['username'] = username;
                requestData['password'] = password;
                break;
            case GrantTypes.saml20:
                const saml20LoginData = <string>loginData;
                requestData['sso_request'] = saml20LoginData;
                requestData['migration_email'] = emailAddress;
                break;
            default:
                throw `Unrecognized grant type: ${grantType}`;
        }

        const { access_token } = await this.tokenRequest(requestData);

        return await this.getMigrationInvite(access_token);
    }

    async getMigrationInvite(accessToken: string): Promise<{ inviteUrl: string, alreadyMigrated: boolean }> {
        // I didn't export the MigrateUser result from the API using TypeLite since it doesn't correctly understand Uri objects.
        return await lastValueFrom(this._http.post<{ inviteUrl: string, alreadyMigrated: boolean }>('/api/account/MigrateUser', null, {
            headers: new HttpHeaders({
                Authorization: `Bearer ${  accessToken}`,
                'Content-Type': 'application/json'
            })
        }));
    }

    async loginFromAceOidc(token: string) {
        try {
            await this.login(GrantTypes.tokenExchange, token);
        }
        catch (err) {
            console.log('Error on token exchange', err);
            if (err?.status == 400) {
                this._authStorageService.showIAMLogoutButton = true;
                this._authStorageService.logoutMessage$.next(logoutMessageInvalidLogin);
                return;
            }
            this._authStorageService.logoutMessage$.next(logoutMessageError);
        }
    }

    async login(
        grantType: GrantTypes,
        loginData: ILoginData | string,
        setNoRelogin: boolean = false,
        isIdpInitSSO: boolean = false
    ): Promise<boolean> {
        this._lastSynchedActivityTime = new Date();
        const loginDate = +this._lastSynchedActivityTime;
        const requestData: { [key:string]: string } = {
            'grant_type': grantType,
            'client_id': clientId
        };

        switch (grantType) {
            case GrantTypes.password:
                const passwordLoginData = <PasswordLoginData>loginData;
                requestData['username'] = passwordLoginData.username;
                requestData['password'] = passwordLoginData.password;
                break;
            case GrantTypes.saml20:
                const saml20LoginData = <string>loginData;
                requestData['sso_request'] = saml20LoginData;
                break;
            case GrantTypes.tokenExchange:
                const token = <string>loginData;
                requestData['subject_token'] = token;
                requestData['subject_token_type'] = 'urn:ietf:params:oauth:token-type:jwt';
                break;
            default:
                throw `Unrecognized grant type: ${grantType}`;
        }

        const tokenResponse = await this.tokenRequest(requestData);

        if (isIdpInitSSO) {
            const isMigrationLogin = await this.getIsMigrationLogin(tokenResponse.access_token);
            if (isMigrationLogin) {
                const inviteData = await this.getMigrationInvite(tokenResponse.access_token);
                if (inviteData.alreadyMigrated) {
                    // Redirect to the home path (so the page doesn't get "stuck" on the sso-login route)
                    window.location.replace(`${window.location.origin}/#/home`);
                    (<any>window.location).reload(true);
                }
                else {
                    window.location.href = inviteData.inviteUrl;
                }
                return false;
            }
            else {
                // This is an IdP-Initiated login for an "exception" SSO provider, so we're not using
                // IAM for the login.
                tokenResponse.migration_exception = true;
            }
        }

        tokenResponse.set_no_relogin = setNoRelogin;

        tokenResponse.weissman2_token = await this.getWeissman2Token(tokenResponse.access_token);

        const tokenResponseResult = await this.handleTokenResponse(tokenResponse);

        if (!tokenResponseResult) {
            this._authStorageService.clearAuthData();
            (<any>window.location).reload(true);
            return false;
        }

        const {
            idleTimeoutInMinutes,
            idleTimeoutMode,
            refreshTokenExpireTimeSpanMinutes,
            needsToAcceptTermsAndConditions
        } = tokenResponseResult.userInfo;

        const newRefreshTokenData: RefreshTokenData = {
            refreshToken: tokenResponse.refresh_token,
            loginDate,
            refreshTokenExpireTimeSpanMinutes,
            setNoRelogin,
            originalAccessToken: tokenResponse.access_token,
            idleTimeoutInMinutes: idleTimeoutInMinutes,
            idleTimeoutMode: idleTimeoutMode
        };

        localStorage[StorageTokenKeys.refreshTokenKey] = JSON.stringify(newRefreshTokenData);

        if (idleTimeoutMode === IdleTimeoutModes.KeepAlive || idleTimeoutMode == IdleTimeoutModes.Activity) {
            localStorage[StorageTokenKeys.lastActivityKey] = (+new Date()).toString();
        }

        if (grantType == GrantTypes.tokenExchange && sessionStorage[StorageTokenKeys.pauseOnIAM]) {
            this._toastrService.info('Login successful. Reload page to continue login process.');
            return false;
        }

        if (needsToAcceptTermsAndConditions || grantType == GrantTypes.tokenExchange) {
            const initialRoute = this._routeService.getInitialRoute();
            if (initialRoute) {
                this._routeService.clearInitialRoute();
                console.log('%c Setting initial route', 'color: #00ffff', initialRoute);
                window.location.href = initialRoute;
            }
            (<any>window.location).reload(true);
            return false;
        }

        this.setupRefreshTokenExpire();
        this.setupIdleTimeout(idleTimeoutMode, idleTimeoutInMinutes);

        return true;
    }

    handleInitialRoute() {
        if (this._rememberedRoute) {
            this._routeService.setInitialRoute(this._rememberedRoute);
        }
    }

    beginHomeRealmDiscovery() {
        this._rememberedRoute = this._routeService.getInitialRoute();

        if (this._rememberedRoute) {
            this._routeService.clearInitialRoute();
        }

        this.homeRealmDiscoveryStarted = true;
        // TODO: The approach of passing properties back and forth directly on the window object is
        // an old-school method and should probably be replaced with shared services.
        window['disableAppStartup'] = false;
        window['weissmanNg1Bootstrap']();
        this._appStateService.appState$.next(AppStates.Initialized);
        this._tokenHandled$.next(true);
    }

    async checkPersistedLogin() {
        if (localStorage[StorageTokenKeys.idleTimeoutStatus]) {
            window['disableAppStartup'] = true;
            this._authStorageService.logoutMessage$.next(logoutMessageIdleTimeout);
            return;
        }
        const { loginSucceeded, needsToAcceptTermsAndConditions } = await this.attemptRefresh();

        if (this._featureFlagsService.featureFlags.enableIAMLogin) {
            if (loginSucceeded) {
                this._tokenHandled$.next(true);
                return;
            }
            else if (localStorage[StorageTokenKeys.idleTimeoutStatus]) {
                // An idle timeout was detected while attempting to refresh the token; don't continue the process
                return;
            }

            this._authStorageService.clearAuthData();

            // The login failed. If a message needs to be shown to the user, use the logout message flow to do so. T&C is handled
            // differently when IAM is disabled, so deal with that first.
            if (needsToAcceptTermsAndConditions) {
                this.initialMessage = 'You must accept terms and conditions to use PropertyPoint.';
            }

            if (this.initialMessage) {
                this._authStorageService.logoutMessage$.next(this.initialMessage);
                return;
            }

            // Kind of a hack; go peek at the URL to see if this is an IdP-Initiated SSO request. Normally the router should deal
            // with this, but all this work is happening pre-router and the IAM component should not be loaded for this case.
            if (location.href.endsWith('#/ssoLogin')) {
                this._appStateService.appState$.next(AppStates.Initialized);
                return;
            }

            // Refresh token not found or didn't work, no initial message, not IdP-Initiated SSO; load the IAM component and try to login
            this._aceAuthOidcWrapperService.login();

            // HACK: Disable the rest of the page bootstrap process
            window['disableAppStartup'] = true;
        }
        else {
            if (needsToAcceptTermsAndConditions) {
                if (!loginSucceeded)
                {
                    this._authStorageService.clearAuthData();
                }
                // If T&C isn't accepted, reload to try again. If it is accepted, reload and the normal refresh will work (logging the user in).
                (<any>window.location).reload(true);
                return;
            }

            if (!loginSucceeded) {
                this._appStateService.appState$.next(AppStates.Initialized);
            }
            this._tokenHandled$.next(true);
        }
    }

    async attemptRefresh() : Promise<{ loginSucceeded: boolean, needsToAcceptTermsAndConditions?: boolean }> {
        const refreshTokenData = localStorage[StorageTokenKeys.refreshTokenKey];

        if (!refreshTokenData) {
            return { loginSucceeded: false };
        }

        try {
            const refreshToken = <RefreshTokenData>JSON.parse(refreshTokenData);

            if (new Date() >= this.refreshTokenExpirationDate(refreshToken)) {
                this._authStorageService.clearAuthData();
                return { loginSucceeded: false };
            }

            if (refreshToken.idleTimeoutInMinutes) {
                const idleTimeoutDate = new Date(
                    (+localStorage[StorageTokenKeys.lastActivityKey]) +
                    (refreshToken.idleTimeoutInMinutes * 60000));

                if (idleTimeoutDate < new Date()) {
                    this.initialMessage = 'Your session has been terminated due to inactivity';
                    return { loginSucceeded: false };
                }
            }

            if (!await this.refreshToken(true)) {
                return { loginSucceeded: false };
            }

            const tokenResponseResult = await this.handleTokenResponse(this._authStorageService.getAuthData());

            // Sort of a hack; handleTokenResponse returns null if the user was shown the Terms and Conditions modal
            // and hit reject
            if (!tokenResponseResult) {
                this._authStorageService.clearAuthData();

                return {
                    loginSucceeded: false,
                    needsToAcceptTermsAndConditions: true
                };
            }

            if (tokenResponseResult.userInfo.needsToAcceptTermsAndConditions) {
                return {
                    loginSucceeded: true,
                    needsToAcceptTermsAndConditions: true
                };
            }

            this.setupRefreshTokenExpire();
            this.setupIdleTimeout(tokenResponseResult.userInfo.idleTimeoutMode, tokenResponseResult.userInfo.idleTimeoutInMinutes);
        }
        catch (err) {
            console.error(err);
            this._authStorageService.clearAuthData();
            return { loginSucceeded: false };
        }

        return { loginSucceeded: true };
    }

    // If the user tries to log in, we should check if they've already logged in on another tab; use
    // the presense of a non-expired refresh token to indicate that's happened.
    checkForExistingRefreshToken() {
        const refreshTokenData = localStorage[StorageTokenKeys.refreshTokenKey];
        if (!refreshTokenData) {
            return false;
        }

        try {
            const refreshToken = <RefreshTokenData>JSON.parse(refreshTokenData);
            if (new Date() >= this.refreshTokenExpirationDate(refreshToken)) {
                return false;
            }

            if (refreshToken.idleTimeoutInMinutes) {
                const idleTimeoutDate = new Date(
                    (+localStorage[StorageTokenKeys.lastActivityKey]) +
                    (refreshToken.idleTimeoutInMinutes * 60000));
                if (idleTimeoutDate < new Date()) {
                    return false;
                }
            }
        }
        catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }

    async navPromise() {
        return new Promise<void>(res => this._tokenHandled$.pipe(filter(h => h)).subscribe(() => res()));
    }

    async resetRoles() {
        const { roleList, rightsByInstance, instanceMembership } = await lastValueFrom(this._http.get<{
            roleList: string[],
            rightsByInstance: Array<UserInstanceRightsViewModel>,
            instanceMembership: Array<Core.InstanceViewModel>
        }>(userInfoUrl));

        this.userData.globalRoles = roleList;
        this.userData.instanceRights = rightsByInstance;
        this.userData.instanceMembership = instanceMembership;
    }

    async refreshToken(initializing?: boolean): Promise<boolean> {
        const storedToken = localStorage[StorageTokenKeys.refreshTokenKey];
        if (!storedToken) {
            return false;
        }
        const refreshTokenData = JSON.parse(storedToken);

        const headers: { [key: string]: string } = {
            'Authorization': `Bearer ${  refreshTokenData.originalAccessToken}`
        };

        if (refreshTokenData.idleTimeoutMode === IdleTimeoutModes.KeepAlive) {
            localStorage[StorageTokenKeys.lastActivityKey] = (+new Date()).toString();
        }
        else if (refreshTokenData.idleTimeoutMode === IdleTimeoutModes.Activity) {
            const nowInMs = +new Date();
            const lastActivity = +localStorage[StorageTokenKeys.lastActivityKey];
            headers['PropertyPoint-Last-Activity'] = `${nowInMs - lastActivity  }`;
            if (initializing) {
                this._lastSynchedActivityTime = new Date();
                // Opening a new tab counts as "activity", so record it as such
                headers['PropertyPoint-Refresh-Type'] = 'Initial';
                this._authStorageService.recordActivity('New Tab');
            }
            else {
                this._lastSynchedActivityTime = new Date(lastActivity);
            }
        }

        try {
            const tokenResponse = await this.tokenRequest({
                'grant_type': 'refresh_token',
                'refresh_token': refreshTokenData.refreshToken,
                'client_id': clientId
            }, headers);

            tokenResponse.weissman2_token = await this.getWeissman2Token(tokenResponse.access_token);

            // The API might not return a refresh token. In that case, the UI needs to remember
            // the last value.
            if (!tokenResponse.refresh_token) {
                tokenResponse.refresh_token = refreshTokenData.refreshToken;
            }
            tokenResponse.set_no_relogin = refreshTokenData.setNoRelogin;
            localStorage[StorageTokenKeys.refreshTokenKey] = JSON.stringify(Object.assign({}, refreshTokenData, {
                refreshToken: tokenResponse.refresh_token,
            }));

            this._authStorageService.setAuthData(tokenResponse);

            return true;
        } catch (err) {
            if (err && err.status === 400 && err.error && err.error.error_description) {
                if (this._featureFlagsService.featureFlags.enableIAMLogin) {
                    this._authStorageService.clearAuthData();
                    const allSessionErrorMessages = await lastValueFrom(this._http.get('/api/account/AllSessionErrorMessages'));
                    if (allSessionErrorMessages[Core.SessionTerminationReasons.IdleTimeout] === err.error.error_description) {
                        localStorage[StorageTokenKeys.idleTimeoutStatus] = 'true';
                        this._authStorageService.logoutMessage$.next(err.error.error_description);
                        window['disableAppStartup'] = true;
                        return false;
                    }
                }
                this.initialMessage = err.error.error_description;
            }
            else {
                this.initialMessage = 'An unexpected error has occurred while resuming your session.';
                console.error('Error refreshing token', err);
            }

            return false;
        }
    }

    async idleTimeout() {
        if (this._featureFlagsService.featureFlags.enableIAMLogin) {
            const authData = await this.initializeLogout();
            const isMigrationException = authData?.migration_exception;

            if (!isMigrationException) {
                localStorage[StorageTokenKeys.idleTimeoutStatus] = 'true';
            }
        }
        else {
            this._authStorageService.clearAuthData();
            this._authStorageService.resetPathOnLogout = false;
        }

        this._authStorageService.logoutMessage$.next(logoutMessageIdleTimeout);
    }

    async logout() {
        const authData = await this.initializeLogout();
        const isMigrationException = authData?.migration_exception;
        this._authStorageService.resetPathOnLogout = true;
        if (this._featureFlagsService.featureFlags.enableIAMLogin && !isMigrationException) {
            await this._aceAuthOidcWrapperService.logout();
        }
        else {
            this._authStorageService.logoutMessage$.next(logoutMessageLoggedOut);
        }
    }

    ensureUserHasAccessToPriorInstanceSelection(userData: UserData): void {
        const currentInstanceSelection = +localStorage.getItem(StorageTokenKeys.selectedInstances);
        const activeInstances = userData.instanceMembership.filter(i => i.inactive === false && i.contactId);

        if (!userData) {
            return;
        }

        if (currentInstanceSelection <= 0 && activeInstances.length > 1) {
            return;
        }

        if (activeInstances.findIndex(im => im.instanceId === currentInstanceSelection) < 0) {
            if (activeInstances.length > 1) {
                localStorage[StorageTokenKeys.selectedInstances] = -1;
            } else {
                localStorage[StorageTokenKeys.selectedInstances] = activeInstances[0].instanceId;
            }
        }
    }

    async getIAMInitialLoginData(emailAddress: string): Promise<Core.IAMInitialLoginDTO> {
        return await lastValueFrom(this._http.post<Core.IAMInitialLoginDTO>(
            '/api/Account/GetIAMInitialLoginData',
            JSON.stringify(emailAddress),
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }));
    }

    async getIsMigrationLogin(authToken: string): Promise<boolean> {
        return await lastValueFrom(this._http.get<boolean>('/api/Account/IsMigrationLogin', {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        }));
    }

    async getDirectAPIToken(): Promise<string> {
        const refreshToken = this._authStorageService.getAuthData().refresh_token;

        const { access_token } = await this.tokenRequest({
            'grant_type': 'refresh_token',
            'client_id': clientId,
            'refresh_token': refreshToken,
            'scope': 'direct_api'
        }, {
            'PropertyPoint-Last-Activity': '0'
        });

        return `weissman_direct_api_${refreshToken}_${access_token}`;
    }

    async updateOnboardingStatus(status: Core.UserOnboardingStatusEnum): Promise<void> {
        await lastValueFrom(this._accountRepository.updateOnboardingStatus(status));
    }

    private async handleTokenResponse(tokenResponse: AuthData) {
        const userInfo = await lastValueFrom(this._http.get<Core.UserViewModel>(userInfoUrl, {
            headers: new HttpHeaders({ 'Authorization': `Bearer ${  tokenResponse.access_token}` })
        }));

        if (userInfo.idleTimeoutMode) {
            userInfo.idleTimeoutMode = userInfo.idleTimeoutMode.toLowerCase();
        }

        if(userInfo.needsToAcceptTermsAndConditions) {
            const params = { tokenResponse };
            const userHasAccepted = await this._weissmanModalService.showAsync(TermsAndConditionsModalComponent, params, 'modal-lg');

            if(userHasAccepted) {
                return { userInfo, userSettings: null };
            } else {
                return null;
            }
        }

        const hasRyanInstance = userInfo.instanceMembership.some(x => x.name === 'Ryan');
        const hasRyanPrivate = userInfo.roleList.some(x => x === Roles.RYANPRIVATEITEMSVIEW || x === Roles.RYANPRIVATEITEMSEDIT);

        this.userData = {
            id: (userInfo.id as string),
            contactId: userInfo.contactID,
            iamId: (userInfo.iamId as string),
            username: userInfo.userName,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            isDemoUser: userInfo.isDemoUser,
            claims: [],
            globalRoles: userInfo.roleList,
            instanceRights: userInfo.rightsByInstance,
            instanceMembership: userInfo.instanceMembership,
            isTeamMemberAndAssignable: userInfo.isTeamMemberAndAssignable,
            isLicensedUser: !(hasRyanInstance && hasRyanPrivate),
            createDate: moment(userInfo.createDate).utc().format(),
            loginDate: moment(tokenResponse.login_date).toDate() as Date,
            settings: [],
            onboardingStatus: userInfo.onboardingStatus,
            defaultTeam: userInfo.defaultTeam
        };

        this.ensureUserHasAccessToPriorInstanceSelection(this.userData);

        const userSettings = await lastValueFrom(this._http.get<{
            userSettingID: number,
            settingName: string,
            settingValue: string,
            userSettingGroupID: number,
            settingGroupName: string,
            userSettingFolderID: number,
            settingFolderName: string
        }[]>('/api/account/usersettings', {
            headers: new HttpHeaders({ 'Authorization': `Bearer ${  tokenResponse.access_token}` })
        }));

        this.userData.settings = userSettings.map(userSetting => {
            return {
                id: userSetting.userSettingID,
                name: userSetting.settingName,
                value: JSON.parse(userSetting.settingValue),
                groupId: userSetting.userSettingGroupID,
                groupName: userSetting.settingGroupName,
                folderId: userSetting.userSettingFolderID,
                folderName: userSetting.settingFolderName,
            };
        });

        this._authStorageService.setAuthData(tokenResponse);
        this.setupRefresh();
        this._tokenHandled$.next(true);
        this._appStateService.appState$.next(AppStates.LoggedIn);
        // Delay authentication interceptors until we've finished validating the user's session
        this._authStorageService.authInterceptEnabled = true;
        if (userInfo.idleTimeoutMode === IdleTimeoutModes.Activity) {
            // Instruct the AuthStorageService to begin making a note of all activities. We will notify
            // the user if their session has expired due to inactivity. We also allow the API to track
            // activity by indicating how long it has been since the last activity on every refresh.
            const activity$ = this._authStorageService.setActivityRecordingMode(true);
            /* The user's session might expire in less than IdleTimeoutInMinutes from the last activity if
             * they close the browser before a refresh occurs. Ensure that the timeout happens at least
             * within an access token's lifetime less than the IdleTimeoutInMinutes setting (so if
             * IdleTimeoutInMinutes is 30 and an access token lives for 15 minutes, the user should be
             * guaranteed at least 15 minutes before being timed out. For IdleTimeout of 60 minutes and
             * access token lifetime of 15 minutes, the user should get at least 45 minutes).
             * To accomplish this, immediately refresh the token if it appears that the user is taking
             * action and it's getting close to the end of the idle timeout window. */
            activity$.subscribe(async lastActivityTime => {
                const authData = this._authStorageService.getAuthData();
                if (authData && (lastActivityTime - (+this._lastSynchedActivityTime)) > (authData.expires_in * 1000)) {
                    await this.refreshToken();
                }
            });
        }

        return { userInfo, userSettings };
    }

    private computeNextRefreshTime(authData: AuthData) {
        // Either refresh "refreshWindow" minutes before the access token expires or halfway between the login date
        // and the expiration date; whichever comes last
        const configuredRefreshMoment =
            moment(authData.login_date) // login_date should be an ISO-8601 date string in UTC
            .add(authData.expires_in, 's') // expires_in is given in seconds
            .subtract(refreshWindow, 'm');
        const alternateRefreshMoment =
            moment(authData.login_date)
            .add(authData.expires_in / 2, 's');
        const refreshMoment = configuredRefreshMoment.isAfter(alternateRefreshMoment) ? configuredRefreshMoment : alternateRefreshMoment;

        return refreshMoment.diff(moment());
    }

    private refreshTokenExpirationDate(refreshTokenData: RefreshTokenData) {
        const expireTimeSpanMs = refreshTokenData.refreshTokenExpireTimeSpanMinutes * 60000;
        return new Date(refreshTokenData.loginDate + expireTimeSpanMs);
    }

    private async getWeissman2Token(accessToken: string) {
        // For now, Weissman2 is only used for SignalR, so if we're not using the JWT from the UI
        // to connect to SignalR, don't bother getting it. If in the future we have a case where
        // Weissman2 is called from the UI, we can simply remove this line and have the Weissman2
        // access token available wherever we need.
        if (!this._settingsService.getEnvironmentConfig().signalRUseUnsafeLogin) {
            return null;
        }

        const { token } = await lastValueFrom(this._http.post< { token: string }>('/api/token/JWT', null, {
            headers: new HttpHeaders({ 'Authorization': `Bearer ${  accessToken}` })
        }));

        return token;
    }

    private async tokenRequest(requestData: { [key: string]: string }, additionalHeaders?: { [key: string]: string }) {
        const requestBody = Object.keys(requestData).map(k => `${k}=${encodeURIComponent(requestData[k])}`).join('&');
        const headers = <{ [name: string]: string }>{ 'Content-Type': 'application/x-www-form-urlencoded' };
        if (additionalHeaders) {
            Object.assign(headers, additionalHeaders);
        }
        const tokenResponse = await lastValueFrom(this._http.post<AuthData>('/api/token', requestBody, {
            headers: new HttpHeaders(headers)
        }));
        tokenResponse.login_date = moment().toJSON();
        return tokenResponse;
    }

    private setupRefresh() {
        // If we don't run this outside the Angular zone, it'll hold up Angular's testing hooks until it's finished
        // (and it's never finished)
        this._ngZone.runOutsideAngular(() => {
            const nextRefreshTime = this.computeNextRefreshTime(this._authStorageService.getAuthData());
            this._timeouts.refresh = this._timer.setTimeout(() => {
                this.refreshToken().then(isRefreshed => {
                    if (isRefreshed) {
                        this.setupRefresh();
                    }
                    else {
                        this._authStorageService.clearAuthData();
                        this._authStorageService.resetPathOnLogout = false;
                        this._authStorageService.logoutMessage$.next(logoutMessageUnexpectedEnd);
                    }
                });
            }, nextRefreshTime);
        });
    }

    private setupRefreshTokenExpire() {
        this._ngZone.runOutsideAngular(() => {
            const refreshTokenData = <RefreshTokenData>JSON.parse(localStorage[StorageTokenKeys.refreshTokenKey]);
            const refreshTokenExpirationTime = +this.refreshTokenExpirationDate(refreshTokenData) - +new Date();
            this._timeouts.loginExpire = this._timer.setTimeout(() => {
                this._authStorageService.clearAuthData();
                this._authStorageService.resetPathOnLogout = false;
                this._authStorageService.logoutMessage$.next(logoutMessageExpired);
            }, refreshTokenExpirationTime);
        });
    }

    private setupIdleTimeout(idleTimeoutMode: string, idleTimeoutInMinutes: number) {
        this._ngZone.runOutsideAngular(async () => {
            if (idleTimeoutMode === IdleTimeoutModes.Activity) {
                const idleTimeoutTime = new Date((+localStorage[StorageTokenKeys.lastActivityKey]) + idleTimeoutInMinutes * 60000);
                if (new Date() > idleTimeoutTime) {
                    await this.idleTimeout();
                }
                else {
                    this._timeouts.idleTimeout = this._timer.setTimeout(() => {
                        this.setupIdleTimeout(idleTimeoutMode, idleTimeoutInMinutes);
                    }, (+idleTimeoutTime) - (+new Date()));
                }
            }
        });
    }

    private async initializeLogout(): Promise<AuthData> {
        this._appStateService.appState$.next(AppStates.LoggingOut);
        await lastValueFrom(this._http.post('/api/account/logout', {}));
        const authData = this._authStorageService.getAuthData();
        this._authStorageService.clearAuthData();
        return authData;
    }

}
