import { Inject } from '@angular/core';
import { UserSettingsService } from '../Account/userSettings.service';

export interface SearchCategory {
    name: string;
    children: SearchCategory[];
}

@Inject({ providedIn: 'root' })
export class SearchCategoriesService {
    constructor(private readonly _userSettingsService: UserSettingsService) {}

    get(): SearchCategory {
        const settings = this._userSettingsService.getAdvancedCriteriaSettings();

        const groupedSettings = settings.reduce((grouped, setting) => {
            if (!grouped[setting.folderName]) {
                grouped[setting.folderName] = [];
            }

            grouped[setting.folderName].push(setting);

            return grouped;
        }, {});

        const searchesChildren = Object.keys(groupedSettings).reduce((children, folder: string) => {
            const folderArr = folder.split('/');

            children = this._buildChildren(folderArr, children, groupedSettings[folder]);

            return children;
        }, []);

        return {
            name: 'tree-top',
            children: searchesChildren
        };
    }

    isLeaf(node: SearchCategory): boolean {
        return !node.children.length;
    }

    private _buildChildren(folderArr: string[], children: SearchCategory[], payload: SearchCategory[]): SearchCategory[] {
        if (folderArr.length === 0) {
            return [...children, ...payload];
        }

        const folder = folderArr[0];

        let folderIndex = children.findIndex(x => x.name === folder);

        if (folderIndex < 0) {
            folderIndex = children.push({
                name: folder,
                children: []
            }) - 1;
        }

        children[folderIndex].children = this._buildChildren(folderArr.slice(1), children[folderIndex].children, payload);

        return children;
    }
}
