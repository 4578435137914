<ws-modal-wrapper headerLabel="Process - No Appeal Warranted"
                  acceptLabel="Process"
                  [disableSave]="loading"
                  [disableCancel]="loading"
                  (save)="process()"
                  (cancel)="cancel()">
    <loading-spinner [show]="loading" [marginTop]="10" [size]="4"></loading-spinner>
    <div *ngIf="!loading && payload">
        <p class="lead text-danger mb-0" *ngIf="isClientApprovalRequired">Please confirm client approval has been received prior to processing.</p>
        <div>
            <strong>Set Assessment Description to:</strong>
        </div>
        <div class="radio">
            <label>
                <input type="radio" [(ngModel)]="payload.revisionChangeTypeID" [value]="revisionChangeTypes.ORIGINAL_TO_FINAL">
                When Original change to Final
            </label>
        </div>
        <div class="radio">
            <label>
                <input type="radio" [(ngModel)]="payload.revisionChangeTypeID" [value]="revisionChangeTypes.FINAL">
                Final
            </label>
        </div>
        <div class="radio">
            <label>
                <input type="radio" [(ngModel)]="payload.revisionChangeTypeID" [value]="revisionChangeTypes.OTHER" (click)="focus()">
                Other
            </label>
            <input type="text"
                   #other
                   name="other"
                   [(ngModel)]="payload.newRevisionDescription"
                   class="form-control d-inline-block ms-3 mb-0">
        </div>
        <div class="radio">
            <label>
                <input type="radio" [(ngModel)]="payload.revisionChangeTypeID" [value]="revisionChangeTypes.NO_CHANGE">
                No Change
            </label>
        </div>
    </div>
    <div *ngIf="!loading" class="row mt-4">
        <div class="col-lg-2">
            Comment:
        </div>
        <div class="col-lg-10">
            <textarea class="form-control" cols="40" rows="5" [(ngModel)]="payload.comment"></textarea>
        </div>
    </div>
</ws-modal-wrapper>
