<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper
    headerLabel="Appeal Recommendation"
    [hideSave]="true"
    [hideCancel]="true"
    [hideFooter]="true"
    acceptLabel=""
    (cancel)="close()"
    [useAceStyles]="false">
    <loading-spinner [show]="!initialized" [size]="4"></loading-spinner>
    <div class="d-flex flex-column">
        <div class="d-flex" *ngIf="initialized">
            <div class="d-flex flex-column pb-4 pe-5">
                <div class="view-toggle d-flex">
                    <button class="align-items-center justify-content-center"
                         [class.selected]="selectedView === PanelView.Options"
                         (click)="changeTab(PanelView.Options)">
                        OPTIONS
                    </button>
                    <button class="align-items-center justify-content-center"
                         [class.selected]="selectedView === PanelView.Attachments"
                         (click)="changeTab(PanelView.Attachments)">
                        ATTACHMENTS
                    </button>
                </div>
                <div class="tab-panel" [class.active]="selectedView === PanelView.Options" [ngStyle]="panelSize" #optionsPanel1>
                    <div class="d-flex justify-content-between" *ngIf="enableAppealRecommendationEmailTemplate">
                        <label class="header-title">Template</label>
                        <div class="float-end header-button-container ws-flex-container-horizontal" [style.visibility]="editing ? 'visible' : 'hidden'">
                            <div class="action-button-wrapper"
                                 helpTooltip
                                 [helpContentId]="editHelpContentId"
                                 position="bottom">
                                <button type="button"
                                        class="btn flat-button icon-button primary-button"
                                        [disabled]="!canEditDelete"
                                        (click)="editTemplate(false)">
                                    <i class="fa fa-save fa-align"></i>
                                </button>
                            </div>
                            <div class="action-button-wrapper"
                                 helpTooltip
                                 [helpContentId]="copyHelpContentId"
                                 position="bottom">
                                <button type="button"
                                        class="btn flat-button icon-button primary-button"
                                        [disabled]="!editing"
                                        (click)="editTemplate(true)">
                                    <i class="fa fa-copy fa-align"></i>
                                </button>
                            </div>
                            <div class="action-button-wrapper"
                                 helpTooltip
                                 [helpContentId]="deleteHelpContentId"
                                 position="bottom">
                                <button type="button"
                                        class="btn flat-button icon-button warning-button"
                                        [disabled]="!canEditDelete"
                                        (click)="deleteTemplate()">
                                    <i class="fa fa-trash fa-align"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3" *ngIf="enableAppealRecommendationEmailTemplate">
                        <select id="selectedTemplate"
                                class="form-select form-select-sm"
                                [(ngModel)]="selectedTemplate"
                                (change)="onTemplateChanged()">
                            <option *ngFor="let spec of templates" [ngValue]="spec">{{ spec.name }}</option>
                        </select>
                        <span class="visibility-tooltip" [popover]="visibilityTooltip" placement="bottom" triggers="mouseenter:mouseleave">
                            <i class="fa fa-info-circle small"></i>
                        </span>
                    </div>
                    <table>
                        <tr>
                            <td class="pe-2">
                                <label>
                                    <input class="mb-0 lh-2-5"
                                           type="checkbox"
                                           id="includeSiteComment"
                                           [(ngModel)]="savedSettings.includeSiteComment"
                                           (ngModelChange)="emitAnalyticsEvent('include-site-comment', { includeSiteComment: $event })"
                                           [disabled]="!editing"
                                           helpTooltip
                                           [helpContentId]="getCheckboxHelpContentId()">
                                    Include Site Comment
                                </label>
                            </td>
                            <td class="ps-2">
                                <div dropdown
                                     container="body"
                                     placement="bottom right"
                                     [isDisabled]="!editing"
                                     helpTooltip
                                     [helpContentId]="getCheckboxHelpContentId()">
                                    <button id="editSiteCommentsButton" dropdownToggle type="button" class="flat-button small-button secondary-button dropdown-toggle"
                                            aria-controls="dropdown-basic">
                                        Site Comment
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="editSiteCommentsDropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                                        <li role="menuitem" *ngFor="let site of sites">
                                            <a class="pointer" (click)="commentSiteChanged(site.siteId)">{{ site.siteName }}</a>
                                        </li>
                                    </ul>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="pe-2">
                                <label class="text-nowrap">
                                    <input type="checkbox"
                                           id="includePriorYear"
                                           [(ngModel)]="savedSettings.showPriorYear"
                                           (ngModelChange)="emitAnalyticsEvent('include-prior-year', { includePriorYear: $event }); settingChanged();"
                                           [disabled]="!editing"
                                           helpTooltip
                                           [helpContentId]="getCheckboxHelpContentId()">
                                    Show Prior Year
                                </label>
                            </td>
                            <td class="ps-2">
                                <label class="text-nowrap">
                                    <input id="showYearBuilt"
                                           [(ngModel)]="savedSettings.showYearBuilt"
                                           (ngModelChange)="settingChanged(analyticsEvent.ClickShowYearBuilt)"
                                           type="checkbox"
                                           [disabled]="!editing"
                                           helpTooltip
                                           [helpContentId]="getCheckboxHelpContentId()"/>
                                    Show Year Built
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="pe-2">
                                <label class="text-nowrap">
                                    <input id="showAcquisitionInfo"
                                           [(ngModel)]="savedSettings.showAcquisitionInfo"
                                           (ngModelChange)="settingChanged(analyticsEvent.ClickShowAcquisitionInfo)"
                                           type="checkbox"
                                           [disabled]="!editing"
                                           helpTooltip
                                           [helpContentId]="getCheckboxHelpContentId()"/>
                                    Show Acquisition Info
                                </label>
                            </td>
                            <td class="ps-2">
                                <label class="text-nowrap">
                                    <input id="showAppealDeadlines"
                                           [(ngModel)]="savedSettings.showAppealDeadlines"
                                           (ngModelChange)="settingChanged(analyticsEvent.ClickShowAppealDeadlines)"
                                           type="checkbox"
                                           [disabled]="!editing"
                                           helpTooltip
                                           [helpContentId]="getCheckboxHelpContentId()"/>
                                    Show Appeal Deadlines
                                </label>
                            </td>
                        </tr>
                    </table>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Assessments</label>
                        <ws-select
                            name="showAssessments"
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showAssessmentOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showAssessments"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowAssessments)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Metrics</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showMetricOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showMetrics"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowMetrics)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Initial Value</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showInitialValueOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showInitialValue"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowInitialValue)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Target Value</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showTargetValueOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showTargetValue"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowTargetValue)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Latest Value</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showLatestValueOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showLatestValue"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowLatestValue)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Tax Details</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showTaxDetailsOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showTaxDetails"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowTaxDetails)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Show Savings</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="showSavingOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.showSavings"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickShowSavings)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Attach Chart</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="attachChartOptions"
                            labelProperty="name"
                            valueProperty="value"
                            disabledProperty="isDisabled"
                            titleProperty="title"
                            [(ngModel)]="savedSettings.attachChart"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickAttachChart)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <label class="text-nowrap">Embed Chart</label>
                        <ws-select
                            helpTooltip
                            [helpContentId]="getCheckboxHelpContentId()"
                            [options]="embedChartOptions"
                            labelProperty="name"
                            valueProperty="value"
                            [(ngModel)]="savedSettings.embedInEmail"
                            (ngModelChange)="settingChanged(analyticsEvent.ClickEmbedChart)"
                            [disabled]="!editing">
                        </ws-select>
                    </div>
                </div>
                <div class="tab-panel" [class.active]="selectedView === PanelView.Attachments" [ngStyle]="panelSize" #optionsPanel2>
                    <div class="d-flex mb-2">
                        <div dropdown
                             container="body"
                             placement="bottom right"
                             [isDisabled]="!editing"
                             helpTooltip
                             [helpContentId]="getCheckboxHelpContentId()">
                            <button id="editSiteAttachmentsButton" dropdownToggle type="button" class="flat-button small-button secondary-button dropdown-toggle"
                                    aria-controls="dropdown-basic">
                                Include Site Attachments
                                <span class="caret"></span>
                            </button>
                            <ul id="editSiteAttachmentsDropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem" *ngFor="let site of sites">
                                    <a class="pointer" (click)="attachmentSiteChanged(site.siteId)">{{ site.siteName }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <ng-container *ngFor="let attachment of attachments">
                            <div class="d-flex w-100 justify-content-between">
                                <span>{{attachment.attachmentName}}</span>
                                <div helpTooltip
                                     [helpContentId]="getCheckboxHelpContentId()">
                                    <button class="btn flat-button icon-button icon-only danger-icon"
                                            [disabled]="!editing"
                                            (click)="removeAttachment(attachment)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="vr"></div>
            <div class="d-flex flex-column flex-grow-1 ps-4 right-panel">
                <div class="line">
                    <div class="email-recipient">
                        <div style="width:25px;">To:</div>
                        <div *ngIf="!editing">{{appealRecommendationInfo.to.name}}</div>
                        <div *ngIf="editing" style="width: 97%">
                            <input id="selectTo"
                                   type="text"
                                   placeholder="{{appealRecommendationInfo.to?.name || 'Search Recipient...'}}"
                                   class="form-control exclude-modal-styling"
                                   autocomplete="off"
                                   (blur)="onToBlur()"
                                   [(ngModel)]="toFilter"
                                   [typeahead]="to$"
                                   typeaheadOptionField="name"
                                   [typeaheadMinLength]="0"
                                   [typeaheadScrollable]="true"
                                   (typeaheadOnSelect)="onToSelected($event)"
                                   [typeaheadOptionsLimit]="1000"/>
                        </div>
                    </div>
                    <span (click)="refresh()" class="clickable" [style.visibility]="editing ? 'hidden' : 'visible'">
                <i class="fa fa-refresh"></i>
            </span>
                </div>
                <div class="line">
                    <div class="email-recipient">
                        <div style="width:25px;">&nbsp;</div>
                        <div *ngIf="!editing">{{appealRecommendationInfo.cc?.name}}</div>
                        <div *ngIf="editing" style="width: 97%">
                            <input id="selectCC"
                                   type="text"
                                   placeholder="{{appealRecommendationInfo.cc?.name || 'Search Recipient...'}}"
                                   class="form-control exclude-modal-styling"
                                   autocomplete="off"
                                   (blur)="onCCBlur()"
                                   [(ngModel)]="ccFilter"
                                   [typeahead]="cc$"
                                   typeaheadOptionField="name"
                                   [typeaheadMinLength]="0"
                                   [typeaheadScrollable]="true"
                                   (typeaheadOnSelect)="onCCSelected($event)"
                                   [typeaheadOptionsLimit]="1000"/>
                        </div>
                    </div>
                    <span class="clickable" style="visibility: hidden;">
                        <i class="fa fa-refresh"></i>
                    </span>
                    </div>
                    <div class="line">
                        <div class="data-text-field w-100 me-3" *ngIf="!editing">{{appealRecommendationInfo.emailSubject}}</div>
                        <input type="text" id="template-subject" class="form-control d-inline-block" style="width: 90%;"
                               *ngIf="editing"
                               [(ngModel)]="appealRecommendationInfo.emailSubjectTemplate"
                               placeholder="Subject ...">
                        <div class="btn-group" style="margin-left: -5px;" dropdown *ngIf="editing">
                            <button dropdownToggle type="button" class="btn btn-default dropdown-toggle" style="width: initial; padding: 4px 12px; height: 34px;">
                                Insert Field
                                <span class="caret"></span>
                            </button>
                            <ul *dropdownMenu class="dropdown-menu" role="menu">
                                <li role="menuitem" *ngFor="let field of emailTemplateSubjectFields">
                                    <a class="dropdown-item clickable" (click)="insertFieldInSubject(field)">{{field.name}}</a>
                                </li>
                            </ul>
                        </div>
                        <span *ngIf="!editing" (click)="edit()" class="clickable">
                            <i class="fa fa-pencil"></i>
                        </span>
                </div>
                <div class="editor d-flex flex-column flex-grow-1 w-100 overflow-hidden">
                    <div *ngIf="!editing" class="data-text-field email-preview-display d-flex flex-column flex-grow-1 w-100"
                         [innerHTML]="emailBody"></div>
                    <div *ngIf="editing" class="d-flex w-100 h-100">
                        <quill-editor
                            class="d-flex flex-column w-100"
                            [(ngModel)]="appealRecommendationInfo.emailBodyTemplate"
                            (onContentChanged)="contentChange($event)"
                            (focusout)="bodyBlur()"
                            [placeholder]="'Email Body ...'"
                            (onEditorCreated)="addBindingCreated($event)">
                            <div quill-editor-toolbar>
                                <!-- Basic buttons -->
                                <span class="ql-formats">
                                    <button class="ql-bold" [title]="'Bold'"></button>
                                    <button class="ql-italic" [title]="'Italic'"></button>
                                    <button class="ql-underline" [title]="'Underline'"></button>
                                    <button class="ql-strike" [title]="'Strike'"></button>
                                </span>

                                <!-- Size -->
                                <span class="ql-formats">
                                    <select class="ql-size" [title]="'Size'">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                                <div class="d-inline-block">
                                    <div class="btn-group" dropdown>
                                        <button dropdownToggle type="button" class="btn dropdown-toggle" style="width: initial;">
                                            Insert Field
                                            <span class="caret"></span>
                                        </button>
                                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                                            <li role="menuitem" *ngFor="let field of emailTemplateBodyFields">
                                                <a style="cursor: pointer;" class="dropdown-item" (click)="insertFieldInBody(field)">{{field.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </quill-editor>
                    </div>
                </div>
            </div>
        </div>
        <div class="line lastSentInfo" *ngIf="lastSent">
            <div
                helpTooltip
                position="top"
                edgeBuffer="6%"
                [contentComponent]="sentInfoTooltip">
                Last sent by {{lastSent}}
            </div>
        </div>
        <div class="line footer">
            <button *ngIf="!editing" class="flat-button icon-button-large primary-button"
                    style="height: 40px; margin-right: 6px;"
                    (click)="downloadEmail()">
                <i class="fa fa-download"></i>
            </button>
            <button *ngIf="!editing" class="btn flat-button primary-button"
                    style="margin-top: 10px; margin-right: 6px;"
                    (click)="sendEmail()">
                Send Email
            </button>
            <button *ngIf="editing" class="btn flat-button primary-button"
                    style="margin-top: 10px; margin-right: 6px;"
                    (click)="save()"
                    [disabled]="!canSave">
                Save
            </button>
            <button *ngIf="editing" class="btn flat-button warning-button"
                    style="margin-top: 10px;"
                    (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</ws-modal-wrapper>

<ng-template #visibilityTooltip>
    <div class="ws-flex-container-vertical" *ngIf="selectedTemplate">
        <div *ngIf="selectedTemplate.description" class="ws-flex-container-vertical mb-2">
            <strong>Description:</strong>
            <div>
                {{ selectedTemplate.description }}
            </div>
        </div>
        <div *ngIf="selectedTemplate.visibilityItem" class="ws-flex-container-vertical mb-2">
            <strong>Visibility:</strong>
            <div>
                <span style="font-style:italic">{{ selectedTemplate.visibilityItem.name }}</span>
                {{ selectedTemplate.visibilityItem.description }}
            </div>
        </div>
        <div *ngIf="selectedTemplate.changedByName" class="ws-flex-container-vertical mb-2">
            <strong>Updated By:</strong>
            <div>
                {{ selectedTemplate.changedByName }}
            </div>
        </div>
    </div>
</ng-template>
