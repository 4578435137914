<ws-modal-wrapper headerLabel="Processing Errors"
                  cancelLabel="Close"
                  [hideSave]="true"
                  (cancel)="close()">
    <div>
        <div *ngIf="topLevelMessage">
            <p>{{topLevelMessage}}</p>
            <ul class="bulk-warning-list">
                <li *ngFor="let message of errors">{{message}}</li>
                <li *ngFor="let message of warnings">{{message}}</li>
            </ul>
        </div>
        <div *ngIf="!topLevelMessage && warnings && warnings.length > 0">
            <p>{{warningMessage}}</p>
            <ul class="bulk-warning-list">
                <li *ngFor="let message of warnings">{{message}}</li>
            </ul>
        </div>
        <div *ngIf="!topLevelMessage && errors && errors.length > 0">
            <p>{{errorMessage}}</p>
            <ul class="bulk-error-list">
                <li *ngFor="let message of errors">{{message}}</li>
            </ul>
        </div>
    </div>
</ws-modal-wrapper>

