<div class="modal-body exclude-modal-styling">
    <loading-spinner [show]="!taxRateArea" [size]="4" [marginBottom]="50"></loading-spinner>
    <div *ngIf="taxRateArea" class="ws-flex-container-vertical ws-stretch">
        <div class="ws-section">
            <div class="ws-section__header">
                <h4 class="ws-section__header__title">Tax Rate Area</h4>
                <div class="ws-section__header__actions">
                    <div class="ws-section__header__actions__item" *ngIf="canEdit">
                        <i *ngIf="cloning" class="fa fa-spinner fa-pulse fa-2x me-4"></i>
                        <button *ngIf="!editMode"
                                type="button"
                                class="btn flat-button small-button primary-button"
                                (click)="goToEditMode()"
                                title="Edit"
                                [disabled]="cloning">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button *ngIf="!editMode && taxRateArea.mayBeDeleted"
                                type="button"
                                class="btn flat-button small-button warning-button"
                                (click)="delete()"
                                title="Delete"
                                [disabled]="cloning">
                            <i class="fa fa-trash"></i>
                        </button>
                        <button *ngIf="editMode && taxRateArea.taxRateAreaId"
                                type="button"
                                class="btn flat-button small-button warning-button"
                                (click)="cancel()"
                                title="Cancel"
                                [disabled]="saving">
                            <i class="fa fa-close"></i>
                        </button>
                        <button *ngIf="!editMode && taxRateArea.taxRateAreaId"
                                type="button"
                                class="btn flat-button small-button primary-button"
                                (click)="clone()"
                                title="Clone"
                                [disabled]="saving || cloning">
                            <i class="fa fa-clone"></i>
                        </button>
                    </div>
                    <div class="ws-section__header__actions__item">
                        <entity-attachments buttonClass="flat-button small-button primary-button"
                                            [(hasAttachments)]="taxRateArea.hasAttachments"
                                            [isNestedModal]="true"
                                            [modalData]="attachmentsModel">
                        </entity-attachments>
                        <entity-comments buttonClass="flat-button small-button primary-button"
                                         [isNestedModal]="true"
                                         [(hasComments)]="taxRateArea.hasComments"
                                         [modalData]="commentsModel">
                        </entity-comments>
                    </div>
                </div>
            </div>
        </div>

        <div class="ws-flex-container-vertical ws-stretch">
            <div class="ws-flex-container-horizontal">
                <div class="card panel-flat-color primary-panel flex-panel pb-0">
                    <div class="panel-flat-header">
                        <h3>Info</h3>
                    </div>
                    <div class="panel-flat-body ws-flex-container-vertical">
                        <div class="ws-flex-auto w-100 h-100 d-flex justify-content-around">
                            <div>
                                <table class="tax-rate-area-info" [class.is-edit-mode]="editMode">
                                    <tr>
                                        <td>Name:</td>
                                        <td>
                                            <span *ngIf="!editMode">{{ taxRateArea.name }}</span>
                                            <input class="form-control" type="text" maxlength="100" *ngIf="editMode"
                                                   [(ngModel)]="taxRateArea.name">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Code:</td>
                                        <td>
                                            <span *ngIf="!editMode">{{ taxRateArea.code }}</span>
                                            <input class="form-control" type="text" maxlength="50" *ngIf="editMode"
                                                   [(ngModel)]="taxRateArea.code">
                                        </td>
                                    </tr>
                                </table>
                                <div class="mt-4">
                                    <div *ngIf="taxRateArea.disabled" class="checkbox">
                                        <label>
                                            <input type="checkbox" [(ngModel)]="taxRateArea.disabled"
                                                   [disabled]="!editMode"> Disabled
                                        </label>
                                    </div>
                                    <button *ngIf="editMode && !taxRateArea.disabled"
                                            class="btn flat-button warning-button small-button m-0"
                                            [popover]="endYearPopover"
                                            placement="right"
                                            [outsideClick]="true"
                                            container="body">Disable
                                    </button>
                                </div>
                            </div>
                            <table class="tax-rate-area-address" [class.is-edit-mode]="editMode">
                                <tr>
                                    <td>Address:</td>
                                    <td>
                                        <address-view-edit [address]="taxRateArea.address"
                                                           [isEditMode]="editMode"
                                                           [isSmall]="true"
                                                           [readOnlyState]="true">
                                        </address-view-edit>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card panel-flat-color secondary-panel flex-panel tax-authorities-list">
                <div class="panel-flat-header">
                    <h3>Tax Authorities</h3>
                </div>
                <div class="panel-flat-body ws-flex-container-vertical">
                    <div *ngIf="!hideAddCollectorButton && editMode"
                         class="btn-group position-absolute"
                         dropdown
                         placement="bottom right"
                         style="right: 20px;"
                         container="body">
                        <button dropdownToggle type="button"
                                class="btn flat-button primary-button small-button dropdown-toggle"
                                [attr.disabled]="shouldDisableAddCollector">
                            Add Collector <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-end collectors" role="menu">
                            <ng-container *ngFor="let collector of taxRateArea.collectors">
                                <li role="menuitem" *ngIf="!collector.isTab">
                                    <a class="dropdown-item pointer" (click)="addCollector(collector)">{{ collector.name }}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div *ngIf="atLeastOneTab"
                         class="ws-flex-auto w-100 h-100 d-flex flex-column justify-content-between">
                        <tabset>
                            <ng-container *ngFor="let collector of taxRateArea.collectors">
                                <tab *ngIf="collector.isTab"
                                     [heading]="collector.name"
                                     [active]="collector.active"
                                     (selectTab)="collector.active = true;"
                                     (deselect)="collector.active = false"
                                     [customClass]="taxRateArea.collectorId ? 'collector-tra' : ''">
                                    <div class="tra-table">
                                        <table class="table table-condensed tax-rate-authorities">
                                            <thead>
                                            <tr>
                                                <th>Code</th>
                                                <th>Tax Authority Name</th>
                                                <th>Category</th>
                                                <th class="ws-text-align-center">Tax Year Begin</th>
                                                <th class="ws-text-align-center">Tax Year End</th>
                                                <th></th>
                                                <th *ngFor="let year of years" class="ws-text-align-right">{{ year }}
                                                </th>
                                            </tr>
                                            </thead>
                                            <ng-container *ngFor="let taxAuthority of collector.taxAuthorities">
                                                <ng-container
                                                    *ngFor="let detail of taxAuthority.taxRateAreaTaxAuthorityDetails">
                                                    <tr *ngIf="detail.efAction !== 'delete'">
                                                        <td>{{ taxAuthority.code }}</td>
                                                        <td>{{ taxAuthority.name }}</td>
                                                        <td>{{ getCategory(taxAuthority.taxAuthorityCategoryId) }}</td>
                                                        <td class="ws-text-align-center">
                                                            <span
                                                                *ngIf="!editMode">{{ detail.yearBegin || 'Infinite' }}</span>
                                                            <select *ngIf="editMode"
                                                                    class="form-select form-select-sm d-inline-block"
                                                                    [(ngModel)]="detail.yearBegin"
                                                                    (change)="yearBeginChange(detail, taxAuthority)"
                                                                    [disabled]="loadingProjections">
                                                                <option [ngValue]="null">Infinite</option>
                                                                <option *ngFor="let year of taxYearBeginEndOptions"
                                                                        [ngValue]="year">{{ year }}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td class="ws-text-align-center">
                                                            <span
                                                                *ngIf="!editMode">{{ detail.yearEnd || 'Infinite' }}</span>
                                                            <select *ngIf="editMode"
                                                                    class="form-select form-select-sm d-inline-block"
                                                                    [(ngModel)]="detail.yearEnd"
                                                                    (change)="yearEndChange(detail, taxAuthority)"
                                                                    [disabled]="loadingProjections">
                                                                <option [ngValue]="null">Infinite</option>
                                                                <option *ngFor="let year of taxYearBeginEndOptions"
                                                                        [ngValue]="year">{{ year }}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button [class.invisible]="!editMode"
                                                                    class="btn flat-button warning-button small-button m-0"
                                                                    (click)="removeTaxRateAreaTaxAuthorityDetail(taxAuthority, detail)"
                                                                    [disabled]="loadingProjections">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                        <td *ngFor="let year of years"
                                                            class="ws-text-align-right"
                                                            [class.not-actual]="getYearEstimated(taxAuthority, year)">
                                                            {{ getYearRate(taxAuthority, detail, year) }}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td *ngFor="let year of years"
                                                    class="ws-text-align-right tra-totals-row"
                                                    [class.not-actual]="getTotalEstimated(collector.taxAuthorities, year)">
                                                    {{ getTotalRateForDisplay(collector.taxAuthorities, year) }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div class="btn-group"
                                         dropdown
                                         container="body"
                                         *ngIf="editMode"
                                         [dropup]="shouldUseDropup(collector.taxAuthorities)">
                                        <button dropdownToggle type="button"
                                                class="flat-button primary-button small-button dropdown-toggle">
                                            Add Tax Authority <span class="caret"></span>
                                        </button>
                                        <ul *dropdownMenu class="dropdown-menu add-tax-auth-menu" role="menu">
                                            <li role="menuitem"
                                                *ngFor="let taxAuthority of getTaxAuthotitiesSortedByName(collector.taxAuthorities)">
                                                <a class="dropdown-item pointer"
                                                   (click)="addTaxAuthority(taxAuthority)">{{ taxAuthority.name }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </tab>
                            </ng-container>
                        </tabset>
                        <div class="tax-authorities-actions mt-2">
                            <div>
                                <label>
                                    <input type="checkbox" [(ngModel)]="groupByCategory" (ngModelChange)="sortData()">
                                    Group by Category
                                </label>
                            </div>
                            <!-- <div>
                                <div>Order:</div>
                                <div>
                                    <label>
                                        <input type="radio" value="alpha" [(ngModel)]="orderBy" (ngModelChange)="orderByChanged()"> Alphabetical
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" value="manual" [(ngModel)]="orderBy" (ngModelChange)="orderByChanged()"> Manual
                                    </label>
                                </div>
                            </div> -->
                            <show-tax-year [(taxYearBegin)]="taxYearBegin"
                                           [(taxYearsPrior)]="taxYearsPrior"
                                           (showTaxYearChanged)="showTaxYearChanged()">
                            </show-tax-year>
                        </div>
                    </div>
                    <div *ngIf="!editMode && !atLeastOneTab" class="ws-text-align-center">
                        <h4><em>No Collectors Added</em></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer pt-0" *ngIf="taxRateArea">
    <i *ngIf="saving" class="fa fa-spinner fa-pulse fa-2x me-4"></i>
    <button class="btn flat-button primary-button" *ngIf="editMode" (click)="save()"
            [disabled]="saving || !taxRateArea?.name">Save
    </button>
    <button class="btn flat-button warning-button" (click)="closeModal()">Close</button>
</div>

<ng-template #endYearPopover>
    <div class="mb-4">
        <label>Enter Tax Year End (All tax Authorities)</label>
        <select class="form-select form-select-sm d-inline-block" [(ngModel)]="globalYearEnd"
                (change)="disableTaxRateArea()">
            <option *ngFor="let year of taxYearBeginEndOptions" [ngValue]="year">{{ year }}</option>
        </select>
    </div>
</ng-template>
