import { Injectable } from '@angular/core';
import { BillClusterService, BillViewModel } from '../../../../Annual-Details/Taxes/bill-cluster.service';
import { Bill } from '../../../../Annual-Details/Taxes/bill.model';
import { Payment } from '../../../../Annual-Details/Taxes/payment.model';

import * as moment from 'moment/moment';
import { flatMap, minBy, some } from 'lodash';

import DetailsInstallmentMessageItem = Weissman.Model.Workflow.DocumentIntake.OcrResponse.Message.DetailsInstallmentMessageItem;


@Injectable({ providedIn: 'root' })
export class IdpService {

    constructor(private readonly _billClusterService: BillClusterService) {}

    public mapIdpResult(billDetail: BillViewModel, idpResults: Core.TaxBillPushMessageDto, forceAccept?: boolean): boolean {
        let lowerConfidence = false;

        billDetail.model.forEach((bill: Bill) => {
            // If the PropertyPoint bill has one payment and there is no canonical installment for just one payment,
            // use the canonical bill's totalAmount due and the earliest payment's due date within the canonical bill.
            // This is basically paying the full amount due by the first payment due date.
            if(bill.payments.length === 1 && !some(idpResults.installments, x => x.details.length === 1)) {
                const idpPayments = flatMap(idpResults.installments, 'details') as DetailsInstallmentMessageItem[];
                const idpPayment = minBy(idpPayments, x => new Date(x.dueDate));

                if(!idpPayment) return;

                bill.calcProjected = false;

                bill.billAmount = idpResults.totalAmount || 0;
                bill.directAsmt = idpResults.directAssessmentAmount || 0;

                bill.payments[0].dueDate = idpPayment.dueDate;
                bill.payments[0].grossPayment = idpResults.totalAmount;
                bill.payments[0].paymentAmount = this._billClusterService.paymentPropChanged(bill.payments[0]);

                return;
            }

            const installment = idpResults.installments.find(x => x.details.length === bill.payments.length);
            let discountIndex = 0;

            if (bill.payments.length === 1) {
                const payment = installment?.details.find(x => moment(x.dueDate).isSame(moment(bill.payments[0].dueDate)));
                discountIndex = payment?.discount.findIndex(x => x.dueDate === bill.payments[0].dueDate) || 0;
            }

            if(!forceAccept) {
                lowerConfidence = some(bill.payments, (payment: Payment, pmtIdx: number) => {
                    const ocrPayment = installment?.details[pmtIdx];
                    const possibleDueDate = ocrPayment?.discount[discountIndex]?.dueDate || ocrPayment.dueDate;
                    // If there is a payment due date, but it's not the same as the possible one
                    // Do not overwrite, and lower the confidence
                    return payment.dueDate && !moment(possibleDueDate).isSame(moment(payment.dueDate));
                });

                if(lowerConfidence) return;
            }

            // Confidence is medium or high now

            bill.billAmount = idpResults.totalAmount || 0;
            bill.directAsmt = idpResults.directAssessmentAmount || 0;

            bill.payments.forEach((payment: Payment, pmtIdx: number) => {
                const ocrPayment = installment?.details[pmtIdx];

                if(!ocrPayment) return;

                bill.calcProjected = false;

                payment.dueDate =  ocrPayment?.discount[discountIndex]?.dueDate || ocrPayment.dueDate;
                payment.grossPayment = ocrPayment?.amount || 0;
                payment.discountAmount = -this.getDiscount(ocrPayment, discountIndex);
                payment.interestAmount = ocrPayment?.interest[discountIndex]?.interestAmount || 0;
                payment.penaltyAmount = ocrPayment?.penalty[discountIndex]?.penaltyAmount || 0;

                payment.paymentAmount = this._billClusterService.paymentPropChanged(payment);
            });

        });

        return lowerConfidence;
    }

    getDiscount(ocrPayment: DetailsInstallmentMessageItem, discountIndex: number): number {
        const discount = ocrPayment?.discount[discountIndex];

        if(discount?.discountAmount) {
            return discount?.discountAmount;
        } else if(discount?.netAmount && ocrPayment.amount) {
            return ocrPayment.amount - discount?.netAmount;
        } else {
            return 0;
        }
    }

    // private _getDirectAssessment(idpResults: Core.TaxBillPushMessageDto): number {
    //     if(idpResults.directAssessmentAmount) {
    //         return idpResults.directAssessmentAmount;
    //     } else {
    //         return reduce(idpResults.taxAuthorities, (sum, ta) => {
    //             if(!ta.taxRate) {
    //                 sum += ta.taxAmount;
    //             }

    //             return sum;
    //         }, 0);
    //     }
    // }
}
