import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';
import { TaskActionTypes } from '../constants.new';
import { MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';

@Injectable({ providedIn: 'root' })
export class TaskRepository extends BaseRepository {
    constructor(
        private readonly _messageBoxService: MessageBoxService,
        httpClient: HttpClient,
        @Inject('$http') private internalHttp: any
    ) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/Tasks';

    getByEntity(entityID: number, entityTypeID: number): Observable<any> {
        const params = {
            entityID: entityID,
            entityTypeID: entityTypeID
        };

        const url = `${this.SERVICE_URL  }/entity`;

        return this.httpGet(url, { params: params });
    }

    getTaskSummaryByEntity(entityID: number, entityTypeID: number): Observable<any> {
        const params = {
            entityID: entityID,
            entityTypeID: entityTypeID
        };

        const url = `${this.SERVICE_URL  }/entity/summary`;

        return this.httpGet(url, { params: params });
    }

    checkIfEntityMayBeDeactivated(entityID: number, entityTypeID: number): Observable<any> {
        const params = {
            entityID: entityID,
            entityTypeID: entityTypeID
        };

        const url = `${this.SERVICE_URL  }/entity/CheckIfMayBeDeactivated`;

        return this.httpGet(url, { params: params });
    }

    rescindTask(task): Observable<any> {
        const taskProcessRequestDTO = {
            entityID: task.entityId,
            entityTypeID: task.entityTypeID,
            taskID: task.taskID,
            rowVersion: task.rowVersion,
            actionID: TaskActionTypes.RESCINDTASK
        };
        const url = `${this.SERVICE_URL  }/performAction`;

        return this.httpPut(url, taskProcessRequestDTO);
    }

    insertTask(task, actionID: number, newTaskTypeID: number): Observable<any> {
        const taskProcessRequestDTO = {
            entityID: task.entityId,
            entityTypeID: task.entityTypeID,
            taskID: task.taskID,
            rowVersion: task.rowVersion,
            actionID: actionID,
            newTaskTypeID: newTaskTypeID
        };

        const url = `${this.SERVICE_URL  }/performAction`;

        return this.httpPut(url, taskProcessRequestDTO);
    }

    updateTask(task): Observable<any> {
        const taskProcessRequestDTO = {
            entityID: task.entityId,
            entityTypeID: task.entityTypeID,
            taskID: task.taskID,
            rowVersion: task.rowVersion,
            actionID: TaskActionTypes.REASSIGNTASK,
            assignedUserID: task.assignedUserID,
            assignedTeamID: task.assignedTeamID
        };
        const url = `${this.SERVICE_URL  }/performAction`;

        return this.httpPut(url, taskProcessRequestDTO);
    }

    deleteTask(task): Observable<any> {
        const taskProcessRequestDTO = {
            entityID: task.entityId,
            entityTypeID: task.entityTypeID,
            taskID: task.taskID,
            rowVersion: task.rowVersion,
            actionID: TaskActionTypes.DELETETASK
        };
        const url = `${this.SERVICE_URL  }/performAction`;

        return this.httpPut(url, taskProcessRequestDTO);
    }

    async completeTask(task): Promise<any> {
        const warning = await lastValueFrom(this.validateAssessorStateReqs([task.taskID]));
        if (warning) {
            await this._messageBoxService.warning(warning);
        }

        const taskProcessRequestDTO = {
            entityID: task.entityId,
            entityTypeID: task.entityTypeID,
            taskID: task.taskID,
            rowVersion: task.rowVersion,
            actionID: TaskActionTypes.COMPLETETASK
        };
        const url = `${this.SERVICE_URL  }/performAction`;

        return lastValueFrom(this.httpPut(url, taskProcessRequestDTO));
    }

    skipTask(task): Observable<any> {
        const taskProcessRequestDTO = {
            entityID: task.entityId,
            entityTypeID: task.entityTypeID,
            taskID: task.taskID,
            rowVersion: task.rowVersion,
            actionID: TaskActionTypes.SKIPTASK
        };
        const url = `${this.SERVICE_URL  }/performAction`;

        return this.httpPut(url, taskProcessRequestDTO);
    }

    getInsertOptions(entityID: number, entityTypeID: number, taskID: number): Observable<any> {
        const params = {
            entityId: entityID,
            entityTypeID: entityTypeID,
            taskID: taskID
        };

        const url = `${this.SERVICE_URL  }/InsertOptions`;

        return this.httpGet(url, { params: params });
    }

    changeContacts(request): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL  }/changecontacts`, request);
    }

    reassignMany(reassignObj): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL  }/reassignUser`, reassignObj);
    }
    skipMany(taskIds: number[]): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL  }/skiptasks`, taskIds);
    }
    completeMany(taskIds: number[]): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL  }/completetasks`, taskIds);
    }

    validateAssessorStateReqs(taskSeriesIds: number[]): Observable<any> {
        return this.httpPost(`${this.SERVICE_URL  }/ValidateAssessorStateReqs`, taskSeriesIds);
    }
}
