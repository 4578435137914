// See comments in Message-Box.Service.js for usage instructions

// Note that this is also defined in the JavaScript globals script
export enum MessageBoxButtons {
    OK = 1,
    OKCancel = 2,
    YesNo = 3,
    YesNoCancel = 4
}

// Note that this is also defined in the JavaScript globals script
export enum MessageBoxResult {
    OK = 1,
    Cancel = 2,
    Yes = 3,
    No = 4
}

export interface MessageBoxOptions {
    title?: string,
    message: string,
    buttons: MessageBoxButtons,
    focusButton?: MessageBoxResult
}

export abstract class MessageBoxService {
    abstract open(options: MessageBoxOptions, isBackdropStatic?: boolean): Promise<MessageBoxResult>;
    abstract confirm(message: string, title?: string, focusButton?: MessageBoxResult): Promise<void>;
    abstract confirmYesNo(message: string, title?: string): Promise<void>;
    abstract warning(message: string, title?: string): Promise<void>;
}
