<div class="idp-card d-flex flex-column mb-3 mx-3">
    <div class="idp-card-header w-100 px-3 my-4">
        <h2 class="m-0">{{detail.title}}</h2>
    </div>
    <div class="idp-card-body mx-3 mb-3 p-3">
        <div class="d-flex justify-content-between">
            <span>{{detail.total.name}}</span>
            <span>{{detail.total.value | currency: 'USD':'symbol'}}</span>
        </div>
        <hr>
        <ng-container *ngFor="let item of detail.details">
            <div class="d-flex justify-content-between">
                <span>{{item.name}}</span>
                <span *ngIf="item.type === 'string'">{{item.value}}</span>
                <span *ngIf="item.type === 'currency'">{{item.value | currency: 'USD':'symbol'}}</span>
                <span *ngIf="item.type === 'date'">{{item.value | weissmanDateFormat: true}}</span>
            </div>
        </ng-container>
    </div>
</div>
