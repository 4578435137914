export class AppealRecommendationGridRowInfoTreeItemModel implements Core.AppealRecommendationGridRowInfoModel{
    accountHandler: string;
    appealDeadline: Date;
    appealLevel: string;
    appealRecommendationStatus: string;
    appealRecommendationStatusId: number;
    assesseeName: string;
    assessorId: number;
    assessorName: string;
    canChangeAppealRecommendationStatus: boolean;
    canEdit: boolean;
    class: string;
    companyId: number;
    companyName: string;
    createDate: Date;
    currentAV: number;
    currentFMV: number;
    currentImpsAV: number;
    currentImpsFMV: number;
    currentPersAV: number;
    currentPersFMV: number;
    currentAltAV: number;
    currentAltFMV: number;
    currentLandAV: number;
    currentLandFMV: number;
    determineIfAppealWarrantedTaskId: number;
    emailSentDate: Date;
    entityId: string;
    entityName: string;
    fmvVariance: number;
    instanceId: number;
    isClientApproved?: boolean;
    isClientApprovalRequired: boolean;
    isReady: boolean;
    originalAV: number;
    originalFMV: number;
    originalImpsFMV: number;
    originalImpsAV: number;
    originalPersFMV: number;
    originalPersAV: number;
    originalAltAV: number;
    originalAltFMV: number;
    originalLandAV: number;
    originalLandFMV: number;
    parcelAccountNumber: string;
    parcelId: number;
    priorYearAppeal: string;
    priorYearAppealId: number;
    priorYearAV: number;
    priorYearFMV: number;
    priorYearImpsAV: number;
    priorYearImpsFMV: number;
    priorYearPersAV: number;
    priorYearPersFMV: number;
    priorYearAltAV: number;
    priorYearAltFMV: number;
    priorYearLandAV: number;
    priorYearLandFMV: number;
    propertyType: string;
    responsibleUser: string;
    revisionName: string;
    savings: number;
    siteId: number;
    siteName: string;
    state: string;
    stateId: number;
    targetValue: number;
    taxYear: number;
    team: string;
    topLevelCompanyId: number;
    topLevelCompanyName: string;
    totalDollarSqFt: number;
    totalDollarUnit: number;
    totalLandDollarAcre: number;
    totalLandDollarSqFt: number;
    totalPriorYearDollarSqFt: number;
    totalPriorYearDollarUnit: number;
    totalPriorYearLandDollarAcre: number;
    totalPriorYearLandDollarSqFt: number;
    hasComments: boolean;
    hasAttachments: boolean;
    hasError: boolean;

    constructor(model: Core.AppealRecommendationGridRowInfoModel) {
        this.accountHandler = model.accountHandler;
        this.appealDeadline = model.appealDeadline;
        this.appealLevel = model.appealLevel;
        this.appealRecommendationStatus = model.appealRecommendationStatus;
        this.appealRecommendationStatusId = model.appealRecommendationStatusId;
        this.assesseeName = model.assesseeName;
        this.assessorId = model.assessorId;
        this.assessorName = model.assessorName;
        this.canChangeAppealRecommendationStatus = model.canChangeAppealRecommendationStatus;
        this.canEdit = model.canEdit;
        this.class = model.class;
        this.companyId = model.companyId;
        this.companyName = model.companyName;
        this.createDate = model.createDate;
        this.currentAV = model.currentAV;
        this.currentFMV = model.currentFMV;
        this.currentImpsAV = model.currentImpsAV;
        this.currentImpsFMV = model.currentImpsFMV;
        this.currentPersAV = model.currentPersAV;
        this.currentPersFMV = model.currentPersFMV;
        this.currentAltAV = model.currentAltAV;
        this.currentAltFMV = model.currentAltFMV;
        this.currentLandAV = model.currentLandAV;
        this.currentLandFMV = model.currentLandFMV;
        this.determineIfAppealWarrantedTaskId = model.determineIfAppealWarrantedTaskId;
        this.emailSentDate = model.emailSentDate;
        this.entityId = model.entityId;
        this.entityName = model.entityName;
        this.fmvVariance = model.fmvVariance;
        this.instanceId = model.instanceId;
        this.isClientApproved = model.isClientApproved;
        this.isClientApprovalRequired = model.isClientApprovalRequired;
        this.isReady = model.isReady;
        this.originalAV = model.originalAV;
        this.originalFMV = model.originalFMV;
        this.originalImpsAV = model.originalImpsAV;
        this.originalImpsFMV = model.originalImpsFMV;
        this.originalPersAV = model.originalPersAV;
        this.originalPersFMV = model.originalPersFMV;
        this.originalAltAV = model.originalAltAV;
        this.originalAltFMV = model.originalAltFMV;
        this.originalLandAV = model.originalLandAV;
        this.originalLandFMV = model.originalLandFMV;
        this.parcelAccountNumber = model.parcelAccountNumber;
        this.priorYearAppealId = model.priorYearAppealId;
        this.priorYearAppeal = model.priorYearAppeal;
        this.parcelId = model.parcelId;
        this.priorYearAV = model.priorYearAV;
        this.priorYearFMV = model.priorYearFMV;
        this.priorYearImpsAV = model.priorYearImpsAV;
        this.priorYearImpsFMV = model.priorYearImpsFMV;
        this.priorYearPersAV = model.priorYearPersAV;
        this.priorYearPersFMV = model.priorYearPersFMV;
        this.priorYearAltAV = model.priorYearAltAV;
        this.priorYearAltFMV = model.priorYearAltFMV;
        this.priorYearLandAV = model.priorYearLandAV;
        this.priorYearLandFMV = model.priorYearLandFMV;
        this.propertyType = model.propertyType;
        this.responsibleUser = model.responsibleUser;
        this.revisionName = model.revisionName;
        this.savings = model.savings;
        this.siteId = model.siteId;
        this.siteName = model.siteName;
        this.state = model.state;
        this.stateId = model.stateId;
        this.targetValue = model.targetValue;
        this.taxYear = model.taxYear;
        this.team = model.team;
        this.topLevelCompanyId = model.topLevelCompanyId;
        this.topLevelCompanyName = model.topLevelCompanyName;
        this.totalDollarSqFt = model.totalDollarSqFt;
        this.totalDollarUnit = model.totalDollarUnit;
        this.totalLandDollarAcre = model.totalLandDollarAcre;
        this.totalLandDollarSqFt = model.totalLandDollarSqFt;
        this.totalPriorYearDollarSqFt = model.totalPriorYearDollarSqFt;
        this.totalPriorYearDollarUnit = model.totalPriorYearDollarUnit;
        this.totalPriorYearLandDollarAcre = model.totalPriorYearLandDollarAcre;
        this.totalPriorYearLandDollarSqFt = model.totalPriorYearLandDollarSqFt;
        this.hasComments = model.hasComments;
        this.hasAttachments = model.hasAttachments;
    }

    get level(): number {
        return this.parcelId ? 2 : 1;
    }
}
