import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ContactsUpgradeService } from '../../Contact/contacts.service.upgrade';
import { ProgressIndicatorConfig, ProgressActions, ProgressIndicator } from '../Models/progressIndicator.model';
import { ToastrService } from 'ngx-toastr';
import { of, Observable, lastValueFrom } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DownloadFileService } from '../../Common/Download/downloadFileService';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import {
    ReturnAssetRepository,
    AssetRepository,
    ReturnRepository,
    GLAccountRepository,
    EntityImportRepository,
    FormSetupRepository,
    AssessorCommandCenterRepository,
    FilingBatchRepository,
    ReturnFormRepository,
    LongRunningProcessRepository,
    ReturnReportRepository, AllocationRepository, FormRepository, ElectronicFilingRepository
} from '../../Compliance/Repositories';
import { TaxRateCommandCenterService } from '../../Tax-Rate/Command-Center/tax.rate.command.center.service';
import { ReportProcessIntegrationRepository } from '../../Reporting/ReportProcessIntegrationRepository';
import { AppealApplicationService } from '../../Appeal-Application/appeal.application.service';
import { ForecastingBudgetingService } from '../../Budget/forecasting.budgeting.service';
import { AccrualsPageService } from '../../Accruals/accruals.page.service';
import { AdvancedSearchIntegrationRepository } from '../../Search/AdvancedSearchIntegrationRepository';
import { ActionViewIntegrationRepository } from '../../Task/ActionView/ActionViewIntegrationRepository';
import { AllocationDetailRepository } from '../../Compliance/Repositories/allocationDetail.repository';
import { RentRollRepository } from '../../Valuation/Rent-Roll/rentRoll.repository';
import { IncomeStatementRepository } from '../../Valuation/Income-Statement/incomeStatement.repository';
import { CsrCommandCenterRepository } from '../../Client-Services/Command-Center/csrCommandCenter.repository';
import { FormLibraryRepository } from '../../Compliance/Repositories/formLibraryRepository';
import { AppealRecommendationRepository } from '../../Appeal-Recommendation/appealRecommendation.repository';
import { PaymentBatchRepository } from 'src/app/Core-Repositories/paymentBatch.repository';

type BaseConfig = (p: Compliance.LongRunningProcessModel) => ProgressIndicatorConfig;

export interface ProcessMessages {
    title: string;
    downloaded?: string;
    cancelled?: string;
    complete?: string;
}

@Injectable()
export class LongRunningProcessIndicatorConfigService {
    constructor(
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _downloadFileService: DownloadFileService,
        private readonly _toastsManager: ToastrService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _taxRateCommandCenterService: TaxRateCommandCenterService,
        private readonly _assetRepository: AssetRepository,
        private readonly _returnRepository: ReturnRepository,
        private readonly _glAccountRepository: GLAccountRepository,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _formSetupRepository: FormSetupRepository,
        private readonly _reportProcessIntegrationRepository: ReportProcessIntegrationRepository,
        private readonly _appealApplicationService: AppealApplicationService,
        private readonly _assessorCommandCenterRepository: AssessorCommandCenterRepository,
        private readonly _forecastingBudgetingService: ForecastingBudgetingService,
        private readonly _accrualsPageService: AccrualsPageService,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _returnFormRepository: ReturnFormRepository,
        private readonly _returnReportRepository: ReturnReportRepository,
        private readonly _avancedSearchIntegrationRepository: AdvancedSearchIntegrationRepository,
        private readonly _actionViewIntegrationRepository: ActionViewIntegrationRepository,
        private readonly _allocationDetailRepository: AllocationDetailRepository,
        private readonly _allocationRepository: AllocationRepository,
        private readonly _rentRollRepository: RentRollRepository,
        private readonly _incomeStatementLineRepository: IncomeStatementRepository,
        private readonly _formRepository: FormRepository,
        private readonly _csrCommandCenterRepository: CsrCommandCenterRepository,
        private readonly _formLibraryRepository: FormLibraryRepository,
        private readonly _contactService: ContactsUpgradeService,
        private readonly _electronicFilingRepository: ElectronicFilingRepository,
        private readonly _appealRecommendationRepository: AppealRecommendationRepository,
        private readonly _paymentBatchRepository: PaymentBatchRepository
    ) { }

    get processMap(): Map<Compliance.LongRunningProcessTypeEnum, BaseConfig> {
        return this._processMap;
    }

    private _processMap: Map<Compliance.LongRunningProcessTypeEnum, BaseConfig>;

    init(): void {

        // Important!! If you add a new process using one of the base configs you must also define the settings for your new process
        this._processMap = new Map([
            [Compliance.LongRunningProcessTypeEnum.ImportFile, this._importFileConfig],
            [Compliance.LongRunningProcessTypeEnum.Return, this._baseNavigateCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.GetAppealApplications, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.Synthetic, this._baseNavigateConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportSourceAssets, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportReportingAssets, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportReturnAssets, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportGLAccounts, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.GenerateReturnOutput, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.AddYearToCompanyPortfolio, this._baseNavigateCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportImportStagingRows, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.FormSetupExport, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.FormSetupImport, this._baseNavigateConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportTaxRates, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.BudgetReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.PsrReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.PseReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.SiteReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.MaarReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.UserActivityReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.UserAccessReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.UserCsrReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.AddMissingForecastTaxYear, this._baseNavigateCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.AddTaxYearAndAnnualBudget, this._baseNavigateCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.SyncBudgets, this._baseNavigateCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportAssessorCommandCenterAssessors, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportBudgetGridToExcel, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportAccrualsGridToExcel, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportReturnReconciliation, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.GeocodeSiteAddressesPostImport, null],
            [Compliance.LongRunningProcessTypeEnum.BudgetVarianceReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.ReturnFormDownload, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ReturnReportDownload, this._returnReportDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportReturnFilingControlSummary, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.AssetBulkUpdate, this._baseNavigateConfig],
            [Compliance.LongRunningProcessTypeEnum.SmartSearchExport, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ActionViewExport, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportAllocationDetails, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportAllocations, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.AssetBulkDelete, this._baseNavigateConfig],
            [Compliance.LongRunningProcessTypeEnum.TransmitAccrual, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.RecalculateTaxBillsForTaChange, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.RentRollLineItem, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.IncomeStatementLine, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.IncomeStatementLineBulkDelete, this._baseNavigateConfig],
            [Compliance.LongRunningProcessTypeEnum.RentRollLineItemBulkDelete, this._baseNavigateConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportFormList, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportClientServiceResponsibilityCommandCenter, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.FormLibraryExport, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.FactorTableExportAttachments, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.FormClassificationMappingExport, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.UpdateCsrBulk, this._baseCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.UpdateCsr, this._baseCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.AppealListReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportLRPList, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.UserTeamUpdate, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportAccrualDataToFile, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.SupportInstanceEscalationActivityReport, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.CloseAccrualAccountingPeriod, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.ReopenAccrualAccountingPeriod, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.CopyMoveEntity, this._baseCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.SiteParcelDelete, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.AccrualsGridGet, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportContacts, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.PaymentBatchDetailsPaymentBulkDelete, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportElectronicFiling, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.UpdateTaxAuthority, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.GenerateElectronicFilingOutput, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.ExportAppealRecommendationCommandCenter, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.PaymentBatchExportFeedsAndFiles, this._baseDownloadConfig],
            [Compliance.LongRunningProcessTypeEnum.DeleteTaxAuthorityTaxRateAreaUpdateOtherEntities, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.ReturnPrepareData, this._baseConfig],
            [Compliance.LongRunningProcessTypeEnum.PaymentBatchExportList, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.PaymentBatchDetailsExport, this._baseDownloadWithCancelConfig],
            [Compliance.LongRunningProcessTypeEnum.PaymentBatchCheckImport, this._baseConfig]
        ]);
    }

    /**
     * Base configurations
     * These are generic configurations that utilize settings maps to define their behavior
     * The unique settings for each process must be defined in the settings maps
     */

     private _baseConfig: BaseConfig = (process) => {
        const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);
        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: (process.isCompleted) ? messages.complete : messages.title,
            progressActions: [],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            process: process,
            error: this._commonMethods.error,
            complete: (snack) => this._commonMethods.complete(snack, messages.complete),
            cancelled: this._commonMethods.cancelled(messages.cancelled)
        };
      };

    private _baseDownloadConfig: BaseConfig = (process) => {
        const downloadServiceCall = this._downloadServiceMap.get(process.longRunningProcessTypeId);
        const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);

        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: messages.title,
            progressActions: [ProgressActions.Download],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            process: process,
            error: this._commonMethods.error,
            complete: (snack) => {
                snack.updateMessage(messages.complete);
            },
            download: async (snack) => {
                snack.updateTitle(messages.downloaded);
                const response = await lastValueFrom(downloadServiceCall(snack.process));
                await this._downloadFileService.downloadResponse(response);
            }
        };
    };

    private _baseDownloadWithCancelConfig: BaseConfig = (process) => {
        const downloadServiceCall = this._downloadServiceMap.get(process.longRunningProcessTypeId);
        const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);

        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: messages.title,
            progressActions: [ProgressActions.DownloadWithCancel],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            process: process,
            error: this._commonMethods.error,
            cancel: this._commonMethods.cancel,
            cancelled: this._commonMethods.cancelled(messages.cancelled),
            complete: (snack) => {
                snack.updateMessage(messages.complete);
            },
            download: async (snack) => {
                snack.updateTitle(messages.downloaded);
                const response = await lastValueFrom(downloadServiceCall(snack.process));
                await this._downloadFileService.downloadResponse(response);
            }
        };
    };

    private _baseNavigateConfig: BaseConfig = (process) => {
        const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);

        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: (process.isCompleted) ? messages.complete : messages.title,
            progressActions: [ProgressActions.Navigate],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            process: process,
            error: this._commonMethods.error,
            complete: (snack) => this._commonMethods.complete(snack, messages.complete),
            navigate: (progress: ProgressIndicator) => {
                const routeTo = this._navigateToMap.get(process.longRunningProcessTypeId);
                routeTo(progress.process);
                progress.remove();
            }
        };
    };

    private _baseNavigateCancelConfig: BaseConfig = (process) => {
        const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);

        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: (process.isCompleted) ? messages.complete : messages.title,
            progressActions: [ProgressActions.Navigate, ProgressActions.Cancel],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            process: process,
            error: this._commonMethods.error,
            cancel: this._commonMethods.cancel,
            cancelled: this._commonMethods.cancelled(messages.cancelled),
            complete: (snack) => this._commonMethods.complete(snack, messages.complete),
            navigate: (progress: ProgressIndicator) => {
                const routeTo = this._navigateToMap.get(process.longRunningProcessTypeId);
                routeTo(progress.process);
                progress.remove();
            }
        };
    };

    private _baseCancelConfig: BaseConfig = (process) => {
        const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);

        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: (process.isCompleted) ? messages.complete : messages.title,
            progressActions: [ProgressActions.Cancel],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            process: process,
            error: this._commonMethods.error,
            cancel: this._commonMethods.cancel,
            complete: (snack) => this._commonMethods.complete(snack, messages.complete),
            cancelled: this._commonMethods.cancelled(messages.cancelled)
        };
    };

    /**
     * Base configuration settings
     * These are the process specific settings for the generic base configurations
     */

    private _commonMethods = {
        error: (status) => this._toastsManager.error(status),
        cancel: async (indicatorRef) => {
            await lastValueFrom(this._longRunningProcessRepository.cancel(indicatorRef.longRunningProcessId));
        },
        cancelled: (message: string) => () => this._toastsManager.error(message),
        acknowledge: async (lrpId: number) => {
            await this._longRunningProcessRepository.acknowledge(lrpId);
        },
        complete: (snack: ProgressIndicator, message: string) => {
            if (message) {
                snack.updateTitle(message);
                snack.updateMessage(null);
            }
        },
    };

    private _importOperations = new Map([
        ['Process', { title: 'Importing File', cancelled: 'File import cancelled', complete: 'File import complete' }],
        ['Transfer', { title: 'Transferring', cancelled: 'File transfer cancelled', complete: 'File transfer complete' }],
        ['Revert', { title: 'Reverting Import', cancelled: 'File revert cancelled', complete: 'File revert complete' }],
        ['Validate', { title: 'Validating Import', cancelled: 'File validate cancelled', complete: 'File validate complete' }],
        ['Bulk Update', { title: 'Bulk Updating Import', cancelled: 'Bulk update cancelled', complete: 'Bulk update complete' }],
        ['Update Field', { title: 'Updating Import', cancelled: 'Update cancelled', complete: 'Update complete' }]
    ]);

    private _processMessageMap: Map<Compliance.LongRunningProcessTypeEnum, (process: Compliance.LongRunningProcessModel) => ProcessMessages> = new Map([
        [Compliance.LongRunningProcessTypeEnum.ImportFile, (process) => ({ title: this._importOperations.get(process.operationName).title, cancelled: this._importOperations.get(process.operationName).cancelled, complete: this._importOperations.get(process.operationName).complete } as ProcessMessages)],
        [Compliance.LongRunningProcessTypeEnum.Return, (process) => ({ title: 'Processing', cancelled: 'Return process cancelled', complete: 'Return process complete' })],
        [Compliance.LongRunningProcessTypeEnum.GetAppealApplications, (process) => ({ title: 'Retrieving Appeal Applications', cancelled: 'Retrieving appeal applications cancelled', complete: 'Retrieving appeal applications complete' })],
        [Compliance.LongRunningProcessTypeEnum.Synthetic, (process) => ({ title: 'Testing', cancelled: 'Test process cancelled', complete: 'Test process complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportSourceAssets, (process) => ({ title: 'Exporting Assets', downloaded: 'Downloading', cancelled: 'Asset export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportReportingAssets, (process) => ({ title: 'Exporting Assets', downloaded: 'Downloading', cancelled: 'Asset export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportReturnAssets, (process) => ({ title: 'Exporting Assets', downloaded: 'Downloading', cancelled: 'Asset export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportGLAccounts, (process) => ({ title: 'Exporting G/L Accounts', downloaded: 'Downloading', cancelled: 'Exporting G/L Accounts cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.GenerateReturnOutput, (process) => ({ title: 'Return Output', downloaded: 'Downloading', cancelled: 'Return output download cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.AddYearToCompanyPortfolio, (process) => ({ title: 'Adding Year', cancelled: 'Adding year cancelled', complete: 'Adding year complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportImportStagingRows, (process) => ({ title: 'Exporting Rows', downloaded: 'Downloading', cancelled: 'Exporting rows cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.FormSetupExport, (process) => ({ title: 'Exporting Forms Setup', downloaded: 'Downloading', cancelled: 'Exporting form setup cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.FormSetupImport, (process) => ({ title: 'Importing Forms Setup', cancelled: 'Importing form setup cancelled', complete: 'Importing forms setup complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportTaxRates, (process) => ({ title: 'Exporting Tax Rates', downloaded: 'Downloading', cancelled: 'Exporting tax rates cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.BudgetReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.PsrReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.PseReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.SiteReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.MaarReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.UserActivityReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.UserAccessReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.UserCsrReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.AddMissingForecastTaxYear, (process) => ({ title: 'Creating Tax Years', cancelled: 'Add forecast tax year cancelled', complete: 'Creating tax years complete' })],
        [Compliance.LongRunningProcessTypeEnum.AddTaxYearAndAnnualBudget, (process) => ({ title: 'Creating Tax Years/Budgets', cancelled: 'Add tax year and annual budget cancelled', complete: 'Creating tax years/budgets complete' })],
        [Compliance.LongRunningProcessTypeEnum.SyncBudgets, (process) => ({ title: 'Syncing Parcel Budgets', cancelled: 'Syncing parcel budgets cancelled', complete: 'Syncing parcel budgets complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportAssessorCommandCenterAssessors, (process) => ({ title: 'Exporting Rows', downloaded: 'Downloading', cancelled: 'State & Jurisdiction export cancelled' })],
        [Compliance.LongRunningProcessTypeEnum.ExportBudgetGridToExcel, (process) => ({ title: 'Exporting Rows', downloaded: 'Downloading', cancelled: 'Forecasting Budgeting export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportAccrualsGridToExcel, (process) => ({ title: 'Exporting Rows', downloaded: 'Downloading', cancelled: 'Accruals export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportReturnReconciliation, (process) => ({ title: 'Exporting Report', downloaded: 'Downloading', cancelled: 'Reconciliation Report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.GeocodeSiteAddressesPostImport, (process) => ({ title: 'Geocoding Site Addresses Post-Import', cancelled: 'Geocoding Site Addresses cancelled', complete: 'Geocoding Site Addresses Complete' })],
        [Compliance.LongRunningProcessTypeEnum.BudgetVarianceReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ReturnFormDownload, (process) => ({ title: 'Return Form Download', downloaded: 'Downloading', cancelled: 'Return form download cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportReturnFilingControlSummary, (process) => ({ title: 'Generating Filing Control for Tracker report', downloaded: 'Downloading', cancelled: 'Generating Filing Control for Tracker report cancelled' })],
        [Compliance.LongRunningProcessTypeEnum.AssetBulkUpdate, (process) => ({ title: 'Updating assets', cancelled: 'Updating assets cancelled', complete: 'Updating assets complete' })],
        [Compliance.LongRunningProcessTypeEnum.SmartSearchExport, (process) => ({ title: 'Exporting search results', downloaded: 'Downloading', cancelled: 'Search results export cancelled', complete: 'Export complete' })],
        [Compliance.LongRunningProcessTypeEnum.ActionViewExport, (process) => ({ title: 'Exporting search results', downloaded: 'Downloading', cancelled: 'Search results export cancelled', complete: 'Export complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportAllocationDetails, (process) => ({ title: 'Exporting Allocation Details', downloaded: 'Downloading', cancelled: 'Allocation Details export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportAllocations, (process) => ({ title: 'Exporting Allocations', downloaded: 'Downloading', cancelled: 'Allocations export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.AssetBulkDelete, (process) => ({ title: 'Deleting assets', cancelled: 'Deleting assets cancelled', complete: 'Deleting assets complete' })],
        [Compliance.LongRunningProcessTypeEnum.TransmitAccrual, (process) => ({title: 'Transmitting Accrual Information', complete: 'Accrual transmission complete'})],
        [Compliance.LongRunningProcessTypeEnum.RecalculateTaxBillsForTaChange, (process) => ({title: 'Recalculate Tax Bills', complete: 'Recalculation complete'})],
        [Compliance.LongRunningProcessTypeEnum.RentRollLineItem, (process) => ({title: 'Exporting Rent Roll', complete: 'Export complete'})],
        [Compliance.LongRunningProcessTypeEnum.IncomeStatementLine, (process) => ({title: 'Exporting Income Statement', complete: 'Export complete'})],
        [Compliance.LongRunningProcessTypeEnum.IncomeStatementLineBulkDelete, (process) => ({title: 'Delete Income Statement', complete: 'Deleting Income Statement complete'})],
        [Compliance.LongRunningProcessTypeEnum.RentRollLineItemBulkDelete, (process) => ({title: 'Delete Rent Roll Line Item', complete: 'Deleting Rent Roll Line Item complete'})],
        [Compliance.LongRunningProcessTypeEnum.ExportFormList, (process) => ({ title: 'Exporting Form List', downloaded: 'Downloading', cancelled: 'Form list export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportClientServiceResponsibilityCommandCenter, (process) => ({title: 'Exporting CSRs', complete: 'Export complete'})],
        [Compliance.LongRunningProcessTypeEnum.FormLibraryExport, (process) => ({ title: 'Exporting Form Library', downloaded: 'Downloading', cancelled: 'Form library export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.FactorTableExportAttachments, (process) => ({ title: 'Exporting Factor Tables Attachments', downloaded: 'Downloading', cancelled: 'Factor Tables Attachments export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.FormClassificationMappingExport, (process) => ({ title: 'Exporting Asset Class Mappings', downloaded: 'Downloading', cancelled: 'Asset Class Mappings export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.UpdateCsrBulk, (process) => ({title: 'Updating CSRs', complete: 'CSRs updated', cancelled: 'CSR Updates cancelled'})],
        [Compliance.LongRunningProcessTypeEnum.UpdateCsr, (process) => ({title: 'Updating CSR', complete: 'CSR updated', cancelled: 'CSR Update cancelled'})],
        [Compliance.LongRunningProcessTypeEnum.AppealListReport, (process) => ({ title: 'Retrieving Appeal List Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportLRPList, (process) => ({ title: 'Exporting Long Running Process List', downloaded: 'Downloading', cancelled: 'Long Running Process List export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.UserTeamUpdate, (process) => ({title: 'User Team Changes', complete: 'User Team Changes complete'})],
        [Compliance.LongRunningProcessTypeEnum.ExportAccrualDataToFile, (process) => ({ title: 'Exporting Accrual Data to File', complete: 'Export Complete'})],
        [Compliance.LongRunningProcessTypeEnum.SupportInstanceEscalationActivityReport, (process) => ({ title: 'Retrieving Report', downloaded: 'Downloading', cancelled: 'Generating report cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.CloseAccrualAccountingPeriod, (process) => ({ title: 'Closing Accrual Accounting Period', complete: 'Close Complete'})],
        [Compliance.LongRunningProcessTypeEnum.ReopenAccrualAccountingPeriod, (process) => ({ title: 'Re-opening Accrual Accounting Period', complete: 'Re-open Complete'})],
        [Compliance.LongRunningProcessTypeEnum.CopyMoveEntity, (process) => ({title: 'Copy/Move Site/Parcel', complete: 'Site/Parcel copied/moved', cancelled: 'Copy/Move Site/Parcel cancelled'})],
        [Compliance.LongRunningProcessTypeEnum.SiteParcelDelete, (process) => ({title: 'Deleting Site/Parcel', complete: 'Site/Parcel deleted'})],
        [Compliance.LongRunningProcessTypeEnum.AccrualsGridGet, (process) => ({ title: 'Retrieving Accruals Grid', complete: 'Accruals Grid Retrieved'})],
        [Compliance.LongRunningProcessTypeEnum.ExportContacts, (process) => ({ title: 'Exporting Contacts', downloaded: 'Downloading', cancelled: 'Export cancelled', complete: 'Export Complete'})],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchDetailsPaymentBulkDelete, (process) => ({ title: 'Removing Batch Payments', complete: 'Removing Batch Payments Complete'})],
        [Compliance.LongRunningProcessTypeEnum.ExportElectronicFiling, (process) => ({ title: 'Exporting Electronic Filings', downloaded: 'Downloading', cancelled: 'Export cancelled', complete: 'Export Complete'})],
        [Compliance.LongRunningProcessTypeEnum.UpdateTaxAuthority, (process) => ({ title: 'Update Tax Authority', complete: 'Tax Authority Updated' })],
        [Compliance.LongRunningProcessTypeEnum.GenerateElectronicFilingOutput, (process) => ({ title: 'Electronic Filing Output', downloaded: 'Downloading', cancelled: 'Electronic filing output download cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.ExportAppealRecommendationCommandCenter, (process) => ({ title: 'Exporting', downloaded: 'Downloading', cancelled: 'Export cancelled', complete: 'Export Complete'})],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchExportFeedsAndFiles, (process) => ({ title: 'Downloading Payment Batch Feeds and Files', complete: 'Downloaded Payment Batch Feeds and Files'})],
        [Compliance.LongRunningProcessTypeEnum.DeleteTaxAuthorityTaxRateAreaUpdateOtherEntities, (process) => ({ title: 'Deleting Tax Authority / Tax Rate Area ', complete: 'Deleting Tax Authority / Tax Rate Area Complete'})],
        [Compliance.LongRunningProcessTypeEnum.ReturnPrepareData, (process) => ({ title: 'Preparing Filing Batch Data', complete: 'Filing Batch Is Ready'})],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchExportList, (process) => ({ title: 'Exporting Payment Batches', downloaded: 'Downloading', cancelled: 'Payment Batch export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchDetailsExport, (process) => ({ title: 'Exporting Payment Batch', downloaded: 'Downloading', cancelled: 'Payment Batch export cancelled', complete: 'Complete' })],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchCheckImport, (process) => ({ title: 'Importing Checks to Payment Batch', complete: 'Importing Checks to Payment Batch Complete'})],

    ]);

    private _downloadServiceMap: Map<Compliance.LongRunningProcessTypeEnum, (process: Compliance.LongRunningProcessModel) => Observable<HttpResponse<Blob>>> = new Map([
        [Compliance.LongRunningProcessTypeEnum.ExportSourceAssets, process => {
            const params: Compliance.AssetExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._assetRepository.getExport(params.companyId, process.longRunningProcessId);
        }],
        [Compliance.LongRunningProcessTypeEnum.ExportReportingAssets, process => {
            const params: Compliance.AssetExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._assetRepository.getExport(params.companyId, process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.ExportReturnAssets, process => this._returnAssetRepository.getReturnAssetsExport(process.entityId, process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportGLAccounts, process => {
            const params: Compliance.GLAccountExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._glAccountRepository.getExport(params.companyId, process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.GenerateReturnOutput, process => this._returnRepository.getDownloadOutput(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportImportStagingRows, process => this._entityImportRepository.getExport(process.entityId, process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.FormSetupExport, process => this._formSetupRepository.getExport(process.longRunningProcessId)],

        [Compliance.LongRunningProcessTypeEnum.SmartSearchExport, process => this._avancedSearchIntegrationRepository.downloadSearchResults(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ActionViewExport, process => this._actionViewIntegrationRepository.downloadSearchResults(process.longRunningProcessId)],

        [Compliance.LongRunningProcessTypeEnum.ExportTaxRates, process => this._taxRateCommandCenterService.getTaxRateDataExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.BudgetReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.PsrReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.PseReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.SiteReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.MaarReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.UserActivityReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.UserAccessReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.UserCsrReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.GetAppealApplications, process => this._appealApplicationService.getOutputFile(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportAssessorCommandCenterAssessors, process => this._assessorCommandCenterRepository.getExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.RentRollLineItem, process => {
            const params: Compliance.RentRollLineItemExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._rentRollRepository.getExport(params.companyId, process.longRunningProcessId);
        }],
        [Compliance.LongRunningProcessTypeEnum.ExportClientServiceResponsibilityCommandCenter, process => {
            const params: Core.ClientServiceResponsibilityCommandCenterExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._csrCommandCenterRepository.getExport(process.entityId, process.longRunningProcessId);
        }],
        [Compliance.LongRunningProcessTypeEnum.IncomeStatementLine, process => {
            const params: Compliance.IncomeStatementLineExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._incomeStatementLineRepository.getExport(params.companyId, process.longRunningProcessId);
        }],
        [Compliance.LongRunningProcessTypeEnum.ExportBudgetGridToExcel, process => this._forecastingBudgetingService.getExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportAccrualsGridToExcel, process => this._accrualsPageService.getExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportReturnReconciliation, process => {
            const params: Compliance.ImportFileProcessParametersModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._returnRepository.getReconciliationReport(params.companyId, process.longRunningProcessId);
        }],
        [Compliance.LongRunningProcessTypeEnum.BudgetVarianceReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ReturnFormDownload, process => this._returnFormRepository.getDownloadForm(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportReturnFilingControlSummary, process => {
            const params: Compliance.ExportReturnFilingControlSummaryLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._returnRepository.getExportedReturnFilingControlSummary(params.filingBatchId, process.longRunningProcessId);
        }],
        [Compliance.LongRunningProcessTypeEnum.ExportAllocationDetails, process => {
            const params: Compliance.AllocationDetailExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }

            return this._allocationDetailRepository.getExport(params.allocationId, process.longRunningProcessId);
            }
        ],
        [Compliance.LongRunningProcessTypeEnum.ExportAllocations, process => {
            const params: Compliance.AllocationExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }

            return this._allocationRepository.getExport(params.companyId, process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.ExportFormList, process => {
            const params: Compliance.ExportFormListLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._formRepository.getExportFormList(process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.FormLibraryExport, process => {
            const params: Compliance.FormLibraryExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._formLibraryRepository.getExport(process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.FactorTableExportAttachments, process => {
            const params: Compliance.FactorTableExportAttachmentLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._formLibraryRepository.getExport(process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.FormClassificationMappingExport, process => {
            const params: Compliance.FormRevisionForLibraryModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._formRepository.getFormClassificationMappingsExport(process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.AppealListReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportLRPList, process => {
            const params: Compliance.ExportLongRunningProcessModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            return this._longRunningProcessRepository.getExport(process.longRunningProcessId);
        }
        ],
        [Compliance.LongRunningProcessTypeEnum.SupportInstanceEscalationActivityReport, process => this._reportProcessIntegrationRepository.downloadReport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportContacts, process => this._contactService.getExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportElectronicFiling, process => this._electronicFilingRepository.getExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.GenerateElectronicFilingOutput, process => this._electronicFilingRepository.getElectronicFilingOutput(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.ExportAppealRecommendationCommandCenter, process => this._appealRecommendationRepository.getExport(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchExportFeedsAndFiles, process => this._paymentBatchRepository.getFilesAndFeeds(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchExportList, process => this._paymentBatchRepository.getFilesAndFeeds(process.longRunningProcessId)],
        [Compliance.LongRunningProcessTypeEnum.PaymentBatchDetailsExport, process => this._paymentBatchRepository.getFilesAndFeeds(process.longRunningProcessId)],
    ]);

    private _navigateToMap: Map<Compliance.LongRunningProcessTypeEnum, (process: Compliance.LongRunningProcessModel) => void> = new Map([
        [Compliance.LongRunningProcessTypeEnum.ImportFile, process => {
            const params: Compliance.ImportFileProcessParametersModel = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            if (params && params.companyId) {
                this._routerService.go('processCompanyImport',
                    { companyId: params.companyId, importId: params.importFileId });
            } else {
                this._routerService.go('processSystemImport', { importId: params.importFileId });
            }
        }],
        [Compliance.LongRunningProcessTypeEnum.Return, process => {
            this._routerService.go('returnFilingBatchPage', { filingBatchId: process.entityId });
        }],
        [Compliance.LongRunningProcessTypeEnum.Synthetic, process => this._routerService.go('longRunningProcess', {})],
        [Compliance.LongRunningProcessTypeEnum.AddYearToCompanyPortfolio, process => {
            const params: Core.CompanyAddYearDTO = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            this._routerService.go('company', { companyId: params.companyId });
        }],
        [Compliance.LongRunningProcessTypeEnum.FormSetupImport, process => this._routerService.go('formList', {})],
        [Compliance.LongRunningProcessTypeEnum.AddMissingForecastTaxYear, process => {
            const params: Core.ForecastParcelMissingYearsDTO = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            this._routerService.go('forecastingBudgeting', { companyId: params.topLevelCompanyId });
        }],
        [Compliance.LongRunningProcessTypeEnum.AddTaxYearAndAnnualBudget, process => {
            const params: Core.BudgetMissingTaxYearAndAnnualBudgetRequest = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            this._routerService.go('forecastingBudgeting', { companyId: params.topLevelCompanyId });
        }],
        [Compliance.LongRunningProcessTypeEnum.SyncBudgets, process => {
            const params: Core.BudgetMissingTaxYearAndAnnualBudgetRequest = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            this._routerService.go('forecastingBudgeting', { companyId: params.topLevelCompanyId });
        }],
        [Compliance.LongRunningProcessTypeEnum.AssetBulkUpdate, process => {
            const params: { companyId: number, model: Compliance.ReportingAssetBulkUpdateModel } = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            this._routerService.go('assetList', { companyId: params.companyId });
        }],
        [Compliance.LongRunningProcessTypeEnum.AssetBulkUpdate, process => {
            const params: { companyId: number, model: Compliance.ReportingAssetBulkUpdateModel } = JSON.parse(process.parameters);
            if (!params) {
                return;
            }
            this._routerService.go('assetList', { companyId: params.companyId });
        }]
    ]);

    /**
     * Custom configurations
     * For unique cases a custom configuration can be provided
     */

    private _importFileConfig: BaseConfig = (process) => {
        if (process.operationName.includes('Validate')) {
            const messages = this._processMessageMap.get(process.longRunningProcessTypeId)(process);
            return {
                identifier: `LRP_${process.longRunningProcessId}`,
                longRunningProcessId: process.longRunningProcessId,
                title: (process.isCompleted) ? messages.complete : messages.title,
                progressActions: [],
                isComplete: process.isCompleted,
                progressFailure: !!process.error,
                isHidden: true,
                process: process,
                error: this._commonMethods.error,
                complete: (snack) => this._commonMethods.complete(snack, messages.complete)
            };
        } else {
            return this._baseNavigateCancelConfig(process);
        }
    };

    private _returnReportDownloadConfig: BaseConfig = (process) => {
        return {
            identifier: `LRP_${process.longRunningProcessId}`,
            longRunningProcessId: process.longRunningProcessId,
            title: 'Return Report Download',
            progressActions: [ProgressActions.DownloadWithCancel],
            isComplete: process.isCompleted,
            progressFailure: !!process.error,
            error: this._commonMethods.error,
            cancel: this._commonMethods.cancel,
            cancelled: this._commonMethods.cancelled('Return report download cancelled'),
            process,
            download: async (snack) => {
                const response = await lastValueFrom(this._returnReportRepository.getDownloadReport(process.longRunningProcessId));
                if (response.status === 204) {
                    const msg = 'The report contains no data';
                    snack.updateTitle(msg);
                    snack.setError(msg);
                    await lastValueFrom(of(null).pipe(delay(2000)));
                    return;
                }
                snack.updateTitle('Downloaded');
                await this._downloadFileService.downloadResponse(response);
            }
        };
    };
}
