import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BillViewModel } from '../../../../Annual-Details/Taxes/bill-cluster.service';
import { Bill } from '../../../../Annual-Details/Taxes/bill.model';
import { WeissmanModalService } from '../../../../Compliance/WeissmanModalService';
import { IdpDetailModalComponent, IdpDetailModalParams } from './Detail-Modal/idpDetailModal.component';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'idp-confidence-panel',
    templateUrl: './idpConfidencePanel.component.html',
    styleUrls: ['./idpConfidencePanel.component.scss']
})
export class IdpConfidencePanelComponent {
    constructor(
        private readonly _weissmanModalService: WeissmanModalService,
		@Inject('$scope') private _scope: any
    ) {
        _scope.$on('changeProcessingDocument', () => {
            this.canSeeDetails = true;
        });
    }

    @Input() currentDocument: Core.IntakeItemInfo;
    @Input() billDetail: BillViewModel;

    @Output() idpAccepted: EventEmitter<Bill[]> = new EventEmitter();

    canSeeDetails = true;

    private _originalBillDetail: Bill[];

    get confidenceLevel(): 'Low' | 'Medium' | 'High' {
        if(this.currentDocument.idpConfidence) {
            return this.currentDocument.idpConfidence <= 0.3 ? 'Low'
                : this.currentDocument.idpConfidence <= 0.3 ? 'Medium'
                : 'High';
        } else {
            return 'Low';
        }
    }

    async seeDetails(): Promise<void> {
        const params: IdpDetailModalParams = {
            confidence: this.confidenceLevel,
            idpResults: this.currentDocument.ocrResults,
            billDetail: cloneDeep(this.billDetail)
        };

        const modalOptions: ModalOptions = {
            class: 'modal-lg',
            initialState: { params },
            backdrop: false,
            ignoreBackdropClick: true,
            keyboard: false
        };

        const updatedBillModel = await this._weissmanModalService.showAsync(IdpDetailModalComponent, params, 'modal-lg', modalOptions);

        if (updatedBillModel) {
            this._originalBillDetail = structuredClone(this.billDetail.model);
            this.canSeeDetails = false;
            this.idpAccepted.emit(updatedBillModel);
        }
    }

    reset(): void {
        this.idpAccepted.emit(this._originalBillDetail);
        this.canSeeDetails = true;
    }
}
