import {
    Component,
    Input,
    Output,
    EventEmitter,
    forwardRef,
    OnChanges,
    SimpleChanges,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import { StateSummary } from '../state.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StateService } from '../States.Service';

@Component({
    selector: 'state-dropdown',
    template: `
        <select *ngIf="!readOnly && !multiple && useHtmlSelect"
                [ngClass]="styleClass"
                [(ngModel)]="selectedState"
                (ngModelChange)="onStateChange($event)"
                class="form-select form-select-sm">
            <option *ngIf="includeBlank" [ngValue]="deselectValue">{{ placeholder }}</option>
            <option *ngFor="let state of states" [ngValue]="state">
                {{ state.name }}
            </option>
        </select>
        <ws-select *ngIf="!readOnly && !multiple && !useHtmlSelect"
                   [disabled]="disabled"
                   [(ngModel)]="selectedState"
                   (selectedOptionChanged)="onStateChange($event)"
                   [options]="states"
                   labelProperty="name"
                   [placeholder]="placeholder"
                   [returnEntireOption]="true"
                   searchType="state"
                   [canSearch]="!abbrOnly || canSearch"
                   [canDeselect]="includeBlank"
                   [deselectValue]="deselectValue">
        </ws-select>
        <span *ngIf="readOnly && !multiple" >{{ selectedState?.name }}</span>
        <ws-multi-select *ngIf="!readOnly && multiple"
                         [disabled]="disabled"
                         [(ngModel)]="selectedStates"
                         (selectedOptionsChanged)="onSelectedStatesChange($event)"
                         [options]="states"
                         labelProperty="name"
                         [placeholder]="placeholder"
                         [objectIsValue]="true"
                         searchType="state"
                         [canSearch]="canSearch"
                         [hasDeselectAll]="hasDeselectAll"
                         [hideSelectAll]="!hasSelectAll">
        </ws-multi-select>
        <span *ngIf="readOnly && multiple" >{{ selectedStatesSummary }}</span>
     `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StateDropdownComponent),
            multi: true
        }
    ]
})
export class StateDropdownComponent implements ControlValueAccessor, OnInit, OnChanges {
    constructor(private readonly _stateService: StateService,
                private readonly _cdRef: ChangeDetectorRef) { }

    @Input() stateId: number;
    @Input() stateIds: number[];
    @Input() readOnly: boolean;
    @Input() isSmall: boolean;
    @Input() disabled: boolean;
    @Input() stateAbbr: string;
    @Input() includeAll: boolean;
    @Input() includeBlank: boolean;
    @Input() includePlaceholder: boolean;
    @Input() abbrOnly: boolean = true;
    @Input() filterByCountryId: number;
    @Input() canSearch: boolean;
    @Input() useHtmlSelect: boolean;
    @Input() styleClass: string;
    @Input() deselectValue: string; //Selecting no state provides this undefined value

    // multiple
    @Input() multiple: boolean;
    @Input() hasSelectAll: boolean;
    @Input() hasDeselectAll: boolean;

    @Output() stateIdChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() stateIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() stateAbbrChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() stateAbbrsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    states: Compliance.NameValuePair<StateSummary>[] = [];
    selectedState: Compliance.NameValuePair<StateSummary>;
    selectedStates: Compliance.NameValuePair<StateSummary>[];
    placeholder: string = '';

    private _loadingStates: boolean = false;

    get selectedStatesSummary(): string {
        return this.selectedStates.map(x => x.name).join(', ');
    }

    onChange: (val: number | number[]) => void = () => {};
    onTouched: () => void = () => {};

    async ngOnInit(): Promise<void> {
        this.placeholder = (this.includePlaceholder) ? 'Select State' : '';

        if(!this.states.length) {
            await this._getStates();
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if(!this.states.length) {
            await this._getStates();
        }

        if(changes.stateId?.currentValue && changes.stateId?.currentValue !== changes.stateId?.previousValue) {
            this.selectedState = this.states.find(x => x.value.stateID === this.stateId);
            this._cdRef.detectChanges();
        } else if (changes.stateAbbr?.currentValue && changes.stateAbbr?.currentValue !== changes.stateAbbr?.previousValue) {
            this.selectedState = this.states.find(x => x.name === this.stateAbbr);
            this._cdRef.detectChanges();
        }
    }

    writeValue(value: number | number[]): void {
        if (value) {
            if (Array.isArray(value)) {
                this.stateIds = value;
                this.selectedStates = this.states.filter(x => value.includes(x.value.stateID));
            } else {
                this.stateId = value;
                this.selectedState = this.states.find(x => x.value.stateID === this.stateId);
            }
        }
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    next(): void {
        if (this.multiple) {
            const selectedIds = this.selectedStates.map(x => x.value.stateID);
            this.onChange(selectedIds);
            this.stateIdsChange.emit(selectedIds);
            this.stateAbbrsChange.emit(this.selectedStates.map(x => x.value.abbr));
        } else {
            this.onChange(this.selectedState?.value.stateID);
            this.stateIdChange.emit(this.selectedState?.value.stateID);
            this.stateAbbrChange.emit(this.selectedState?.value.abbr);
        }
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onStateChange(selection: Compliance.NameValuePair<StateSummary>) {
        this.selectedState = selection;
        this.next();
    }

    onSelectedStatesChange(selections: Compliance.NameValuePair<StateSummary>[]) {
        this.selectedStates = selections;
        this.next();
    }

    private async _getStates(): Promise<void> {
        if (this._loadingStates) {
            return;
        }
        this._loadingStates = true;

        const states: StateSummary[] = await this._stateService.getSummary();

        this.states = states.map((s) => {
            return {
                name: (this.abbrOnly) ? `${s.abbr}` : `${s.fullName} (${s.abbr})`,
                value: s
            };
        });

        if (this.includeAll) {
            this.states.unshift({ name: 'All', value: { stateID: null } as StateSummary });
        }
        if(this.filterByCountryId) {
            this.states = this.states.filter(x => x.value.countryID === +this.filterByCountryId);
        }
        if (this.stateId) {
            this.selectedState = this.states.find(x => x.value.stateID === this.stateId);
        }
        this._loadingStates = false;
    }
}
