import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchOperatorsService } from '../../../Search/searchOperators.service';
import { ProductAnalyticsService } from '../../Amplitude/productAnalytics.service';

@Component({
    selector: 'ws-column-filters',
    templateUrl: './columnFilters.component.html'
})
export class ColumnFiltersComponent {
    constructor(private readonly _searchOperators: SearchOperatorsService,
                private readonly _productAnalyticsService: ProductAnalyticsService) {}

    @Input() columnFilters: any[] = [];
    @Input() showOrs: boolean;
    @Input() hideDelete: boolean;
    @Input() onlyFrequentlyUsedCompanies: boolean;
    @Input() activeOnly: boolean;

    @Output() emptyFilter: EventEmitter<void> = new EventEmitter();
    @Output() updateColumnFilters: EventEmitter<any[]> = new EventEmitter();

    isDatePickerOpen: any = {};

    readonly COMPANY_NAME_TOP_LEVEL_ID = 109;
    private readonly COMPANY_NAME_ID = 101;

    private _filtersUsed: string[] = [];

    removeOr(filter: any, or: any): void {
        const index = filter.or.indexOf(or);
        filter.or.splice(index, 1);

        if (!filter.or.length) {
            this.columnFilters = this.columnFilters.filter((columnFilter) => columnFilter.or && columnFilter.or.length);
            this.updateColumnFilters.emit(this.columnFilters);
            this.emptyFilter.emit();
        }
    }

    addOr(filter: any): void {
        filter.or.push(this._searchOperators.getOr(filter.operators));
        this.updateColumnFilters.emit(this.columnFilters);
    }

    updateOrValue(value: any, or: any): void {
        or.value = value;
        this.updateColumnFilters.emit(this.columnFilters);
        const filtersUsed = this.columnFilters.filter(x => x.or.some(y => y.value)).map(x => x.resultColumnName || x.resultsColumnName || x.displayName);
        if (this._filtersUsed.sort().join() !== filtersUsed.sort().join()) {
            this._productAnalyticsService.logEvent('enter-action-view-filters', { actionViewFilter: filtersUsed });
            this._filtersUsed = filtersUsed;
        }
    }

    maybeHideValue(operatorName: string): string {
        if (operatorName === 'blank' || operatorName === 'notBlank' || operatorName === 'isNA') {
            return 'invisible';
        }
    }

    hideRow(filter: any): string {
        const filterId = filter.columnId || filter.id || filter.advancedSearchFieldID;

        if (filterId === 9002 || filterId === 1430) {
            return 'none';
        } else {
            return 'table-row';
        }
    }

    changeOperator(filter: any, or: any, oldOrName: string): void {
        // Reset the filter value when the selector type changes
        if (this.selectorType(filter, or.operator.name) !== this.selectorType(filter, oldOrName)) {
            or.value = null;
        }
        this.updateColumnFilters.emit(this.columnFilters);
    }

    selectorType(filter: any, orName: string) {
        if(!filter.type) {
            if(filter.advancedSearchDataType) {
                filter.type = filter.advancedSearchDataType.name;
            }
        }

        const dateFilters = ['datetime2', 'Date', 'DateTime', 'DateText', 'UTCDateTime', 'UTCDate'];
        if (orName === 'withinDate') {
            return 'withinDate';
        }
        else if (dateFilters.indexOf(filter.type) > -1) {
            return 'date';
        }
        else if ((filter.columnId === this.COMPANY_NAME_ID ||
                filter.columnId === this.COMPANY_NAME_TOP_LEVEL_ID ||
                filter.advancedSearchFieldID === this.COMPANY_NAME_ID ||
                filter.advancedSearchFieldID === this.COMPANY_NAME_TOP_LEVEL_ID) &&
            (orName === 'equal' || orName === 'notEqual')) {
            return 'company';
        }
        else if (filter.type === 'YesNo') {
            return 'yesNo';
        }
        else {
            return 'input';
        }
    }

    datePickerOpened(selectedOr: any): void {
        this.columnFilters.forEach(filter => {
            filter.or.forEach(or => {
                if (or.$$hashKey !== selectedOr.$$hashKey) {
                    this.isDatePickerOpen[or.$$hashKey] = false;
                }
            });
        });
    }

    columnFiltersTrackBy(item: any, i: number): number {
        return i;
    }
}
