import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GridApi, GridReadyEvent, ColDef } from 'ag-grid-community';
import { YearRateSummary, TaxAuthorityDetail, TaxRateArea, TaxAuthority, TaxRateAreaDetail, TaxRateAreaTaxAuthorityDetail } from '../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { RestrictService, Roles } from '../Common/Permissions/restrict.service';
import { AgGridColumns } from '../Compliance/AgGrid';
import { TaxRatesPanelTaxAuthoritiesCellRendererComponent, ICellRendererParamsForTaxRatesPanelTaxAuthorities } from '../Assessor-Collector/Tax-Rates/agGridTaxAuthoritiesCellRenderer.component';
import { TaxAuthorityStatuses } from '../constants.new';
import { TaxAuthorityComponent } from './Tax-Authority/tax.authority.component';
import { TaxRateAreaComponent } from './Tax-Rate-Area/tax.rate.area.component';
import { TaxRateGridService } from './tax.rate.grid.service';
import { TaxRateQcByCellRendererComponent, ICellRendererParamsForTaxRateQcBy } from './agGridQcByCellRenderer.component';

export type GridSource = 'authorities' | 'areas';
type YearObject = YearRateSummary | TaxAuthorityDetail;

export abstract class TaxRateGridComponent {
    hasTaxRatePermission: boolean = false;
    protected isInitialized: boolean = true;

    selectedGridSourceOption: GridSource = 'authorities';

    protected constructor(
        private taxRateGridService: TaxRateGridService,
        protected _restrictService: RestrictService,
        private _modalService: BsModalService) {
            this.hasTaxRatePermission = _restrictService.isInRole(Roles.TAXRATESETUP);
    }

    protected gridApi: GridApi;

    showTaxYearChanged() {
        this.gridApi.setColumnDefs(this.getGridColumns());
        this.gridApi.sizeColumnsToFit();
    }

    protected getGridColumns(): ColDef[] {
        let cols: ColDef[] = this.getSharedColumns();

        if(this.selectedGridSourceOption == 'areas') {
            cols = [...cols, ...this.getAreaColumns()];
        } else {
            cols = [...cols, ...this.getAuthorityColumns()];
        }

        cols = _.chain(cols)
            .sortBy('sequence')
            .map(col => _.omit(col, 'sequence'))
            .value();

        const taxYears: number[] = _.range(this.taxRateGridService.taxYearBegin, this.taxRateGridService.taxYearBegin - this.taxRateGridService.taxYearsPrior - 1);

        cols = _.reduce(taxYears, (columns: ColDef[], taxYear: number, i: number) => {
            columns = [...columns, this._getRateColumn(taxYear)];

            if(i < taxYears.length - 1) {
                columns.push(this._getChangeColumn(taxYear));
            }

            return columns;
        }, cols);

        return cols;
    }

    private _isValidNumber(value: string | number): boolean {
        if (value === null || value === undefined || value === '' || value === '.')
            return false;

        return true;
    }

    private _getRateColumn(year: number): ColDef {
        if(this.selectedGridSourceOption == 'areas') {
            return {
                headerName: `${year} Rate`,
                headerClass: 'text-align-right',
                field: 'rate',
                // filter: 'agNumberColumnFilter',
                // filterParams: AgGridFilterParams.numberFilterParams,
                // floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                sortable: false,
                width: AgGridColumns.numericColumnWidth,
                suppressToolPanel: true,
                cellRendererFramework: TaxRatesPanelTaxAuthoritiesCellRendererComponent,
                cellRendererParams: {
                    rate: this._getTaxAreaRateByYear.bind(this),
                    taxRateAuthorities: this._getAuthoritiesByTaxRateArea.bind(this),
                    year: year
                } as ICellRendererParamsForTaxRatesPanelTaxAuthorities,
                cellClass: params => this._getCellClass(params, year)
            };
        } else {
            return {
                headerName: `${year} Rate`,
                headerClass: 'text-align-right',
                field: 'rate',
                // filter: 'agNumberColumnFilter',
                // filterParams: AgGridFilterParams.numberFilterParams,
                // floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                sortable: false,
                width: AgGridColumns.numericColumnWidth,
                suppressToolPanel: true,
                cellRendererFramework: TaxRateQcByCellRendererComponent,
                cellRendererParams: {
                    rate: this._getTaxAuthorityRateByYear.bind(this),
                    year: year
                } as ICellRendererParamsForTaxRateQcBy,
                cellClass: params => this._getCellClass(params, year)
            };
        }
    }

    private _getChangeColumn(year: number): ColDef {
        return  {
            headerName: '% Change',
            headerClass: 'text-align-right',
            field: 'change',
            // filter: 'agNumberColumnFilter',
            // filterParams: AgGridFilterParams.numberFilterParams,
            // floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            sortable: false,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            valueGetter: (params) => {
                if(!params.data) {
                    return;
                }
                const yearObj: YearObject = this._getYearObj(params, year);
                return  yearObj && this._isValidNumber(yearObj.rateChange)  ? (`${new Decimal(yearObj.rateChange).times(100).toFixed(2)  }%`) : '';
            },
            cellClass: params => this._getCellClass(params, year)
        };
    }

    private _getTaxAuthorityRateByYear(params, year: number): string {
        if(!params.data) {
            return;
        }

        const yearObj: YearObject = this._getYearObj(params, year);
        return yearObj && this._isValidNumber(yearObj.rate) ? new Decimal(yearObj.rate).times(100).toFixed(6) : '';
    }


    private _getYearObj(params, year: number): YearObject {
        return this.selectedGridSourceOption == 'areas'
            ? _.find(params.data.yearRateSummaryList as YearRateSummary[], {year: year})
            : _.find(params.data.details as TaxAuthorityDetail[], {taxYear: year});
    }

    private _getCellClass(params, year: number): string {
        if(!params.data) {
            return;
        }

        const baseClass = 'text-align-right';
        const estimatedClass = 'tax-rate-estimated';
        const pendingClass = 'pending';

        if(this.selectedGridSourceOption == 'areas') {
            const yearObj: YearRateSummary = _.find(params.data.yearRateSummaryList as YearRateSummary[], {year: year});
            return yearObj && !yearObj.isActual ? `${baseClass} ${estimatedClass}` : baseClass;
        } else {
            const yearObj: TaxAuthorityDetail = _.find(params.data.details as TaxAuthorityDetail[], {taxYear: year});

            if(!yearObj) {
                return baseClass;
            }

            switch(yearObj.taxAuthorityStatusId) {
                case TaxAuthorityStatuses.Estimated:
                    return `${baseClass} ${estimatedClass}`;
                case TaxAuthorityStatuses.Pending:
                    return `${baseClass} ${pendingClass}`;
                default:
                    return baseClass;
            }
        }
    }

    private _getTaxAreaRateByYear(taxRateArea: TaxRateArea, year: number): string {
        if(!taxRateArea) {
            return '';
        }

        const yearRateSummary: YearRateSummary = _.find(taxRateArea.yearRateSummaryList, {year: year});

        if (yearRateSummary && yearRateSummary.rate == 0)
            return new Decimal(0).toFixed(6);

        return yearRateSummary ? new Decimal(yearRateSummary.rate).times(100).toFixed(6) : null;
    }

    private _getAuthoritiesByTaxRateArea(taxRateArea: TaxRateArea, year: number): {name: string, rate: number | string}[] {
        if(!taxRateArea) {
            return [{name: '', rate: ''}];
        }
        const taxAuthorities: TaxAuthority[] = this.taxRateGridService.allTaxAuthorities;

        return _.chain(taxRateArea.details)
            .map((detail: TaxRateAreaDetail) =>  {
                const taxAuthority: TaxAuthority = _.find(taxAuthorities, {taxAuthorityId: detail.taxAuthorityId});

                if(taxAuthority) {
                    const detail: TaxAuthorityDetail = _.find(taxAuthority.details, {taxYear: year});

                    const includeThisTaxAuthorityForThisYear = _.some(taxAuthority.taxRateAreaTaxAuthorityDetails, (tratad: TaxRateAreaTaxAuthorityDetail) => {
                        return  (tratad.taxRateAreaId == taxRateArea.taxRateAreaId)
                            && (!tratad.yearBegin || tratad.yearBegin <= year)
                            && (!tratad.yearEnd || tratad.yearEnd >= year);
                    });

                    if(includeThisTaxAuthorityForThisYear && detail) {
                        return {
                            name: taxAuthority.name,
                            rate: detail.rate
                        };
                    }
                }
            })
            .uniqBy('name')
            .value();
    }

    launchTaxAuthorityModal(taxAuthorityId: number, launchInEditMode: boolean): void {
        const initialState = { taxAuthorityId, launchInEditMode };
        const modalRef = this._modalService.show(TaxAuthorityComponent, {initialState, class: 'tax-rate-modal', ignoreBackdropClick: true});

        modalRef.content.onClose = (savedTaxAuthority: TaxAuthority) => {
            this.loadPanel(savedTaxAuthority);
            this.selectedGridSourceOption = 'authorities';
            
        };
    }

    launchTaxRateAreaModal(taxRateAreaId: number, editMode: boolean): void {
        const initialState = { taxRateAreaId, editMode };
        const modalRef = this._modalService.show(TaxRateAreaComponent, {initialState, class: 'tax-rate-modal', ignoreBackdropClick: true});

        modalRef.content.onClose = (savedTaxRateArea: TaxRateArea) => {
            this.loadPanel(savedTaxRateArea);
            this.selectedGridSourceOption = 'areas';
        };
    }

    protected abstract onAgGridReady(event: GridReadyEvent): void;
    protected abstract loadPanel(entity?: TaxRateArea | TaxAuthority): void;
    protected abstract getSharedColumns(): ColDef[];
    protected abstract getAuthorityColumns(): ColDef[];
    protected abstract getAreaColumns(): ColDef[];

}
