import { Component, OnInit, OnDestroy } from '@angular/core';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnService } from '../../../return.service';
import { ReturnSettingsService } from '.././returnSettings.service';
import { BusyIndicatorService } from '../../../../../Busy-Indicator';
import { WeissmanMutexService, IMutexServiceHandler } from '../../../../WeissmanMutexService';
import { Subscription, BehaviorSubject, lastValueFrom } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ReturnAssetsService } from '../../Assets/returnAssets.service';
import * as _ from 'lodash';

@Component({
    selector: 'return-settings-general',
    templateUrl: './returnSettingsGeneral.component.html',
    styleUrls: ['./returnSettingsGeneral.component.scss']
})
export class ReturnSettingsGeneralComponent implements OnInit, OnDestroy, IReturnPartComponent, IMutexServiceHandler {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnSettingsService: ReturnSettingsService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _returnAssetService: ReturnAssetsService
    ) { }

    hasChanges: boolean = false;
    editMode: boolean = false;
    isInitialized: boolean = false;
    reportNBVInsteadOfFactoredCostControl: UntypedFormControl = new UntypedFormControl(false);
    returnCostReportingDisplayControl: UntypedFormControl = new UntypedFormControl(false);

    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;
    private _generalSettings: Compliance.FilingBatchGeneralReturnSettings;
    private _reportNBVInsteadOfFactoredCost: boolean;
    private _returnCostReportingDisplayId: number;


    get canEdit(): boolean {
        return !this._returnService.isReturnInReadOnlyMode && this._returnService.canEditCompany;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._returnService.editGroup);
    }

    ngOnInit(): void {
        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._returnService.editGroup);
                this.reportNBVInsteadOfFactoredCostControl.disable();
                this.returnCostReportingDisplayControl.disable();
            } else{
                this.reportNBVInsteadOfFactoredCostControl.enable();
                this.returnCostReportingDisplayControl.enable();
            }
        });

        this._returnSettingsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnSettingsService.unsubscribeFromServiceActivationCycle(this);
        this._editModeSub.unsubscribe();
        this._mutexService.release(this, this._returnService.editGroup);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        if (!this.isInitialized) {
            const settings = {
                reportNBVInsteadOfFactoredCost: this._returnService.filingBatch.reportNBVInsteadOfFactoredCost,
                returnCostReportingDisplayId: this._returnService.filingBatch.returnCostReportingDisplayId,
                rowVersion: this._returnService.filingBatch.rowVersion
            } as Compliance.FilingBatchGeneralReturnSettings;

            this._generalSettings = settings;
            this._setSettings(this._generalSettings.reportNBVInsteadOfFactoredCost, this._generalSettings.returnCostReportingDisplayId);
            this.isInitialized = true;
        }
    }

    onReturnPartServiceDeactivated(): void { }

    onReturnSettingsChanged(value: boolean) {
        this.hasChanges = value;
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._mutexService.acquire(this, this._returnService.editGroup);
        this._editModeSubject.next(true);
    }

    async save(): Promise<void> {
        if (!this.hasChanges) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            const updateModel = {
                reportNBVInsteadOfFactoredCost: this._reportNBVInsteadOfFactoredCost,
                returnCostReportingDisplayId: this._returnCostReportingDisplayId,
                rowVersion: this._generalSettings.rowVersion
            } as Compliance.FilingBatchGeneralReturnSettings;

            this._generalSettings = await lastValueFrom(this._returnService.updateFilingBatchGeneralReturnSettings(updateModel));
            this._editModeSubject.next(false);
            this._mutexService.release(this, this._returnService.editGroup);

            await this._returnAssetService.notifyAssetDetailsUpdated(null, false);
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    cancel(): void {
        this._setSettings(this._generalSettings.reportNBVInsteadOfFactoredCost, this._generalSettings.returnCostReportingDisplayId);
        this._editModeSubject.next(false);
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    reportNBVInsteadOfFactoredCostChanged(value: boolean): void {
        this._reportNBVInsteadOfFactoredCost = value;
        this.hasChanges = true;
    }

    returnCostReportingDisplayChanged(value: number): void{
        this._returnCostReportingDisplayId = value;
        this.hasChanges = true;
    }

    get showReportNBVInsteadOfFactoredCost(): boolean {
        return (!!_.find(this._returnService.getAssociatedReturnFormRevisions(), i => i.supportsNBVReporting) ||
            this._returnService.isReturnInReadOnlyMode && this._returnService.filingBatch.reportNBVInsteadOfFactoredCost);

    }

    get showReturnCostReportingDisplay(): boolean {
        return (!!_.find(this._returnService.getAssociatedReturnFormRevisions(), i => i.supportsEstFMVReporting) ||
            this._returnService.isReturnInReadOnlyMode &&
            this._returnService.filingBatch.returnCostReportingDisplayId !== Compliance.ReturnCostReportingDisplayEnum.Both);
    }

    private _setSettings(reportNBVInsteadOfFactoredCost: boolean, returnCostReportingDisplayId: number): void{
        this._reportNBVInsteadOfFactoredCost = reportNBVInsteadOfFactoredCost;
        this._returnCostReportingDisplayId = returnCostReportingDisplayId == 0 ? Compliance.ReturnCostReportingDisplayEnum.Both : returnCostReportingDisplayId;
        this.reportNBVInsteadOfFactoredCostControl.setValue(reportNBVInsteadOfFactoredCost);
        this.returnCostReportingDisplayControl.setValue(returnCostReportingDisplayId);
    }
}
