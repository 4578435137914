<div class="payment-batch-details ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="pageTitle"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Payment Batch Details
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <strong>Status:</strong>
                    <a style="cursor:pointer; margin-left: 10px;" (click)="launchTaskModal($event)">{{workflowStatus?.taskStatusName}}</a>
                </div>

                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         position="bottom">
                        <button type="button"
                                *ngIf="workflowStatus"
                                (click)="advanceTask()"
                                [disabled]="!workflowStatus?.canAdvanceTask || rowCount === 0"
                                class="flat-button small-button primary-button">
                                {{workflowStatus?.taskAdvanceName}}
                        </button>
                    </div>
                    <div class="action-button-wrapper"
                         position="bottom">
                        <button type="button"
                                *ngIf="workflowStatus && isBillPay"
                                (click)="launchExceptionModal()"
                                [disabled]="!workflowStatus?.canReassignTask && !workflowStatus?.canRescindTask"
                                class="flat-button small-button warning-button">
                                Exception
                        </button>
                        <button type="button"
                                *ngIf="isTransmittal && workflowStatus?.isSeriesCompleted"
                                (click)="recallBatch()"
                                class="flat-button small-button warning-button">
                                Recall
                        </button>
                    </div>
               </div>
                <div class="ws-section__header__actions__item">
                    <entity-attachments  [(hasAttachments)]="hasAttachments"
                        buttonClass="flat-button primary-button small-button"
                        [modalData]="attachmentModalData"
                        helpTooltip
                        helpContentId="app.attachments"
                        position="bottom">
                    </entity-attachments>
                    <entity-comments 
                        [(hasComments)]="hasComments"
                        buttonClass="flat-button primary-button small-button"
                        [modalData]="commentModalData"
                        helpTooltip
                        helpContentId="app.comments"
                        position="bottom">
                    </entity-comments>
                </div>
                <div class="ws-section__header__actions__item">
                    <button (click)="goBack()" class="small-button flat-button primary-button">
                        Go Back
                    </button>
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="app.refresh"
                         position="bottom">
                        <button type="button" class="flat-button small-button secondary-button" (click)="refreshPage({payments: true, packages: true, status: true}, true)">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-section" *ngIf="paymentBatch">
        <div class="row payment-detail-header">
            <div class="col-md-4">
                <table>
                    <tr>
                        <td>Top Level Company:</td>
                        <td>{{paymentBatch.topLevelCompanyName}}</td>
                    </tr>
                    <tr>
                        <td>Company:</td>
                        <td>{{paymentBatch.company}}</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td>{{paymentBatch.description}}</td>
                    </tr>
                    <tr>
                        <td>Batch #:</td>
                        <td>{{paymentBatch.batchNo}}</td>
                    </tr>
                    <tr>
                        <td>Batch Type:</td>
                        <td>{{paymentBatch.batchTypeName}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <table>
                    <tr>
                        <td>Total Payments:</td>
                        <td>{{paymentBatch.paymentCount}}</td>
                    </tr>
                    <tr>
                        <td>Total Payment Amount:</td>
                        <td>{{paymentBatch.paymentAmount | currency}}</td>
                    </tr>
                    <tr *ngIf="!isTransmittal">
                        <td style="vertical-align: middle;">Total Funded Amount:</td>
                        <td style="display: flex; gap: 10px; align-items: center;">
                            <div>
                                <span *ngIf="!editingFundedAmount">{{paymentBatch.fundedAmount | currency}}</span>
                                <ws-currency-input *ngIf="editingFundedAmount" 
                                    [hasMargin]="false" 
                                    [(ngModel)]="paymentBatch.fundedAmount" 
                                    [disabled]="savingFundedAmount">
                                </ws-currency-input>
                            </div>
                            <div>
                                <button *ngIf="!editingFundedAmount" 
                                    class="flat-button icon-button" 
                                    style="margin: 0;" 
                                    [disabled]="!workflowStatus?.canModifyFundedAmount"
                                    (click)="editFundedAmount()">
                                        <i class="fa fa-pencil"></i>
                                </button>
                                <div *ngIf="editingFundedAmount" class="ws-button-row">
                                    <button class="flat-button icon-button" 
                                        style="margin: 0;" 
                                        (click)="saveFundedAmount()" 
                                        [disabled]="savingFundedAmount">
                                            <i *ngIf="!savingFundedAmount" class="fa fa-save"></i>
                                            <i *ngIf="savingFundedAmount" class="fa fa-spin fa-spinner"></i>
                                    </button>
                                    <button class="flat-button icon-button warning-button" 
                                        style="margin: 0;" 
                                        (click)="cancelEditFundedAmount()" 
                                        [disabled]="savingFundedAmount" 
                                        (click)="editingFundedAmount = true">
                                            <i class="fa fa-close"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <table>
                    <tr>
                        <td>Earliest Due Date:</td>
                        <td>
                            <div *ngIf="paymentBatch.earliestDueDate">
                                {{paymentBatch.earliestDueDate | weissmanDateFormat}}
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!isTransmittal">
                        <td>Funding Due Date:</td>
                        <td>
                            <div *ngIf="paymentBatch.fundingDueDate">
                                {{paymentBatch.fundingDueDate | weissmanDateFormat}}
                            </div>
                        </td>
                    </tr>
                </table>
                <div>
                    <button class="flat-button icon-button" 
                    *ngIf="paymentDetailsTabs.tabs[0].active"
                    (click)="launchAddPaymentsModal()" 
                    [disabled]="!workflowStatus?.canAddDeletePayments"
                    helpTooltip helpContentId="payment-batch-details.add-payments-button">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
                <div style="color: red"
                    *ngIf="paymentBatch.isCheckFeedDirty">
                    A change was made to Payments of the Batch that may have invalidated the AP and Check Feeds. Regeneration of the feeds is recommended.
                </div>
                <div style="color: red"
                    *ngIf="paymentBatch.isAPFeedDirty && !paymentBatch.isCheckFeedDirty">
                    A change was made to Payments of the Batch that may have invalidated the AP Feed. Regeneration of the feed is recommended.
                </div>
           </div>
        </div>
    </div>

    <tabset #paymentDetailsTabs>
        <tab heading="Payments" [disabled]="settingsEditing">
            <payment-batch-payments-grid #paymentBatchDetailsGrid 
                (gridDataChanged)="loadPaymentBatch()" 
                [workflowStatus]="workflowStatus"
                (rowCount)="rowCount = $event"
                [paymentBatch]="paymentBatch">
            </payment-batch-payments-grid>
        </tab>
        <tab heading="Packages" #packagesTab="tab" [disabled]="settingsEditing">
            <payment-batch-packages #paymentBatchPackages
                *ngIf="paymentBatch && packagesTab.active" 
                [workflowStatus]="workflowStatus"
                [paymentBatch]="paymentBatch">
            </payment-batch-packages>
        </tab>
        <tab heading="Output" *ngIf="paymentBatch && isBillPay" [disabled]="!paymentBatch.hasAPFeed || settingsEditing">
            <payment-batch-output [paymentBatch]="paymentBatch"></payment-batch-output>
        </tab>
        <tab heading="Settings" *ngIf="paymentBatch" >
            <payment-batch-settings *ngIf="workflowStatus" [paymentBatch]="paymentBatch" [workflowStatus]="workflowStatus" (editChange)="settingsEditing = $event"></payment-batch-settings>
        </tab>
    </tabset>
    
</div>
